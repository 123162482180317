import { Subject, filter } from "rxjs";
import type { PageVisibility } from "../common/pageVisibility";
import { pageVisibilityFactory } from "../common/pageVisibility";
import { Injectable } from "../dependency-injection/Injectable";
import type { ServerEvent } from "../generated-proto/blizzard/cameraKitEvents";
import { ServerEventBatch } from "../generated-proto/blizzard/cameraKitEvents";
import type {
    OperationalMetric,
    OperationalMetricsBundle,
} from "../generated-proto/pb_schema/camera_kit/v3/operational_metrics";
import type { SetBusinessEventsRequest } from "../generated-proto/pb_schema/camera_kit/v3/service";
import { MetricsDefinition } from "../generated-proto/pb_schema/camera_kit/v3/service";
import type { Handler, RequestMetadata } from "../handlers/HandlerChainBuilder";
import { HandlerChainBuilder } from "../handlers/HandlerChainBuilder";
import { createBatchingHandler } from "../handlers/batchingHandler";
import { createMappingHandler } from "../handlers/mappingHandler";
import { createRateLimitingHandler } from "../handlers/rateLimitingHandler";
import { isCountMetric } from "../metrics/operational/Count";
import type { Metric } from "../metrics/operational/Metric";
import type { TsProtoServiceClient } from "./createTsProtoClient";
import { createTsProtoClient } from "./createTsProtoClient";
import type { GrpcHandler } from "./grpcHandler";
import { grpcHandlerFactory } from "./grpcHandler";

// Send at most one metric (operational or business) per second.
const METRIC_REQUEST_RATE_LIMIT_MS = 1000;

// It is rather cumbersome to check the actual final size of a batch, but we can easily limit the number of events we
// include in each batch -- looking at historical data, typical events average ~1.3kb per event. But there are some
// events (like CAMERA_KIT_EXCEPTION, which includes a stack trace) that can be much larger.
//
// To prevent us running over the 64kibibyte limit imposed by browsers on `keep-alive` requests, we'll set quite a low
// limit to ensure we don't lose events which are larger in size than we expect.
const BUSINESS_EVENT_BATCH_MAX_SIZE = 10;
const BUSINESS_EVENT_BATCH_MAX_AGE_MS = 5000;

// These values are (currently) arbitrarily selected.
// TODO: Once we have gathered a sufficient quantity of metrics data, we should tune these numbers to ensure we're
// operating with the right cost vs. alarming SLA vs. IDB storage size tradeoffs.
const METRIC_BATCH_MAX_SIZE = 100;
const METRIC_BATCH_MAX_AGE_MS = 5000;

export class MetricsClient {
    private readonly businessEventsHandler: Handler<ServerEvent, void, RequestInit | undefined>;
    private readonly operationalMetricsHandler: Handler<OperationalMetric, void, RequestInit | undefined>;

    constructor(
        private readonly grpcClient: TsProtoServiceClient<typeof MetricsDefinition>,
        pageVisibility: PageVisibility | false
    ) {
        // Both business events and operational metrics will share a rate limit.
        const rateLimitingHandler = createRateLimitingHandler<any, any, RequestMetadata>(
            METRIC_REQUEST_RATE_LIMIT_MS,
            pageVisibility
        );

        // The business events handler has to do some manual encoding, because the CameraKit service's backend expects
        // an `Any` type inside of SetBusinessEventsRequest -- even though this `Any` type is always `ServerEventBatch`.
        // eslint-disable-next-line max-len
        // See: https://github.sc-corp.net/Snapchat/pb_schema/blob/4bc5ec98243c472c848cccc577d8cfd21317af51/proto/camera_kit/v3/service.proto#L94
        this.businessEventsHandler = new HandlerChainBuilder(async (request: SetBusinessEventsRequest) => {
            await this.grpcClient.setBusinessEvents(request);
        })
            .map(rateLimitingHandler)
            .map(
                createMappingHandler((serverEvents: ServerEvent[]) => {
                    const batch: ServerEventBatch = ServerEventBatch.fromPartial({ serverEvents });
                    const request: SetBusinessEventsRequest = {
                        batchEvents: {
                            typeUrl: "com.snapchat.analytics.blizzard.ServerEventBatch",
                            value: ServerEventBatch.encode(batch).finish(),
                        },
                    };
                    return request;
                }, pageVisibility)
            )
            .map(
                createBatchingHandler({
                    batchReduce: (previous: ServerEvent[] | undefined, event: ServerEvent) => {
                        const batch = previous ?? [];
                        batch.push(event);
                        return batch;
                    },
                    isBatchComplete: (batch) => batch.length >= BUSINESS_EVENT_BATCH_MAX_SIZE,
                    maxBatchAge: BUSINESS_EVENT_BATCH_MAX_AGE_MS,
                    pageVisibility,
                })
            ).handler;

        // The operational metrics handler is slightly simpler, but it has more interesting batching logic (e.g. we can
        // sum up Count metrics, for example, rather than sending multiple single-count metrics objects).
        this.operationalMetricsHandler = new HandlerChainBuilder(async (metrics: OperationalMetricsBundle) => {
            await this.grpcClient.setOperationalMetrics({ metrics });
        })
            .map(rateLimitingHandler)
            .map(
                createBatchingHandler({
                    // The batching logic here is very simple – it could be improved by e.g.
                    // computing statistics to reduce overall data sent, etc. Right now this is
                    // premature optimization, but could become a good idea in the future.
                    batchReduce: (previous: OperationalMetricsBundle | undefined, metric: OperationalMetric) => {
                        const batch: OperationalMetricsBundle = { metrics: previous?.metrics ?? [] };

                        // For "count" metrics, it's straightforward to merge them into
                        // a single metric with the same name.
                        if (isCountMetric(metric)) {
                            const priorCount = batch.metrics.find((m) => {
                                return isCountMetric(m) && m.name === metric.name;
                            });
                            if (priorCount && isCountMetric(priorCount)) {
                                priorCount.metric.count = `${
                                    Number(priorCount.metric.count) + Number(metric.metric.count)
                                }`;
                                return batch;
                            }
                        }

                        // For all other cases, we'll just add the metric separately to the batch.
                        batch.metrics.push(metric);
                        return batch;
                    },
                    isBatchComplete: (bundle) => bundle.metrics.length >= METRIC_BATCH_MAX_SIZE,
                    maxBatchAge: METRIC_BATCH_MAX_AGE_MS,
                    pageVisibility,
                })
            ).handler;
    }

    async setBusinessEvents(event: ServerEvent): Promise<void> {
        await this.businessEventsHandler(event);
    }

    async setOperationalMetrics(metric: Metric): Promise<void> {
        await Promise.all(
            metric.toOperationalMetric().map((metric) => {
                return this.operationalMetricsHandler(metric);
            })
        );
    }
}

const validExternalMetrics = /^push2web_/;

/** @internal */
export const externalMetricsSubjectFactory = Injectable("externalMetricsSubject", () => new Subject<Metric>());

export const metricsClientFactory = Injectable(
    "metricsClient",
    [externalMetricsSubjectFactory.token, grpcHandlerFactory.token, pageVisibilityFactory.token] as const,
    (externalMetricsSubjectFactory: Subject<Metric>, grpcHandler: GrpcHandler, pageVisibility: PageVisibility) => {
        const metrics = new MetricsClient(createTsProtoClient(MetricsDefinition, grpcHandler), pageVisibility);
        externalMetricsSubjectFactory.pipe(filter((metric) => validExternalMetrics.test(metric.name))).subscribe({
            next: (metric) => {
                metrics.setOperationalMetrics(metric);
            },
        });
        return metrics;
    }
);
