/**
 * Copy only those properties of an object which are not undefined.
 *
 * This can be useful when using `Object.assign(foo, bar)` or `{ ...foo, ...bar }` to copy values from one object to
 * another. There's a (rather annoying) difference between a property not existing in an object and that property
 * existing but having an undefined value. When copying values using either of the methods above, it's generally
 * expected that undefined properties won't overwrite defined properties. But that's not the behavior – this helper
 * function is needed to ensure undefined properties in `bar` don't clobber corresponding properties in `foo`.
 *
 * @param obj Any object, possibly with properties whose values are undefined.
 * @returns A copy of the input object, without keys whose values were undefined.
 */
export function copyDefinedProperties<T extends {}>(obj: T): T {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined)) as T;
}
