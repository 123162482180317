import Long from "long";
import _m0 from "protobufjs/minimal";
import { Snappable } from "./snappable";
import { Lures } from "./lures";
import { UserData } from "./user_data";
import { PersistentStore } from "./persistent_store";
import { LaunchParams } from "./launch_params";
export const protobufPackage = "snapchat.lenses";
export interface LaunchData {
    snappable: Snappable | undefined;
    lures: Lures | undefined;
    userId: string;
    entryPoint: LaunchData_EntryPoint;
    userData: UserData | undefined;
    persistentStore: PersistentStore | undefined;
    launchParams: LaunchParams | undefined;
    apiDescriptors: LaunchData_ApiDescriptor[];
    renderConfig: LaunchData_RenderConfig;
    activationSource: LaunchData_ActivationSource;
}
export enum LaunchData_EntryPoint {
    LIVE_CAMERA = "LIVE_CAMERA",
    STORY_REPLY = "STORY_REPLY",
    CHAT_REPLY = "CHAT_REPLY",
    RESTART = "RESTART",
    PREVIEW_CANCEL = "PREVIEW_CANCEL",
    MAP = "MAP",
    BITMOJI_STICKERS = "BITMOJI_STICKERS",
    POST_CAPTURE_PREVIEW = "POST_CAPTURE_PREVIEW",
    POST_CAPTURE_TRANSCODING = "POST_CAPTURE_TRANSCODING",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function launchData_EntryPointFromJSON(object: any): LaunchData_EntryPoint {
    switch (object) {
        case 0:
        case "LIVE_CAMERA":
            return LaunchData_EntryPoint.LIVE_CAMERA;
        case 1:
        case "STORY_REPLY":
            return LaunchData_EntryPoint.STORY_REPLY;
        case 2:
        case "CHAT_REPLY":
            return LaunchData_EntryPoint.CHAT_REPLY;
        case 3:
        case "RESTART":
            return LaunchData_EntryPoint.RESTART;
        case 4:
        case "PREVIEW_CANCEL":
            return LaunchData_EntryPoint.PREVIEW_CANCEL;
        case 5:
        case "MAP":
            return LaunchData_EntryPoint.MAP;
        case 6:
        case "BITMOJI_STICKERS":
            return LaunchData_EntryPoint.BITMOJI_STICKERS;
        case 7:
        case "POST_CAPTURE_PREVIEW":
            return LaunchData_EntryPoint.POST_CAPTURE_PREVIEW;
        case 8:
        case "POST_CAPTURE_TRANSCODING":
            return LaunchData_EntryPoint.POST_CAPTURE_TRANSCODING;
        case -1:
        case "UNRECOGNIZED":
        default:
            return LaunchData_EntryPoint.UNRECOGNIZED;
    }
}
export function launchData_EntryPointToNumber(object: LaunchData_EntryPoint): number {
    switch (object) {
        case LaunchData_EntryPoint.LIVE_CAMERA:
            return 0;
        case LaunchData_EntryPoint.STORY_REPLY:
            return 1;
        case LaunchData_EntryPoint.CHAT_REPLY:
            return 2;
        case LaunchData_EntryPoint.RESTART:
            return 3;
        case LaunchData_EntryPoint.PREVIEW_CANCEL:
            return 4;
        case LaunchData_EntryPoint.MAP:
            return 5;
        case LaunchData_EntryPoint.BITMOJI_STICKERS:
            return 6;
        case LaunchData_EntryPoint.POST_CAPTURE_PREVIEW:
            return 7;
        case LaunchData_EntryPoint.POST_CAPTURE_TRANSCODING:
            return 8;
        default:
            return 0;
    }
}
export enum LaunchData_ApiDescriptor {
    NONE = "NONE",
    EXPERIMENTAL = "EXPERIMENTAL",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function launchData_ApiDescriptorFromJSON(object: any): LaunchData_ApiDescriptor {
    switch (object) {
        case 0:
        case "NONE":
            return LaunchData_ApiDescriptor.NONE;
        case 1:
        case "EXPERIMENTAL":
            return LaunchData_ApiDescriptor.EXPERIMENTAL;
        case -1:
        case "UNRECOGNIZED":
        default:
            return LaunchData_ApiDescriptor.UNRECOGNIZED;
    }
}
export function launchData_ApiDescriptorToNumber(object: LaunchData_ApiDescriptor): number {
    switch (object) {
        case LaunchData_ApiDescriptor.NONE:
            return 0;
        case LaunchData_ApiDescriptor.EXPERIMENTAL:
            return 1;
        default:
            return 0;
    }
}
export enum LaunchData_RenderConfig {
    DEFAULT = "DEFAULT",
    REPLAY_STREAM = "REPLAY_STREAM",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function launchData_RenderConfigFromJSON(object: any): LaunchData_RenderConfig {
    switch (object) {
        case 0:
        case "DEFAULT":
            return LaunchData_RenderConfig.DEFAULT;
        case 1:
        case "REPLAY_STREAM":
            return LaunchData_RenderConfig.REPLAY_STREAM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return LaunchData_RenderConfig.UNRECOGNIZED;
    }
}
export function launchData_RenderConfigToNumber(object: LaunchData_RenderConfig): number {
    switch (object) {
        case LaunchData_RenderConfig.DEFAULT:
            return 0;
        case LaunchData_RenderConfig.REPLAY_STREAM:
            return 1;
        default:
            return 0;
    }
}
export enum LaunchData_ActivationSource {
    ACTIVATION_SOURCE_UNSET = "ACTIVATION_SOURCE_UNSET",
    ACTIVATION_SOURCE_DEFAULT = "ACTIVATION_SOURCE_DEFAULT",
    CREATIVE = "CREATIVE",
    SCAN = "SCAN",
    SCAN_HISTORY = "SCAN_HISTORY",
    CHAT_FEED_PSA = "CHAT_FEED_PSA",
    GROWTH_NOTIFICATION = "GROWTH_NOTIFICATION",
    MASS_SNAP = "MASS_SNAP",
    SMART_CTA = "SMART_CTA",
    MASS_CHAT = "MASS_CHAT",
    BILLBOARD_FHP = "BILLBOARD_FHP",
    LENS_ACTIVITY_CENTER = "LENS_ACTIVITY_CENTER",
    AR_BAR = "AR_BAR",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function launchData_ActivationSourceFromJSON(object: any): LaunchData_ActivationSource {
    switch (object) {
        case 0:
        case "ACTIVATION_SOURCE_UNSET":
            return LaunchData_ActivationSource.ACTIVATION_SOURCE_UNSET;
        case 1:
        case "ACTIVATION_SOURCE_DEFAULT":
            return LaunchData_ActivationSource.ACTIVATION_SOURCE_DEFAULT;
        case 2:
        case "CREATIVE":
            return LaunchData_ActivationSource.CREATIVE;
        case 3:
        case "SCAN":
            return LaunchData_ActivationSource.SCAN;
        case 4:
        case "SCAN_HISTORY":
            return LaunchData_ActivationSource.SCAN_HISTORY;
        case 5:
        case "CHAT_FEED_PSA":
            return LaunchData_ActivationSource.CHAT_FEED_PSA;
        case 6:
        case "GROWTH_NOTIFICATION":
            return LaunchData_ActivationSource.GROWTH_NOTIFICATION;
        case 7:
        case "MASS_SNAP":
            return LaunchData_ActivationSource.MASS_SNAP;
        case 8:
        case "SMART_CTA":
            return LaunchData_ActivationSource.SMART_CTA;
        case 9:
        case "MASS_CHAT":
            return LaunchData_ActivationSource.MASS_CHAT;
        case 10:
        case "BILLBOARD_FHP":
            return LaunchData_ActivationSource.BILLBOARD_FHP;
        case 11:
        case "LENS_ACTIVITY_CENTER":
            return LaunchData_ActivationSource.LENS_ACTIVITY_CENTER;
        case 12:
        case "AR_BAR":
            return LaunchData_ActivationSource.AR_BAR;
        case -1:
        case "UNRECOGNIZED":
        default:
            return LaunchData_ActivationSource.UNRECOGNIZED;
    }
}
export function launchData_ActivationSourceToNumber(object: LaunchData_ActivationSource): number {
    switch (object) {
        case LaunchData_ActivationSource.ACTIVATION_SOURCE_UNSET:
            return 0;
        case LaunchData_ActivationSource.ACTIVATION_SOURCE_DEFAULT:
            return 1;
        case LaunchData_ActivationSource.CREATIVE:
            return 2;
        case LaunchData_ActivationSource.SCAN:
            return 3;
        case LaunchData_ActivationSource.SCAN_HISTORY:
            return 4;
        case LaunchData_ActivationSource.CHAT_FEED_PSA:
            return 5;
        case LaunchData_ActivationSource.GROWTH_NOTIFICATION:
            return 6;
        case LaunchData_ActivationSource.MASS_SNAP:
            return 7;
        case LaunchData_ActivationSource.SMART_CTA:
            return 8;
        case LaunchData_ActivationSource.MASS_CHAT:
            return 9;
        case LaunchData_ActivationSource.BILLBOARD_FHP:
            return 10;
        case LaunchData_ActivationSource.LENS_ACTIVITY_CENTER:
            return 11;
        case LaunchData_ActivationSource.AR_BAR:
            return 12;
        default:
            return 0;
    }
}
function createBaseLaunchData(): LaunchData {
    return {
        snappable: undefined,
        lures: undefined,
        userId: "",
        entryPoint: LaunchData_EntryPoint.LIVE_CAMERA,
        userData: undefined,
        persistentStore: undefined,
        launchParams: undefined,
        apiDescriptors: [],
        renderConfig: LaunchData_RenderConfig.DEFAULT,
        activationSource: LaunchData_ActivationSource.ACTIVATION_SOURCE_UNSET,
    };
}
export const LaunchData = {
    encode(message: LaunchData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.snappable !== undefined) {
            Snappable.encode(message.snappable, writer.uint32(10).fork()).ldelim();
        }
        if (message.lures !== undefined) {
            Lures.encode(message.lures, writer.uint32(18).fork()).ldelim();
        }
        if (message.userId !== "") {
            writer.uint32(26).string(message.userId);
        }
        if (message.entryPoint !== LaunchData_EntryPoint.LIVE_CAMERA) {
            writer.uint32(32).int32(launchData_EntryPointToNumber(message.entryPoint));
        }
        if (message.userData !== undefined) {
            UserData.encode(message.userData, writer.uint32(42).fork()).ldelim();
        }
        if (message.persistentStore !== undefined) {
            PersistentStore.encode(message.persistentStore, writer.uint32(50).fork()).ldelim();
        }
        if (message.launchParams !== undefined) {
            LaunchParams.encode(message.launchParams, writer.uint32(58).fork()).ldelim();
        }
        writer.uint32(66).fork();
        for (const v of message.apiDescriptors) {
            writer.int32(launchData_ApiDescriptorToNumber(v));
        }
        writer.ldelim();
        if (message.renderConfig !== LaunchData_RenderConfig.DEFAULT) {
            writer.uint32(72).int32(launchData_RenderConfigToNumber(message.renderConfig));
        }
        if (message.activationSource !== LaunchData_ActivationSource.ACTIVATION_SOURCE_UNSET) {
            writer.uint32(80).int32(launchData_ActivationSourceToNumber(message.activationSource));
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): LaunchData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLaunchData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.snappable = Snappable.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.lures = Lures.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.userId = reader.string();
                    break;
                case 4:
                    message.entryPoint = launchData_EntryPointFromJSON(reader.int32());
                    break;
                case 5:
                    message.userData = UserData.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.persistentStore = PersistentStore.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.launchParams = LaunchParams.decode(reader, reader.uint32());
                    break;
                case 8:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.apiDescriptors.push(launchData_ApiDescriptorFromJSON(reader.int32()));
                        }
                    }
                    else {
                        message.apiDescriptors.push(launchData_ApiDescriptorFromJSON(reader.int32()));
                    }
                    break;
                case 9:
                    message.renderConfig = launchData_RenderConfigFromJSON(reader.int32());
                    break;
                case 10:
                    message.activationSource = launchData_ActivationSourceFromJSON(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<LaunchData>, I>>(object: I): LaunchData {
        const message = createBaseLaunchData();
        message.snappable =
            object.snappable !== undefined && object.snappable !== null ? Snappable.fromPartial(object.snappable) : undefined;
        message.lures = object.lures !== undefined && object.lures !== null ? Lures.fromPartial(object.lures) : undefined;
        message.userId = object.userId ?? "";
        message.entryPoint = object.entryPoint ?? LaunchData_EntryPoint.LIVE_CAMERA;
        message.userData =
            object.userData !== undefined && object.userData !== null ? UserData.fromPartial(object.userData) : undefined;
        message.persistentStore =
            object.persistentStore !== undefined && object.persistentStore !== null
                ? PersistentStore.fromPartial(object.persistentStore)
                : undefined;
        message.launchParams =
            object.launchParams !== undefined && object.launchParams !== null
                ? LaunchParams.fromPartial(object.launchParams)
                : undefined;
        message.apiDescriptors = object.apiDescriptors?.map((e) => e) || [];
        message.renderConfig = object.renderConfig ?? LaunchData_RenderConfig.DEFAULT;
        message.activationSource = object.activationSource ?? LaunchData_ActivationSource.ACTIVATION_SOURCE_UNSET;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
