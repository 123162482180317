import { catchError, firstValueFrom, map, of } from "rxjs";
import { Writer } from "protobufjs";
import { Lens } from "../generated-proto/pb_schema/camera_kit/v3/lens";
import type { LensesClient } from "../clients/lensesClient";
import { lensesClientFactory } from "../clients/lensesClient";
import { Injectable } from "../dependency-injection/Injectable";
import type { RemoteConfiguration } from "../remote-configuration/remoteConfiguration";
import { remoteConfigurationFactory } from "../remote-configuration/remoteConfiguration";
import type { ConfigResult } from "../generated-proto/pb_schema/cdp/cof/config_result";
import { getLogger } from "../logger/logger";
import type { LensSource } from "./LensSource";
import { lensSourcesFactory } from "./LensSource";
import { watermarksLensGroup } from "./fetchWatermarkLens";

const logger = getLogger("CameraKitLensSource");

const hasAnyValue = (c: ConfigResult): c is ConfigResult & { value: { anyValue: { value: Uint8Array } } } => {
    return c.value?.anyValue?.value instanceof Uint8Array;
};

const defaultWatermarkLens = {
    id: "60515300902",
    name: "Watermark",
    content: {
        lnsSha256: "3EDEAEBCD51A547FF4D1F5708FBD6F4D628AD736BEE07AB3844B14E6C69EC510",
        lnsUrlBolt:
            "https://bolt-gcdn.sc-cdn.net/3/L6uAe5Fhyg0ZFf3RLsCVZ?bo=EhgaABoAMgF9OgEEQgYIkbHPpgZIAlASYAE%3D&uc=18",
    },
};
/**
 * This LensSource loads lenses from the CameraKit backend service. It is meant to be used as the last LensSource in the
 * LensSource[] array used by LensRepository to load lenses.
 *
 * We ensure this is the case by providing cameraKitLensSourceFactory *after* the DI container has been modified by the
 * application during bootstrap -- this way we're guaranteed to place this LensSource after all other LensSources.
 *
 * @internal
 */
export const cameraKitLensSourceFactory = Injectable(
    lensSourcesFactory.token,
    [lensSourcesFactory.token, lensesClientFactory.token, remoteConfigurationFactory.token] as const,
    (lensSources: LensSource[], lensesClient: LensesClient, remoteConfig: RemoteConfiguration): LensSource[] => [
        // Watermark source goes always first not allowing apps to override it
        {
            isGroupOwner(groupId: string) {
                return groupId === watermarksLensGroup;
            },
            async loadLens() {
                const lensMessage = await firstValueFrom(
                    remoteConfig.get("CAMERA_KIT_WATERMARK_LENS").pipe(
                        map((configResults) => {
                            const lensMessage = configResults.find(hasAnyValue)?.value.anyValue.value;
                            if (!lensMessage) throw new Error("Failed to read watermark Lens from COF response.");
                            return lensMessage;
                        }),
                        catchError((error) => {
                            logger.error(error);
                            return of(Lens.encode(Lens.fromPartial(defaultWatermarkLens)).finish());
                        })
                    )
                );

                return Writer.create().uint32(10).bytes(lensMessage).finish();
            },
            async loadLensGroup() {
                throw new Error("Not implemented.");
            },
        },
        // custom lens sources provided by apps
        ...lensSources,
        {
            // This LensSource will claim ownership of all lens groups -- it should be used as the last element in a
            // list of LensSources, as a catch-all to load any lens groups not claimed by other LensSources.
            isGroupOwner(): boolean {
                return true;
            },

            async loadLens(lensId: string, groupId: string): Promise<ArrayBuffer> {
                const result = await lensesClient.getGroupLens({ lensId, groupId });
                if (!result.ok) {
                    const error = result.unwrapErr();
                    throw new Error(
                        `Cannot load lens lens ${lensId} from group ${groupId}. An error occured in the ` +
                            `gRPC client:\n\t[${error.status}] ${error.statusMessage}`
                    );
                }
                const response = result.unwrap();
                if (!response.message?.lens) {
                    throw new Error(
                        `Cannot load lens ${lensId} from group ${groupId}. The response did not contain ` +
                            `a lens.\n\t${JSON.stringify(result)} for requestId ${response.headers.get("x-request-id")}`
                    );
                }
                return Lens.encode(response.message.lens, Writer.create().uint32(10).fork()).ldelim().finish();
            },

            async loadLensGroup(groupId: string): Promise<ArrayBuffer> {
                const result = await lensesClient.getGroup({ id: groupId });
                if (!result.ok) {
                    const error = result.unwrapErr();
                    throw new Error(
                        `Cannot load lens group ${groupId}. An error occurred in the gRPC client:\n` +
                            `\t[${error.status}] ${error.statusMessage}`
                    );
                }
                const response = result.unwrap();
                if (!response.message?.lenses) {
                    throw new Error(
                        `Cannot load lens group ${groupId}. The response contained no lenses ` +
                            `\n\t${JSON.stringify(response)} for requestId ${response.headers.get("x-request-id")}`
                    );
                }
                const writer = Writer.create();
                response.message.lenses.forEach((lens) => Lens.encode(lens, writer.uint32(10).fork()).ldelim());
                return writer.finish();
            },
        },
    ]
);
