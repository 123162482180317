import Long from "long";
import _m0 from "protobufjs/minimal";
import { Any } from "../google/protobuf/any";
export const protobufPackage = "snapchat.common";
export interface MapRecord {
    key: string;
    value: string;
}
export interface MapRecords {
    records: MapRecord[];
}
export interface StringArray {
    value: string[];
}
export interface Value {
    intValue: number | undefined;
    longValue: string | undefined;
    floatValue: number | undefined;
    doubleValue: number | undefined;
    boolValue: boolean | undefined;
    stringValue: string | undefined;
    anyValue: Any | undefined;
    mapValue: MapRecords | undefined;
    intPairValue: string | undefined;
    stringArrayValue: StringArray | undefined;
}
function createBaseMapRecord(): MapRecord {
    return { key: "", value: "" };
}
export const MapRecord = {
    encode(message: MapRecord, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): MapRecord {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMapRecord();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<MapRecord>, I>>(object: I): MapRecord {
        const message = createBaseMapRecord();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseMapRecords(): MapRecords {
    return { records: [] };
}
export const MapRecords = {
    encode(message: MapRecords, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.records) {
            MapRecord.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): MapRecords {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMapRecords();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.records.push(MapRecord.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<MapRecords>, I>>(object: I): MapRecords {
        const message = createBaseMapRecords();
        message.records = object.records?.map((e) => MapRecord.fromPartial(e)) || [];
        return message;
    },
};
function createBaseStringArray(): StringArray {
    return { value: [] };
}
export const StringArray = {
    encode(message: StringArray, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.value) {
            writer.uint32(10).string(v!);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): StringArray {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseStringArray();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.value.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<StringArray>, I>>(object: I): StringArray {
        const message = createBaseStringArray();
        message.value = object.value?.map((e) => e) || [];
        return message;
    },
};
function createBaseValue(): Value {
    return {
        intValue: undefined,
        longValue: undefined,
        floatValue: undefined,
        doubleValue: undefined,
        boolValue: undefined,
        stringValue: undefined,
        anyValue: undefined,
        mapValue: undefined,
        intPairValue: undefined,
        stringArrayValue: undefined,
    };
}
export const Value = {
    encode(message: Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.intValue !== undefined) {
            writer.uint32(8).int32(message.intValue);
        }
        if (message.longValue !== undefined) {
            writer.uint32(16).int64(message.longValue);
        }
        if (message.floatValue !== undefined) {
            writer.uint32(29).float(message.floatValue);
        }
        if (message.doubleValue !== undefined) {
            writer.uint32(73).double(message.doubleValue);
        }
        if (message.boolValue !== undefined) {
            writer.uint32(32).bool(message.boolValue);
        }
        if (message.stringValue !== undefined) {
            writer.uint32(42).string(message.stringValue);
        }
        if (message.anyValue !== undefined) {
            Any.encode(message.anyValue, writer.uint32(50).fork()).ldelim();
        }
        if (message.mapValue !== undefined) {
            MapRecords.encode(message.mapValue, writer.uint32(58).fork()).ldelim();
        }
        if (message.intPairValue !== undefined) {
            writer.uint32(65).fixed64(message.intPairValue);
        }
        if (message.stringArrayValue !== undefined) {
            StringArray.encode(message.stringArrayValue, writer.uint32(82).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Value {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.intValue = reader.int32();
                    break;
                case 2:
                    message.longValue = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.floatValue = reader.float();
                    break;
                case 9:
                    message.doubleValue = reader.double();
                    break;
                case 4:
                    message.boolValue = reader.bool();
                    break;
                case 5:
                    message.stringValue = reader.string();
                    break;
                case 6:
                    message.anyValue = Any.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.mapValue = MapRecords.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.intPairValue = longToString(reader.fixed64() as Long);
                    break;
                case 10:
                    message.stringArrayValue = StringArray.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Value>, I>>(object: I): Value {
        const message = createBaseValue();
        message.intValue = object.intValue ?? undefined;
        message.longValue = object.longValue ?? undefined;
        message.floatValue = object.floatValue ?? undefined;
        message.doubleValue = object.doubleValue ?? undefined;
        message.boolValue = object.boolValue ?? undefined;
        message.stringValue = object.stringValue ?? undefined;
        message.anyValue =
            object.anyValue !== undefined && object.anyValue !== null ? Any.fromPartial(object.anyValue) : undefined;
        message.mapValue =
            object.mapValue !== undefined && object.mapValue !== null ? MapRecords.fromPartial(object.mapValue) : undefined;
        message.intPairValue = object.intPairValue ?? undefined;
        message.stringArrayValue =
            object.stringArrayValue !== undefined && object.stringArrayValue !== null
                ? StringArray.fromPartial(object.stringArrayValue)
                : undefined;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToString(long: Long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
