import { Injectable } from "../dependency-injection/Injectable";
import type { TypedCustomEvent } from "../events/TypedCustomEvent";
import { TypedEventTarget } from "../events/TypedEventTarget";
import type { AssetValidationFailed } from "../lens/assets/LensAssetsProvider";
import type { AssetDownload, LensDownload } from "./reporters/reportLensAndAssetDownload";
import type { LensContentValidationFailed } from "./reporters/reportLensValidationFailed";
import type { LensView } from "./reporters/reportLensView";
import type { LensWait } from "./reporters/reportLensWait";
import type { Session } from "./reporters/reportUserSession";
import type { BenchmarkComplete } from "./reporters/reportBenchmarks";
import type { LegalPrompt } from "./reporters/reportLegalState";
import type { MakeTaggedBusinessEvent } from "./businessEventsReporter";

/**
 * The Exception metric reports every time we handle an exception.
 */
export type Exception = MakeTaggedBusinessEvent<"exception">;

export type CameraKitBootstrapMetricEvents = TypedCustomEvent<BenchmarkComplete["name"], BenchmarkComplete>;

export type CameraKitSessionMetricEvents =
    | TypedCustomEvent<LegalPrompt["name"], LegalPrompt>
    | TypedCustomEvent<LensView["name"], LensView>
    | TypedCustomEvent<LensWait["name"], LensWait>
    | TypedCustomEvent<LensDownload["name"], LensDownload>
    | TypedCustomEvent<AssetDownload["name"], AssetDownload>
    | TypedCustomEvent<AssetValidationFailed["name"], AssetValidationFailed>
    | TypedCustomEvent<LensContentValidationFailed["name"], LensContentValidationFailed>
    | TypedCustomEvent<Session["name"], Session>;

/**
 * A union of all event types emitted by the {@link CameraKit.events} event emitter.
 *
 * @internal
 */
export type CameraKitMetricEvents =
    | CameraKitSessionMetricEvents
    | CameraKitBootstrapMetricEvents
    | TypedCustomEvent<Exception["name"], Exception>;

/**
 * This event target may be used to listen for any of the metrics events reported by CameraKit.
 *
 * @internal
 */
export type MetricsEventTarget = TypedEventTarget<CameraKitMetricEvents>;

/**
 * Any metrics that we wish to emit to applications should be dispatched on this event target.
 *
 * Our business events reporter also listens to this event target, and may report the emitted metrics to our backend.
 *
 * @internal
 */
export const metricsEventTargetFactory = Injectable(
    "metricsEventTarget",
    (): MetricsEventTarget => new TypedEventTarget()
);
