import { Injectable } from "../dependency-injection/Injectable";
import { Envelope } from "../generated-proto/pb_schema/camera_kit/v3/export";
import type { Lens } from "../generated-proto/pb_schema/camera_kit/v3/lens";

/**
 * Provides Lens metadata for one or more Lens groups.
 *
 * When a Lens or Lens group is requested via the {@link LensRepository}, CameraKit will find the LensSource which
 * claims ownership of that group. The appropriate LensSource method will be called and must return an ArrayBuffer
 * containing the encoded Lens metadata -- this can be obtained from a CameraKit backend API.
 */
export interface LensSource {
    isGroupOwner(groupId: string): boolean;
    loadLens(lensId: string, groupId: string): Promise<ArrayBuffer>;
    loadLensGroup(groupId: string): Promise<ArrayBuffer>;
}

/**
 * By default, no custom {@link LensSource} is provided to CameraKit. But to enable certain advanced use-cases,
 * applications may provide their own {@link LensSource}.
 *
 * Perhaps the most convenient way to do this is with {@link ConcatInjectable}, as shown here:
 *
 * @example
 * ```ts
 * import { bootstrapCameraKit, lensSourcesFactory, LensSource } from '@snap/camera-kit'
 *
 * const cameraKit = bootstrapCameraKit(config, (container) => {
 *   return container.provides(ConcatInjectable(
 *     lensSourcesFactory.token,
 *     (): LensSource => { return ... }
 *   ))
 * })
 * ```
 */
export const lensSourcesFactory = Injectable("lensSources", (): LensSource[] => []);

/**
 * Given a list of LensSources (like the one provided by CameraKit's DI container under the `'lensSources'` token), and
 * a groupId/lensId, return a list of lenses loaded by the first LensSource claiming ownership of the given groupId.
 *
 * @internal
 */
export async function loadLensesFromSources(sources: LensSource[], groupId: string, lensId?: string): Promise<Lens[]> {
    const source = sources.find((source) => source.isGroupOwner(groupId));
    if (!source) {
        throw new Error(
            `Cannot load lens ${lensId ? `${lensId} from ` : ""}group ${groupId}. ` +
                `No LensSource claimed ownership of that lens group.`
        );
    }
    const envelope =
        lensId === undefined ? await source.loadLensGroup(groupId) : await source.loadLens(lensId, groupId);

    return envelope instanceof ArrayBuffer || ArrayBuffer.isView(envelope)
        ? Envelope.decode(envelope instanceof Uint8Array ? envelope : new Uint8Array(envelope)).lenses
        : [];
}
