import { WasmFeatures, getPlatformCapabilities } from "../../platform/platformCapabilities";
import { getPlatformInfo } from "../../platform/platformInfo";

/**
 * Map various combinations of WebAssembly capabilities to the corresponding LensCore build flavours which make use
 * of them.
 */
const wasmCapabilitiesToLensCoreBuildFlavor = {
    [WasmFeatures.Default]: "release",
    [WasmFeatures.ExceptionHandling]: "rel-neh",
    [WasmFeatures.SIMD]: "release-simd",
    [WasmFeatures.ExceptionHandling | WasmFeatures.SIMD]: "rel-simd-neh",
};

const wasmAssets = ["LensCoreWebAssembly.js", "LensCoreWebAssembly.wasm"];

/**
 * Returns a list of URLs for resources which will be fetched during {@link bootstrapCameraKit}.
 *
 * When CameraKit is used on a website, these URLs must be reachable in order for CameraKit to be successfully
 * bootstrapped.
 *
 * @param endpointOverride Optional endpoint override to load the assets from.
 * @returns An array of asset URLs.
 *
 * @category Bootstrapping and Configuration
 */
export async function getRequiredBootstrapURLs(endpointOverride?: string): Promise<string[]> {
    // If we have an endpoint override, remove trailing `/` so we can construct a valid URL.
    const endpoint = endpointOverride?.replace(/[\/]+$/, "");

    const { wasm } = await getPlatformCapabilities();
    if (!wasm.supported) throw wasm.error;

    const { lensCore } = getPlatformInfo();
    const flavor = wasmCapabilitiesToLensCoreBuildFlavor[wasm.wasmFeatures];
    if (!flavor)
        throw new Error(
            `Could not determine a LensCore build flavor corresponding to the bitstring ` +
                `${wasm.wasmFeatures.toString(2)}. CameraKit cannot be bootstrapped.`
        );
    const version = lensCore.version;
    const buildNumber = lensCore.buildNumber;
    return wasmAssets.map((asset) => {
        if (endpoint) return `${endpoint}/${asset}`;
        const { origin, pathname, search } = new URL(lensCore.baseUrl);
        return `${origin}${pathname}/${version}/${buildNumber}/${flavor}/${asset}${search}`;
    });
}
