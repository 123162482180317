type UnknownRecord<T = unknown> = Record<string | number | symbol, T>;

export type Guard<T> = (value: unknown) => value is T;

export function isString(value: unknown): value is string {
    return typeof value === "string";
}

/**
 * Ensures given value is a safe string.
 *
 * Safe strings allow to prevent CRLF attacks. We use encoding approach inspired by:
 * https://community.veracode.com/s/question/0D53n00007hJJV5CAO/is-cwe-id-117-intended-for-consolelog
 */
export function isSafeString(value: unknown): value is string {
    return isString(value) && encodeURIComponent(value) === value;
}

export function isNumber(value: unknown): value is number {
    return typeof value === "number";
}
export function isValidNumber(value: unknown): value is number {
    return isNumber(value) && !Number.isNaN(value) && Number.isFinite(value);
}
export function isValidNumberOrUndefined(value: unknown): value is number | undefined {
    return isUndefined(value) || isValidNumber(value);
}

export function isArrayOfType<T>(elementTypeGuard: Guard<T>, value: unknown): value is T[] {
    return Array.isArray(value) && value.every((id) => elementTypeGuard(id));
}

export function isSafeStringArray(value: unknown): value is string[] {
    return isArrayOfType(isSafeString, value);
}

export function isStringOrUndefined(value: unknown): value is string | undefined {
    return isUndefined(value) || isString(value);
}

/**
 * Guards given value is instance of ArrayBuffer.
 */
export function isArrayBuffer(value: unknown): value is ArrayBuffer {
    return value instanceof ArrayBuffer;
}

/**
 * Guards given value is a typed array.
 */
export function isTypedArray<T>(value: unknown): value is T {
    return value instanceof Object.getPrototypeOf(Uint8Array);
}

/**
 * Guards given object is not undefined.
 */
export function isUndefined<T>(value: unknown): value is T {
    return typeof value === "undefined";
}

/**
 * Narrow an unknown type to a Record (i.e. a non-null JS object). This holds true for class instances, not just
 * plain objects.
 */
export function isRecord(value: unknown): value is UnknownRecord {
    return typeof value === "object" && value !== null && !Array.isArray(value);
}

/**
 * Check if the value is an arbitrary function
 */
export function isFunction(value: unknown): value is (...args: unknown[]) => unknown {
    return typeof value === "function";
}

/**
 * Type guard function to check if a value is an instance of Date.
 * @param value - The value to check.
 * @returns True if the value is a Date, false otherwise.
 */
export function isDate(value: unknown): value is Date {
    return value instanceof Date;
}

export function isDateOrUndefined(value: unknown): value is Date | undefined {
    return isUndefined(value) || isDate(value);
}

/**
 * Type guard function to check if a value is an instance of Date and represents a valid date.
 * @param value - The value to check.
 * @returns True if the value is a valid Date, false otherwise.
 */
export function isValidDate(value: unknown): value is Date {
    return isDate(value) && !isNaN(value.getTime());
}

/**
 * Apply a given type predicate asserting values are of type T to each value in a Record.
 *
 * @param predicate Any unary type predicate
 * @returns A type predicate which takes an UnknownRecord and tests that all its values are of type T
 */
export function predicateRecordValues<T>(predicate: (value: unknown) => value is T) {
    return (value: UnknownRecord): value is UnknownRecord<T> => {
        for (const v of Object.values(value)) if (!predicate(v)) return false;
        return true;
    };
}

/**
 * Checks whether given value is undefined or a safe URL string.
 *
 * The function checks whether given string is a valid URL with https: protocol.
 *
 * @param urlString URL string to check.
 * @returns True if URL is valid.
 */
export function isEmptyOrSafeUrl(urlString: string | undefined): boolean {
    if (!urlString) return true;
    try {
        const url = new URL(urlString);
        return url.protocol === "https:" || url.protocol === "http:";
    } catch {
        return false;
    }
}
