import { isState } from "@snap/state-management";
import type { MetricsClient } from "../clients/metricsClient";
import { metricsClientFactory } from "../clients/metricsClient";
import { Injectable } from "../dependency-injection/Injectable";
import type { LensKeyboard } from "../session/LensKeyboard";
import { lensKeyboardFactory } from "../session/LensKeyboard";
import type { LensState } from "../session/lensState";
import { lensStateFactory } from "../session/lensState";
import type { LensRepository } from "../lens/LensRepository";
import { lensRepositoryFactory } from "../lens/LensRepository";
import type { SessionState } from "../session/sessionState";
import { sessionStateFactory } from "../session/sessionState";
import { getLogger } from "../logger/logger";
import { lensCoreFactory } from "../lens-core-module/loader/lensCoreFactory";
import type { LensCore } from "../lens-core-module/lensCore";
import type { RemoteApiServices } from "./RemoteApiServices";
import { getRemoteApiUriHandler, remoteApiServicesFactory } from "./RemoteApiServices";
import type { UriHandlers, UriResponse } from "./UriHandlers";
import { extractSchemeAndRoute, isUriHandlers, isUriResponse, uriHandlersFactory } from "./UriHandlers";

const logger = getLogger("uriHandlersRegister");

/**
 * Registers URI handlers within LensCore.
 * @internal
 */
export const registerUriHandlers = Injectable(
    "registerUriHandlers",
    [
        lensCoreFactory.token,
        lensStateFactory.token,
        uriHandlersFactory.token,
        lensKeyboardFactory.token,
        remoteApiServicesFactory.token,
        lensRepositoryFactory.token,
        sessionStateFactory.token,
        metricsClientFactory.token,
    ] as const,
    (
        lensCore: LensCore,
        lensState: LensState,
        userHandlers: UriHandlers,
        lensKeyboard: LensKeyboard,
        remoteApiServices: RemoteApiServices,
        lensRepository: LensRepository,
        sessionState: SessionState,
        metrics: MetricsClient
    ): void => {
        if (!isUriHandlers(userHandlers)) {
            throw new Error("Expected an array of UriHandler objects");
        }

        // Users may define UriHandlers using the uriHandlersFactory.token, but we need to add some internally-defined
        // handlers (lens keyboard and Remote API) before registering handlers with LensCore.
        const allHandlers = userHandlers.concat(
            lensKeyboard.uriHandler,
            getRemoteApiUriHandler(remoteApiServices, sessionState, lensState, lensRepository, metrics)
        );

        for (const { uri, handleRequest, cancelRequest } of allHandlers) {
            const uris = Array.isArray(uri) ? uri : [uri];
            for (const { scheme, route } of uris.map(extractSchemeAndRoute)) {
                lensCore.registerUriListener(scheme, route, {
                    handleRequest: (request) => {
                        const reply = (response: UriResponse) => {
                            if (!isUriResponse(response)) {
                                throw new Error("Expected UriResponse object");
                            }
                            lensCore.provideUriResponse(request.identifier, response);
                        };

                        // Since lenses are the only things that make URI requests, we expect to always be in the
                        // "lensApplied" state – we'll sanity check, though, and log a warning if we're not.
                        const state = lensState.getState();
                        if (isState(state, "noLensApplied")) {
                            logger.warn(
                                `Got a URI request for ${request.uri}, but there is no active lens. The ` +
                                    `request will not be processed.`
                            );
                            return;
                        }

                        // NOTE: we do not handle any error thrown on an extension side when handleRequest() is called.
                        // That responsibility is delegated to the extension by design and that is exactly what Android
                        // and iOS SDKs do.
                        handleRequest(request, reply, state.data);
                    },
                    cancelRequest: (request) => {
                        if (cancelRequest) {
                            const state = lensState.getState();
                            if (isState(state, "noLensApplied")) {
                                logger.warn(
                                    `Got a URI cancel request for ${request.uri}, but there is no active ` +
                                        `lens. The cancel request will not be processed.`
                                );
                                return;
                            }
                            cancelRequest(request, state.data);
                        }
                    },
                });
            }
        }
    }
);
