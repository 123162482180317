import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "snapchat.lenses";
export enum HttpRequestMethod {
    HTTP_METHOD_UNSET = "HTTP_METHOD_UNSET",
    HTTP_METHOD_GET = "HTTP_METHOD_GET",
    HTTP_METHOD_POST = "HTTP_METHOD_POST",
    HTTP_METHOD_PUT = "HTTP_METHOD_PUT",
    HTTP_METHOD_DELETE = "HTTP_METHOD_DELETE",
    HTTP_METHOD_PATCH = "HTTP_METHOD_PATCH",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function httpRequestMethodFromJSON(object: any): HttpRequestMethod {
    switch (object) {
        case 0:
        case "HTTP_METHOD_UNSET":
            return HttpRequestMethod.HTTP_METHOD_UNSET;
        case 1:
        case "HTTP_METHOD_GET":
            return HttpRequestMethod.HTTP_METHOD_GET;
        case 2:
        case "HTTP_METHOD_POST":
            return HttpRequestMethod.HTTP_METHOD_POST;
        case 3:
        case "HTTP_METHOD_PUT":
            return HttpRequestMethod.HTTP_METHOD_PUT;
        case 4:
        case "HTTP_METHOD_DELETE":
            return HttpRequestMethod.HTTP_METHOD_DELETE;
        case 5:
        case "HTTP_METHOD_PATCH":
            return HttpRequestMethod.HTTP_METHOD_PATCH;
        case -1:
        case "UNRECOGNIZED":
        default:
            return HttpRequestMethod.UNRECOGNIZED;
    }
}
export function httpRequestMethodToNumber(object: HttpRequestMethod): number {
    switch (object) {
        case HttpRequestMethod.HTTP_METHOD_UNSET:
            return 0;
        case HttpRequestMethod.HTTP_METHOD_GET:
            return 1;
        case HttpRequestMethod.HTTP_METHOD_POST:
            return 2;
        case HttpRequestMethod.HTTP_METHOD_PUT:
            return 3;
        case HttpRequestMethod.HTTP_METHOD_DELETE:
            return 4;
        case HttpRequestMethod.HTTP_METHOD_PATCH:
            return 5;
        default:
            return 0;
    }
}
export enum ResponseCode {
    RESPONSE_CODE_UNSET = "RESPONSE_CODE_UNSET",
    SUCCESS = "SUCCESS",
    REDIRECTED = "REDIRECTED",
    BAD_REQUEST = "BAD_REQUEST",
    ACCESS_DENIED = "ACCESS_DENIED",
    NOT_FOUND = "NOT_FOUND",
    TIMEOUT = "TIMEOUT",
    REQUEST_TOO_LARGE = "REQUEST_TOO_LARGE",
    SERVER_ERROR = "SERVER_ERROR",
    CANCELLED = "CANCELLED",
    PROXY_ERROR = "PROXY_ERROR",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function responseCodeFromJSON(object: any): ResponseCode {
    switch (object) {
        case 0:
        case "RESPONSE_CODE_UNSET":
            return ResponseCode.RESPONSE_CODE_UNSET;
        case 1:
        case "SUCCESS":
            return ResponseCode.SUCCESS;
        case 2:
        case "REDIRECTED":
            return ResponseCode.REDIRECTED;
        case 3:
        case "BAD_REQUEST":
            return ResponseCode.BAD_REQUEST;
        case 4:
        case "ACCESS_DENIED":
            return ResponseCode.ACCESS_DENIED;
        case 5:
        case "NOT_FOUND":
            return ResponseCode.NOT_FOUND;
        case 6:
        case "TIMEOUT":
            return ResponseCode.TIMEOUT;
        case 7:
        case "REQUEST_TOO_LARGE":
            return ResponseCode.REQUEST_TOO_LARGE;
        case 8:
        case "SERVER_ERROR":
            return ResponseCode.SERVER_ERROR;
        case 9:
        case "CANCELLED":
            return ResponseCode.CANCELLED;
        case 10:
        case "PROXY_ERROR":
            return ResponseCode.PROXY_ERROR;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ResponseCode.UNRECOGNIZED;
    }
}
export function responseCodeToNumber(object: ResponseCode): number {
    switch (object) {
        case ResponseCode.RESPONSE_CODE_UNSET:
            return 0;
        case ResponseCode.SUCCESS:
            return 1;
        case ResponseCode.REDIRECTED:
            return 2;
        case ResponseCode.BAD_REQUEST:
            return 3;
        case ResponseCode.ACCESS_DENIED:
            return 4;
        case ResponseCode.NOT_FOUND:
            return 5;
        case ResponseCode.TIMEOUT:
            return 6;
        case ResponseCode.REQUEST_TOO_LARGE:
            return 7;
        case ResponseCode.SERVER_ERROR:
            return 8;
        case ResponseCode.CANCELLED:
            return 9;
        case ResponseCode.PROXY_ERROR:
            return 10;
        default:
            return 0;
    }
}
export interface PerformHttpCallRequest {
    url: string;
    method: HttpRequestMethod;
    headers: {
        [key: string]: string;
    };
    body: Uint8Array;
    apiSpecId: string;
    isStudioDev: boolean;
}
export interface PerformHttpCallRequest_HeadersEntry {
    key: string;
    value: string;
}
export interface PerformHttpCallResponse {
    code: number;
    headers: {
        [key: string]: string;
    };
    body: Uint8Array;
}
export interface PerformHttpCallResponse_HeadersEntry {
    key: string;
    value: string;
}
export interface GetOAuth2InfoRequest {
    apiSpecId: string;
}
export interface GetOAuth2InfoResponse {
    clientId: string;
    grantType: GetOAuth2InfoResponse_GrantType;
    authorizationUrl: string;
    scope: string;
}
export enum GetOAuth2InfoResponse_GrantType {
    GRANT_TYPE_UNSET = "GRANT_TYPE_UNSET",
    AUTHORIZATION_CODE = "AUTHORIZATION_CODE",
    AUTHORIZATION_CODE_WITH_PKCE = "AUTHORIZATION_CODE_WITH_PKCE",
    IMPLICIT = "IMPLICIT",
    CLIENT_CREDENTIALS = "CLIENT_CREDENTIALS",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function getOAuth2InfoResponse_GrantTypeFromJSON(object: any): GetOAuth2InfoResponse_GrantType {
    switch (object) {
        case 0:
        case "GRANT_TYPE_UNSET":
            return GetOAuth2InfoResponse_GrantType.GRANT_TYPE_UNSET;
        case 1:
        case "AUTHORIZATION_CODE":
            return GetOAuth2InfoResponse_GrantType.AUTHORIZATION_CODE;
        case 2:
        case "AUTHORIZATION_CODE_WITH_PKCE":
            return GetOAuth2InfoResponse_GrantType.AUTHORIZATION_CODE_WITH_PKCE;
        case 3:
        case "IMPLICIT":
            return GetOAuth2InfoResponse_GrantType.IMPLICIT;
        case 4:
        case "CLIENT_CREDENTIALS":
            return GetOAuth2InfoResponse_GrantType.CLIENT_CREDENTIALS;
        case -1:
        case "UNRECOGNIZED":
        default:
            return GetOAuth2InfoResponse_GrantType.UNRECOGNIZED;
    }
}
export function getOAuth2InfoResponse_GrantTypeToNumber(object: GetOAuth2InfoResponse_GrantType): number {
    switch (object) {
        case GetOAuth2InfoResponse_GrantType.GRANT_TYPE_UNSET:
            return 0;
        case GetOAuth2InfoResponse_GrantType.AUTHORIZATION_CODE:
            return 1;
        case GetOAuth2InfoResponse_GrantType.AUTHORIZATION_CODE_WITH_PKCE:
            return 2;
        case GetOAuth2InfoResponse_GrantType.IMPLICIT:
            return 3;
        case GetOAuth2InfoResponse_GrantType.CLIENT_CREDENTIALS:
            return 4;
        default:
            return 0;
    }
}
export interface TokenExchangeError {
    error: TokenExchangeError_TokenExchangeErrorType;
    errorDescription: string;
}
export enum TokenExchangeError_TokenExchangeErrorType {
    ERROR_TYPE_UNSET = "ERROR_TYPE_UNSET",
    INVALID_REQUEST = "INVALID_REQUEST",
    INVALID_CLIENT = "INVALID_CLIENT",
    INVALID_GRANT = "INVALID_GRANT",
    UNAUTHORIZED_CLIENT = "UNAUTHORIZED_CLIENT",
    UNSUPPORTED_GRANT_TYPE = "UNSUPPORTED_GRANT_TYPE",
    INVALID_SCOPE = "INVALID_SCOPE",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function tokenExchangeError_TokenExchangeErrorTypeFromJSON(object: any): TokenExchangeError_TokenExchangeErrorType {
    switch (object) {
        case 0:
        case "ERROR_TYPE_UNSET":
            return TokenExchangeError_TokenExchangeErrorType.ERROR_TYPE_UNSET;
        case 1:
        case "INVALID_REQUEST":
            return TokenExchangeError_TokenExchangeErrorType.INVALID_REQUEST;
        case 2:
        case "INVALID_CLIENT":
            return TokenExchangeError_TokenExchangeErrorType.INVALID_CLIENT;
        case 3:
        case "INVALID_GRANT":
            return TokenExchangeError_TokenExchangeErrorType.INVALID_GRANT;
        case 4:
        case "UNAUTHORIZED_CLIENT":
            return TokenExchangeError_TokenExchangeErrorType.UNAUTHORIZED_CLIENT;
        case 5:
        case "UNSUPPORTED_GRANT_TYPE":
            return TokenExchangeError_TokenExchangeErrorType.UNSUPPORTED_GRANT_TYPE;
        case 6:
        case "INVALID_SCOPE":
            return TokenExchangeError_TokenExchangeErrorType.INVALID_SCOPE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return TokenExchangeError_TokenExchangeErrorType.UNRECOGNIZED;
    }
}
export function tokenExchangeError_TokenExchangeErrorTypeToNumber(object: TokenExchangeError_TokenExchangeErrorType): number {
    switch (object) {
        case TokenExchangeError_TokenExchangeErrorType.ERROR_TYPE_UNSET:
            return 0;
        case TokenExchangeError_TokenExchangeErrorType.INVALID_REQUEST:
            return 1;
        case TokenExchangeError_TokenExchangeErrorType.INVALID_CLIENT:
            return 2;
        case TokenExchangeError_TokenExchangeErrorType.INVALID_GRANT:
            return 3;
        case TokenExchangeError_TokenExchangeErrorType.UNAUTHORIZED_CLIENT:
            return 4;
        case TokenExchangeError_TokenExchangeErrorType.UNSUPPORTED_GRANT_TYPE:
            return 5;
        case TokenExchangeError_TokenExchangeErrorType.INVALID_SCOPE:
            return 6;
        default:
            return 0;
    }
}
export interface TokenDetails {
    accessToken: string;
    tokenType: string;
    expiresInSeconds: string;
    refreshToken: string;
    scope: string;
}
export interface PerformTokenExchangeRequest {
    apiSpecId: string;
    authorizationCode: string;
    codeVerifier: string;
}
export interface PerformTokenExchangeResponse {
    response?: {
        $case: "tokenDetails";
        tokenDetails: TokenDetails;
    } | {
        $case: "error";
        error: TokenExchangeError;
    };
}
export interface RefreshTokenRequest {
    apiSpecId: string;
    refreshToken: string;
}
export interface RefreshTokenResponse {
    response?: {
        $case: "tokenDetails";
        tokenDetails: TokenDetails;
    } | {
        $case: "error";
        error: TokenExchangeError;
    };
}
export interface PerformApiCallRequest {
    apiSpecSetId: string;
    endpointRefId: string;
    parameters: {
        [key: string]: string;
    };
    body: Uint8Array;
    lensId: string;
    isStudioDev: boolean;
    cacheTtlSec: number;
    linkedResources: LinkedResource[];
}
export interface PerformApiCallRequest_ParametersEntry {
    key: string;
    value: string;
}
export interface PerformApiCallResponse {
    responseCode: ResponseCode;
    metadata: {
        [key: string]: string;
    };
    body: Uint8Array;
    userLocationUsed: boolean;
    linkedResources: LinkedResource[];
}
export interface PerformApiCallResponse_MetadataEntry {
    key: string;
    value: string;
}
export interface LinkedResource {
    url: string;
    encryptionKey: Uint8Array;
}
function createBasePerformHttpCallRequest(): PerformHttpCallRequest {
    return {
        url: "",
        method: HttpRequestMethod.HTTP_METHOD_UNSET,
        headers: {},
        body: new Uint8Array(),
        apiSpecId: "",
        isStudioDev: false,
    };
}
export const PerformHttpCallRequest = {
    encode(message: PerformHttpCallRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.method !== HttpRequestMethod.HTTP_METHOD_UNSET) {
            writer.uint32(16).int32(httpRequestMethodToNumber(message.method));
        }
        Object.entries(message.headers).forEach(([key, value]) => {
            PerformHttpCallRequest_HeadersEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
        });
        if (message.body.length !== 0) {
            writer.uint32(34).bytes(message.body);
        }
        if (message.apiSpecId !== "") {
            writer.uint32(42).string(message.apiSpecId);
        }
        if (message.isStudioDev === true) {
            writer.uint32(48).bool(message.isStudioDev);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformHttpCallRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformHttpCallRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.method = httpRequestMethodFromJSON(reader.int32());
                    break;
                case 3:
                    const entry3 = PerformHttpCallRequest_HeadersEntry.decode(reader, reader.uint32());
                    if (entry3.value !== undefined) {
                        message.headers[entry3.key] = entry3.value;
                    }
                    break;
                case 4:
                    message.body = reader.bytes();
                    break;
                case 5:
                    message.apiSpecId = reader.string();
                    break;
                case 6:
                    message.isStudioDev = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformHttpCallRequest>, I>>(object: I): PerformHttpCallRequest {
        const message = createBasePerformHttpCallRequest();
        message.url = object.url ?? "";
        message.method = object.method ?? HttpRequestMethod.HTTP_METHOD_UNSET;
        message.headers = Object.entries(object.headers ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.body = object.body ?? new Uint8Array();
        message.apiSpecId = object.apiSpecId ?? "";
        message.isStudioDev = object.isStudioDev ?? false;
        return message;
    },
};
function createBasePerformHttpCallRequest_HeadersEntry(): PerformHttpCallRequest_HeadersEntry {
    return { key: "", value: "" };
}
export const PerformHttpCallRequest_HeadersEntry = {
    encode(message: PerformHttpCallRequest_HeadersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformHttpCallRequest_HeadersEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformHttpCallRequest_HeadersEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformHttpCallRequest_HeadersEntry>, I>>(object: I): PerformHttpCallRequest_HeadersEntry {
        const message = createBasePerformHttpCallRequest_HeadersEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBasePerformHttpCallResponse(): PerformHttpCallResponse {
    return { code: 0, headers: {}, body: new Uint8Array() };
}
export const PerformHttpCallResponse = {
    encode(message: PerformHttpCallResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.code !== 0) {
            writer.uint32(8).int32(message.code);
        }
        Object.entries(message.headers).forEach(([key, value]) => {
            PerformHttpCallResponse_HeadersEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
        });
        if (message.body.length !== 0) {
            writer.uint32(26).bytes(message.body);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformHttpCallResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformHttpCallResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.code = reader.int32();
                    break;
                case 2:
                    const entry2 = PerformHttpCallResponse_HeadersEntry.decode(reader, reader.uint32());
                    if (entry2.value !== undefined) {
                        message.headers[entry2.key] = entry2.value;
                    }
                    break;
                case 3:
                    message.body = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformHttpCallResponse>, I>>(object: I): PerformHttpCallResponse {
        const message = createBasePerformHttpCallResponse();
        message.code = object.code ?? 0;
        message.headers = Object.entries(object.headers ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.body = object.body ?? new Uint8Array();
        return message;
    },
};
function createBasePerformHttpCallResponse_HeadersEntry(): PerformHttpCallResponse_HeadersEntry {
    return { key: "", value: "" };
}
export const PerformHttpCallResponse_HeadersEntry = {
    encode(message: PerformHttpCallResponse_HeadersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformHttpCallResponse_HeadersEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformHttpCallResponse_HeadersEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformHttpCallResponse_HeadersEntry>, I>>(object: I): PerformHttpCallResponse_HeadersEntry {
        const message = createBasePerformHttpCallResponse_HeadersEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseGetOAuth2InfoRequest(): GetOAuth2InfoRequest {
    return { apiSpecId: "" };
}
export const GetOAuth2InfoRequest = {
    encode(message: GetOAuth2InfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.apiSpecId !== "") {
            writer.uint32(10).string(message.apiSpecId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetOAuth2InfoRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetOAuth2InfoRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.apiSpecId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetOAuth2InfoRequest>, I>>(object: I): GetOAuth2InfoRequest {
        const message = createBaseGetOAuth2InfoRequest();
        message.apiSpecId = object.apiSpecId ?? "";
        return message;
    },
};
function createBaseGetOAuth2InfoResponse(): GetOAuth2InfoResponse {
    return { clientId: "", grantType: GetOAuth2InfoResponse_GrantType.GRANT_TYPE_UNSET, authorizationUrl: "", scope: "" };
}
export const GetOAuth2InfoResponse = {
    encode(message: GetOAuth2InfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientId !== "") {
            writer.uint32(10).string(message.clientId);
        }
        if (message.grantType !== GetOAuth2InfoResponse_GrantType.GRANT_TYPE_UNSET) {
            writer.uint32(16).int32(getOAuth2InfoResponse_GrantTypeToNumber(message.grantType));
        }
        if (message.authorizationUrl !== "") {
            writer.uint32(26).string(message.authorizationUrl);
        }
        if (message.scope !== "") {
            writer.uint32(34).string(message.scope);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetOAuth2InfoResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetOAuth2InfoResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientId = reader.string();
                    break;
                case 2:
                    message.grantType = getOAuth2InfoResponse_GrantTypeFromJSON(reader.int32());
                    break;
                case 3:
                    message.authorizationUrl = reader.string();
                    break;
                case 4:
                    message.scope = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetOAuth2InfoResponse>, I>>(object: I): GetOAuth2InfoResponse {
        const message = createBaseGetOAuth2InfoResponse();
        message.clientId = object.clientId ?? "";
        message.grantType = object.grantType ?? GetOAuth2InfoResponse_GrantType.GRANT_TYPE_UNSET;
        message.authorizationUrl = object.authorizationUrl ?? "";
        message.scope = object.scope ?? "";
        return message;
    },
};
function createBaseTokenExchangeError(): TokenExchangeError {
    return { error: TokenExchangeError_TokenExchangeErrorType.ERROR_TYPE_UNSET, errorDescription: "" };
}
export const TokenExchangeError = {
    encode(message: TokenExchangeError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.error !== TokenExchangeError_TokenExchangeErrorType.ERROR_TYPE_UNSET) {
            writer.uint32(8).int32(tokenExchangeError_TokenExchangeErrorTypeToNumber(message.error));
        }
        if (message.errorDescription !== "") {
            writer.uint32(18).string(message.errorDescription);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): TokenExchangeError {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTokenExchangeError();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.error = tokenExchangeError_TokenExchangeErrorTypeFromJSON(reader.int32());
                    break;
                case 2:
                    message.errorDescription = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<TokenExchangeError>, I>>(object: I): TokenExchangeError {
        const message = createBaseTokenExchangeError();
        message.error = object.error ?? TokenExchangeError_TokenExchangeErrorType.ERROR_TYPE_UNSET;
        message.errorDescription = object.errorDescription ?? "";
        return message;
    },
};
function createBaseTokenDetails(): TokenDetails {
    return { accessToken: "", tokenType: "", expiresInSeconds: "0", refreshToken: "", scope: "" };
}
export const TokenDetails = {
    encode(message: TokenDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.accessToken !== "") {
            writer.uint32(10).string(message.accessToken);
        }
        if (message.tokenType !== "") {
            writer.uint32(18).string(message.tokenType);
        }
        if (message.expiresInSeconds !== "0") {
            writer.uint32(24).int64(message.expiresInSeconds);
        }
        if (message.refreshToken !== "") {
            writer.uint32(34).string(message.refreshToken);
        }
        if (message.scope !== "") {
            writer.uint32(42).string(message.scope);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): TokenDetails {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseTokenDetails();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.accessToken = reader.string();
                    break;
                case 2:
                    message.tokenType = reader.string();
                    break;
                case 3:
                    message.expiresInSeconds = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.refreshToken = reader.string();
                    break;
                case 5:
                    message.scope = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<TokenDetails>, I>>(object: I): TokenDetails {
        const message = createBaseTokenDetails();
        message.accessToken = object.accessToken ?? "";
        message.tokenType = object.tokenType ?? "";
        message.expiresInSeconds = object.expiresInSeconds ?? "0";
        message.refreshToken = object.refreshToken ?? "";
        message.scope = object.scope ?? "";
        return message;
    },
};
function createBasePerformTokenExchangeRequest(): PerformTokenExchangeRequest {
    return { apiSpecId: "", authorizationCode: "", codeVerifier: "" };
}
export const PerformTokenExchangeRequest = {
    encode(message: PerformTokenExchangeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.apiSpecId !== "") {
            writer.uint32(10).string(message.apiSpecId);
        }
        if (message.authorizationCode !== "") {
            writer.uint32(18).string(message.authorizationCode);
        }
        if (message.codeVerifier !== "") {
            writer.uint32(26).string(message.codeVerifier);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformTokenExchangeRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformTokenExchangeRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.apiSpecId = reader.string();
                    break;
                case 2:
                    message.authorizationCode = reader.string();
                    break;
                case 3:
                    message.codeVerifier = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformTokenExchangeRequest>, I>>(object: I): PerformTokenExchangeRequest {
        const message = createBasePerformTokenExchangeRequest();
        message.apiSpecId = object.apiSpecId ?? "";
        message.authorizationCode = object.authorizationCode ?? "";
        message.codeVerifier = object.codeVerifier ?? "";
        return message;
    },
};
function createBasePerformTokenExchangeResponse(): PerformTokenExchangeResponse {
    return { response: undefined };
}
export const PerformTokenExchangeResponse = {
    encode(message: PerformTokenExchangeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.response?.$case === "tokenDetails") {
            TokenDetails.encode(message.response.tokenDetails, writer.uint32(10).fork()).ldelim();
        }
        if (message.response?.$case === "error") {
            TokenExchangeError.encode(message.response.error, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformTokenExchangeResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformTokenExchangeResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = { $case: "tokenDetails", tokenDetails: TokenDetails.decode(reader, reader.uint32()) };
                    break;
                case 2:
                    message.response = { $case: "error", error: TokenExchangeError.decode(reader, reader.uint32()) };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformTokenExchangeResponse>, I>>(object: I): PerformTokenExchangeResponse {
        const message = createBasePerformTokenExchangeResponse();
        if (object.response?.$case === "tokenDetails" &&
            object.response?.tokenDetails !== undefined &&
            object.response?.tokenDetails !== null) {
            message.response = {
                $case: "tokenDetails",
                tokenDetails: TokenDetails.fromPartial(object.response.tokenDetails),
            };
        }
        if (object.response?.$case === "error" && object.response?.error !== undefined && object.response?.error !== null) {
            message.response = { $case: "error", error: TokenExchangeError.fromPartial(object.response.error) };
        }
        return message;
    },
};
function createBaseRefreshTokenRequest(): RefreshTokenRequest {
    return { apiSpecId: "", refreshToken: "" };
}
export const RefreshTokenRequest = {
    encode(message: RefreshTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.apiSpecId !== "") {
            writer.uint32(10).string(message.apiSpecId);
        }
        if (message.refreshToken !== "") {
            writer.uint32(18).string(message.refreshToken);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): RefreshTokenRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRefreshTokenRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.apiSpecId = reader.string();
                    break;
                case 2:
                    message.refreshToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<RefreshTokenRequest>, I>>(object: I): RefreshTokenRequest {
        const message = createBaseRefreshTokenRequest();
        message.apiSpecId = object.apiSpecId ?? "";
        message.refreshToken = object.refreshToken ?? "";
        return message;
    },
};
function createBaseRefreshTokenResponse(): RefreshTokenResponse {
    return { response: undefined };
}
export const RefreshTokenResponse = {
    encode(message: RefreshTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.response?.$case === "tokenDetails") {
            TokenDetails.encode(message.response.tokenDetails, writer.uint32(10).fork()).ldelim();
        }
        if (message.response?.$case === "error") {
            TokenExchangeError.encode(message.response.error, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): RefreshTokenResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRefreshTokenResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.response = { $case: "tokenDetails", tokenDetails: TokenDetails.decode(reader, reader.uint32()) };
                    break;
                case 2:
                    message.response = { $case: "error", error: TokenExchangeError.decode(reader, reader.uint32()) };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<RefreshTokenResponse>, I>>(object: I): RefreshTokenResponse {
        const message = createBaseRefreshTokenResponse();
        if (object.response?.$case === "tokenDetails" &&
            object.response?.tokenDetails !== undefined &&
            object.response?.tokenDetails !== null) {
            message.response = {
                $case: "tokenDetails",
                tokenDetails: TokenDetails.fromPartial(object.response.tokenDetails),
            };
        }
        if (object.response?.$case === "error" && object.response?.error !== undefined && object.response?.error !== null) {
            message.response = { $case: "error", error: TokenExchangeError.fromPartial(object.response.error) };
        }
        return message;
    },
};
function createBasePerformApiCallRequest(): PerformApiCallRequest {
    return {
        apiSpecSetId: "",
        endpointRefId: "",
        parameters: {},
        body: new Uint8Array(),
        lensId: "",
        isStudioDev: false,
        cacheTtlSec: 0,
        linkedResources: [],
    };
}
export const PerformApiCallRequest = {
    encode(message: PerformApiCallRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.apiSpecSetId !== "") {
            writer.uint32(10).string(message.apiSpecSetId);
        }
        if (message.endpointRefId !== "") {
            writer.uint32(18).string(message.endpointRefId);
        }
        Object.entries(message.parameters).forEach(([key, value]) => {
            PerformApiCallRequest_ParametersEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
        });
        if (message.body.length !== 0) {
            writer.uint32(34).bytes(message.body);
        }
        if (message.lensId !== "") {
            writer.uint32(42).string(message.lensId);
        }
        if (message.isStudioDev === true) {
            writer.uint32(48).bool(message.isStudioDev);
        }
        if (message.cacheTtlSec !== 0) {
            writer.uint32(56).int32(message.cacheTtlSec);
        }
        for (const v of message.linkedResources) {
            LinkedResource.encode(v!, writer.uint32(66).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformApiCallRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformApiCallRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.apiSpecSetId = reader.string();
                    break;
                case 2:
                    message.endpointRefId = reader.string();
                    break;
                case 3:
                    const entry3 = PerformApiCallRequest_ParametersEntry.decode(reader, reader.uint32());
                    if (entry3.value !== undefined) {
                        message.parameters[entry3.key] = entry3.value;
                    }
                    break;
                case 4:
                    message.body = reader.bytes();
                    break;
                case 5:
                    message.lensId = reader.string();
                    break;
                case 6:
                    message.isStudioDev = reader.bool();
                    break;
                case 7:
                    message.cacheTtlSec = reader.int32();
                    break;
                case 8:
                    message.linkedResources.push(LinkedResource.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformApiCallRequest>, I>>(object: I): PerformApiCallRequest {
        const message = createBasePerformApiCallRequest();
        message.apiSpecSetId = object.apiSpecSetId ?? "";
        message.endpointRefId = object.endpointRefId ?? "";
        message.parameters = Object.entries(object.parameters ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.body = object.body ?? new Uint8Array();
        message.lensId = object.lensId ?? "";
        message.isStudioDev = object.isStudioDev ?? false;
        message.cacheTtlSec = object.cacheTtlSec ?? 0;
        message.linkedResources = object.linkedResources?.map((e) => LinkedResource.fromPartial(e)) || [];
        return message;
    },
};
function createBasePerformApiCallRequest_ParametersEntry(): PerformApiCallRequest_ParametersEntry {
    return { key: "", value: "" };
}
export const PerformApiCallRequest_ParametersEntry = {
    encode(message: PerformApiCallRequest_ParametersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformApiCallRequest_ParametersEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformApiCallRequest_ParametersEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformApiCallRequest_ParametersEntry>, I>>(object: I): PerformApiCallRequest_ParametersEntry {
        const message = createBasePerformApiCallRequest_ParametersEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBasePerformApiCallResponse(): PerformApiCallResponse {
    return {
        responseCode: ResponseCode.RESPONSE_CODE_UNSET,
        metadata: {},
        body: new Uint8Array(),
        userLocationUsed: false,
        linkedResources: [],
    };
}
export const PerformApiCallResponse = {
    encode(message: PerformApiCallResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.responseCode !== ResponseCode.RESPONSE_CODE_UNSET) {
            writer.uint32(8).int32(responseCodeToNumber(message.responseCode));
        }
        Object.entries(message.metadata).forEach(([key, value]) => {
            PerformApiCallResponse_MetadataEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
        });
        if (message.body.length !== 0) {
            writer.uint32(26).bytes(message.body);
        }
        if (message.userLocationUsed === true) {
            writer.uint32(32).bool(message.userLocationUsed);
        }
        for (const v of message.linkedResources) {
            LinkedResource.encode(v!, writer.uint32(42).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformApiCallResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformApiCallResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.responseCode = responseCodeFromJSON(reader.int32());
                    break;
                case 2:
                    const entry2 = PerformApiCallResponse_MetadataEntry.decode(reader, reader.uint32());
                    if (entry2.value !== undefined) {
                        message.metadata[entry2.key] = entry2.value;
                    }
                    break;
                case 3:
                    message.body = reader.bytes();
                    break;
                case 4:
                    message.userLocationUsed = reader.bool();
                    break;
                case 5:
                    message.linkedResources.push(LinkedResource.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformApiCallResponse>, I>>(object: I): PerformApiCallResponse {
        const message = createBasePerformApiCallResponse();
        message.responseCode = object.responseCode ?? ResponseCode.RESPONSE_CODE_UNSET;
        message.metadata = Object.entries(object.metadata ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.body = object.body ?? new Uint8Array();
        message.userLocationUsed = object.userLocationUsed ?? false;
        message.linkedResources = object.linkedResources?.map((e) => LinkedResource.fromPartial(e)) || [];
        return message;
    },
};
function createBasePerformApiCallResponse_MetadataEntry(): PerformApiCallResponse_MetadataEntry {
    return { key: "", value: "" };
}
export const PerformApiCallResponse_MetadataEntry = {
    encode(message: PerformApiCallResponse_MetadataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PerformApiCallResponse_MetadataEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePerformApiCallResponse_MetadataEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PerformApiCallResponse_MetadataEntry>, I>>(object: I): PerformApiCallResponse_MetadataEntry {
        const message = createBasePerformApiCallResponse_MetadataEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseLinkedResource(): LinkedResource {
    return { url: "", encryptionKey: new Uint8Array() };
}
export const LinkedResource = {
    encode(message: LinkedResource, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.url !== "") {
            writer.uint32(10).string(message.url);
        }
        if (message.encryptionKey.length !== 0) {
            writer.uint32(18).bytes(message.encryptionKey);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): LinkedResource {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLinkedResource();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.encryptionKey = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<LinkedResource>, I>>(object: I): LinkedResource {
        const message = createBaseLinkedResource();
        message.url = object.url ?? "";
        message.encryptionKey = object.encryptionKey ?? new Uint8Array();
        return message;
    },
};
export interface RemoteApiService {
    PerformHttpCall(request: PerformHttpCallRequest): Promise<PerformHttpCallResponse>;
    GetOAuth2Info(request: GetOAuth2InfoRequest): Promise<GetOAuth2InfoResponse>;
    PerformTokenExchange(request: PerformTokenExchangeRequest): Promise<PerformTokenExchangeResponse>;
    RefreshToken(request: RefreshTokenRequest): Promise<RefreshTokenResponse>;
    PerformApiCall(request: PerformApiCallRequest): Promise<PerformApiCallResponse>;
}
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToString(long: Long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
