import { take } from "rxjs";
import { Injectable } from "../dependency-injection/Injectable";
import { Namespace } from "../generated-proto/pb_schema/cdp/cof/namespace";
import { lensCoreFactory } from "../lens-core-module/loader/lensCoreFactory";
import type { LensCore } from "../lens-core-module/lensCore";
import type { LensCoreConfig, SetPreloadedConfigurationInput } from "../lens-core-module/generated-types";
import { getLogger } from "../logger/logger";
import type { RemoteConfiguration } from "./remoteConfiguration";
import { remoteConfigurationFactory } from "./remoteConfiguration";

const logger = getLogger("setPreloadedConfiguration");

export const setPreloadedConfiguration = Injectable(
    "setPreloadedConfiguration",
    [lensCoreFactory.token, remoteConfigurationFactory.token] as const,
    (lensCore: LensCore, remoteConfiguration: RemoteConfiguration) => {
        remoteConfiguration
            .getNamespace(Namespace.LENS_CORE_CONFIG)
            .pipe(take(1))
            .subscribe({
                next: (configs) => {
                    const inputs: SetPreloadedConfigurationInput = configs.map(
                        ({ configId, value }) => ({ configId, value } as LensCoreConfig)
                    );

                    lensCore.setPreloadedConfiguration(inputs);
                },
                error: logger.error,
            });
    }
);
