import type { OperationalMetric } from "../../generated-proto/pb_schema/camera_kit/v3/operational_metrics";

type MetricConstructor<T> = new (name: string, dimensions: MetricDimensions) => T;

const nameDelimiter = "_";
const dimensionDelimiter = ".";
const delimiterRegex = new RegExp(`^${nameDelimiter}+|${nameDelimiter}+$`, "g");

/** @internal */
export type MetricDimensions = Record<string, string | number>;

/** @internal */
export abstract class Metric {
    constructor(readonly name: string, readonly dimensions: MetricDimensions = {}) {}

    child<ChildName extends string, T extends Metric>(
        constructor: MetricConstructor<T>,
        name: ChildName,
        dimensions: MetricDimensions = {}
    ): T {
        return new constructor(`${this.name}${nameDelimiter}${name}`, dimensions);
    }

    abstract toOperationalMetric(): Required<OperationalMetric>[];
}

/** @internal */
export type JoinMetricNames<Parent extends string, Child extends string> = `${Parent}${typeof nameDelimiter}${Child}`;

/** @internal */
export function joinMetricNames(names: string[]): string {
    return names.join(nameDelimiter).replace(delimiterRegex, "");
}

/** @internal */
export function serializeMetricDimensions(dimensions: MetricDimensions): string {
    if (Object.keys(dimensions).length === 0) return "";
    // The order in which we'll iterate over the dimension keys is governed by the (rather complex) rules for object
    // key iteration in JS -- since ES2015, for string keys of own properties, this is insertion order.
    return `${dimensionDelimiter}${Array.from(Object.entries(dimensions))
        .map((d) => d.join(dimensionDelimiter))
        .join(dimensionDelimiter)}`;
}
