import { forActions } from "@snap/state-management";
import { map } from "rxjs";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import { CameraKitLegalPromptResult } from "../../generated-proto/blizzard/cameraKitEvents";
import type { LegalState } from "../../legal/legalState";
import { legalStateFactory } from "../../legal/legalState";
import type { MakeTaggedBusinessEvent } from "../businessEventsReporter";
import type { MetricsEventTarget } from "../metricsEventTarget";
import { metricsEventTargetFactory } from "../metricsEventTarget";
import type { MetricsClient } from "../../clients/metricsClient";
import { metricsClientFactory } from "../../clients/metricsClient";
import { Count } from "../operational/Count";

/**
 * The LegalPrompt metric reports each time a BIPA legal prompt is shown.
 */
export type LegalPrompt = MakeTaggedBusinessEvent<"legalPrompt">;

/**
 * @internal
 */
export const reportLegalState = Injectable(
    "reportLegalState",
    [legalStateFactory.token, metricsEventTargetFactory.token, metricsClientFactory.token] as const,
    (legalState: LegalState, metricsEventTarget: MetricsEventTarget, metrics: MetricsClient) => {
        legalState.events
            .pipe(
                forActions("accept", "reject"),
                map(([{ data, name }]) => ({
                    name: "legalPrompt" as const,
                    legalPromptId: data,
                    legalPromptResult:
                        name === "accept"
                            ? CameraKitLegalPromptResult.CAMERA_KIT_LEGAL_PROMPT_ACCEPTED
                            : CameraKitLegalPromptResult.CAMERA_KIT_LEGAL_PROMPT_DISMISSED,
                }))
            )
            .subscribe({
                next: (legalPromptEvent) => {
                    metricsEventTarget.dispatchEvent(new TypedCustomEvent("legalPrompt", legalPromptEvent));
                    metrics.setOperationalMetrics(
                        Count.count("legal_prompt_interaction", 1, {
                            accepted: (
                                legalPromptEvent.legalPromptResult ===
                                CameraKitLegalPromptResult.CAMERA_KIT_LEGAL_PROMPT_ACCEPTED
                            ).toString(),
                        })
                    );
                },
            });
    }
);
