import type { LensExecutionError, LensImagePickerError, LensAbortError } from "../namedErrors";
import type { TypedCustomEvent } from "../events/TypedCustomEvent";
import type { TypedEventListener } from "../events/TypedEventTarget";
import type { Lens } from "../lens/Lens";

/**
 * A type guard helper to detect unreachable code.
 */
const isReachable = (_: never) => false;

/**
 * Events emitted by {@link CameraKitSession.events | CameraKitSession.events}.
 *
 * The following events are emitted:
 *   - `error`: An error has been encountered during lens rendering. May contain an error of type:
 *     - {@link LensExecutionError} If an error of this type occurs, the rendering lens will be automatically removed
 * from the associated CameraKitSession.
 *     - {@link LensImagePickerError}
 *
 * @category Rendering
 * @category Lenses
 */
export type CameraKitSessionEvents = TypedCustomEvent<
    "error",
    { error: LensExecutionError | LensAbortError | LensImagePickerError; lens: Lens }
>;

/**
 * Listener of {@link CameraKitSessionEvents} events.
 */
export type CameraKitSessionEventListener = TypedEventListener<CameraKitSessionEvents>;

/**
 * Determines if the value is an error to be reported to the host application.
 */
export function isPublicLensError(value: unknown): value is CameraKitSessionEvents["detail"]["error"] {
    if (!(value instanceof Error)) return false;

    const maybeSessionErrorName = (value as CameraKitSessionEvents["detail"]["error"]).name;
    switch (maybeSessionErrorName) {
        case "LensAbortError":
        case "LensExecutionError":
        case "LensImagePickerError":
            return true;
        default:
            return isReachable(maybeSessionErrorName);
    }
}
