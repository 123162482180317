/* eslint-disable max-classes-per-file */

interface BaseResult<T, E> {
    readonly ok: boolean;

    unwrap(): T;
    unwrapErr(): E;

    map<T2>(m: (value: T) => T2): Result<T2, E>;
    flatMap<T2 extends OkResult<unknown>>(m: (value: T) => T2): Result<ValueOf<T2>, E>;
    flatMap<E2 extends ErrResult<unknown>>(m: (value: T) => E2): Result<T, E | ValueOf<E2>>;
    flatMap<R extends Result<unknown, unknown>>(m: (value: T) => R): Result<OkValueOf<R>, E | ErrValueOf<R>>;
}

type OkValueOf<R> = R extends OkResult<infer T> ? T : never;
type ErrValueOf<R> = R extends ErrResult<infer E> ? E : never;
type ValueOf<R> = OkValueOf<R> | ErrValueOf<R>;

export class OkResult<T> implements BaseResult<T, never> {
    readonly ok = true;
    constructor(private readonly value: T) {}

    unwrap(): T {
        return this.value;
    }

    unwrapErr(): never {
        throw new Error("Ok Result cannot unwrapErr.");
    }

    map<T2>(m: (value: T) => T2): OkResult<T2> {
        return new OkResult(m(this.value));
    }

    flatMap<T2 extends OkResult<unknown>>(m: (value: T) => T2): T2;
    flatMap<E2 extends ErrResult<unknown>>(m: (value: T) => E2): E2;
    flatMap<R extends Result<unknown, unknown>>(m: (value: T) => R): R;
    flatMap<R extends Result<unknown, unknown>>(m: (value: T) => R): R {
        return m(this.value);
    }
}

export const Ok = <T>(value: T) => new OkResult(value);

export class ErrResult<E> implements BaseResult<never, E> {
    readonly ok = false;
    constructor(private readonly value: E) {}

    unwrap(): never {
        throw this.value;
    }

    unwrapErr(): E {
        return this.value;
    }

    map(): ErrResult<E> {
        return this;
    }

    flatMap(): ErrResult<E> {
        return this;
    }
}

export const Err = <E>(value: E) => new ErrResult(value);

export type Result<T, E> = OkResult<T> | ErrResult<E>;
