import Long from "long";
import _m0 from "protobufjs/minimal";
import { ConfigResult } from "./config_result";
import { BenchmarkRequest } from "./benchmark";
export const protobufPackage = "snapchat.cdp.cof";
export interface ConfigTargetingResponseDebugData {
    abResultChecksumInput: string;
}
export interface ConfigTargetingResponse {
    configResults: ConfigResult[];
    configResultsEtag: string;
    benchmarkRequest: BenchmarkRequest | undefined;
    fullResults: boolean;
    abResultChecksum: number;
    iso3166Alpha2CountryCodeFromRequestIp: string;
    debugData: ConfigTargetingResponseDebugData | undefined;
}
function createBaseConfigTargetingResponseDebugData(): ConfigTargetingResponseDebugData {
    return { abResultChecksumInput: "" };
}
export const ConfigTargetingResponseDebugData = {
    encode(message: ConfigTargetingResponseDebugData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.abResultChecksumInput !== "") {
            writer.uint32(10).string(message.abResultChecksumInput);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigTargetingResponseDebugData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigTargetingResponseDebugData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.abResultChecksumInput = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigTargetingResponseDebugData>, I>>(object: I): ConfigTargetingResponseDebugData {
        const message = createBaseConfigTargetingResponseDebugData();
        message.abResultChecksumInput = object.abResultChecksumInput ?? "";
        return message;
    },
};
function createBaseConfigTargetingResponse(): ConfigTargetingResponse {
    return {
        configResults: [],
        configResultsEtag: "",
        benchmarkRequest: undefined,
        fullResults: false,
        abResultChecksum: 0,
        iso3166Alpha2CountryCodeFromRequestIp: "",
        debugData: undefined,
    };
}
export const ConfigTargetingResponse = {
    encode(message: ConfigTargetingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.configResults) {
            ConfigResult.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        if (message.configResultsEtag !== "") {
            writer.uint32(18).string(message.configResultsEtag);
        }
        if (message.benchmarkRequest !== undefined) {
            BenchmarkRequest.encode(message.benchmarkRequest, writer.uint32(26).fork()).ldelim();
        }
        if (message.fullResults === true) {
            writer.uint32(32).bool(message.fullResults);
        }
        if (message.abResultChecksum !== 0) {
            writer.uint32(40).int32(message.abResultChecksum);
        }
        if (message.iso3166Alpha2CountryCodeFromRequestIp !== "") {
            writer.uint32(50).string(message.iso3166Alpha2CountryCodeFromRequestIp);
        }
        if (message.debugData !== undefined) {
            ConfigTargetingResponseDebugData.encode(message.debugData, writer.uint32(58).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigTargetingResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigTargetingResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configResults.push(ConfigResult.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.configResultsEtag = reader.string();
                    break;
                case 3:
                    message.benchmarkRequest = BenchmarkRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.fullResults = reader.bool();
                    break;
                case 5:
                    message.abResultChecksum = reader.int32();
                    break;
                case 6:
                    message.iso3166Alpha2CountryCodeFromRequestIp = reader.string();
                    break;
                case 7:
                    message.debugData = ConfigTargetingResponseDebugData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigTargetingResponse>, I>>(object: I): ConfigTargetingResponse {
        const message = createBaseConfigTargetingResponse();
        message.configResults = object.configResults?.map((e) => ConfigResult.fromPartial(e)) || [];
        message.configResultsEtag = object.configResultsEtag ?? "";
        message.benchmarkRequest =
            object.benchmarkRequest !== undefined && object.benchmarkRequest !== null
                ? BenchmarkRequest.fromPartial(object.benchmarkRequest)
                : undefined;
        message.fullResults = object.fullResults ?? false;
        message.abResultChecksum = object.abResultChecksum ?? 0;
        message.iso3166Alpha2CountryCodeFromRequestIp = object.iso3166Alpha2CountryCodeFromRequestIp ?? "";
        message.debugData =
            object.debugData !== undefined && object.debugData !== null
                ? ConfigTargetingResponseDebugData.fromPartial(object.debugData)
                : undefined;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
