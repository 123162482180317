import {
    isArrayBuffer,
    isArrayOfType,
    isFunction,
    isNumber,
    isRecord,
    isString,
    isTypedArray,
    isUndefined,
    predicateRecordValues,
} from "../common/typeguards";
import { Injectable } from "../dependency-injection/Injectable";
import type { Uri, UriCancelRequest, UriRequest, UriResponse } from "../lens-core-module/generated-types";
import type { Lens } from "../lens/Lens";

const SEPARATOR = "://";

export function extractSchemeAndRoute(uri: Uri) {
    const separatorIndex = uri.indexOf(SEPARATOR);
    const scheme = uri.slice(0, separatorIndex);
    const route = uri.slice(separatorIndex + SEPARATOR.length);
    return { scheme, route };
}

function isUri(value: unknown): value is Uri {
    return isString(value) && value.includes(SEPARATOR);
}

function isUriHandler(value: unknown): value is UriHandler {
    return (
        isRecord(value) &&
        (isUri(value.uri) || isArrayOfType(isUri, value.uri)) &&
        isFunction(value.handleRequest) &&
        (isUndefined(value.cancelRequest) || isFunction(value.cancelRequest))
    );
}

export function isUriHandlers(value: unknown): value is UriHandlers {
    return isArrayOfType(isUriHandler, value);
}

export function isUriResponse(value: unknown): value is UriResponse {
    return (
        isRecord(value) &&
        isNumber(value.code) &&
        isString(value.description) &&
        isString(value.contentType) &&
        (isArrayBuffer(value.data) || isTypedArray(value.data)) &&
        (isUndefined(value.metadata) || predicateRecordValues(isString)(value.metadata))
    );
}

/**
 * Provides a way for a lens to call into external services that work under HTTP-like protocol.
 * @internal
 */
export interface UriHandler {
    /**
     * [scheme]://[route] pattern that this handler processes or an array of patterns.
     */
    uri: Uri | Uri[];

    /**
     * Called to process the provided request.
     * @param request Lens request.
     * @param reply A callback function to send response back.
     * The function can be called none or multiple number of times.
     * @param lens Lens the request is coming from.
     */
    handleRequest(request: UriRequest, reply: (response: UriResponse) => void, lens: Lens): void;

    /**
     * Called to indicated that URI request initated earlier is cancelled.
     * @param request Lens request.
     * @param lens Lens the request is coming from.
     */
    cancelRequest?(request: UriCancelRequest, lens: Lens): void;
}

/**
 * Array of {@link UriHandler} objects.
 * @internal
 */
export type UriHandlers = UriHandler[];

/**
 * An extension point for client URI handlers.
 * @internal
 */
export const uriHandlersFactory = Injectable("UriHandlers", () => {
    const uriHandlers: UriHandlers = [];
    return uriHandlers;
});

export { Uri, UriCancelRequest, UriRequest, UriResponse } from "../lens-core-module/generated-types";
