/**
 * Creates an array of unique values, in order, from all given arrays using a specified iteratee.
 * If multiple elements have the same key generated by the iteratee, the last occurrence is kept.
 *
 * @param {((item: T) => unknown) | keyof T} iteratee - The iteratee invoked per element.
 * @param {...T[][]} arrays - The arrays to inspect and unite.
 * @returns {T[]} - Returns the new array of combined elements, preserving the order of last occurrences.
 * @example
 * // Using a property name as iteratee
 * const result = unionBy(
 *   'id',
 *   [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }],
 *   [{ id: 1, name: 'Sally' }, { id: 3, name: 'Doe' }]
 * );
 * console.log(result);
 * // Output: [{ id: 1, name: 'Sally' }, { id: 2, name: 'Jane' }, { id: 3, name: 'Doe' }]
 *
 * @example
 * // Using a function as iteratee
 * const result = unionBy(
 *   item => item.id,
 *   [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }],
 *   [{ id: 1, name: 'Sally' }, { id: 3, name: 'Doe' }]
 * );
 * console.log(result);
 * // Output: [{ id: 1, name: 'Sally' }, { id: 2, name: 'Jane' }, { id: 3, name: 'Doe' }]
 */
export function unionBy<T>(iteratee: ((item: T) => unknown) | keyof T, ...arrays: T[][]): T[] {
    const throwIterateeError = () => {
        throw new Error("Iteratee must be a function or a valid property key of the item");
    };

    // One helpful feature of Map is maintainig the insertion order of elements. This is particularly nice for the
    // unionBy function since it ensures that the combined array preserves the order of elements as they first appear
    // in the input arrays.
    const seen = new Map<unknown, T>();

    for (const item of arrays.flat()) {
        const key =
            typeof iteratee === "function"
                ? iteratee(item)
                : typeof item === "object" && item !== null && iteratee in item
                ? item[iteratee]
                : throwIterateeError();

        seen.set(key, item);
    }

    return Array.from(seen.values());
}
