import { Injectable } from "../dependency-injection/Injectable";
import { persistentStoreError } from "../namedErrors";
import { getLogger } from "../logger/logger";
import { IndexedDBPersistence } from "../persistence/IndexedDBPersistence";
import { lensCoreFactory } from "../lens-core-module/loader/lensCoreFactory";
import type { LensCore } from "../lens-core-module/lensCore";

const logger = getLogger("LensPersistenceStore");

export const lensPersistenceStoreFactory = Injectable(
    "lensPersistenceStore",
    [lensCoreFactory.token] as const,
    (lensCore: LensCore): IndexedDBPersistence<ArrayBuffer> => {
        const db = new IndexedDBPersistence<ArrayBuffer>({ databaseName: "lensPersistenceStore" });
        lensCore.registerSavePersistentStoreCallback(async (id, data) => {
            try {
                await db.store(id, data);
            } catch (error) {
                logger.error(persistentStoreError(`Error occurred while storing data for lens ${id}.`, error));
            }
        });
        return db;
    }
);
