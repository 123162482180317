import type { InjectableFunction, ServicesFromTokenizedParams } from "./types";

/**
 * Create an Injectable factory function with no dependencies (i.e. the factory function has no arguments).
 *
 * Ex:
 * ```ts
 * const createMyService = Injectable(
 *   'MyService',
 *   () => { return ... },
 * )
 * ```
 *
 * @param token A unique string Token which will correspond to the created Service.
 * @param fn A function with no arguments which returns the Service.
 */
export function Injectable<Token extends string, Service>(
    token: Token,
    fn: () => Service
): InjectableFunction<any, [], Token, Service>;

/**
 * Create an Injectable factory function with dependencies (i.e. the factory function has arguments).
 *
 * **Note:** the list of dependencies must contain only string literals or string consts.
 *
 * Ex:
 * ```ts
 * const DependencyB = 'DependencyB'
 * const createMyService = Injectable(
 *   'MyService',
 *   ['DependencyA', DependencyB] as const,
 *   (a: A, b: B) => { return ... },
 * )
 * ```
 *
 * @param token A unique string Token which will correspond to the created Service.
 * @param dependencies A *readonly* list of Tokens corresponding to dependencies (i.e. arguments to the Factory), which
 * will be resolved by the Container to which this Injectable is provided.
 * @param fn A function with arguments matching in type and length to the given list of dependencies. When called, it
 * must return the Service.
 */
export function Injectable<
    Token extends string,
    Tokens extends readonly string[],
    Params extends readonly any[],
    Service
>(
    token: Token,
    dependencies: Tokens,
    // The function arity (number of arguments) must match the number of dependencies specified – if they don't, we'll
    // force a compiler error by saying the arguments should be `void[]`. We'll also throw at runtime, so the return
    // type will be `never`.
    fn: (...args: Tokens["length"] extends Params["length"] ? Params : void[]) => Service
): Tokens["length"] extends Params["length"]
    ? InjectableFunction<ServicesFromTokenizedParams<Tokens, Params>, Tokens, Token, Service>
    : never;

export function Injectable(
    token: string,
    dependenciesOrFn?: readonly string[] | (() => any),
    maybeFn?: (...args: any[]) => any
): InjectableFunction<any, readonly string[], string, any> {
    const dependencies: string[] = Array.isArray(dependenciesOrFn) ? dependenciesOrFn : [];
    const fn = typeof dependenciesOrFn === "function" ? dependenciesOrFn : maybeFn;

    if (!fn) {
        throw new TypeError(
            "[Injectable] Received invalid arguments. The factory function must be either the second " +
                "or third argument."
        );
    }

    if (fn.length !== dependencies.length) {
        throw new TypeError(
            "[Injectable] Function arity does not match the number of dependencies. Function has arity " +
                `${fn.length}, but ${dependencies.length} dependencies were specified.` +
                `\nDependencies: ${JSON.stringify(dependencies)}`
        );
    }

    const factory = (...args: any[]) => fn(...args);
    factory.token = token;
    factory.dependencies = dependencies;
    return factory;
}

/**
 * Create an Injectable factory function without dependencies (i.e. the factory function has no arguments) that appends
 * a Service onto an existing array of Services of the same type.
 *
 * Ex:
 * ```ts
 * import { myServiceFactory, MyService } from './my-service'
 *
 * const createMyService = ConcatInjectable(
 *   myServiceFactory.token,
 *   (): MyService => { return ... },
 * )
 *
 * // Consumers then do:
 * const myConsumingServiceFactory = Injectable(
 *   'myConsumingService',
 *   [myServiceFactory.token] as const,
 *   (myServices: MyService[]) => { return ... }
 * )
 * ```
 *
 * @param token A string Token identifying an existing Service that has an Array type, to which will be appended the
 * Service created by this factory function.
 * @param fn A function with no arguments which returns the Service.
 */
export function ConcatInjectable<Token extends string, Service>(
    token: Token,
    fn: () => Service
): InjectableFunction<{ [T in keyof Token]: Service[] }, [], Token, Service[]>;

/**
 * Create an Injectable factory function with dependencies (i.e. the factory function has arguments) that appends
 * a Service onto an existing array of Services of the same type.
 *
 * Ex:
 * ```ts
 * import { myServiceFactory, MyService } from './my-service'
 *
 * const createMyService = ConcatInjectable(
 *   myServiceFactory.token,
 *   ['DependencyA', 'DependencyB'] as const,
 *   (a: A, b: B): MyService => { return ... },
 * )
 *
 * // Consumers then do:
 * const myConsumingServiceFactory = Injectable(
 *   'myConsumingService',
 *   [myServiceFactory.token] as const,
 *   (myServices: MyService[]) => { return ... }
 * )
 * ```
 *
 * @param token A string Token identifying an existing Service that has an Array type, to which will be appended the
 * Service created by this factory function.
 * @param dependencies A *readonly* list of Tokens corresponding to dependencies (i.e. arguments to the Factory), which
 * will be resolved by the Container to which this Injectable is provided.
 * @param fn A function with no arguments which returns the Service.
 */
export function ConcatInjectable<
    Token extends string,
    Tokens extends readonly string[],
    Params extends readonly any[],
    Service
>(
    token: Token,
    dependencies: Tokens,
    fn: (...args: Tokens["length"] extends Params["length"] ? Params : void[]) => Service
): InjectableFunction<ServicesFromTokenizedParams<Tokens, Params>, Tokens, Token, Service[]>;

export function ConcatInjectable(
    token: string,
    dependenciesOrFn?: readonly string[] | (() => any),
    maybeFn?: (...args: any[]) => any
): InjectableFunction<any, readonly string[], string, any[]> {
    const dependencies: string[] = Array.isArray(dependenciesOrFn) ? dependenciesOrFn : [];
    const fn = typeof dependenciesOrFn === "function" ? dependenciesOrFn : maybeFn;

    if (!fn) {
        throw new TypeError(
            "[ConcatInjectable] Received invalid arguments. The factory function must be either the second " +
                "or third argument."
        );
    }

    if (fn.length !== dependencies.length) {
        throw new TypeError(
            "[Injectable] Function arity does not match the number of dependencies. Function has arity " +
                `${fn.length}, but ${dependencies.length} dependencies were specified.` +
                `\nDependencies: ${JSON.stringify(dependencies)}`
        );
    }

    const factory = (array: any[], ...args: any[]) => {
        return array.concat(fn(...args));
    };
    factory.token = token;
    factory.dependencies = [token, ...dependencies];
    return factory;
}
