import type { PageVisibility } from "../common/pageVisibility";
import type { ChainableHandler, RequestMetadata } from "./HandlerChainBuilder";
import { createMappingHandler } from "./mappingHandler";

const delay = (duration: number) => new Promise<void>((resolve) => setTimeout(resolve, duration));

/**
 * Limit the rate at which requests are passed to the next handler in the chain.
 *
 * During any page transitions to "hidden" – possibly indicating the page is about to terminate – requests will not be
 * rate limited, to ensure that they are not lost.
 *
 * TODO: If there are requests in the queue waiting to be sent when the page transitions to "hidden," these will not
 * be immediately sent. This means there still is an edge case in which a request may be lost on page termination. This
 * can be fixed with changes to `createMappingHandler`.
 *
 * **NOTE:** Under the hood, requests that come in faster than the set `duration` are placed in an unbounded buffer.
 * If many requests are made quickly and `duration` is long, this could result in high memory usage. Keep this in mind
 * when using this handler.
 *
 * @param duration In milliseconds. Requests will be passed to the next handler in the chain no faster than this. That
 * is, if `duration` is `1000`, the next handler will be called at most once per second.
 * @param pageVisibility Determines whether to flush buffered requests when the page becomes hidden.
 * `false` value indicates that page visibility handling is avoided, while
 * a {@link PageVisibility} instance is used to subscribe to page visibility change events.
 * @returns {@link ChainableHandler}, suitable for use in {@link HandlerChainBuilder.map}
 */
export const createRateLimitingHandler = <Req, Res, Meta extends RequestMetadata>(
    duration: number,
    pageVisibility: PageVisibility | false
): ChainableHandler<Req, Res, Req, Res, Meta> => {
    let mostRecentSendTime: number | undefined = undefined;

    const mappingHandler = createMappingHandler<Req, Req, Res, Meta>(
        async (request) => {
            if (mostRecentSendTime !== undefined) {
                const millisUntilNextSend = duration - (Date.now() - mostRecentSendTime);
                if (millisUntilNextSend > 0) await delay(millisUntilNextSend);
            }
            mostRecentSendTime = Date.now();
            return request;
        },
        pageVisibility,
        1
    );

    return (next) => (request, metadata) => {
        // Requests may be made while the page is transitioning to hidden – for example, the page is being unloaded and
        // we're reporting final metrics. In this case, we need to skip rate limiting and synchronously call `next`
        // so that the request is not lost.
        if (pageVisibility && pageVisibility.isDuringVisibilityTransition("hidden")) return next(request, metadata);
        return mappingHandler(next)(request, metadata);
    };
};
