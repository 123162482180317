import type { grpc } from "@improbable-eng/grpc-web";
import type { Writer } from "protobufjs";
import { entries, fromEntries } from "../common/entries";
import type { Result } from "../common/result";
import type { Exact, DeepPartial } from "../generated-proto/pb_schema/camera_kit/v3/service";
import type { GrpcHandler } from "./grpcHandler";

type TsProtoServiceDefinition<Methods extends TsProtoMethods> = {
    name: string;
    fullName: string;
    methods: Methods;
};

type TsProtoMethods = { [methodName: string]: TsProtoMethodDefinition<any, any> };

type TsProtoMethodDefinition<Request, Response> = {
    name: string;
    requestType: TsProtoMessage<Request>;
    responseType: TsProtoMessage<Response>;
};

type TsProtoMessage<M> = {
    encode: (message: M) => Writer;
    decode: (message: Uint8Array) => M;
    fromPartial: (partialMessage: any) => M;
};

export type TsProtoServiceClient<S extends TsProtoServiceDefinition<any>> = {
    [MethodName in keyof S["methods"]]: InferTsProtoMethod<S["methods"][MethodName]>;
};

type InferTsProtoMethod<M extends TsProtoMethodDefinition<any, any>> = M extends TsProtoMethodDefinition<
    infer Request,
    infer Response
>
    ? <I extends Exact<DeepPartial<Request>, I>>(
          request: I
      ) => Promise<
          Result<grpc.UnaryOutput<Response & grpc.ProtobufMessage>, grpc.UnaryOutput<Response & grpc.ProtobufMessage>>
      >
    : never;

function messageClass<M>(message: TsProtoMessage<M>, data: M): grpc.ProtobufMessageClass<grpc.ProtobufMessage> {
    return class Message implements grpc.ProtobufMessage {
        constructor() {
            Object.assign(this, message.fromPartial(data));
        }

        static deserializeBinary(data: Uint8Array): Message {
            return new (messageClass(message, message.decode(data)))() as Message;
        }

        serializeBinary(): Uint8Array {
            return message.encode(this as any).finish();
        }

        toObject(): this {
            return this;
        }
    };
}

/**
 * Convert a service definition generated by ts-proto (using the `outputServices=generic-definitions` CLI option) into
 * a working client.
 *
 * @param serviceDefinition
 * @param handler
 * @returns A client that can make requests to a remote service by sending Protobuf-encoded messages over HTTP using the
 * grpc-web package.
 *
 * @internal
 */
export function createTsProtoClient<S extends TsProtoServiceDefinition<TsProtoMethods>>(
    serviceDefinition: S,
    handler: GrpcHandler
): TsProtoServiceClient<S> {
    return fromEntries(
        entries(serviceDefinition.methods).map(([methodName, methodDefinition]) => {
            return [
                methodName,
                async (request: unknown) => {
                    const requestType = messageClass(methodDefinition.requestType, request);
                    const responseType = messageClass(methodDefinition.responseType, {});
                    return handler({
                        serviceName: serviceDefinition.fullName,
                        methodName: methodDefinition.name,
                        requestType,
                        responseType,
                    });
                },
            ];
            // Safety: We're mapping from the method definitions object into the GrpcServiceClient object in a manner
            // that preserves each key in the method definitions object, pairing it with the corresponding
            // serialization/deserialization logic for that particular method. But in doing this, we lose type
            // specificity by converting the method definition object to a list of entries, mapping them, and then
            // converting back into the client object -- so we're forced into this type cast.
        })
    ) as TsProtoServiceClient<S>;
}
