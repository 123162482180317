import Long from "long";
import _m0 from "protobufjs/minimal";
import { Geopoint } from "./geopoint";
export const protobufPackage = "snapchat.lenses";
export interface Geocircle {
    radius: number;
    center: Geopoint | undefined;
}
function createBaseGeocircle(): Geocircle {
    return { radius: 0, center: undefined };
}
export const Geocircle = {
    encode(message: Geocircle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.radius !== 0) {
            writer.uint32(9).double(message.radius);
        }
        if (message.center !== undefined) {
            Geopoint.encode(message.center, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Geocircle {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGeocircle();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.radius = reader.double();
                    break;
                case 2:
                    message.center = Geopoint.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Geocircle>, I>>(object: I): Geocircle {
        const message = createBaseGeocircle();
        message.radius = object.radius ?? 0;
        message.center =
            object.center !== undefined && object.center !== null ? Geopoint.fromPartial(object.center) : undefined;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
