import { copyDefinedProperties } from "../common/copyDefinedProperties";
import { isString } from "../common/typeguards";
import type { ChainableHandler, RequestMetadata } from "./HandlerChainBuilder";

const sleep = (millis: number) => new Promise<void>((resolve) => setTimeout(resolve, millis));

const defaultOptions: TimeoutHandlerOptions<unknown> = {
    createError: (request) => {
        // The string and Request types are very common, so our default error creator special-cases those types to
        // provide better error messages.
        const destination = isString(request)
            ? `for ${request}`
            : request instanceof Request
            ? `for ${request.url}`
            : "";
        return new Error(`Request ${destination} timed out by client timeout handler.`);
    },
    timeout: 30 * 1000,
};

export interface TimeoutHandlerOptions<Req> {
    /**
     * A function that returns a new Error instance when a timeout occurs.
     */
    createError: (req: Req, meta?: RequestMetadata | void) => Error;

    /**
     * Abort requests after this number of milliseconds. Defaults to 30 seconds.
     */
    timeout: number;
}

/**
 * Timeout requests after a given number of milliseconds, rejecting the Response promise with a custom error.
 *
 * @param options
 * @returns {@link ChainableHandler}, suitable for use in {@link HandlerChainBuilder.map}
 */
export const createTimeoutHandler = <Req, Res, Meta extends RequestMetadata>(
    options: Partial<TimeoutHandlerOptions<Req>> = {}
): ChainableHandler<Req, Res, Req, Res, Meta> => {
    const definedOptions = copyDefinedProperties(options);
    const { createError, timeout } = { ...defaultOptions, ...definedOptions };

    // If the timeout Promise wins the race, the HandlerChainBuilder sets the abort signal for subsequent handlers. They
    // may look at the abort signal in order to terminate themselves early.
    return (next) => (req, meta) =>
        Promise.race([next(req, meta), sleep(timeout).then(() => Promise.reject(createError(req, meta)))]);
};
