import { Injectable } from "../dependency-injection/Injectable";

export class PageVisibility {
    private onHiddenHandlers = new Set<() => void>();
    private onVisibleHandlers = new Set<() => void>();

    private previousVisibilityState = document.visibilityState;
    private visibilityTransition: false | DocumentVisibilityState = false;

    constructor() {
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.isDuringVisibilityTransition = this.isDuringVisibilityTransition.bind(this);
        this.onPageHidden = this.onPageHidden.bind(this);
        this.onPageVisible = this.onPageVisible.bind(this);
        this.destroy = this.destroy.bind(this);
        document.addEventListener("visibilitychange", this.onVisibilityChange);
    }

    isDuringVisibilityTransition(test: DocumentVisibilityState): boolean {
        return test === this.visibilityTransition;
    }

    /**
     * Run a function when the page is hidden. If this occurs due to tab / browser closure,
     * only synchronous functions will run to completion.
     *
     * If the given handler throws an error, it will be silently swallowed.
     *
     * @param handler
     * @returns A function which, when called, removes the function from the set of visibility change handlers.
     */
    onPageHidden(handler: () => void): () => void {
        this.onHiddenHandlers.add(handler);
        return () => this.onHiddenHandlers.delete(handler);
    }

    /**
     * Run a function when the page is made visible.
     *
     * If the given handler throws an error, it will be silently swallowed.
     *
     * @param handler
     * @returns A function which, when called, removes the function from the set of visibility change handlers.
     */
    onPageVisible(handler: () => void): () => void {
        this.onVisibleHandlers.add(handler);
        return () => this.onVisibleHandlers.delete(handler);
    }

    destroy() {
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
        this.onHiddenHandlers.clear();
        this.onVisibleHandlers.clear();
    }

    private onVisibilityChange() {
        const handlers =
            this.previousVisibilityState === "visible" && document.visibilityState === "hidden"
                ? this.onHiddenHandlers
                : this.previousVisibilityState === "hidden" && document.visibilityState === "visible"
                ? this.onVisibleHandlers
                : new Set<() => void>();

        this.visibilityTransition = document.visibilityState;

        for (const handler of handlers) {
            try {
                handler();
            } catch (error) {
                // We'll do the same thing here that we would do if the handler was added directly as an event
                // listener and dispatch an error event if we can.
                if (typeof window !== "undefined") window.dispatchEvent(new CustomEvent("error", { detail: error }));
            }
        }

        this.previousVisibilityState = this.visibilityTransition;
        this.visibilityTransition = false;
    }
}

export const pageVisibilityFactory = Injectable("pageVisibility", () => new PageVisibility());
