import type { Logger } from "./logger";

/**
 * Creates a class member decorator that logs errors thrown during the member invocation.
 * If the member returns a Promise, promise rejections are handled.
 * Any caught errors are re-thrown.
 * @param logger The logger to report errors to.
 * @return A decorator function.
 */
export function errorLoggingDecorator<This, Args extends any[], Return>(logger: Logger) {
    const handleError = (error: unknown) => logger.error(error);
    return function validator(
        target: (this: This, ...args: Args) => Return,
        _context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
    ): (this: This, ...args: Args) => Return {
        return function (this, ...args) {
            try {
                // "this" refers to original scope this function is called in
                const result = target.apply(this, args);
                if (result instanceof Promise) {
                    // in case of a promise we don't return our promise chain
                    // that logs an error because we want callers to handle the error as well
                    result.catch(handleError);
                }
                return result;
            } catch (error) {
                handleError(error);
                throw error;
            }
        };
    };
}
