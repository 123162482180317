import { LaunchData } from "../generated-proto/pb_schema/lenses/launchdata";
import {
    isArrayOfType,
    isDateOrUndefined,
    isValidNumberOrUndefined,
    isRecord,
    isString,
    isStringOrUndefined,
    isUndefined,
    isValidNumber,
    predicateRecordValues,
} from "../common/typeguards";
import type { LaunchParams } from "../generated-proto/pb_schema/lenses/launch_params";
import { UserData_Zodiac } from "../generated-proto/pb_schema/lenses/user_data";
import type { EnumToPublicStringLiteralMap, ExcludeKeys } from "../common/types";

/**
 * Lens launch params.
 */
export type LensLaunchParams = Record<string, string | number | string[] | number[]>;

/**
 * Some Lenses may accept (or require) certain data provided to them when the Lens is applied.
 *
 * This data may include things like user info (to render the user's name, for example, or perform some task based on
 * their birth date), or arbitrary `launchParams` defined by the Lens.
 *
 * @category Lenses
 */
export interface LensLaunchData {
    userId?: string;
    userData?: LensUserData;
    launchParams?: LensLaunchParams;
}

export interface LensUserData {
    userId?: string;
    username?: string;
    birthdate?: string;
    displayName?: string;
    countrycode?: string;
    score?: string;
    bitmojiInfo?: BitmojiUserInfo;
    friendInfo?: FriendUserInfo;
    zodiac?: Zodiac;
}

export interface BitmojiUserInfo {
    avatarId?: string;
    selfieId?: string;
}

export interface FriendUserInfo {
    friendshipStart?: Date;
    lastInteraction?: Date;
    streak?: number;
}

// Extract public values of UserData_Zodiac
const zodiacMap: ExcludeKeys<EnumToPublicStringLiteralMap<typeof UserData_Zodiac>, "invalid" | "unrecognized"> = {
    aquarius: UserData_Zodiac.Aquarius,
    aries: UserData_Zodiac.Aries,
    cancer: UserData_Zodiac.Cancer,
    capricorn: UserData_Zodiac.Capricorn,
    gemini: UserData_Zodiac.Gemini,
    leo: UserData_Zodiac.Leo,
    libra: UserData_Zodiac.Libra,
    pisces: UserData_Zodiac.Pisces,
    sagittarius: UserData_Zodiac.Sagittarius,
    scorpio: UserData_Zodiac.Scorpio,
    taurus: UserData_Zodiac.Taurus,
    virgo: UserData_Zodiac.Virgo,
    // NOTE: This rule helps keep the public-facing Zodiac type consistent with the proto.
    // We prefer using a separate type for TypeDoc purposes.
} satisfies Record<Zodiac, UserData_Zodiac>;

export type Zodiac =
    | "aquarius"
    | "aries"
    | "cancer"
    | "capricorn"
    | "gemini"
    | "leo"
    | "libra"
    | "pisces"
    | "sagittarius"
    | "scorpio"
    | "taurus"
    | "virgo";

const zodiacValueSet = new Set(Object.keys(zodiacMap) as Zodiac[]);

export function isZodiac(value: unknown): value is Zodiac {
    return zodiacValueSet.has(value as Zodiac);
}

export function isLensLaunchDataOrUndefined(value: unknown): value is LensLaunchData | undefined {
    return isUndefined(value) || isLensLaunchData(value);
}

function isLensLaunchData(value: unknown): value is LensLaunchData {
    return (
        isRecord(value) &&
        isStringOrUndefined(value.userId) &&
        isLensUserDataOrUndefined(value.userData) &&
        isLensLaunchParamsOrUndefined(value.launchParams)
    );
}

function isLensUserDataOrUndefined(value: unknown): value is LensUserData | undefined {
    return isUndefined(value) || isLensUserData(value);
}

function isLensUserData(value: unknown): value is LensUserData {
    return (
        isRecord(value) &&
        isStringOrUndefined(value.userId) &&
        isStringOrUndefined(value.username) &&
        isStringOrUndefined(value.birthdate) &&
        isStringOrUndefined(value.displayName) &&
        isStringOrUndefined(value.countrycode) &&
        isStringOrUndefined(value.score) &&
        isBitmojiUserInfoOrUndefined(value.bitmojiInfo) &&
        isFriendUserInfoOrUndefined(value.friendInfo) &&
        isZodiacOrUndefined(value.zodiac)
    );
}

function isBitmojiUserInfoOrUndefined(value: unknown): value is BitmojiUserInfo | undefined {
    return isUndefined(value) || isBitmojiUserInfo(value);
}

function isBitmojiUserInfo(value: unknown): value is BitmojiUserInfo {
    return isRecord(value) && isStringOrUndefined(value.avatarId) && isStringOrUndefined(value.selfieId);
}

function isFriendUserInfoOrUndefined(value: unknown): value is FriendUserInfo | undefined {
    return isUndefined(value) || isFriendUserInfo(value);
}

function isFriendUserInfo(value: unknown): value is FriendUserInfo {
    return (
        isRecord(value) &&
        isDateOrUndefined(value.friendshipStart) &&
        isDateOrUndefined(value.lastInteraction) &&
        isValidNumberOrUndefined(value.streak)
    );
}

function isZodiacOrUndefined(value: unknown): value is Zodiac | undefined {
    return isUndefined(value) || isZodiac(value);
}

function isLensLaunchParamsOrUndefined(value: unknown): value is LensLaunchParams | undefined {
    return isUndefined(value) || isLensLaunchParams(value);
}

function isLensLaunchParams(value: unknown): value is LensLaunchParams {
    return isRecord(value) && predicateRecordValues(isStringOrNumberOrArrayOfStringsOrNumbers)(value);
}

function isStringOrNumberOrArrayOfStringsOrNumbers(value: unknown): value is string | number | string[] | number[] {
    return (
        isString(value) || isValidNumber(value) || isArrayOfType(isString, value) || isArrayOfType(isValidNumber, value)
    );
}

/**
 * @internal
 */
export const encodeLensLaunchData = (launchData: LensLaunchData, persistentStore: ArrayBuffer): Uint8Array => {
    // finish() protobufjs method returns UInt8Array with shared ArrayBuffer
    // to avoid of detached buffer error when passing data to Lens Core
    // data should be copied using slice() method
    return LaunchData.encode(
        LaunchData.fromPartial({
            ...launchData,
            userData: launchData.userData
                ? {
                      ...launchData.userData,
                      zodiac: launchData.userData?.zodiac ? zodiacMap[launchData.userData.zodiac] : undefined,
                  }
                : undefined,
            launchParams: launchData.launchParams ? encodeLensLaunchParams(launchData.launchParams) : undefined,
            persistentStore: { store: new Uint8Array(persistentStore) },
        })
    )
        .finish()
        .slice();
};

function encodeLensLaunchParams(launchParams?: LensLaunchParams): LaunchParams {
    return { data: new TextEncoder().encode(JSON.stringify(launchParams)) };
}
