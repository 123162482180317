import type { OperatorFunction, SchedulerLike } from "rxjs";
import { Observable, asyncScheduler, buffer, debounceTime, tap } from "rxjs";

export type Debounced<T> =
    | {
          type: "initial";
          value: T;
      }
    | {
          type: "debounced";
          values: T[];
      };

/**
 * Creates an RxJS operator to emit the first source value immediately, and then buffers subsequent
 * source values for a specified duration. Once the duration elapses, it emits all buffered values
 * from the source observable as an array. The operator resets and repeats this behavior for each
 * source emission series.
 *
 * This operator is useful for scenarios where immediate feedback is required from the first event,
 * followed by a collective response to all subsequent events within the debounce period.
 *
 * @param {number} amount The number of initial events to pass through immediately.
 * @param {number} duration The debounce time in milliseconds during which subsequent events are collected.
 * @param {SchedulerLike} scheduler The scheduler to use for managing the timers that handle the debounce mechanism.
 *                                  Defaults to `asyncScheduler`.
 * @returns {OperatorFunction<T, Debounced<T>>} An RxJS operator function that emits an object containing
 * the immediate event and an array of debounced events.
 */
export function debounceTimeAfter<T>(
    amount: number,
    duration: number,
    scheduler: SchedulerLike = asyncScheduler
): OperatorFunction<T, Debounced<T>> {
    return (source: Observable<T>): Observable<Debounced<T>> => {
        return new Observable<Debounced<T>>((subscriber) => {
            // keep track of iteration count until flow completes
            let iterationCount = 0;

            return source
                .pipe(
                    tap((value) => {
                        // increment iteration count
                        iterationCount++;
                        // emit value to subscriber when it is <= iteration amount
                        if (iterationCount <= amount) {
                            subscriber.next({ type: "initial", value });
                        }
                    }),
                    // debounce according to provided duration
                    buffer(source.pipe(debounceTime(duration, scheduler))),
                    tap((values) => {
                        // emit subsequent values to subscriber
                        if (iterationCount > amount) {
                            subscriber.next({ type: "debounced", values: values.slice(amount) });
                        }
                        // reset iteration count when debounce is completed
                        iterationCount = 0;
                    })
                )
                .subscribe();
        });
    };
}
