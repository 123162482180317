import Long from "long";
import _m0 from "protobufjs/minimal";
import { Lens } from "./lens";
export const protobufPackage = "com.snap.camerakit.v3";
export interface ExportLensesByIdRequest {
    unlockableIds: string[];
    context: ExportLensesByIdRequest_Context | undefined;
}
export interface ExportLensesByIdRequest_Context {
    userAgent: string;
    locale: string;
    extention: ExportLensesByIdRequest_Context_Extension | undefined;
    extension: ExportLensesByIdRequest_Context_Extension | undefined;
    extensionRequestContext: Uint8Array;
}
export interface ExportLensesByIdRequest_Context_Extension {
    name: ExportLensesByIdRequest_Context_Extension_Name;
    version: string;
}
export enum ExportLensesByIdRequest_Context_Extension_Name {
    UNSET = "UNSET",
    SHOP_KIT = "SHOP_KIT",
    LENS_WEB_BUILDER = "LENS_WEB_BUILDER",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function exportLensesByIdRequest_Context_Extension_NameFromJSON(object: any): ExportLensesByIdRequest_Context_Extension_Name {
    switch (object) {
        case 0:
        case "UNSET":
            return ExportLensesByIdRequest_Context_Extension_Name.UNSET;
        case 1:
        case "SHOP_KIT":
            return ExportLensesByIdRequest_Context_Extension_Name.SHOP_KIT;
        case 2:
        case "LENS_WEB_BUILDER":
            return ExportLensesByIdRequest_Context_Extension_Name.LENS_WEB_BUILDER;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ExportLensesByIdRequest_Context_Extension_Name.UNRECOGNIZED;
    }
}
export function exportLensesByIdRequest_Context_Extension_NameToNumber(object: ExportLensesByIdRequest_Context_Extension_Name): number {
    switch (object) {
        case ExportLensesByIdRequest_Context_Extension_Name.UNSET:
            return 0;
        case ExportLensesByIdRequest_Context_Extension_Name.SHOP_KIT:
            return 1;
        case ExportLensesByIdRequest_Context_Extension_Name.LENS_WEB_BUILDER:
            return 2;
        default:
            return 0;
    }
}
export interface ExportLensesByIdResponse {
    lenses: {
        [key: string]: Uint8Array;
    };
    excludedLenses: ExportLensesByIdResponse_ExcludedLens[];
}
export interface ExportLensesByIdResponse_LensesEntry {
    key: string;
    value: Uint8Array;
}
export interface ExportLensesByIdResponse_ExcludedLens {
    lensId: string;
    code: ExportLensesByIdResponse_ExcludedLens_Code;
    reason: string;
}
export enum ExportLensesByIdResponse_ExcludedLens_Code {
    UNSET = "UNSET",
    UNKNOWN = "UNKNOWN",
    NOT_FOUND = "NOT_FOUND",
    INCOMPATIBLE_LENS_CORE_VERSION = "INCOMPATIBLE_LENS_CORE_VERSION",
    ARCHIVED_OR_INVISIBLE = "ARCHIVED_OR_INVISIBLE",
    CONTAINS_MUSIC = "CONTAINS_MUSIC",
    UNRECOGNIZED = "UNRECOGNIZED"
}
export function exportLensesByIdResponse_ExcludedLens_CodeFromJSON(object: any): ExportLensesByIdResponse_ExcludedLens_Code {
    switch (object) {
        case 0:
        case "UNSET":
            return ExportLensesByIdResponse_ExcludedLens_Code.UNSET;
        case 1:
        case "UNKNOWN":
            return ExportLensesByIdResponse_ExcludedLens_Code.UNKNOWN;
        case 2:
        case "NOT_FOUND":
            return ExportLensesByIdResponse_ExcludedLens_Code.NOT_FOUND;
        case 3:
        case "INCOMPATIBLE_LENS_CORE_VERSION":
            return ExportLensesByIdResponse_ExcludedLens_Code.INCOMPATIBLE_LENS_CORE_VERSION;
        case 4:
        case "ARCHIVED_OR_INVISIBLE":
            return ExportLensesByIdResponse_ExcludedLens_Code.ARCHIVED_OR_INVISIBLE;
        case 5:
        case "CONTAINS_MUSIC":
            return ExportLensesByIdResponse_ExcludedLens_Code.CONTAINS_MUSIC;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ExportLensesByIdResponse_ExcludedLens_Code.UNRECOGNIZED;
    }
}
export function exportLensesByIdResponse_ExcludedLens_CodeToNumber(object: ExportLensesByIdResponse_ExcludedLens_Code): number {
    switch (object) {
        case ExportLensesByIdResponse_ExcludedLens_Code.UNSET:
            return 0;
        case ExportLensesByIdResponse_ExcludedLens_Code.UNKNOWN:
            return 1;
        case ExportLensesByIdResponse_ExcludedLens_Code.NOT_FOUND:
            return 2;
        case ExportLensesByIdResponse_ExcludedLens_Code.INCOMPATIBLE_LENS_CORE_VERSION:
            return 3;
        case ExportLensesByIdResponse_ExcludedLens_Code.ARCHIVED_OR_INVISIBLE:
            return 4;
        case ExportLensesByIdResponse_ExcludedLens_Code.CONTAINS_MUSIC:
            return 5;
        default:
            return 0;
    }
}
export interface ExtensionRequestContext {
    userAgent: string;
    locale: string;
}
export interface Envelope {
    lenses: Lens[];
}
function createBaseExportLensesByIdRequest(): ExportLensesByIdRequest {
    return { unlockableIds: [], context: undefined };
}
export const ExportLensesByIdRequest = {
    encode(message: ExportLensesByIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        writer.uint32(10).fork();
        for (const v of message.unlockableIds) {
            writer.int64(v);
        }
        writer.ldelim();
        if (message.context !== undefined) {
            ExportLensesByIdRequest_Context.encode(message.context, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ExportLensesByIdRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExportLensesByIdRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.unlockableIds.push(longToString(reader.int64() as Long));
                        }
                    }
                    else {
                        message.unlockableIds.push(longToString(reader.int64() as Long));
                    }
                    break;
                case 2:
                    message.context = ExportLensesByIdRequest_Context.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ExportLensesByIdRequest>, I>>(object: I): ExportLensesByIdRequest {
        const message = createBaseExportLensesByIdRequest();
        message.unlockableIds = object.unlockableIds?.map((e) => e) || [];
        message.context =
            object.context !== undefined && object.context !== null
                ? ExportLensesByIdRequest_Context.fromPartial(object.context)
                : undefined;
        return message;
    },
};
function createBaseExportLensesByIdRequest_Context(): ExportLensesByIdRequest_Context {
    return {
        userAgent: "",
        locale: "",
        extention: undefined,
        extension: undefined,
        extensionRequestContext: new Uint8Array(),
    };
}
export const ExportLensesByIdRequest_Context = {
    encode(message: ExportLensesByIdRequest_Context, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.userAgent !== "") {
            writer.uint32(10).string(message.userAgent);
        }
        if (message.locale !== "") {
            writer.uint32(18).string(message.locale);
        }
        if (message.extention !== undefined) {
            ExportLensesByIdRequest_Context_Extension.encode(message.extention, writer.uint32(26).fork()).ldelim();
        }
        if (message.extension !== undefined) {
            ExportLensesByIdRequest_Context_Extension.encode(message.extension, writer.uint32(34).fork()).ldelim();
        }
        if (message.extensionRequestContext.length !== 0) {
            writer.uint32(42).bytes(message.extensionRequestContext);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ExportLensesByIdRequest_Context {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExportLensesByIdRequest_Context();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.userAgent = reader.string();
                    break;
                case 2:
                    message.locale = reader.string();
                    break;
                case 3:
                    message.extention = ExportLensesByIdRequest_Context_Extension.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.extension = ExportLensesByIdRequest_Context_Extension.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.extensionRequestContext = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ExportLensesByIdRequest_Context>, I>>(object: I): ExportLensesByIdRequest_Context {
        const message = createBaseExportLensesByIdRequest_Context();
        message.userAgent = object.userAgent ?? "";
        message.locale = object.locale ?? "";
        message.extention =
            object.extention !== undefined && object.extention !== null
                ? ExportLensesByIdRequest_Context_Extension.fromPartial(object.extention)
                : undefined;
        message.extension =
            object.extension !== undefined && object.extension !== null
                ? ExportLensesByIdRequest_Context_Extension.fromPartial(object.extension)
                : undefined;
        message.extensionRequestContext = object.extensionRequestContext ?? new Uint8Array();
        return message;
    },
};
function createBaseExportLensesByIdRequest_Context_Extension(): ExportLensesByIdRequest_Context_Extension {
    return { name: ExportLensesByIdRequest_Context_Extension_Name.UNSET, version: "" };
}
export const ExportLensesByIdRequest_Context_Extension = {
    encode(message: ExportLensesByIdRequest_Context_Extension, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.name !== ExportLensesByIdRequest_Context_Extension_Name.UNSET) {
            writer.uint32(8).int32(exportLensesByIdRequest_Context_Extension_NameToNumber(message.name));
        }
        if (message.version !== "") {
            writer.uint32(18).string(message.version);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ExportLensesByIdRequest_Context_Extension {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExportLensesByIdRequest_Context_Extension();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = exportLensesByIdRequest_Context_Extension_NameFromJSON(reader.int32());
                    break;
                case 2:
                    message.version = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ExportLensesByIdRequest_Context_Extension>, I>>(object: I): ExportLensesByIdRequest_Context_Extension {
        const message = createBaseExportLensesByIdRequest_Context_Extension();
        message.name = object.name ?? ExportLensesByIdRequest_Context_Extension_Name.UNSET;
        message.version = object.version ?? "";
        return message;
    },
};
function createBaseExportLensesByIdResponse(): ExportLensesByIdResponse {
    return { lenses: {}, excludedLenses: [] };
}
export const ExportLensesByIdResponse = {
    encode(message: ExportLensesByIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        Object.entries(message.lenses).forEach(([key, value]) => {
            ExportLensesByIdResponse_LensesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
        });
        for (const v of message.excludedLenses) {
            ExportLensesByIdResponse_ExcludedLens.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ExportLensesByIdResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExportLensesByIdResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = ExportLensesByIdResponse_LensesEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.lenses[entry1.key] = entry1.value;
                    }
                    break;
                case 2:
                    message.excludedLenses.push(ExportLensesByIdResponse_ExcludedLens.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ExportLensesByIdResponse>, I>>(object: I): ExportLensesByIdResponse {
        const message = createBaseExportLensesByIdResponse();
        message.lenses = Object.entries(object.lenses ?? {}).reduce<{
            [key: string]: Uint8Array;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});
        message.excludedLenses =
            object.excludedLenses?.map((e) => ExportLensesByIdResponse_ExcludedLens.fromPartial(e)) || [];
        return message;
    },
};
function createBaseExportLensesByIdResponse_LensesEntry(): ExportLensesByIdResponse_LensesEntry {
    return { key: "0", value: new Uint8Array() };
}
export const ExportLensesByIdResponse_LensesEntry = {
    encode(message: ExportLensesByIdResponse_LensesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "0") {
            writer.uint32(8).int64(message.key);
        }
        if (message.value.length !== 0) {
            writer.uint32(18).bytes(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ExportLensesByIdResponse_LensesEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExportLensesByIdResponse_LensesEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.value = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ExportLensesByIdResponse_LensesEntry>, I>>(object: I): ExportLensesByIdResponse_LensesEntry {
        const message = createBaseExportLensesByIdResponse_LensesEntry();
        message.key = object.key ?? "0";
        message.value = object.value ?? new Uint8Array();
        return message;
    },
};
function createBaseExportLensesByIdResponse_ExcludedLens(): ExportLensesByIdResponse_ExcludedLens {
    return { lensId: "0", code: ExportLensesByIdResponse_ExcludedLens_Code.UNSET, reason: "" };
}
export const ExportLensesByIdResponse_ExcludedLens = {
    encode(message: ExportLensesByIdResponse_ExcludedLens, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.lensId !== "0") {
            writer.uint32(8).int64(message.lensId);
        }
        if (message.code !== ExportLensesByIdResponse_ExcludedLens_Code.UNSET) {
            writer.uint32(16).int32(exportLensesByIdResponse_ExcludedLens_CodeToNumber(message.code));
        }
        if (message.reason !== "") {
            writer.uint32(26).string(message.reason);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ExportLensesByIdResponse_ExcludedLens {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExportLensesByIdResponse_ExcludedLens();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lensId = longToString(reader.int64() as Long);
                    break;
                case 2:
                    message.code = exportLensesByIdResponse_ExcludedLens_CodeFromJSON(reader.int32());
                    break;
                case 3:
                    message.reason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ExportLensesByIdResponse_ExcludedLens>, I>>(object: I): ExportLensesByIdResponse_ExcludedLens {
        const message = createBaseExportLensesByIdResponse_ExcludedLens();
        message.lensId = object.lensId ?? "0";
        message.code = object.code ?? ExportLensesByIdResponse_ExcludedLens_Code.UNSET;
        message.reason = object.reason ?? "";
        return message;
    },
};
function createBaseExtensionRequestContext(): ExtensionRequestContext {
    return { userAgent: "", locale: "" };
}
export const ExtensionRequestContext = {
    encode(message: ExtensionRequestContext, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.userAgent !== "") {
            writer.uint32(10).string(message.userAgent);
        }
        if (message.locale !== "") {
            writer.uint32(18).string(message.locale);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ExtensionRequestContext {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExtensionRequestContext();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.userAgent = reader.string();
                    break;
                case 2:
                    message.locale = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ExtensionRequestContext>, I>>(object: I): ExtensionRequestContext {
        const message = createBaseExtensionRequestContext();
        message.userAgent = object.userAgent ?? "";
        message.locale = object.locale ?? "";
        return message;
    },
};
function createBaseEnvelope(): Envelope {
    return { lenses: [] };
}
export const Envelope = {
    decode(input: _m0.Reader | Uint8Array, length?: number): Envelope {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseEnvelope();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lenses.push(Lens.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Envelope>, I>>(object: I): Envelope {
        const message = createBaseEnvelope();
        message.lenses = object.lenses?.map((e) => Lens.fromPartial(e)) || [];
        return message;
    },
};
export interface Export {
    ExportLensesById(request: ExportLensesByIdRequest): Promise<ExportLensesByIdResponse>;
}
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToString(long: Long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
