import type { MetricsClient } from "../../clients/metricsClient";
import { metricsClientFactory } from "../../clients/metricsClient";
import type { CameraKitConfiguration } from "../../configuration";
import { configurationToken } from "../../configuration";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import type { MakeTaggedBusinessEvent } from "../businessEventsReporter";
import type { MetricsEventTarget } from "../metricsEventTarget";
import { metricsEventTargetFactory } from "../metricsEventTarget";
import { Histogram } from "../operational/Histogram";

// Allowlist the benchmarks we wish to report.
const reportableBenchmarks = ["gflops"];

export type BenchmarkComplete = MakeTaggedBusinessEvent<"benchmarkComplete">;

export const reportBenchmarks = Injectable(
    "reportBenchmarks",
    [metricsEventTargetFactory.token, metricsClientFactory.token, configurationToken] as const,
    async (metricsEventTarget: MetricsEventTarget, metrics: MetricsClient, config: CameraKitConfiguration) => {
        if (config.lensPerformance === undefined) return;
        // Safety: config.lensPerformance cannot reject – all Promises contained in CameraKitConfiguration have
        // catch blocks which return a default value.
        const lensPerformance = await config.lensPerformance;
        const baseBenchmark: Pick<BenchmarkComplete, "name" | "performanceCluster" | "webglRendererInfo"> = {
            name: "benchmarkComplete",
            performanceCluster: `${lensPerformance.cluster}`,
            webglRendererInfo: lensPerformance.webglRendererInfo,
        };
        const dimensions = { performance_cluster: lensPerformance.cluster.toString() };
        for (const benchmark of lensPerformance.benchmarks) {
            if (!reportableBenchmarks.includes(benchmark.name)) continue;
            const benchmarkComplete: BenchmarkComplete = {
                ...baseBenchmark,
                benchmarkName: benchmark.name,
                benchmarkValue: benchmark.value,
            };
            metricsEventTarget.dispatchEvent(new TypedCustomEvent("benchmarkComplete", benchmarkComplete));
            metrics.setOperationalMetrics(Histogram.level(`benchmark.${benchmark.name}`, benchmark.value, dimensions));
        }
    }
);
