import { fromEvent, map, merge, take } from "rxjs";

/**
 * Dialog options.
 */
interface DialogOptions<Keys extends string> {
    /**
     * Element to attach the dialgo to.
     */
    container: HTMLElement;
    /**
     * Body content string. Can be HTML string.
     */
    body?: string;
    /**
     * Title string to render. Can be HTML string.
     */
    title?: string;
    /**
     * Accessible dialog title text,
     * especially useful for voice-over features when the title field is an image.
     */
    titleText?: string;
    /**
     * "data-testid" attribute for testing purposes.
     */
    dataTestId?: string;
    /**
     * Use the lang field to set "lang" attribute on the dialog if that should be different from the parent page.
     */
    lang?: string;
    /**
     * Accessbile text of dismiss (X) button,
     * especially useful for voice-over features when the title field is an image.
     */
    dismissButtonText?: string;
    /**
     * Buttons to display at the bottom of the dialog.
     */
    buttons?: DialogButton<Keys>[];
}

/**
 * Dialog button.
 */
interface DialogButton<Key extends string> {
    /**
     * Button text.
     */
    text: string;
    /**
     * Value returned by the {@link showDialog} function after the button is clicked.
     */
    key: Key;
    /**
     * Whether to apply "secondary" styles on the button.
     */
    isSecondary?: boolean;
}

type DismissKey = "dismiss";

const stylesCss = `
dialog {
    display: flex;
    flex-direction: column;

    background-color: #fff;
    border: #efefef 1px solid;
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);

    max-width: 80vw;
    max-height: 80vh;
    padding: 44px 0 24px 0;

    font-size: 16px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

dialog::backdrop {
    background-color: rgba(0, 0, 0, 0.4);
}

.title {
    color: #16191C;
    padding: 0 32px;
    text-align: center;
}

.body {
    color: #656D78;
    font-size: 14px;
    font-weight: 500;
    margin-top: 16px;
    max-width: 350px;
    padding: 0 32px;
    overflow: auto;
}

a {
    color: rgb(78, 171, 248);
}

button {
    cursor: pointer;
}

button.dismiss {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 0;
    height: 36px;
    width: 36px;
    margin: 0;
    background-color: transparent;
    border: 0;
}

button.dismiss svg {
    fill: black;
}

.buttons {
    margin-top: 8px;
    padding: 0 32px;
}

.buttons button {
    background: #0FADFF;
    border: 0;
    border-radius: 25px;

    width: 100%;
    padding: 1rem;
    margin-top: 8px;

    color: #fff;
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
}

.buttons button.secondary {
    background-color: transparent;
    color: #656D78;
}

// Proper filling of X button in High Contrast themes
@media (forced-colors: active) {
    button.dismiss svg {
        fill: ButtonText;
    }
}
`;

function getDismissButtonHtml(button: DialogButton<DismissKey>) {
    /* eslint-disable max-len */
    return `
        <button class="dismiss" autofocus data-key=${button.key}>
            <svg xmlns="http://www.w3.org/2000/svg" role="img" width="36" height="36" viewBox="0 0 36 36">
                <title>${button.text}</title>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6763 11.2621C12.2858 10.8716 11.6527 10.8716 11.2621 11.2621C10.8716 11.6527 10.8716 12.2858 11.2621 12.6763L16.5858 18L11.2621 23.3237C10.8716 23.7142 10.8716 24.3474 11.2621 24.7379C11.6527 25.1284 12.2858 25.1284 12.6764 24.7379L18 19.4142L23.3237 24.7379C23.7142 25.1284 24.3474 25.1284 24.7379 24.7379C25.1284 24.3474 25.1284 23.7142 24.7379 23.3237L19.4142 18L24.7379 12.6763C25.1284 12.2858 25.1284 11.6527 24.7379 11.2621C24.3474 10.8716 23.7142 10.8716 23.3237 11.2621L18 16.5858L12.6763 11.2621Z" fill-opacity="0.4"/>
            </svg>
        </button>`;
    /* eslint-enable */
}

function getTitleHtml(title: string | undefined) {
    return title ? `<div class="title" role="heading">${title}</div>` : "";
}

function getBodyHtml(body: string | undefined) {
    return body ? `<div class="body">${body}</div>` : "";
}

function getButtonHtml<Key extends string>(button: DialogButton<Key>) {
    return `<button data-key="${button.key}"${button.isSecondary ? ` class="secondary"` : ""}>${button.text}</button>`;
}

function getButtonsHtml<Key extends string>(buttons: DialogButton<Key>[]) {
    if (buttons.length === 0) return "";
    return `
        <div class="buttons">
        ${buttons.map((b) => getButtonHtml(b)).join("\n")}
        </div>`;
}

function setAttribute(element: HTMLElement, attr: string, value: string | undefined) {
    if (value) element.setAttribute(attr, value);
}

export function showDialog<Keys extends string>(options: DialogOptions<Keys>): Promise<Keys | DismissKey> {
    return new Promise((res) => {
        const element = document.createElement("div");
        setAttribute(element, "data-testid", options.dataTestId);
        const shadow = element.attachShadow({ mode: "open" });

        const style = document.createElement("style");
        shadow.appendChild(style);
        style.innerHTML = stylesCss;

        const prompt = document.createElement("dialog");
        setAttribute(prompt, "aria-label", options.titleText ?? options.title);
        setAttribute(prompt, "lang", options.lang);
        setAttribute(prompt, "dir", "auto");
        shadow.appendChild(prompt);

        prompt.innerHTML = `
            ${getDismissButtonHtml({ key: "dismiss", text: options.dismissButtonText ?? "Dismiss" })}
            ${getTitleHtml(options.title)}
            ${getBodyHtml(options.body)}
            ${getButtonsHtml(options.buttons ?? [])}
        `;

        const buttonsElements = Array.from(prompt.querySelectorAll("button"));
        merge(
            ...buttonsElements.map((b) => fromEvent(b, "click").pipe(map(() => b.dataset.key as Keys))),
            fromEvent(prompt, "cancel").pipe(map(() => "dismiss" as const))
        )
            .pipe(take(1))
            .subscribe({ next: res, complete: () => element.remove() });
        options.container.appendChild(element);
        prompt.showModal();
    });
}
