import type { Observable, Subscription } from "rxjs";
import { Subject, map } from "rxjs";
import { copyDefinedProperties } from "../common/copyDefinedProperties";
import { ensureError, stringifyErrorMessage } from "../common/errorHelpers";
import type { LensCoreError } from "../lens-core-module/lensCoreError";
import { Transform2D } from "../transforms/Transform2D";
import { debounceTimeAfter } from "../observable-operators/debounceTimeAfter";
import type { CameraKitDeviceOptions } from "./CameraKitSource";
import { CameraKitSource, defaultDeviceInfo } from "./CameraKitSource";

const defaultOptions: MediaStreamSourceOptions = {
    ...defaultDeviceInfo,
    transform: Transform2D.Identity,
    disableSourceAudio: false,
};

function closeWorklet(worklet: AudioWorkletNode | undefined) {
    if (!worklet) return;
    worklet.port.close();
    worklet.port.onmessage = null;
    worklet.disconnect();
}

async function closeAudioContext(audioContext: AudioContext | undefined) {
    if (!audioContext || audioContext.state === "closed") return;
    return audioContext.close();
}

function handleAudioProcessingErrors(errors: Observable<LensCoreError>, reportError: (error: Error) => void) {
    return errors
        .pipe(
            // Emit the first error immediately and debounce any subsequent errors for 1 second.
            debounceTimeAfter(1, 1000),

            map((event) => {
                if (event.type === "initial") {
                    reportError(
                        new Error("The first audio processing error before debouncing.", { cause: event.value })
                    );
                } else if (event.type === "debounced") {
                    const errorMessages = [...new Set(event.values.map(stringifyErrorMessage))].join("\n");
                    reportError(
                        new Error(`Debounced ${event.values.length} audio processing errors.`, {
                            cause: new Error(errorMessages),
                        })
                    );
                }
            })
        )
        .subscribe();
}

/**
 * Media stream source options.
 *
 * @category Rendering
 */
export interface MediaStreamSourceOptions extends CameraKitDeviceOptions {
    /**
     * Specifies the 2D transformation to apply to the Lens.
     * By default, CameraKit applies no transformation.
     */
    transform: Transform2D;

    /**
     * Indicates whether the audio from the source should be disabled.
     * By default, CameraKit passes audio to the Lens.
     */
    disableSourceAudio: boolean;
}

/**
 * Create a {@link CameraKitSource} from any
 * [MediaStream](https://developer.mozilla.org/en-US/docs/Web/API/MediaStream).
 *
 * @param stream Any MediaStream, such as obtained via `canvas.captureStream()` or `mediaDevices.getUserMedia()`.
 * @param options Options.
 *
 * @category Rendering
 */
export function createMediaStreamSource(
    stream: MediaStream,
    options: Partial<MediaStreamSourceOptions> = {}
): CameraKitSource {
    const { facingMode } =
        stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0].getSettings() : { facingMode: undefined };

    const detectedCameraType = facingMode === "user" || facingMode === "environment" ? facingMode : undefined;

    const optionsWithDefaults = {
        ...defaultOptions,
        ...copyDefinedProperties(options),
        cameraType: options.cameraType ?? detectedCameraType,
    };

    const enableSourceAudio: boolean = stream.getAudioTracks().length > 0 && !optionsWithDefaults.disableSourceAudio;

    const simulateStereoAudio = true;
    const sampleRate: number = 44100;

    let audioContext: AudioContext | undefined = undefined;
    let audioSource: MediaStreamAudioSourceNode | undefined = undefined;
    let worklet: AudioWorkletNode | undefined = undefined;
    let microphoneRecorderUrl: string;

    if (enableSourceAudio) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_AudioWorklet
        const microphoneRecorderWorklet = `
        class MicrophoneWorkletProcessor extends AudioWorkletProcessor {
            process(inputs, outputs, parameters) {
                this.port.postMessage({
                    eventType: 'data',
                    buffer: inputs
                });
                return true;
            }
        }
        registerProcessor('microphone-worklet', MicrophoneWorkletProcessor);`;
        const microphoneRecorderBlob = new Blob([microphoneRecorderWorklet], {
            type: "application/javascript",
        });
        microphoneRecorderUrl = URL.createObjectURL(microphoneRecorderBlob);
    }

    // Subscription for audio processing errors.
    let audioProcessingErrorSubscription: Subscription | undefined = undefined;

    return new CameraKitSource(
        { media: stream },
        {
            onAttach: async (source, lensCore, reportError) => {
                await source.setTransform(optionsWithDefaults.transform);

                if (enableSourceAudio) {
                    // We call LensCore.processAudioSampleBuffer every time microphone data appears,
                    // which occurs multiple times per second.
                    // It may happen that LensCore enters an inoperable state, resulting in the call failing with
                    // the same error over and over again.
                    // Instead of reporting all errors, we debounce them within a second and only report two errors:
                    // 1. The initial error when the debounce starts.
                    // 2. An aggregated error that includes unique error messages from all errors
                    // within the debounce period.
                    const audioProcessingErrors = new Subject<LensCoreError>();
                    audioProcessingErrorSubscription = handleAudioProcessingErrors(audioProcessingErrors, reportError);

                    // Audio parameters set has to be called before lens is applied
                    await lensCore.setAudioParameters({
                        parameters: {
                            numChannels: simulateStereoAudio ? 2 : 1,
                            sampleRate,
                        },
                    });

                    try {
                        // There is a possibility of the onAttach method being called twice in a row due to a bug.
                        // To ensure there are not leaks, it is better to close any existing connections.
                        closeWorklet(worklet);
                        audioSource?.disconnect();
                        await closeAudioContext(audioContext);
                    } catch (error) {
                        // We still want to continue if anything above failed
                        reportError(ensureError(error));
                    }

                    audioContext = new AudioContext();
                    audioSource = audioContext.createMediaStreamSource(stream);
                    const scopedAudioSource = audioSource;
                    audioContext.audioWorklet
                        .addModule(microphoneRecorderUrl)
                        .then(() => {
                            if (audioContext) {
                                worklet = new AudioWorkletNode(audioContext, "microphone-worklet");
                                scopedAudioSource.connect(worklet);
                                worklet.connect(audioContext.destination);
                                // NOTE: We subscribe to messages here, and they will continue to arrive
                                // even after audioContext.close() is called. To disconnect the audio worklets
                                // created here, we need to track two variables - worklet and audioSource.
                                // By calling disconnect() on them, we can properly
                                // disconnect the audio worklets.
                                worklet.port.onmessage = (e) => {
                                    if (e.data.eventType === "data") {
                                        // developer.mozilla.org/en-US/docs/Web/API/AudioWorkletProcessor/process
                                        // inputs[n][m] is the list of samples in the n-th input at the m-th channel.
                                        const leftSamples = e.data.buffer[0][0] as Float32Array;

                                        // Firefox might have leftSamples undefined:
                                        // https://jira.sc-corp.net/browse/CAMKIT-5189
                                        if (!leftSamples) return;

                                        let inputBuffers = [leftSamples];
                                        if (simulateStereoAudio) {
                                            const rightSamples =
                                                e.data.buffer[0].length > 1 ? e.data.buffer[0][1] : leftSamples.slice();
                                            inputBuffers.push(rightSamples);
                                        }

                                        lensCore
                                            .processAudioSampleBuffer({ input: inputBuffers })
                                            .catch((error) => audioProcessingErrors.next(error));
                                    }
                                };
                            }
                        })
                        .catch((error: Error) => {
                            reportError(error);
                        });
                }
            },
            onDetach: async (reportError) => {
                if (worklet) {
                    closeWorklet(worklet);
                    worklet = undefined;
                }
                if (audioSource) {
                    audioSource.disconnect();
                    audioSource = undefined;
                }
                if (audioContext) {
                    await closeAudioContext(audioContext).catch(reportError);
                    audioContext = undefined;
                }
                if (audioProcessingErrorSubscription) {
                    audioProcessingErrorSubscription.unsubscribe();
                    audioProcessingErrorSubscription = undefined;
                }
            },
        },
        optionsWithDefaults
    );
}
