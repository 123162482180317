import { Injectable } from "../../dependency-injection/Injectable";
import type { LensState } from "../../session/lensState";
import { lensStateFactory } from "../../session/lensState";
import type { GlobalExceptionReporter } from "./reportGlobalException";
import { reportGlobalException } from "./reportGlobalException";

/**
 * Reports log entries to Blizzard during a CameraKit session.
 *
 * @internal
 */
export const reportSessionException = Injectable(
    "reportSessionException",
    [reportGlobalException.token, lensStateFactory.token] as const,
    (globalExceptionReporter: GlobalExceptionReporter, lensState: LensState) => {
        globalExceptionReporter.attachLensContext(lensState);
    }
);
