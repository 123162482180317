import { validate } from "../common/validate";
import { isArrayOfType, isSafeString, isSafeStringArray, isString, isUndefined } from "../common/typeguards";
import { Injectable } from "../dependency-injection/Injectable";
import type { FetchHandler } from "../handlers/defaultFetchHandler";
import { defaultFetchHandlerFactory } from "../handlers/defaultFetchHandler";
import type { RequestStateEventTarget } from "../handlers/requestStateEmittingHandler";
import {
    createRequestStateEmittingHandler,
    requestStateEventTargetFactory,
} from "../handlers/requestStateEmittingHandler";
import type { LensDownloadDimensions } from "../metrics/reporters/reportLensAndAssetDownload";
import type { Handler } from "../handlers/HandlerChainBuilder";
import { HandlerChainBuilder } from "../handlers/HandlerChainBuilder";
import { createArrayBufferParsingHandler } from "../handlers/arrayBufferParsingHandler";
import { LensAssetManifestItem_RequestTiming } from "../generated-proto/pb_schema/camera_kit/v3/lens";
import { getLogger } from "../logger/logger";
import { errorLoggingDecorator } from "../logger/errorLoggingDecorator";
import { ensureError } from "../common/errorHelpers";
import { withRequestPriority } from "../handlers/utils";
import type { EnumToPublicStringLiteralMap, ExcludeKeys } from "../common/types";
import type { Lens, LensProtoWithGroupId } from "./Lens";
import { isLensArray, toPublicLens } from "./Lens";
import type { LensAssetRepository } from "./assets/LensAssetRepository";
import { lensAssetRepositoryFactory } from "./assets/LensAssetRepository";
import type { LensSource } from "./LensSource";
import { loadLensesFromSources, lensSourcesFactory } from "./LensSource";

const logger = getLogger("LensRepository");

type LensFetchHandler = Handler<
    [RequestInfo, LensDownloadDimensions],
    [ArrayBuffer, Response],
    RequestInit | undefined
>;

const assetTimingMap: ExcludeKeys<
    EnumToPublicStringLiteralMap<typeof LensAssetManifestItem_RequestTiming>,
    "preloadUnset" | "unrecognized"
> = {
    required: LensAssetManifestItem_RequestTiming.REQUIRED,
    onDemand: LensAssetManifestItem_RequestTiming.ON_DEMAND,
    // NOTE: This rule helps keep the public-facing AssetTiming type consistent with the proto.
    // We prefer using a separate type for TypeDoc purposes.
} satisfies Record<AssetTiming, LensAssetManifestItem_RequestTiming>;

function isAssetTiming(value: unknown): value is AssetTiming {
    return isString(value) && assetTimingMap.hasOwnProperty(value);
}

function isAssetTimingArrayOrUndefined(value: unknown): value is undefined | AssetTiming[] {
    return isUndefined(value) || isArrayOfType(isAssetTiming, value);
}

/**
 * Lens assets are included in a manifest, and each will indicate when that asset will be used by the lens.
 *
 * Assets can have the following timing values:
 * - `required`: the lens will definitely request this asset immediately when the lens is applied.
 * - `onDemand`: the lens may request this asset at some time while the lens is applied.
 *
 * Depending on the use-case, an application may want to cache both required and onDemand assets for
 * a particular lens, or may decide to only cache required assets (or cache no assets).
 *
 * @category Lenses
 */
export type AssetTiming = "required" | "onDemand";

export interface LensBinary {
    lensBuffer: ArrayBuffer;
    lensChecksum: string;
}

/**
 * The LensRepository is used to query for lenses from specific lens groups, or for a lens with a specific ID.
 *
 * Lens groups are configured in the CameraKit Portal -- that's where you'll find lens group IDs and lens IDs.
 *
 * Lenses must be loaded by the LensRepository before they can be applied to a {@link CameraKitSession}.
 *
 * @example
 * ```ts
 * const cameraKit = await bootstrapCameraKit(options)
 * const session = await cameraKit.createSession()
 * const lens = await cameraKit.lensRepository.loadLens(lensId, groupId)
 * session.applyLens(lens)
 * ```
 *
 * @category Lenses
 */
class LensRepository {
    private readonly metadataCache = new Map<string, LensProtoWithGroupId>();
    private readonly binariesCache = new Map<string, ArrayBuffer>();

    /** @internal */
    constructor(
        private readonly lensFetchHandler: LensFetchHandler,
        private readonly lensSources: LensSource[],
        private readonly lensAssetRepository: LensAssetRepository
    ) {}

    /**
     * Retrieve a single Lens.
     *
     * @param lensId Desired Lens's unique ID. Can be found in the CameraKit Portal.
     * @param groupId The ID of a group containing the desired Lens. Can be found in the CameraKit Portal.
     * @returns Resolves with the desired Lens, or rejects if an error occurred (including a missing Lens).
     */
    @validate(isSafeString, isSafeString)
    @errorLoggingDecorator(logger)
    async loadLens(lensId: string, groupId: string): Promise<Lens> {
        const lens = (await loadLensesFromSources(this.lensSources, groupId, lensId))[0];
        if (!lens) {
            throw new Error(`Cannot load lens. No lens with id ${lensId} was found in lens group ${groupId}.`);
        }
        const lensWithGroup: LensProtoWithGroupId = { ...lens, groupId };
        this.metadataCache.set(lens.id, lensWithGroup);
        return toPublicLens(lensWithGroup);
    }

    /**
     * Retrieve the Lenses contained in a list of Lens Groups.
     *
     * This may result in multiple requests to retrieve Lens data (e.g. one per desired group). If any constituent
     * requests fail, those errors will be reported in the response – but the returned Promise will not be rejected. Any
     * Lenses which could be successfully retrieved will be available in the response.
     *
     * @param groupIds A list of Lens Group IDs. Can be found in the CameraKit Portal.
     * @returns Resolves with a flattened list of all lenses in the desired groups. If any errors occurred during the
     * query operation, these will be included in a separate list. If errors are present, the list of Lenses may not
     * contain all the Lenses from the desired groups.
     */
    @validate(isSafeStringArray)
    @errorLoggingDecorator(logger)
    async loadLensGroups(groupIds: string[]): Promise<{
        errors: Error[];
        lenses: Lens[];
    }> {
        const responses = await Promise.all(
            groupIds.map(async (groupId) => {
                try {
                    return (await loadLensesFromSources(this.lensSources, groupId)).map((lens) => {
                        const lensWithGroup = { ...lens, groupId };
                        this.metadataCache.set(lens.id, lensWithGroup);
                        return toPublicLens(lensWithGroup);
                    });
                } catch (e) {
                    const error = ensureError(e);
                    logger.error(new Error(`Failed to load lens group ${groupId}.`, { cause: error }));
                    return error;
                }
            })
        );

        return responses.reduce(
            (result, response) => {
                if (response instanceof Error) result.errors.push(response);
                else result.lenses.push(...response);
                return result;
            },
            { errors: [], lenses: [] } as { errors: Error[]; lenses: Lens[] }
        );
    }

    /**
     * Loads and caches lens content and dependencies to reduce latency when {@link CameraKitSession.applyLens} is later
     * called to apply the lens. This is an in-memory cache, it will not be persisted across page loads.
     *
     * This may useful if the application A) knows which lenses will be applied and B) has some opportunity to call
     * this method before a lens is applied. For example, if the user must perform some other actions before lenses
     * become active, this might be a good opportunity to cache lenses to improve applyLens latency.
     *
     * @example
     * ```ts
     * const lens = await cameraKit.lensRepository.loadLens(lensId, groupId)
     * await cameraKit.lensRepository.cacheLensContent([lens])
     *
     * // sometime later -- this call will use the cached lens content, making lens application faster.
     * await cameraKitSession.applyLens(lens)
     * ```
     *
     * @param lenses Array of lenses to cache in memory.
     * @param assetTimingsToCache Lenses specify certain required assets that are necessary for the lens to render, and
     * other assets which may be needed by the lens. By default this method will cache all of those assets, but this
     * behavior can be modified to only load the required assets, only the "onDemand" assets, or neither (by passing
     * an empty array).
     */
    @validate(isLensArray, isAssetTimingArrayOrUndefined)
    @errorLoggingDecorator(logger)
    async cacheLensContent(lenses: Lens[], assetTimingsToCache: AssetTiming[] = ["required", "onDemand"]) {
        const assetTimingsToLoad = assetTimingsToCache.map((timing) => assetTimingMap[timing]);
        await Promise.all(
            lenses.map(async (lens) => {
                try {
                    const { lensBuffer } = await this.getLensContent(lens, true);
                    // Safety: getLensContent() call above ensures metadata to exist
                    const { content } = this.metadataCache.get(lens.id)!;
                    this.binariesCache.set(lens.id, lensBuffer);
                    await this.lensAssetRepository.cacheAssets(content!.assetManifest, lens, assetTimingsToLoad, true);
                } catch (error) {
                    logger.warn(`Failed to cache lens ${lens.id}.`, error);
                }
            })
        );
    }

    /**
     * Returns loaded Lens metadata if available.
     */
    getLensMetadata(lensId: string): LensProtoWithGroupId | undefined {
        return this.metadataCache.get(lensId);
    }

    /**
     * Removes Lens content from the in-memory cache.
     */
    removeCachedLenses(lenses: Lens[]) {
        lenses.forEach((lens) => this.binariesCache.delete(lens.id));
    }

    /**
     * Fetches lens content and assets. This may come from the cache, otherwise network requests will be made.
     *
     * @internal
     *
     * @param lens Lens to fetch content for.
     * @param lowPriority Flag indicating if the fetch requests should be treated with lower priority,
     *                    leveraging browser capabilities to defer or deprioritize network traffic.
     */
    async getLensContent(lens: Lens, lowPriority: boolean = false): Promise<LensBinary> {
        const { content } = this.metadataCache.get(lens.id) ?? {};
        if (!content) {
            throw new Error(`Cannot find metadata for lens ${lens.id}.`);
        }

        const cachedLensBuffer = this.binariesCache.get(lens.id);
        if (cachedLensBuffer) {
            return {
                lensBuffer: cachedLensBuffer,
                lensChecksum: content.lnsSha256,
            };
        }

        const [lensBuffer] = await this.lensFetchHandler([
            // TODO: remove force-cache once https://jira.sc-corp.net/browse/CAMKIT-3671 is addressed
            new Request(content.lnsUrlBolt, withRequestPriority({ cache: "force-cache" }, lowPriority)),
            {
                requestType: "lens_content",
                lensId: lens.id,
            },
        ]);
        return { lensBuffer, lensChecksum: content.lnsSha256 };
    }
}

// NOTE: jest doesn't like default exports of classes with decorated methods.
export { LensRepository };

/**
 * @internal
 */
export const lensRepositoryFactory = Injectable(
    "LensRepository",
    [
        requestStateEventTargetFactory.token,
        defaultFetchHandlerFactory.token,
        lensSourcesFactory.token,
        lensAssetRepositoryFactory.token,
    ] as const,
    (
        requestStateEventTarget: RequestStateEventTarget,
        defaultFetchHandler: FetchHandler,
        lensSources: LensSource[],
        lensAssetRepository: LensAssetRepository
    ) => {
        const lensFetchHandler = new HandlerChainBuilder(defaultFetchHandler)
            .map(createRequestStateEmittingHandler<LensDownloadDimensions>(requestStateEventTarget))
            .map(createArrayBufferParsingHandler()).handler;

        return new LensRepository(lensFetchHandler, lensSources, lensAssetRepository);
    }
);
