import { ensureError } from "../common/errorHelpers";
import { Injectable } from "../dependency-injection/Injectable";
import { TypedCustomEvent } from "../events/TypedCustomEvent";
import { TypedEventTarget } from "../events/TypedEventTarget";
import { Timer } from "../metrics/operational/Timer";
import type { ChainableHandler, RequestMetadata } from "./HandlerChainBuilder";

let requestId = 0;
const safeParseInt = (str: string | null) => {
    if (str == null) return 0;
    const maybeInt = parseInt(str);
    return isNaN(maybeInt) ? 0 : maybeInt;
};

interface Started {
    requestId: number;
    timer: Timer<"download_latency">;
    dimensions: Dimensions;
}

interface Completed {
    requestId: number;
    dimensions: Dimensions;
    status: number;
    sizeByte: number;
}

interface Errored {
    requestId: number;
    dimensions: Dimensions;
    error: Error;
}

export type Dimensions = Record<string, string | undefined>;

export type RequestStateEvents =
    | TypedCustomEvent<"started", Started>
    | TypedCustomEvent<"completed", Completed>
    | TypedCustomEvent<"errored", Errored>;

export const dispatchRequestStarted = (
    requestStateEventTarget: RequestStateEventTarget,
    data: Omit<Started, "requestId" | "timer">
): Started => {
    const started: Started = { ...data, requestId: requestId++, timer: new Timer("download_latency") };
    requestStateEventTarget.dispatchEvent(new TypedCustomEvent("started", started));
    return started;
};

export const dispatchRequestCompleted = (
    requestStateEventTarget: RequestStateEventTarget,
    data: Completed
): Completed => {
    requestStateEventTarget.dispatchEvent(new TypedCustomEvent("completed", data));
    return data;
};

export const dispatchRequestErrored = (requestStateEventTarget: RequestStateEventTarget, data: Errored): Errored => {
    requestStateEventTarget.dispatchEvent(new TypedCustomEvent("errored", data));
    return data;
};

export const createRequestStateEmittingHandler =
    <D extends Dimensions = Dimensions>(
        requestStateEventTarget: RequestStateEventTarget
    ): ChainableHandler<[RequestInfo, D], Response, RequestInfo, Response, RequestMetadata> =>
    (next) =>
    async ([request, dimensions], metadata) => {
        const { requestId } = dispatchRequestStarted(requestStateEventTarget, { dimensions });
        try {
            const response = await next(request, metadata);
            const status = response.status;
            const sizeByte = safeParseInt(response.headers.get("content-length"));
            dispatchRequestCompleted(requestStateEventTarget, { requestId, dimensions, status, sizeByte });
            return response;
        } catch (error) {
            dispatchRequestErrored(requestStateEventTarget, { requestId, dimensions, error: ensureError(error) });
            throw error;
        }
    };

/**
 * This event target may be used to listen for any network request state changes initiated by CameraKit.
 *
 * @internal
 */
export type RequestStateEventTarget = TypedEventTarget<RequestStateEvents>;

/**
 * @internal
 */
export const requestStateEventTargetFactory = Injectable(
    "requestStateEventTarget",
    (): RequestStateEventTarget => new TypedEventTarget()
);
