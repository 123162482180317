import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "snapchat.cdp.cof";
export enum Namespace {
    DEFAULT = 0,
    LENS_CORE = 64,
    CAMERA_KIT_CORE = 65,
    LENS_CORE_CONFIG = 143,
    UNRECOGNIZED = -1
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
