import { Injectable } from "../../dependency-injection/Injectable";
import { createArrayBufferParsingHandler } from "../../handlers/arrayBufferParsingHandler";
import type { FetchHandler } from "../../handlers/defaultFetchHandler";
import { defaultFetchHandlerFactory } from "../../handlers/defaultFetchHandler";
import { HandlerChainBuilder } from "../../handlers/HandlerChainBuilder";
import { withRequestPriority } from "../../handlers/utils";
import type { AssetLoader } from "./LensAssetRepository";

/**
 * @internal
 */
export const remoteMediaAssetLoaderFactory = Injectable(
    "remoteMediaAssetLoader",
    [defaultFetchHandlerFactory.token] as const,
    (fetchHandler: FetchHandler): AssetLoader => {
        const handler = new HandlerChainBuilder(fetchHandler).map(createArrayBufferParsingHandler()).handler;

        return async function remoteMediaAssetLoader({ assetDescriptor: { assetId }, lowPriority }) {
            // as a default option assuming that RemoteMedia asset type contains asset url in assetId
            // this behavior could be changed by providing own implementation for RemoteMedia asset loader
            //   const cameraKit = bootstrapCameraKit(config, container => {
            //     return container.provides(Injectable(
            //       remoteMediaAssetLoaderFactory.token, ...
            // TODO: remove force-cache once https://jira.sc-corp.net/browse/CAMKIT-3671 is addressed
            const [buffer, response] = await handler(
                assetId,
                withRequestPriority({ cache: "force-cache" }, lowPriority)
            );
            if (!response.ok) throw response;
            return buffer;
        };
    }
);
