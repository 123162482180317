import { copyDefinedProperties } from "../common/copyDefinedProperties";
import type { CameraKitDeviceOptions } from "./CameraKitSource";
import { CameraKitSource, defaultDeviceInfo } from "./CameraKitSource";

const defaultOptions: VideoSourceOptions = {
    ...defaultDeviceInfo,
    trackingData: new ArrayBuffer(0),
};

/**
 * Video source options.
 *
 * @category Rendering
 */
export interface VideoSourceOptions extends CameraKitDeviceOptions {
    /**
     * Optional pre-computed tracking data to enhance rendering performance.
     * While not commonly provided, this can be used in specific scenarios
     * where such data is available to optimize video processing.
     */
    trackingData: ArrayBuffer;
}

/**
 * Create a {@link CameraKitSource} from an
 * [HTMLVideoElement](https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement).
 *
 * @param video CameraKit performs best when the source video is 720p – arbitrary resolutions are supported, but very
 * high resolutions may not perform well.
 * @param options Options.
 *
 * @category Rendering
 */
export function createVideoSource(video: HTMLVideoElement, options: Partial<VideoSourceOptions> = {}): CameraKitSource {
    const { trackingData } = { ...defaultOptions, ...copyDefinedProperties(options) };
    const replayTrackingData = trackingData.byteLength > 0 ? { buffer: trackingData } : undefined;

    return new CameraKitSource(
        {
            media: video,
            replayTrackingData,
        },
        {},
        options
    );
}
