import Long from "long";
import { grpc } from "@improbable-eng/grpc-web";
import _m0 from "protobufjs/minimal";
import { Namespace } from "./namespace";
import { ConfigTargetingRequest } from "./config_request";
import { ConfigTargetingResponse } from "./config_response";
import { BrowserHeaders } from "browser-headers";
import { ConfigResult } from "./config_result";
import { DebugTrace } from "./debug_info";
import { Ruid } from "../../common/ruid";
export const protobufPackage = "snapchat.cdp.cof";
export interface GetAbConfigValuesRequest {
    configNames: string[];
    namespaces: Namespace[];
    cofConfigNames: string[];
    includeAllConfigs: boolean;
    disableExposureLogging: boolean;
    enableDebug: boolean;
}
export interface GetAbConfigValuesResponse {
    configResults: ConfigResult[];
    debugTrace: DebugTrace | undefined;
}
export interface ConfigSetGenerationRequest {
    targetingParameters: ConfigTargetingRequest | undefined;
    overrides: ConfigResult[];
}
export interface ConfigSetGenerationResponse {
    targetingResponse: ConfigTargetingResponse | undefined;
}
export interface PinConfigRequest {
    configSetToken: string;
    userId: string;
    ttlSeconds: number;
}
export interface PinConfigResponse {
    debug: string;
}
export interface UnpinConfigRequest {
    configSetToken: string;
    userId: string;
}
export interface UnpinConfigResponse {
    debug: string;
}
export interface ConfigWithNamespace {
    configName: string;
    namespace: Namespace;
}
export interface GetConfigValuesByTokenRequest {
    token: string;
    configNames: ConfigWithNamespace[];
    namespaces: Namespace[];
    disableExposureLogging: boolean;
    getAllConfigs: boolean;
}
export interface GetConfigValuesByTokenResponse {
    configResults: ConfigResult[];
}
export interface GetCachedConfigResultSequenceIdsRequest {
}
export interface GetCachedConfigResultSequenceIdsResponse {
    serializedBitmap: Uint8Array;
}
export interface GetConfigResultsBySequenceIdsRequest {
    sequenceIds: number[];
}
export interface ConfigResultWithSequenceId {
    sequenceId: number;
    configResult: ConfigResult | undefined;
}
export interface GetConfigResultsBySequenceIdsResponse {
    configResultWithSequenceIds: ConfigResultWithSequenceId[];
}
export interface GetBitmapByTokenRequest {
    token: string;
}
export interface GetBitmapByTokenResponse {
    idBitmap: Uint8Array;
}
export interface GetUnevaluatedConfigResultsWithAbTargetingRequest {
    namespaces: Namespace[];
    edgeConfigClientVersion: string;
}
export interface GetUnevaluatedConfigResultsWithAbTargetingResponse {
    configResults: ConfigResult[];
}
export interface AddRuidsForDebuggingRequest {
    ruids: Ruid[];
    configNames: string[];
    maxSnapshots: number;
}
export interface AddRuidsForDebuggingResponse {
}
function createBaseGetAbConfigValuesRequest(): GetAbConfigValuesRequest {
    return {
        configNames: [],
        namespaces: [],
        cofConfigNames: [],
        includeAllConfigs: false,
        disableExposureLogging: false,
        enableDebug: false,
    };
}
export const GetAbConfigValuesRequest = {
    encode(message: GetAbConfigValuesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.configNames) {
            writer.uint32(10).string(v!);
        }
        writer.uint32(18).fork();
        for (const v of message.namespaces) {
            writer.int32(v);
        }
        writer.ldelim();
        for (const v of message.cofConfigNames) {
            writer.uint32(26).string(v!);
        }
        if (message.includeAllConfigs === true) {
            writer.uint32(32).bool(message.includeAllConfigs);
        }
        if (message.disableExposureLogging === true) {
            writer.uint32(40).bool(message.disableExposureLogging);
        }
        if (message.enableDebug === true) {
            writer.uint32(104).bool(message.enableDebug);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetAbConfigValuesRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetAbConfigValuesRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configNames.push(reader.string());
                    break;
                case 2:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.namespaces.push(reader.int32() as any);
                        }
                    }
                    else {
                        message.namespaces.push(reader.int32() as any);
                    }
                    break;
                case 3:
                    message.cofConfigNames.push(reader.string());
                    break;
                case 4:
                    message.includeAllConfigs = reader.bool();
                    break;
                case 5:
                    message.disableExposureLogging = reader.bool();
                    break;
                case 13:
                    message.enableDebug = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetAbConfigValuesRequest>, I>>(object: I): GetAbConfigValuesRequest {
        const message = createBaseGetAbConfigValuesRequest();
        message.configNames = object.configNames?.map((e) => e) || [];
        message.namespaces = object.namespaces?.map((e) => e) || [];
        message.cofConfigNames = object.cofConfigNames?.map((e) => e) || [];
        message.includeAllConfigs = object.includeAllConfigs ?? false;
        message.disableExposureLogging = object.disableExposureLogging ?? false;
        message.enableDebug = object.enableDebug ?? false;
        return message;
    },
};
function createBaseGetAbConfigValuesResponse(): GetAbConfigValuesResponse {
    return { configResults: [], debugTrace: undefined };
}
export const GetAbConfigValuesResponse = {
    encode(message: GetAbConfigValuesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.configResults) {
            ConfigResult.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        if (message.debugTrace !== undefined) {
            DebugTrace.encode(message.debugTrace, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetAbConfigValuesResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetAbConfigValuesResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configResults.push(ConfigResult.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.debugTrace = DebugTrace.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetAbConfigValuesResponse>, I>>(object: I): GetAbConfigValuesResponse {
        const message = createBaseGetAbConfigValuesResponse();
        message.configResults = object.configResults?.map((e) => ConfigResult.fromPartial(e)) || [];
        message.debugTrace =
            object.debugTrace !== undefined && object.debugTrace !== null
                ? DebugTrace.fromPartial(object.debugTrace)
                : undefined;
        return message;
    },
};
function createBaseConfigSetGenerationRequest(): ConfigSetGenerationRequest {
    return { targetingParameters: undefined, overrides: [] };
}
export const ConfigSetGenerationRequest = {
    encode(message: ConfigSetGenerationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.targetingParameters !== undefined) {
            ConfigTargetingRequest.encode(message.targetingParameters, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.overrides) {
            ConfigResult.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigSetGenerationRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigSetGenerationRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.targetingParameters = ConfigTargetingRequest.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.overrides.push(ConfigResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigSetGenerationRequest>, I>>(object: I): ConfigSetGenerationRequest {
        const message = createBaseConfigSetGenerationRequest();
        message.targetingParameters =
            object.targetingParameters !== undefined && object.targetingParameters !== null
                ? ConfigTargetingRequest.fromPartial(object.targetingParameters)
                : undefined;
        message.overrides = object.overrides?.map((e) => ConfigResult.fromPartial(e)) || [];
        return message;
    },
};
function createBaseConfigSetGenerationResponse(): ConfigSetGenerationResponse {
    return { targetingResponse: undefined };
}
export const ConfigSetGenerationResponse = {
    encode(message: ConfigSetGenerationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.targetingResponse !== undefined) {
            ConfigTargetingResponse.encode(message.targetingResponse, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigSetGenerationResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigSetGenerationResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.targetingResponse = ConfigTargetingResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigSetGenerationResponse>, I>>(object: I): ConfigSetGenerationResponse {
        const message = createBaseConfigSetGenerationResponse();
        message.targetingResponse =
            object.targetingResponse !== undefined && object.targetingResponse !== null
                ? ConfigTargetingResponse.fromPartial(object.targetingResponse)
                : undefined;
        return message;
    },
};
function createBasePinConfigRequest(): PinConfigRequest {
    return { configSetToken: "", userId: "", ttlSeconds: 0 };
}
export const PinConfigRequest = {
    encode(message: PinConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.configSetToken !== "") {
            writer.uint32(10).string(message.configSetToken);
        }
        if (message.userId !== "") {
            writer.uint32(18).string(message.userId);
        }
        if (message.ttlSeconds !== 0) {
            writer.uint32(24).int32(message.ttlSeconds);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PinConfigRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePinConfigRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configSetToken = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.ttlSeconds = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PinConfigRequest>, I>>(object: I): PinConfigRequest {
        const message = createBasePinConfigRequest();
        message.configSetToken = object.configSetToken ?? "";
        message.userId = object.userId ?? "";
        message.ttlSeconds = object.ttlSeconds ?? 0;
        return message;
    },
};
function createBasePinConfigResponse(): PinConfigResponse {
    return { debug: "" };
}
export const PinConfigResponse = {
    encode(message: PinConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.debug !== "") {
            writer.uint32(10).string(message.debug);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): PinConfigResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePinConfigResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.debug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<PinConfigResponse>, I>>(object: I): PinConfigResponse {
        const message = createBasePinConfigResponse();
        message.debug = object.debug ?? "";
        return message;
    },
};
function createBaseUnpinConfigRequest(): UnpinConfigRequest {
    return { configSetToken: "", userId: "" };
}
export const UnpinConfigRequest = {
    encode(message: UnpinConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.configSetToken !== "") {
            writer.uint32(10).string(message.configSetToken);
        }
        if (message.userId !== "") {
            writer.uint32(18).string(message.userId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): UnpinConfigRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUnpinConfigRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configSetToken = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<UnpinConfigRequest>, I>>(object: I): UnpinConfigRequest {
        const message = createBaseUnpinConfigRequest();
        message.configSetToken = object.configSetToken ?? "";
        message.userId = object.userId ?? "";
        return message;
    },
};
function createBaseUnpinConfigResponse(): UnpinConfigResponse {
    return { debug: "" };
}
export const UnpinConfigResponse = {
    encode(message: UnpinConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.debug !== "") {
            writer.uint32(10).string(message.debug);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): UnpinConfigResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseUnpinConfigResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.debug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<UnpinConfigResponse>, I>>(object: I): UnpinConfigResponse {
        const message = createBaseUnpinConfigResponse();
        message.debug = object.debug ?? "";
        return message;
    },
};
function createBaseConfigWithNamespace(): ConfigWithNamespace {
    return { configName: "", namespace: 0 };
}
export const ConfigWithNamespace = {
    encode(message: ConfigWithNamespace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.configName !== "") {
            writer.uint32(10).string(message.configName);
        }
        if (message.namespace !== 0) {
            writer.uint32(16).int32(message.namespace);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigWithNamespace {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigWithNamespace();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configName = reader.string();
                    break;
                case 2:
                    message.namespace = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigWithNamespace>, I>>(object: I): ConfigWithNamespace {
        const message = createBaseConfigWithNamespace();
        message.configName = object.configName ?? "";
        message.namespace = object.namespace ?? 0;
        return message;
    },
};
function createBaseGetConfigValuesByTokenRequest(): GetConfigValuesByTokenRequest {
    return { token: "", configNames: [], namespaces: [], disableExposureLogging: false, getAllConfigs: false };
}
export const GetConfigValuesByTokenRequest = {
    encode(message: GetConfigValuesByTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.token !== "") {
            writer.uint32(10).string(message.token);
        }
        for (const v of message.configNames) {
            ConfigWithNamespace.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        writer.uint32(26).fork();
        for (const v of message.namespaces) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.disableExposureLogging === true) {
            writer.uint32(32).bool(message.disableExposureLogging);
        }
        if (message.getAllConfigs === true) {
            writer.uint32(40).bool(message.getAllConfigs);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetConfigValuesByTokenRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetConfigValuesByTokenRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                case 2:
                    message.configNames.push(ConfigWithNamespace.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.namespaces.push(reader.int32() as any);
                        }
                    }
                    else {
                        message.namespaces.push(reader.int32() as any);
                    }
                    break;
                case 4:
                    message.disableExposureLogging = reader.bool();
                    break;
                case 5:
                    message.getAllConfigs = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetConfigValuesByTokenRequest>, I>>(object: I): GetConfigValuesByTokenRequest {
        const message = createBaseGetConfigValuesByTokenRequest();
        message.token = object.token ?? "";
        message.configNames = object.configNames?.map((e) => ConfigWithNamespace.fromPartial(e)) || [];
        message.namespaces = object.namespaces?.map((e) => e) || [];
        message.disableExposureLogging = object.disableExposureLogging ?? false;
        message.getAllConfigs = object.getAllConfigs ?? false;
        return message;
    },
};
function createBaseGetConfigValuesByTokenResponse(): GetConfigValuesByTokenResponse {
    return { configResults: [] };
}
export const GetConfigValuesByTokenResponse = {
    encode(message: GetConfigValuesByTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.configResults) {
            ConfigResult.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetConfigValuesByTokenResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetConfigValuesByTokenResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configResults.push(ConfigResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetConfigValuesByTokenResponse>, I>>(object: I): GetConfigValuesByTokenResponse {
        const message = createBaseGetConfigValuesByTokenResponse();
        message.configResults = object.configResults?.map((e) => ConfigResult.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetCachedConfigResultSequenceIdsRequest(): GetCachedConfigResultSequenceIdsRequest {
    return {};
}
export const GetCachedConfigResultSequenceIdsRequest = {
    encode(_: GetCachedConfigResultSequenceIdsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetCachedConfigResultSequenceIdsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetCachedConfigResultSequenceIdsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetCachedConfigResultSequenceIdsRequest>, I>>(_: I): GetCachedConfigResultSequenceIdsRequest {
        const message = createBaseGetCachedConfigResultSequenceIdsRequest();
        return message;
    },
};
function createBaseGetCachedConfigResultSequenceIdsResponse(): GetCachedConfigResultSequenceIdsResponse {
    return { serializedBitmap: new Uint8Array() };
}
export const GetCachedConfigResultSequenceIdsResponse = {
    encode(message: GetCachedConfigResultSequenceIdsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.serializedBitmap.length !== 0) {
            writer.uint32(10).bytes(message.serializedBitmap);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetCachedConfigResultSequenceIdsResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetCachedConfigResultSequenceIdsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.serializedBitmap = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetCachedConfigResultSequenceIdsResponse>, I>>(object: I): GetCachedConfigResultSequenceIdsResponse {
        const message = createBaseGetCachedConfigResultSequenceIdsResponse();
        message.serializedBitmap = object.serializedBitmap ?? new Uint8Array();
        return message;
    },
};
function createBaseGetConfigResultsBySequenceIdsRequest(): GetConfigResultsBySequenceIdsRequest {
    return { sequenceIds: [] };
}
export const GetConfigResultsBySequenceIdsRequest = {
    encode(message: GetConfigResultsBySequenceIdsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        writer.uint32(10).fork();
        for (const v of message.sequenceIds) {
            writer.int32(v);
        }
        writer.ldelim();
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetConfigResultsBySequenceIdsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetConfigResultsBySequenceIdsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.sequenceIds.push(reader.int32());
                        }
                    }
                    else {
                        message.sequenceIds.push(reader.int32());
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetConfigResultsBySequenceIdsRequest>, I>>(object: I): GetConfigResultsBySequenceIdsRequest {
        const message = createBaseGetConfigResultsBySequenceIdsRequest();
        message.sequenceIds = object.sequenceIds?.map((e) => e) || [];
        return message;
    },
};
function createBaseConfigResultWithSequenceId(): ConfigResultWithSequenceId {
    return { sequenceId: 0, configResult: undefined };
}
export const ConfigResultWithSequenceId = {
    encode(message: ConfigResultWithSequenceId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sequenceId !== 0) {
            writer.uint32(8).int32(message.sequenceId);
        }
        if (message.configResult !== undefined) {
            ConfigResult.encode(message.configResult, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ConfigResultWithSequenceId {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseConfigResultWithSequenceId();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sequenceId = reader.int32();
                    break;
                case 2:
                    message.configResult = ConfigResult.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ConfigResultWithSequenceId>, I>>(object: I): ConfigResultWithSequenceId {
        const message = createBaseConfigResultWithSequenceId();
        message.sequenceId = object.sequenceId ?? 0;
        message.configResult =
            object.configResult !== undefined && object.configResult !== null
                ? ConfigResult.fromPartial(object.configResult)
                : undefined;
        return message;
    },
};
function createBaseGetConfigResultsBySequenceIdsResponse(): GetConfigResultsBySequenceIdsResponse {
    return { configResultWithSequenceIds: [] };
}
export const GetConfigResultsBySequenceIdsResponse = {
    encode(message: GetConfigResultsBySequenceIdsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.configResultWithSequenceIds) {
            ConfigResultWithSequenceId.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetConfigResultsBySequenceIdsResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetConfigResultsBySequenceIdsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configResultWithSequenceIds.push(ConfigResultWithSequenceId.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetConfigResultsBySequenceIdsResponse>, I>>(object: I): GetConfigResultsBySequenceIdsResponse {
        const message = createBaseGetConfigResultsBySequenceIdsResponse();
        message.configResultWithSequenceIds =
            object.configResultWithSequenceIds?.map((e) => ConfigResultWithSequenceId.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetBitmapByTokenRequest(): GetBitmapByTokenRequest {
    return { token: "" };
}
export const GetBitmapByTokenRequest = {
    encode(message: GetBitmapByTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.token !== "") {
            writer.uint32(10).string(message.token);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetBitmapByTokenRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBitmapByTokenRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetBitmapByTokenRequest>, I>>(object: I): GetBitmapByTokenRequest {
        const message = createBaseGetBitmapByTokenRequest();
        message.token = object.token ?? "";
        return message;
    },
};
function createBaseGetBitmapByTokenResponse(): GetBitmapByTokenResponse {
    return { idBitmap: new Uint8Array() };
}
export const GetBitmapByTokenResponse = {
    encode(message: GetBitmapByTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.idBitmap.length !== 0) {
            writer.uint32(10).bytes(message.idBitmap);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetBitmapByTokenResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetBitmapByTokenResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.idBitmap = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetBitmapByTokenResponse>, I>>(object: I): GetBitmapByTokenResponse {
        const message = createBaseGetBitmapByTokenResponse();
        message.idBitmap = object.idBitmap ?? new Uint8Array();
        return message;
    },
};
function createBaseGetUnevaluatedConfigResultsWithAbTargetingRequest(): GetUnevaluatedConfigResultsWithAbTargetingRequest {
    return { namespaces: [], edgeConfigClientVersion: "" };
}
export const GetUnevaluatedConfigResultsWithAbTargetingRequest = {
    encode(message: GetUnevaluatedConfigResultsWithAbTargetingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        writer.uint32(10).fork();
        for (const v of message.namespaces) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.edgeConfigClientVersion !== "") {
            writer.uint32(18).string(message.edgeConfigClientVersion);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetUnevaluatedConfigResultsWithAbTargetingRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetUnevaluatedConfigResultsWithAbTargetingRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.namespaces.push(reader.int32() as any);
                        }
                    }
                    else {
                        message.namespaces.push(reader.int32() as any);
                    }
                    break;
                case 2:
                    message.edgeConfigClientVersion = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetUnevaluatedConfigResultsWithAbTargetingRequest>, I>>(object: I): GetUnevaluatedConfigResultsWithAbTargetingRequest {
        const message = createBaseGetUnevaluatedConfigResultsWithAbTargetingRequest();
        message.namespaces = object.namespaces?.map((e) => e) || [];
        message.edgeConfigClientVersion = object.edgeConfigClientVersion ?? "";
        return message;
    },
};
function createBaseGetUnevaluatedConfigResultsWithAbTargetingResponse(): GetUnevaluatedConfigResultsWithAbTargetingResponse {
    return { configResults: [] };
}
export const GetUnevaluatedConfigResultsWithAbTargetingResponse = {
    encode(message: GetUnevaluatedConfigResultsWithAbTargetingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.configResults) {
            ConfigResult.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetUnevaluatedConfigResultsWithAbTargetingResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetUnevaluatedConfigResultsWithAbTargetingResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.configResults.push(ConfigResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetUnevaluatedConfigResultsWithAbTargetingResponse>, I>>(object: I): GetUnevaluatedConfigResultsWithAbTargetingResponse {
        const message = createBaseGetUnevaluatedConfigResultsWithAbTargetingResponse();
        message.configResults = object.configResults?.map((e) => ConfigResult.fromPartial(e)) || [];
        return message;
    },
};
function createBaseAddRuidsForDebuggingRequest(): AddRuidsForDebuggingRequest {
    return { ruids: [], configNames: [], maxSnapshots: 0 };
}
export const AddRuidsForDebuggingRequest = {
    encode(message: AddRuidsForDebuggingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.ruids) {
            Ruid.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        for (const v of message.configNames) {
            writer.uint32(18).string(v!);
        }
        if (message.maxSnapshots !== 0) {
            writer.uint32(24).int32(message.maxSnapshots);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): AddRuidsForDebuggingRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAddRuidsForDebuggingRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.ruids.push(Ruid.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.configNames.push(reader.string());
                    break;
                case 3:
                    message.maxSnapshots = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<AddRuidsForDebuggingRequest>, I>>(object: I): AddRuidsForDebuggingRequest {
        const message = createBaseAddRuidsForDebuggingRequest();
        message.ruids = object.ruids?.map((e) => Ruid.fromPartial(e)) || [];
        message.configNames = object.configNames?.map((e) => e) || [];
        message.maxSnapshots = object.maxSnapshots ?? 0;
        return message;
    },
};
function createBaseAddRuidsForDebuggingResponse(): AddRuidsForDebuggingResponse {
    return {};
}
export const AddRuidsForDebuggingResponse = {
    encode(_: AddRuidsForDebuggingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): AddRuidsForDebuggingResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAddRuidsForDebuggingResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<AddRuidsForDebuggingResponse>, I>>(_: I): AddRuidsForDebuggingResponse {
        const message = createBaseAddRuidsForDebuggingResponse();
        return message;
    },
};
export interface CircumstancesService {
    targetingQuery(request: DeepPartial<ConfigTargetingRequest>, metadata?: grpc.Metadata): Promise<ConfigTargetingResponse>;
    getAbConfigValues(request: DeepPartial<GetAbConfigValuesRequest>, metadata?: grpc.Metadata): Promise<GetAbConfigValuesResponse>;
    getConfigValuesByToken(request: DeepPartial<GetConfigValuesByTokenRequest>, metadata?: grpc.Metadata): Promise<GetConfigValuesByTokenResponse>;
    getConfigResultsBySequenceIds(request: DeepPartial<GetConfigResultsBySequenceIdsRequest>, metadata?: grpc.Metadata): Promise<GetConfigResultsBySequenceIdsResponse>;
    getCachedConfigResultSequenceIds(request: DeepPartial<GetCachedConfigResultSequenceIdsRequest>, metadata?: grpc.Metadata): Promise<GetCachedConfigResultSequenceIdsResponse>;
    getBitmapByToken(request: DeepPartial<GetBitmapByTokenRequest>, metadata?: grpc.Metadata): Promise<GetBitmapByTokenResponse>;
    generateConfigSet(request: DeepPartial<ConfigSetGenerationRequest>, metadata?: grpc.Metadata): Promise<ConfigSetGenerationResponse>;
    pinConfigSet(request: DeepPartial<PinConfigRequest>, metadata?: grpc.Metadata): Promise<PinConfigResponse>;
    unpinConfigSet(request: DeepPartial<UnpinConfigRequest>, metadata?: grpc.Metadata): Promise<UnpinConfigResponse>;
    getUnevaluatedConfigResultsWithAbTargeting(request: DeepPartial<GetUnevaluatedConfigResultsWithAbTargetingRequest>, metadata?: grpc.Metadata): Promise<GetUnevaluatedConfigResultsWithAbTargetingResponse>;
    addRuidsForDebugging(request: DeepPartial<AddRuidsForDebuggingRequest>, metadata?: grpc.Metadata): Promise<AddRuidsForDebuggingResponse>;
}
export class CircumstancesServiceClientImpl implements CircumstancesService {
    private readonly rpc: Rpc;
    constructor(rpc: Rpc) {
        this.rpc = rpc;
        this.targetingQuery = this.targetingQuery.bind(this);
        this.getAbConfigValues = this.getAbConfigValues.bind(this);
        this.getConfigValuesByToken = this.getConfigValuesByToken.bind(this);
        this.getConfigResultsBySequenceIds = this.getConfigResultsBySequenceIds.bind(this);
        this.getCachedConfigResultSequenceIds = this.getCachedConfigResultSequenceIds.bind(this);
        this.getBitmapByToken = this.getBitmapByToken.bind(this);
        this.generateConfigSet = this.generateConfigSet.bind(this);
        this.pinConfigSet = this.pinConfigSet.bind(this);
        this.unpinConfigSet = this.unpinConfigSet.bind(this);
        this.getUnevaluatedConfigResultsWithAbTargeting = this.getUnevaluatedConfigResultsWithAbTargeting.bind(this);
        this.addRuidsForDebugging = this.addRuidsForDebugging.bind(this);
    }
    targetingQuery(request: DeepPartial<ConfigTargetingRequest>, metadata?: grpc.Metadata): Promise<ConfigTargetingResponse> {
        return this.rpc.unary(CircumstancesServicetargetingQueryDesc, ConfigTargetingRequest.fromPartial(request), metadata);
    }
    getAbConfigValues(request: DeepPartial<GetAbConfigValuesRequest>, metadata?: grpc.Metadata): Promise<GetAbConfigValuesResponse> {
        return this.rpc.unary(CircumstancesServicegetAbConfigValuesDesc, GetAbConfigValuesRequest.fromPartial(request), metadata);
    }
    getConfigValuesByToken(request: DeepPartial<GetConfigValuesByTokenRequest>, metadata?: grpc.Metadata): Promise<GetConfigValuesByTokenResponse> {
        return this.rpc.unary(CircumstancesServicegetConfigValuesByTokenDesc, GetConfigValuesByTokenRequest.fromPartial(request), metadata);
    }
    getConfigResultsBySequenceIds(request: DeepPartial<GetConfigResultsBySequenceIdsRequest>, metadata?: grpc.Metadata): Promise<GetConfigResultsBySequenceIdsResponse> {
        return this.rpc.unary(CircumstancesServicegetConfigResultsBySequenceIdsDesc, GetConfigResultsBySequenceIdsRequest.fromPartial(request), metadata);
    }
    getCachedConfigResultSequenceIds(request: DeepPartial<GetCachedConfigResultSequenceIdsRequest>, metadata?: grpc.Metadata): Promise<GetCachedConfigResultSequenceIdsResponse> {
        return this.rpc.unary(CircumstancesServicegetCachedConfigResultSequenceIdsDesc, GetCachedConfigResultSequenceIdsRequest.fromPartial(request), metadata);
    }
    getBitmapByToken(request: DeepPartial<GetBitmapByTokenRequest>, metadata?: grpc.Metadata): Promise<GetBitmapByTokenResponse> {
        return this.rpc.unary(CircumstancesServicegetBitmapByTokenDesc, GetBitmapByTokenRequest.fromPartial(request), metadata);
    }
    generateConfigSet(request: DeepPartial<ConfigSetGenerationRequest>, metadata?: grpc.Metadata): Promise<ConfigSetGenerationResponse> {
        return this.rpc.unary(CircumstancesServicegenerateConfigSetDesc, ConfigSetGenerationRequest.fromPartial(request), metadata);
    }
    pinConfigSet(request: DeepPartial<PinConfigRequest>, metadata?: grpc.Metadata): Promise<PinConfigResponse> {
        return this.rpc.unary(CircumstancesServicepinConfigSetDesc, PinConfigRequest.fromPartial(request), metadata);
    }
    unpinConfigSet(request: DeepPartial<UnpinConfigRequest>, metadata?: grpc.Metadata): Promise<UnpinConfigResponse> {
        return this.rpc.unary(CircumstancesServiceunpinConfigSetDesc, UnpinConfigRequest.fromPartial(request), metadata);
    }
    getUnevaluatedConfigResultsWithAbTargeting(request: DeepPartial<GetUnevaluatedConfigResultsWithAbTargetingRequest>, metadata?: grpc.Metadata): Promise<GetUnevaluatedConfigResultsWithAbTargetingResponse> {
        return this.rpc.unary(CircumstancesServicegetUnevaluatedConfigResultsWithAbTargetingDesc, GetUnevaluatedConfigResultsWithAbTargetingRequest.fromPartial(request), metadata);
    }
    addRuidsForDebugging(request: DeepPartial<AddRuidsForDebuggingRequest>, metadata?: grpc.Metadata): Promise<AddRuidsForDebuggingResponse> {
        return this.rpc.unary(CircumstancesServiceaddRuidsForDebuggingDesc, AddRuidsForDebuggingRequest.fromPartial(request), metadata);
    }
}
export const CircumstancesServiceDesc = {
    serviceName: "snapchat.cdp.cof.CircumstancesService",
};
export const CircumstancesServicetargetingQueryDesc: UnaryMethodDefinitionish = {
    methodName: "targetingQuery",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return ConfigTargetingRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...ConfigTargetingResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServicegetAbConfigValuesDesc: UnaryMethodDefinitionish = {
    methodName: "getAbConfigValues",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetAbConfigValuesRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...GetAbConfigValuesResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServicegetConfigValuesByTokenDesc: UnaryMethodDefinitionish = {
    methodName: "getConfigValuesByToken",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetConfigValuesByTokenRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...GetConfigValuesByTokenResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServicegetConfigResultsBySequenceIdsDesc: UnaryMethodDefinitionish = {
    methodName: "getConfigResultsBySequenceIds",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetConfigResultsBySequenceIdsRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...GetConfigResultsBySequenceIdsResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServicegetCachedConfigResultSequenceIdsDesc: UnaryMethodDefinitionish = {
    methodName: "getCachedConfigResultSequenceIds",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetCachedConfigResultSequenceIdsRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...GetCachedConfigResultSequenceIdsResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServicegetBitmapByTokenDesc: UnaryMethodDefinitionish = {
    methodName: "getBitmapByToken",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetBitmapByTokenRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...GetBitmapByTokenResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServicegenerateConfigSetDesc: UnaryMethodDefinitionish = {
    methodName: "generateConfigSet",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return ConfigSetGenerationRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...ConfigSetGenerationResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServicepinConfigSetDesc: UnaryMethodDefinitionish = {
    methodName: "pinConfigSet",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return PinConfigRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...PinConfigResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServiceunpinConfigSetDesc: UnaryMethodDefinitionish = {
    methodName: "unpinConfigSet",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return UnpinConfigRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...UnpinConfigResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServicegetUnevaluatedConfigResultsWithAbTargetingDesc: UnaryMethodDefinitionish = {
    methodName: "getUnevaluatedConfigResultsWithAbTargeting",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return GetUnevaluatedConfigResultsWithAbTargetingRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...GetUnevaluatedConfigResultsWithAbTargetingResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
export const CircumstancesServiceaddRuidsForDebuggingDesc: UnaryMethodDefinitionish = {
    methodName: "addRuidsForDebugging",
    service: CircumstancesServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
        serializeBinary() {
            return AddRuidsForDebuggingRequest.encode(this).finish();
        },
    } as any,
    responseType: {
        deserializeBinary(data: Uint8Array) {
            return {
                ...AddRuidsForDebuggingResponse.decode(data),
                toObject() {
                    return this;
                },
            };
        },
    } as any,
};
interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
    requestStream: any;
    responseStream: any;
}
type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;
interface Rpc {
    unary<T extends UnaryMethodDefinitionish>(methodDesc: T, request: any, metadata: grpc.Metadata | undefined): Promise<any>;
}
export class GrpcWebImpl {
    private host: string;
    private options: {
        transport?: grpc.TransportFactory;
        debug?: boolean;
        metadata?: grpc.Metadata;
    };
    constructor(host: string, options: {
        transport?: grpc.TransportFactory;
        debug?: boolean;
        metadata?: grpc.Metadata;
    }) {
        this.host = host;
        this.options = options;
    }
    unary<T extends UnaryMethodDefinitionish>(methodDesc: T, _request: any, metadata: grpc.Metadata | undefined): Promise<any> {
        const request = { ..._request, ...methodDesc.requestType };
        const maybeCombinedMetadata = metadata && this.options.metadata
            ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
            : metadata || this.options.metadata;
        return new Promise((resolve, reject) => {
            grpc.unary(methodDesc, {
                request,
                host: this.host,
                metadata: maybeCombinedMetadata,
                transport: this.options.transport,
                debug: this.options.debug,
                onEnd: function (response) {
                    if (response.status === grpc.Code.OK) {
                        resolve(response.message);
                    }
                    else {
                        const err = new Error(response.statusMessage) as any;
                        err.code = response.status;
                        err.metadata = response.trailers;
                        reject(err);
                    }
                },
            });
        });
    }
}
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
