import type { Memoized } from "../common/memoize";
import { isMemoized, memoize } from "../common/memoize";
import { PartialContainer } from "./PartialContainer";
import type { AddService, InjectableFunction, ValidTokens } from "./types";

type MaybeMemoizedFactories<Services> = {
    [K in keyof Services]:
        | ((c: Container<Services>) => Services[K])
        | Memoized<(c: Container<Services>) => Services[K]>;
};

export type Factories<Services> = {
    // We'd like the Container type constructor to be covariant -- if type A ≤ B (that is, if A is more
    // specific than B), we would like Container<A> ≤ Container<B>.
    //
    // But here in this Factories type (which is used to type the `factories` property of Container), we want to use
    // our type variable in *both* a covariant and contravariant position -- we use the keys of Services as the index
    // type (covariant), but we also want to use Container<Services> as the argument of the factory function
    // (contravariant).
    //
    // This would result the Container type (by virtue of containing the `factories` property) being *invariant* -- that
    // is, only if types A and B are each assignable to the other will Container<B> be assignable to Container<A>.
    //
    // To avoid this, we use Container<any> in the contravariant position -- that way the Services type variable is only
    // used in covariant position and our Container type constructor remains covariant.
    //
    // Safety: this does mean that calling the factory function (as is done in `Container.get()`) is not type safe.
    // But we only use this type as a private property of Container, which still type checks the constructor argument
    // used to populate the `factories` property. So there's no way we can end up with a `factories` property containing
    // functions which require Services not provided by the Container.
    [K in keyof Services]: Memoized<(c: Container<any>) => Services[K]>;
};

export const CONTAINER = "$container";
export type ContainerToken = typeof CONTAINER;

/**
 * A Container of values, indexed each by a unique token, which can be used throughout CameraKit. This is how CameraKit
 * implements simple dependency injection.
 *
 * Dependency injection is a way to decouple the *use* of a dependency from the *creation* of that dependency. This
 * improves modularity and re-usability, since components only care about the *interfaces* of dependencies (since that
 * determines their use) and not about their concrete creation. New implementations of a particular dependency may be
 * provided without the need to change any of the consumers of that dependency.
 *
 * There are a few commonly-used terms used when talking about dependency injection:
 *
 *   - Container (or Injector): Maintains a registry of all available Services and understands how to create them.
 *   - Service: Anything that can be provided by the Container is called a Service – this can be a value of any type.
 *   - Token: Each Service is associated with a unique name, or Token. In order to obtain a Service from the Container,
 *     the consumer must provide the Token corresponding to that Service.
 *   - InjectableFunction: Services are created by InjectableFunctions. When adding a Service to a Container, the
 *     Service provider gives the Container a InjectableFunction which, when called will return the Service. These
 *     InjectableFunctions may themselves use other Services, which will be passed to them as arguments.
 *
 * Services are, by default, singletons – that is, each call to `get()` a particular Service will return a reference
 * to the same value. In other words, InjectableFunctions are only invoked once. If multiple instances of a Service are
 * desired, a new Container can be created using the `copy([Token])` method – passing a Token to this method forces the
 * new Container to recreate the corresponding Service (the InjectableFunction will be invoked again). We say that the
 * Service is then "scoped" to the new Container.
 *
 *
 * One common downside of many dependency injection implementations is that the dependency graph formed by the various
 * Services can only be validated at runtime. That is, if a dependency is missing or a circular dependency is found, the
 * developer must wait until runtime to discover the error. These errors can often be confusing and hard to debug.
 *
 * This implementation eliminates this issue by moving these sorts of errors to compile time. If an unknown dependency
 * is used in a InjectableFunction, for example, the code simply won't compile.
 *
 * To achieve this, we do lose the ability to implicitly define the dependency graph, as is common with many dependency
 * injection frameworks that employ decorators to define Services and their dependencies. Instead, the dependency graph
 * must be constructed explicitly, step-by-step, via successive calls to the `provide()` method. This is a suitable
 * trade-off for CameraKit, as there are a relatively small number of Services.
 *
 * Here's a simple example of Container usage:
 * ```ts
 * const fooFactory = Injectable('Foo', () => new Foo())
 * const barFactory = Injectable('Bar', ['Foo'] as const, (foo: Foo) => new Bar(foo))
 * const container = Container.empy()
 *   .provide(fooFactory)
 *   .provide(barFactory)
 *
 * const bar: Bar = container.get('Bar')
 * ```
 */
/** @internal */
export class Container<Services = {}> {
    /**
     * Create a new [Container] by providing a [PartialContainer] that has no dependencies.
     */
    static provides<Services>(container: PartialContainer<Services, {}> | Container<Services>): Container<Services>;

    /**
     * Create a new [Container] by providing a Service that has no dependencies.
     */
    static provides<Token extends string, Service>(
        fn: InjectableFunction<{}, [], Token, Service>
    ): Container<AddService<{}, Token, Service>>;

    static provides(
        fnOrContainer: InjectableFunction<{}, [], string, any> | PartialContainer<any, {}> | Container<any>
    ): Container<any> {
        // Although the `provides` method has overloads that match both members of the union type separately, it does
        // not match the union type itself, so the compiler forces us to branch and handle each type within the union
        // separately. (Maybe in the future the compiler will decide to infer this, but for now this is necessary.)
        if (fnOrContainer instanceof PartialContainer) return new Container({}).provides(fnOrContainer);
        if (fnOrContainer instanceof Container) return new Container({}).provides(fnOrContainer);
        return new Container({}).provides(fnOrContainer);
    }

    private readonly factories: Factories<Services>;

    constructor(factories: MaybeMemoizedFactories<Services>) {
        this.factories = {} as Factories<Services>;
        for (const k in factories) {
            const fn = factories[k];
            if (isMemoized(fn)) this.factories[k] = fn;
            else this.factories[k] = memoize(fn);
        }
    }

    /**
     * Create a copy of this Container, optionally providing a list of Services which will be scoped to the copy.
     *
     * This can be useful, for example, if different parts of an application wish to use the same Service interface, but
     * do not want to share a reference to same Service instance.
     *
     * Say we have a Service which manages a list of Users. Our application wishes to display two lists of Users, which
     * may be edited independently. In this case it may be desirable to create a Container for each list component, with
     * the UserList Service scoped to those Containers – that way, each list component gets a unique copy of the
     * UserList Service that can be edited independently of the other.
     *
     * @param scopedServices A list of Tokens identifying Services which will be scoped to the new Container – that is,
     * if those Services had already been created by the source Container, they will be re-created by their Factory
     * functions when provided by the new Container.
     * @returns A new copy of this Container, sharing all of this Container's Services. Services corresponding to any
     * Tokens passed to this method will be re-created by the new Container (i.e. they become "scoped" to the new
     * Container).
     */
    copy<Tokens extends readonly (keyof Services)[]>(scopedServices?: Tokens): Container<Services> {
        const factories: MaybeMemoizedFactories<Services> = { ...this.factories };

        // We "un-memoize" scoped Service InjectableFunctions so they will create a new copy of their Service when
        // provided by the new Container – we re-memoize them so the new Container will itself only create one Service
        // instance.
        (scopedServices || []).forEach((token: keyof Services) => {
            factories[token] = this.factories[token].delegate;
        });
        return new Container(factories);
    }

    /**
     * Gets a reference to this Container.
     *
     * @param token The CONTAINER token.
     * @returns This Container.
     */
    get(token: ContainerToken): this;

    /**
     * Get a specific Service provided by this Container.
     *
     * @param token A unique string corresponding to a Service
     * @returns A Service corresponding to the given Token.
     */
    get<Token extends keyof Services>(token: Token): Services[Token];

    get(token: ContainerToken | keyof Services): this | Services[keyof Services] {
        if (token === CONTAINER) return this;
        const factory = this.factories[token];
        if (!factory) {
            throw new Error(
                `[Container::get] Could not find Service for Token "${String(token)}". This should've caused a ` +
                    "compile-time error. If the Token is 'undefined', check all your calls to the Injectable " +
                    "function. Make sure you define dependencies using string literals or string constants that are " +
                    "definitely initialized before the call to Injectable."
            );
        }
        return factory(this);
    }

    /**
     * Run the services in this [PartialContainer]. "Run" simply means that [Container::get] will be called for each
     * Service, which invokes that Service's factory function, creating the Service.
     *
     * This may be useful e.g. if services need to initialize themselves, since generally a Service factory is only
     * invoked when the Service is needed.
     *
     * Note this method cannot be used to add services to a Container. – that is, calling this method does not provide
     * the services in a new Container.
     *
     * @param container Optionally provide a [PartialContainer], which will be used as a filter – the only services
     * from *this* container that will run are those with a token that is also present in this PartialContainer.
     * @returns No mutation is done to the Container, it is returned as-is (convenient for chaining).
     */
    run<AdditionalServices, Dependencies, FulfilledDependencies extends Dependencies>(
        // FullfilledDependencies is assignable to Dependencies -- by specifying Container<FulfilledDependencies> as the
        // `this` type, we ensure this Container can provide all the Dependencies required by the PartialContainer.
        this: Container<FulfilledDependencies>,
        container: PartialContainer<AdditionalServices, Dependencies>
    ): this;

    /**
     * Run the given Service. "Run" simply means that [Container::get] will be called for this Service, which invokes
     * the Service's factory function, creating the Service.
     *
     * This may be useful e.g. if services need to initialize themselves, since generally a Service factory is only
     * invoked when the Service is needed.
     *
     * Note this method cannot be used to add services to a Container. – that is, calling this method does not provide
     * the services in a new Container.
     *
     * @param fn Optionally provide an [InjectableFunction], which will be used as a filter – the only services
     * from *this* container that will run are those with a token that is also present in this PartialContainer.
     * @returns No mutation is done to the Container, it is returned as-is (convenient for chaining).
     */
    run<Token extends string, Tokens extends readonly ValidTokens<Services>[], Service>(
        fn: InjectableFunction<Services, Tokens, Token, Service>
    ): this;

    run<Token extends string, Tokens extends readonly ValidTokens<Services>[], Service, AdditionalServices>(
        fnOrContainer:
            | InjectableFunction<Services, Tokens, Token, Service>
            | PartialContainer<AdditionalServices, Services>
    ): this {
        if (fnOrContainer instanceof PartialContainer) {
            const runnableContainer = this.provides(fnOrContainer);
            for (const token of fnOrContainer.getTokens()) {
                runnableContainer.get(token);
            }
        } else {
            this.provides(fnOrContainer).get(fnOrContainer.token);
        }
        return this;
    }

    /**
     * Create a new Container from this Container with additional services from a given [PartialContainer].
     *
     * Services in the provided PartialContainer take precedence if there are service token conflicts.
     *
     * Services from the provided PartialContainer become scoped to the new Container – that is, if PartialContainer A
     * is provided to Container X and Container Y, each resultant Container will contain its own copy of the services
     * from PartialContainer A.
     *
     * @param container A [PartialContainer] providing additional services.
     */
    provides<AdditionalServices, Dependencies, FulfilledDependencies extends Dependencies>(
        // FullfilledDependencies is assignable to Dependencies -- by specifying Container<FulfilledDependencies> as the
        // `this` type, we ensure this Container can provide all the Dependencies required by the PartialContainer.
        this: Container<FulfilledDependencies>,
        container: PartialContainer<AdditionalServices, Dependencies>
    ): Container<Services & AdditionalServices>;

    /**
     * Creates a new Container from this Container with additional services from another Container.
     *
     * Services in the provided PartialContainer take precedence if there are service token conflicts.
     *
     * Services from the provided Container become scoped to both Containers (the one from which they were provided
     * and the new Container returned by this method) - that is, if Container A is provided to Container B,
     * they will share the same instances of any Services provided by Container A.
     * If Container B should re-create new instances of the Services from Container A,
     * Container A must first be copied before providing it here.
     *
     * @param container A [Container] providing additional services.
     */
    provides<AdditionalServices>(container: Container<AdditionalServices>): Container<Services & AdditionalServices>;

    /**
     * Create a new Container which provides a Service created by the given [InjectableFunction].
     *
     * The InjectableFunction contains metadata specifying the Token by which the created Service will be known, as well
     * as an ordered list of Tokens to be resolved and provided to the InjectableFunction as arguments.
     *
     * If any of these required dependencies are missing from the Container (or if there is a mismatch between the types
     * of those dependencies and the arguments of the InjectableFunction), a compiler error will be raised.
     *
     * @param fn A factory function, taking dependencies as arguments, which returns the Service.
     */
    provides<Token extends string, Tokens extends readonly ValidTokens<Services>[], Service>(
        fn: InjectableFunction<Services, Tokens, Token, Service>
    ): Container<AddService<Services, Token, Service>>;

    provides<Token extends string, Tokens extends readonly ValidTokens<Services>[], Service, AdditionalServices>(
        fnOrContainer:
            | InjectableFunction<Services, Tokens, Token, Service>
            | PartialContainer<AdditionalServices, Services>
            | Container<AdditionalServices>
    ): Container<any> {
        if (fnOrContainer instanceof PartialContainer || fnOrContainer instanceof Container) {
            const factories =
                fnOrContainer instanceof PartialContainer ? fnOrContainer.getFactories(this) : fnOrContainer.factories;
            // Safety: `this.factories` and `factories` are both properly type checked, so merging them produces
            // a Factories object with keys from both Services and AdditionalServices. The compiler is unable to
            // infer that Factories<A> & Factories<B> == Factories<A & B>, so the cast is required.
            return new Container({
                ...this.factories,
                ...factories,
            } as unknown as MaybeMemoizedFactories<Services & AdditionalServices>);
        }
        return this.providesService(fnOrContainer);
    }

    private providesService<Token extends string, Tokens extends readonly ValidTokens<Services>[], Service>(
        fn: InjectableFunction<Services, Tokens, Token, Service>
    ): Container<AddService<Services, Token, Service>> {
        const token = fn.token;
        const dependencies: readonly any[] = fn.dependencies;

        const factory = memoize((container: Container<Services>) => {
            return fn(
                ...(dependencies.map((t) => {
                    // To support overwriting an already-existing service with a new implementation, it should be
                    // possibleto do `provide(A, [A], a => createNewServiceFromOld(a))` – that is, inject a dependency
                    // with the same token as this service's token.
                    //
                    // To avoid a circular dependency (in which the factory for service A depends on itself), we always
                    // use the service defined in the *parent* container (i.e. this) when injecting a dependency with
                    // the same token as the service we're providing. If we did not do this, calling `container.get(t)`
                    // would result in an infinite loop.
                    return t === token ? this.get(t) : container.get(t);
                }) as any)
            );
        });

        // Safety: `token` and `factory` are properly type checked, so extending `this.factories` produces a
        // MaybeMemoizedFactories object with the expected set of services – but when using the spread operation to
        // merge two objects, the compiler widens the Token type to string. So we must re-narrow via casting.
        const factories = { ...this.factories, [token]: factory };
        return new Container(factories as unknown as MaybeMemoizedFactories<AddService<Services, Token, Service>>);
    }
}
