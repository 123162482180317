export type RequestMetadata =
    | {
          signal?: AbortSignal | null | undefined;
          /**
           * When calling a handler, if that handler is part of a handler chain, then by default an abort signal will be
           * sent to that handler if another handler higher up in the chain completes first. Usually this is desirable,
           * since we know if a handler higher up in the chain has completed and returned a response to its caller, then
           * the response from all the handlers below it in the chain will not be used.
           *
           * But in some cases, a handler in a handler chain wants to call the next handler in the chain as a
           * side-effect. If a handler is called as a side-effect, it will not be sent an abort signal when handlers
           * higher up in the chain complete.
           *
           * For example: a caching handler might return a cached value immediately, but then call the next handler as
           * a side-effect to fetch an updated value to refresh the cache in the background. In that case, the next
           * handler should not be sent an abort signal when the caching handler returns the cached value.
           */
          isSideEffect?: boolean;
      }
    | undefined;
export type Handler<Req, Res, Meta extends RequestMetadata> = (req: Req, metadata?: Meta) => Promise<Res>;
export type ChainableHandler<Req, Res, NextReq, NextRes, Meta extends RequestMetadata | undefined> = (
    next: Handler<NextReq, NextRes, Meta>
) => Handler<Req, Res, Meta>;

/**
 * Creates a Handler chain – a series of functions composed such that each function may call a supplied `next` function
 * which passes execution down the chain. When the final Handler in the chain returns, execution passes back up the
 * chain eventually returning to the caller.
 *
 * Each Handler chain begins with a "raw" Handler – this is a function which takes some request and returns some
 * response. A chain is then created by supplying a series of mapping functions – the ChainableHandler type – which will
 * be called with the `next` Handler in the chain.
 *
 * Ex:
 * ```ts
 * const handler = (request: string, metadata?: RequestMetadata) => Promise.resolve(`Responded to ${request}`)
 * const chainable = (next: Handler<string, string>) => (request: string, metadata?: RequestMetadata) => {
 *   return next(`modified ${request}`, metadata)
 * }
 *
 * const chain = new HandlerChainBuilder(handler)
 *   .map(chainable)
 *   .handler
 *
 * const response = await chain('hello')
 * expect(response).toBe('Responded to modified hello; 0')
 * ```
 * You can largely ignore the `metadata` argument present in the above example. This is the mechanism by which an
 * AbortSignal is passed to each Handler in the chain, but the only real requirement when implementing a Handler is
 * to pass this argument along to the `next` function. In fact, many Handlers will want to be generic over the type
 * of metadata:
 * ```ts
 * const chainable = <Meta>(next: Handler<string, string, Meta>) => (request: string, metadata: Meta) => {
 *   return next(`modified ${request}`, metadata)
 * }
 * ```
 * Actually, it's a very good idea for Handlers to be as generic as possible, since that will allow greater re-use. In
 * the above example, we don't do anything with the response from `next`, so we can let that be generic, too:
 * ```ts
 * const chainable = <Res, Meta>(next: Handler<string, Res, Meta>) => (request: string, metadata: Meta) => {
 *   return next(`modified ${request}`, metadata)
 * }
 * ```
 * Now if some other Handler in the chain decides to return a different response type, our Handler won't require any
 * changes to compile.
 *
 * ---
 *
 * Since execution passes from handler to handler in the chain, and then back, handlers have the opportunity to modify
 * or observe both the request and response. This might be useful for implementing serialization/deserialization, but
 * the simplest example that demonstrates this feature is measuring request latency:
 * ```ts
 * const latencyMeasuringHandler = <Req, Res, Meta>(next: Handler<Req, Res, Meta>) =>
 *   async (req: Req, metadata: Meta) => {
 *     const start = performance.now()
 *     const response = await next(req, metadata)
 *     const latency = performance.now() - start
 *     console.log(`latency for request ${request} was ${latency}`)
 *     return response
 *   }
 * ```
 * Execution is first passed to our measuring handler, which marks the `start` timestamp. Then it passes execution on
 * down the chain. After a response is received (by some handler down the chain), execution passes back up to our
 * handler here, which records the amount of time spent inside `next`.
 *
 * ---
 *
 * Handlers may also abort requests. They can do this in two ways:
 *   1. Create an `AbortController` and add its `AbortSignal` to the `metadata` object when calling `next`.
 *   2. Resolve its returned Promise.
 *
 * The first approach is straightforward, but the second may benefit from an example – the simplest is a handler which
 * will timeout a request:
 * ```ts
 * const timeoutHandler = <Req, Res, Meta>(next: Handler<Req, Res, Meta>) => (req: Req, metadata: Meta) => {
 *   return Promise.race([
 *     next(req, metadata),
 *     sleep(1000),
 *   ])
 * }
 * ```
 * The Promise returned by this handler will resolve either when the `next` handler resolves or 1 second has elapsed,
 * whichever happens first. If the timeout happens first, we want the `next` handler to recieve an abort signal so that
 * it can terminate early (since its result is no longer needed).
 *
 * HandlerChainBuilder makes this happen by observing when each handler completes, and sending an abort signal to all
 * the handlers "downstream" from the aborting handler.
 */
export class HandlerChainBuilder<Req, Res, Meta extends RequestMetadata> {
    private readonly inner: Handler<Req, Res, Meta>;

    constructor(inner: (req: Req, metadata: Meta) => Promise<Res>) {
        // The TS compiler has the following behavior:
        //
        // class Infer<T extends SomeType | undefined> { constructor(f: (t?: T) => void) {} }
        // const f = (t?: SomeType) => {}
        // const i = new Infer(f)
        //
        // The type of `i` is inferred to be `Infer<SomeType>` instead of `Infer<SomeType | undefined>`, even though the
        // type of `f`'s argument is `SomeType | undefined`. This seems to be a bug in type inference. Note that making
        // the constructor argument required gives the expected behavior:
        //
        // class Infer<T extends SomeType | undefined> { constructor(f: (t: T) => void) {} }
        // const f = (t?: SomeType) => {}
        // const i = new Infer(f)
        //
        // Now `i` is inferred to be `Infer<SomeType | undefined>`.
        //
        // This has consequences if the inferred type T is used elsewhere in the class.
        //
        // In this case, we need to make sure that if the given `inner` function marks the metadata argument as
        // optional, that HandlerChainBuilder correctly infers that the Meta type includes undefined. So we don't mark
        // metadata as optional, and so we must cast to `Handler` (which does mark it as optional).
        //
        // Safety: We're adding `| undefined` to the metadata type, which may be unsafe – `undefined` may not be
        // assignable to Meta. But when handling the argument of type Meta, we simply pass it through from handler to
        // handler – we never call `inner` without passing the metadata argument we've received from some call to an
        // outer handler. The typing visible to callers remains safe.
        this.inner = inner as Handler<Req, Res, Meta>;
    }

    get handler(): Handler<Req, Res, Meta> {
        return this.inner;
    }

    map<PriorReq, PriorRes>(
        outer: ChainableHandler<PriorReq, PriorRes, Req, Res, Meta>
    ): HandlerChainBuilder<PriorReq, PriorRes, Meta> {
        // To create the next handler in the chain, we compose the "outer" handler with the "inner" handler.
        //
        // The outer handler observes its own completion and sends an abort signal to the inner handler when it has
        // resolved. To prevent unexpected behavior, the inner handler also observes its own completion, setting a flag
        // when it resolves so that – if it resolves before the outer handler – the outer handler can skip sending the
        // abort signal (since the inner handler has already completed).
        const outerHandler = (req: PriorReq, metadata: Meta): Promise<PriorRes> => {
            const abort = new AbortController();
            const signal = abort.signal;

            // It's important to not signal an abort to an inner handler which has already completed – it seems like
            // this would be a non-issue (shouldn't aborting after completion be a no-op?), but specifically for the
            // browser's implementation of `fetch`, aborting even after the `fetch` Promise resolves can cause an abort
            // error if e.g. the Fetch Response's body has not yet been read.
            //
            // So, for safety, we will only abort inner handlers which are still executing.
            let innerCompleted = false;

            const maybeAbort = () => {
                // Safety: we never give `abort` to anyone else, so we know if the signal is aborted, this function
                // has already run, so we can return early without fear of leaking. We also know if inner has completed,
                // it has already performed cleanup.
                if (signal.aborted || innerCompleted) return;

                // If we've gotten here, the outer handler has either completed, or we heard an abort event while the
                // inner handler is still executing – so we pass the abort signal down to the inner handler.
                abort.abort();
                metadata?.signal?.removeEventListener("abort", maybeAbort);
            };

            metadata?.signal?.addEventListener("abort", maybeAbort);

            const innerHandler = new Proxy(this.inner, {
                apply: (target, thisArg, args) => {
                    const [req, metadata] = args as Parameters<typeof target>;

                    // When calling the inner handler, we may not care about the result and don't want the handler's
                    // operation to be interrupted by an abort signal. For example, we might be calling the inner
                    // handler as a side-effect which we want to continue after the outer handler has completed.
                    //
                    // In this cases, we'll treat the inner handler as having completed immediately -- as far as the
                    // outer handler is concerned, the inner handler is a no-op. This means that when the outer handler
                    // completes, `maybeAbort` will not send an abort signal to the inner handler.
                    //
                    // A concrete example: returning a value from cache immediately, but then calling the inner handler
                    // as a side-effect to refresh the cache "in the background."
                    if (metadata?.isSideEffect) innerCompleted = true;

                    // To help Handler authors out, we'll do some bookkeeping and cleanup for them – if they forget to
                    // remove an abort event listener, we'll remove it for them when the Promise they return resolves.
                    // Note: No need to proxy removeEventListener, since removing a non-existent listener just no-ops.
                    const abortListeners: EventListenerOrEventListenerObject[] = [];
                    signal.addEventListener = new Proxy(signal.addEventListener, {
                        apply: (target, thisArg, args) => {
                            abortListeners.push(args[1]);
                            return Reflect.apply(target, thisArg, args);
                        },
                    });

                    const cleanupAndMarkComplete = () => {
                        // The only reason we listen to upstream aborts is to pass them to the inner handler – since the
                        // inner handler has completed, we no longer need the listener.
                        metadata?.signal?.removeEventListener("abort", maybeAbort);
                        abortListeners.forEach((listener) => signal.removeEventListener("abort", listener));
                        innerCompleted = true;
                    };

                    const innerResponse: ReturnType<typeof target> = Reflect.apply(target, thisArg, [
                        req,
                        // Side-effect state does not propagate down the handler chain -- each outer handler must set
                        // this property on their own when calling their inner handler. One outer handler may treat its
                        // inner handler as a side-effect, but that doesn't each subsequent handler in the chain should
                        // be treated as a side-effect. In other words, passing isSideEffect is only relevant to the
                        // HandlerChainBuilder (telling it not to abort the inner handler), and not to any subsequent
                        // handlers in the chain.
                        { ...metadata, isSideEffect: false, signal },
                    ]);

                    // Using `finally` is more idiomatic, but causes trouble in some environments (e.g. some testing
                    // runtimes which detect uncaught rejected promises).
                    innerResponse.catch(() => {}).then(cleanupAndMarkComplete);
                    return innerResponse;
                },
            });

            const outerResponse = outer(innerHandler)(req, metadata);
            outerResponse.catch(() => {}).then(maybeAbort);
            return outerResponse;
        };
        return new HandlerChainBuilder(outerHandler as Handler<PriorReq, PriorRes, Meta>);
    }
}
