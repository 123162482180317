import { take } from "rxjs";
import type { RemoteConfiguration } from "../remote-configuration/remoteConfiguration";
import { remoteConfigurationFactory } from "../remote-configuration/remoteConfiguration";
import { Injectable } from "../dependency-injection/Injectable";
import { getLogger } from "../logger/logger";
import type { LensRepository } from "./LensRepository";
import { lensRepositoryFactory } from "./LensRepository";

const logger = getLogger("fetchWatermarkLens");

// Special group for all "watermark" lenses that are applied by the Camera Kit internally.
export const watermarksLensGroup = "watermarks";

/**
 * @internal
 */
export const fetchWatermarkLens = Injectable(
    "fetchWatermarkLens",
    [remoteConfigurationFactory.token, lensRepositoryFactory.token] as const,
    (remoteConfiguration: RemoteConfiguration, lensRepository: LensRepository) => {
        remoteConfiguration
            .getInitializationConfig()
            .pipe(take(1))
            .subscribe({
                next: async (config) => {
                    if (config.watermarkEnabled) {
                        const lens = await lensRepository.loadLens("", watermarksLensGroup);
                        await lensRepository.cacheLensContent([lens]);
                    }
                },
                error: logger.error,
            });
    }
);
