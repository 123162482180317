import type { Observable } from "rxjs";
import { Subject } from "rxjs";
import type {
    DefaultErrorHandler,
    DefaultEventHandler,
    LensCoreModule,
    PlayCanvasInput,
    LensCoreError as NativeLensCoreError,
} from "./generated-types";
import type { LensCoreError } from "./lensCoreError";
import { wrapLensCoreError } from "./lensCoreError";

// Note: While this looks similar to Omit, Omit breaks discriminated unions:
// https://github.com/microsoft/TypeScript/issues/31501
// This is relevant in the case of setRenderMode().
type SafeOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

/**
 * LensCore methods that require additional handling.
 */
type LensCoreCustomMethods = {
    // initialize() param has exceptionHandler, which requires error mapping.
    initialize(input: PromisifiedParam<MapParamErrorCallback<"initialize", "exceptionHandler">>): Promise<void>;

    // provideRemoteAssetsResponse() param has onFailure, which requires error mapping.
    provideRemoteAssetsResponse(input: MapParamErrorCallback<"provideRemoteAssetsResponse", "onFailure">): void;

    // playCanvas() should resolve its Promise when the `onReady` callback is invoked, instead of the `onSuccess`.
    playCanvas(input: Omit<PlayCanvasInput, "onReady" | "onSuccess" | "onFailure">): Promise<void>;
};

// Ensure method names defined in CustomLensCoreMethods are the ones that exist in LensCoreModule.
type LensCoreCustomMethodNames = keyof LensCoreCustomMethods extends keyof LensCoreModule
    ? keyof LensCoreCustomMethods
    : never;

type PropertyKinds = "enum" | "sync method" | "promisifiable method" | "custom method";

// Create a mapping between property keys and the kind of property it is (enum, sync method, or async method)
type LensCorePropertyKinds = {
    [K in keyof LensCoreModule]: LensCoreModule[K] extends (...args: any[]) => any
        ? K extends LensCoreCustomMethodNames
            ? "custom method"
            : Parameters<LensCoreModule[K]>[0] extends {
                  onSuccess?: DefaultEventHandler;
                  onFailure?: DefaultErrorHandler;
              }
            ? "promisifiable method"
            : "sync method"
        : "enum";
};

// Helper to select LensCoreModule properties of a particular kind.
type PropertiesOfKind<T extends PropertyKinds> = Exclude<
    {
        [K in keyof LensCorePropertyKinds]: LensCorePropertyKinds[K] extends T ? K : never;
    }[keyof LensCorePropertyKinds],
    undefined
>;
type FirstParameter<T> = T extends (first: infer P, ...rest: any[]) => any ? P : never;
type LensCoreEnums = Pick<LensCoreModule, PropertiesOfKind<"enum">>;
type LensCoreSyncMethods = Pick<LensCoreModule, PropertiesOfKind<"sync method">>;
type LensCorePromisifiedMethods = {
    [K in PropertiesOfKind<"promisifiable method">]: K extends keyof LensCoreModule
        ? keyof SafeOmit<Parameters<LensCoreModule[K]>[0], "onSuccess" | "onFailure"> extends never
            ? () => Promise<FirstParameter<Parameters<LensCoreModule[K]>[0]["onSuccess"]>>
            : (
                  input: SafeOmit<Parameters<LensCoreModule[K]>[0], "onSuccess" | "onFailure">
              ) => Promise<FirstParameter<Parameters<LensCoreModule[K]>[0]["onSuccess"]>>
        : never;
};
type PromisifiedParam<T extends { onSuccess?: DefaultEventHandler; onFailure?: DefaultErrorHandler }> = {
    [P in keyof T as P extends "onSuccess" | "onFailure" ? never : P]: T[P];
};
type MapParamErrorCallback<
    T extends PropertiesOfKind<"custom method">,
    U extends keyof LensCoreMethodFirstParam<T>
> = MapErrorCallback<LensCoreMethodFirstParam<T>, U>;
type LensCoreMethodFirstParam<T extends PropertiesOfKind<"custom method">> = Parameters<LensCoreModule[T]>[0];
type MapErrorCallback<T, U extends keyof T> = { [P in keyof T]: P extends U ? (err: LensCoreError) => void : T[P] };

// By using PropertiesOfKind, we can ensure a compile-time error if LensCoreModule adds a new async method,
// but we forget to update this list.
const promisifiableMethods: { [K in PropertiesOfKind<"promisifiable method">]: null } = {
    addLens: null,
    clearAllLenses: null,
    imageToYuvBuffer: null,
    pauseCanvas: null,
    processAudioSampleBuffer: null,
    processFrame: null,
    removeLens: null,
    replaceLenses: null,
    setAudioParameters: null,
    setDeviceClass: null,
    setFPSLimit: null,
    setInputTransform: null,
    setOnFrameProcessedCallback: null,
    setRenderLoopMode: null,
    setRenderSize: null,
    teardown: null,
    useMediaElement: null,
    yuvBufferToBitmap: null,
};

/** @internal */
export type LensCore = LensCoreEnums &
    LensCoreSyncMethods &
    LensCorePromisifiedMethods &
    LensCoreCustomMethods &
    LensCoreErrorField;

/**
 * @internal
 */
export interface LensCoreErrorField {
    errors: Observable<LensCoreError>;
}

/**
 * Returns a native LensCore error mapper, which maps LensCore native errors to Camera Kit errors.
 *
 * @param isForFrameErrors Indicates whether the errors occurred during frame processing.
 * @param errors An observable subject where this error is emitted.
 */
function createErrorWrapper(isForFrameErrors: boolean, errors: Subject<LensCoreError>) {
    return (fn: (e: LensCoreError) => void) => {
        return (nativeError: NativeLensCoreError): void => {
            const error = wrapLensCoreError(nativeError, isForFrameErrors);
            fn(error);
            errors.next(error);
        };
    };
}

function withTryCatch<T extends (...args: any) => any>(fn: T, errors: Subject<LensCoreError>) {
    return function (this: LensCoreModule, ...args: Parameters<T>) {
        try {
            return fn.apply(this, args);
        } catch (e) {
            const error = wrapLensCoreError(e, false);
            errors.next(error);
            throw error;
        }
    };
}

const errorsFieldName: keyof LensCoreErrorField = "errors";

/**
 * Create wrapper around LensCore module which adds Promise interfaces to the methods.
 *
 * NOTE: We try to keep as close to the native LensCore module as possible.
 * Ideally when LensCore makes its interface Promises, we won't need this Proxy.
 *
 * @internal
 */
export const createLensCore = (lensCoreModule: LensCoreModule): LensCore => {
    const errors = new Subject<LensCoreError>();
    const errorsObservable = errors.asObservable();
    const getRegularErrorWrapper = createErrorWrapper(false, errors);
    const getFrameErrorWrapper = createErrorWrapper(true, errors);

    const customMethods: LensCoreCustomMethods = {
        initialize(input) {
            return new Promise((onSuccess, onFailure) =>
                lensCoreModule.initialize({
                    ...input,
                    exceptionHandler: getFrameErrorWrapper(input.exceptionHandler ?? (() => {})),
                    onSuccess,
                    onFailure: getRegularErrorWrapper(onFailure),
                })
            );
        },

        provideRemoteAssetsResponse(input) {
            return lensCoreModule.provideRemoteAssetsResponse({
                ...input,
                onFailure: getRegularErrorWrapper(input.onFailure ?? (() => {})),
            });
        },

        playCanvas(input) {
            return new Promise((onReady, onFailure) => {
                lensCoreModule.playCanvas({
                    ...input,
                    onReady,
                    onFailure: getRegularErrorWrapper(onFailure),
                });
            });
        },
    };

    return new Proxy(lensCoreModule, {
        get: (target, property, receiver) => {
            if (property === errorsFieldName) {
                return errorsObservable;
            }

            // Handle special methods with custom implementations
            if (property in customMethods) {
                // Safety: "in" operator above ensures that property is keyof LensCoreCustomMethods
                return withTryCatch(customMethods[property as keyof LensCoreCustomMethods], errors);
            }

            const targetProperty = Reflect.get(target, property, receiver);
            if (!targetProperty) return targetProperty;

            // All other async methods return Promises
            if (property in promisifiableMethods) {
                return withTryCatch(function (
                    input: Parameters<LensCorePromisifiedMethods[keyof LensCorePromisifiedMethods]>[0]
                ) {
                    return new Promise((onSuccess, onFailure) =>
                        targetProperty({
                            ...input,
                            onSuccess,
                            onFailure: getRegularErrorWrapper(onFailure),
                        })
                    );
                },
                errors);
            }

            // All other kinds of properties (enums, sync methods) are unmodified.
            if (typeof targetProperty === "function") {
                if ("values" in targetProperty) {
                    // this is enum
                    return targetProperty;
                } else {
                    // this is a sync method
                    return withTryCatch(targetProperty, errors);
                }
            }
            return targetProperty;
        },
        // Safety: We ensured safety by defining types for both custom and promisifiable methods.
    }) as LensCore;
};
