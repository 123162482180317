import { fromEvent, merge, take, tap } from "rxjs";

/**
 * Adds script element to document body and starts downloading provided script URL.
 * @param scriptUri Script URL.
 * @returns Script element added.
 */
export function loadScript(scriptUri: string): Promise<HTMLScriptElement> {
    return new Promise((resolve, reject) => {
        const scriptElement = document.createElement("script");
        scriptElement.src = scriptUri;
        scriptElement.async = true;
        merge(
            fromEvent(scriptElement, "load").pipe(tap(() => resolve(scriptElement))),
            fromEvent(scriptElement, "error").pipe(tap((e) => reject(e)))
        )
            .pipe(take(1))
            .subscribe();
        document.body.appendChild(scriptElement);
    });
}
