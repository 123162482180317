import { getCameraKitUserAgent } from "../platform/cameraKitUserAgent";
import { ExtensionRequestContext } from "../generated-proto/pb_schema/camera_kit/v3/export";
import { getPlatformInfo } from "../platform/platformInfo";

function getExtensionRequestContext(): ArrayBuffer {
    return ExtensionRequestContext.encode({
        userAgent: getCameraKitUserAgent(),
        locale: getPlatformInfo().fullLocale,
    }).finish();
}

/**
 * Extension request context.
 */
export const extensionRequestContext = getExtensionRequestContext();
