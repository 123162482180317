import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
export const protobufPackage = "com.snap.camerakit.v3";
export interface OperationalMetric {
    name: string;
    timestamp: Date | undefined;
    metric?: {
        $case: "count";
        count: string;
    } | {
        $case: "latencyMillis";
        latencyMillis: string;
    } | {
        $case: "histogram";
        histogram: string;
    };
}
export interface OperationalMetricsBundle {
    metrics: OperationalMetric[];
}
function createBaseOperationalMetric(): OperationalMetric {
    return { name: "", timestamp: undefined, metric: undefined };
}
export const OperationalMetric = {
    encode(message: OperationalMetric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(18).fork()).ldelim();
        }
        if (message.metric?.$case === "count") {
            writer.uint32(24).uint64(message.metric.count);
        }
        if (message.metric?.$case === "latencyMillis") {
            writer.uint32(32).uint64(message.metric.latencyMillis);
        }
        if (message.metric?.$case === "histogram") {
            writer.uint32(40).int64(message.metric.histogram);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): OperationalMetric {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOperationalMetric();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.metric = { $case: "count", count: longToString(reader.uint64() as Long) };
                    break;
                case 4:
                    message.metric = { $case: "latencyMillis", latencyMillis: longToString(reader.uint64() as Long) };
                    break;
                case 5:
                    message.metric = { $case: "histogram", histogram: longToString(reader.int64() as Long) };
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<OperationalMetric>, I>>(object: I): OperationalMetric {
        const message = createBaseOperationalMetric();
        message.name = object.name ?? "";
        message.timestamp = object.timestamp ?? undefined;
        if (object.metric?.$case === "count" && object.metric?.count !== undefined && object.metric?.count !== null) {
            message.metric = { $case: "count", count: object.metric.count };
        }
        if (object.metric?.$case === "latencyMillis" &&
            object.metric?.latencyMillis !== undefined &&
            object.metric?.latencyMillis !== null) {
            message.metric = { $case: "latencyMillis", latencyMillis: object.metric.latencyMillis };
        }
        if (object.metric?.$case === "histogram" &&
            object.metric?.histogram !== undefined &&
            object.metric?.histogram !== null) {
            message.metric = { $case: "histogram", histogram: object.metric.histogram };
        }
        return message;
    },
};
function createBaseOperationalMetricsBundle(): OperationalMetricsBundle {
    return { metrics: [] };
}
export const OperationalMetricsBundle = {
    encode(message: OperationalMetricsBundle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.metrics) {
            OperationalMetric.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): OperationalMetricsBundle {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseOperationalMetricsBundle();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.metrics.push(OperationalMetric.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<OperationalMetricsBundle>, I>>(object: I): OperationalMetricsBundle {
        const message = createBaseOperationalMetricsBundle();
        message.metrics = object.metrics?.map((e) => OperationalMetric.fromPartial(e)) || [];
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function toTimestamp(date: Date): Timestamp {
    const seconds = Math.trunc(date.getTime() / 1000).toString();
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t: Timestamp): Date {
    let millis = Number(t.seconds) * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
function longToString(long: Long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
