import { Subject } from "rxjs";
import { entries } from "../common/entries";

/**
 * A reference to the recently created log messages subject.
 * The subject is overridden in resetLogger() method, which is called during CameraKit bootstrapping.
 */
let logEntriesSubject = new Subject<LogEntry>();

/**
 * This map associates log level names with their corresponding level value.
 * This means that a configured log level will match all log entries with a level value greater than
 * or equal to the configured value.
 */
export const logLevelMap = {
    error: 3,
    warn: 2,
    log: 1,
    info: 1,
    debug: 0,
};

/**
 * Initializes a new logger subject.
 *
 * Note: currently only one `CameraKit` instance is allowed to listen to log messages at a time, therefore that is
 * necessary to avoid sharing the same subject between multiple `CameraKit` instances by calling`resetLogger()`.
 * Also, `resetLogger()` should be called when there is no interest in logged messages.
 * This allows the previous logEntriesSubject to be GCec.
 * @internal
 */
export function resetLogger() {
    return (logEntriesSubject = new Subject<LogEntry>());
}

export interface LogEntry {
    time: Date;
    module: string;
    level: keyof Logger;
    messages: any[];
}

export type LogLevelName = keyof typeof logLevelMap;
export type Logger = Record<LogLevelName, (message?: any, ...optionalParams: any[]) => void>;

/**
 * Gets logger for a given module.
 *
 * @internal
 *
 * @param module Module name.
 * @returns Logger instance.
 */
export function getLogger(module: string): Logger {
    return entries(logLevelMap).reduce((logger, [level]) => {
        logger[level] = (...messages: any[]) => {
            logEntriesSubject.next({
                time: new Date(),
                module,
                level,
                messages,
            });
        };
        return logger;
    }, {} as Logger);
}
