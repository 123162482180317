import { argumentValidationError } from "../namedErrors";
import type { Guard } from "./typeguards";

const ordinalSuffixMap: Record<number, string> = {
    1: "st",
    2: "nd",
    3: "rd",
};

function getArgumentInfo(target: unknown, methodName: string | symbol, argumentIndex: number, arg: unknown) {
    let argString;
    try {
        argString = JSON.stringify(arg);
    } catch {
        argString = String(arg);
    }
    return {
        argPosition: `${argumentIndex + 1}${ordinalSuffixMap[argumentIndex + 1] ?? "th"}`,
        methodPath: `${getTypeName(target)}.${String(methodName)}()`,
        argString,
    };
}

/* eslint-disable max-len */
/**
 * Returns type string of a value. It mostly mimics the behavior of typeof, but for non-primitives
 * (i.e. objects and functions), it returns a more granular type name where possible. Source:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof#custom_method_that_gets_a_more_specific_type
 */
export function getTypeName(value: unknown): string {
    if (value === null) {
        return "null";
    }

    const baseType = typeof value;
    // Primitive types
    if (!["object", "function"].includes(baseType)) {
        return baseType;
    }

    // Safety: at this point null and undefined values are already handled
    const nonNullValue = value!;

    // Symbol.toStringTag often specifies the "display name" of the
    // object's class. It's used in Object.prototype.toString().
    // Safety: cast to an object with Symbol.toStringTag key in order to check for its existance.
    const tag = (nonNullValue as { [Symbol.toStringTag]?: string })[Symbol.toStringTag];
    if (typeof tag === "string") {
        return tag;
    }

    // If it's a function whose source code starts with the "class" keyword
    if (baseType === "function" && Function.prototype.toString.call(nonNullValue).startsWith("class")) {
        return "class";
    }

    // The name of the constructor; for example `Array`, `GeneratorFunction`,
    // `Number`, `String`, `Boolean` or `MyCustomClass`
    const className = nonNullValue.constructor.name;
    if (typeof className === "string" && className !== "") {
        return className;
    }

    // At this point there's no robust way to get the type of value,
    // so we use the base implementation.
    return baseType;
}

/**
 * Decorator to validate method arguments.
 * @param guards Parameter guards to validate arguments.
 * @returns
 */
export function validate<This, Args extends any[], Return>(...guards: { [K in keyof Args]: Guard<Args[K]> }) {
    return function validator(
        target: (this: This, ...args: Args) => Return,
        context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
    ): (this: This, ...args: Args) => Return {
        return function (this, ...args) {
            for (const [index, guard] of guards.entries()) {
                if (!guard(args[index])) {
                    const { argPosition, methodPath, argString } = getArgumentInfo(
                        this,
                        context.name,
                        index,
                        args[index]
                    );
                    throw argumentValidationError(
                        `The ${argPosition} argument to ${methodPath} method has an invalid value: ${argString}.`
                    );
                }
            }
            return target.apply(this, args);
        };
    };
}
