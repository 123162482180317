import { Injectable } from "../../dependency-injection/Injectable";
import { createArrayBufferParsingHandler } from "../../handlers/arrayBufferParsingHandler";
import type { FetchHandler } from "../../handlers/defaultFetchHandler";
import { defaultFetchHandlerFactory } from "../../handlers/defaultFetchHandler";
import { HandlerChainBuilder } from "../../handlers/HandlerChainBuilder";
import { withRequestPriority } from "../../handlers/utils";
import type { AssetLoader } from "./LensAssetRepository";

/**
 * @internal
 */
export const staticAssetLoaderFactory = Injectable(
    "staticAssetLoader",
    [defaultFetchHandlerFactory.token] as const,
    (fetchHandler: FetchHandler): AssetLoader => {
        const handler = new HandlerChainBuilder(fetchHandler).map(createArrayBufferParsingHandler()).handler;

        return async ({ assetDescriptor: { assetId }, assetManifest, lowPriority }) => {
            const assetUrl = assetManifest?.find((manifest) => manifest.id === assetId)?.assetUrl;
            if (!assetUrl) {
                throw new Error(`Cannot load lens asset ${assetId}. Static asset URL could not be found.`);
            }
            // TODO: remove force-cache once https://jira.sc-corp.net/browse/CAMKIT-3671 is addressed
            const [buffer, response] = await handler(
                assetUrl,
                withRequestPriority({ cache: "force-cache" }, lowPriority)
            );
            if (!response.ok) throw response;
            return buffer;
        };
    }
);
