import type { MonoTypeOperatorFunction } from "rxjs";
import { defer, finalize, tap } from "rxjs";

/**
 * Returns an Observable that mirrors the source Observable, but will call a specified function when the source has no
 * more subscribers.
 *
 * This is exactly like the `finalize` operator, except that the specified function will be called only when the source
 * is explicitly unsubscribed.
 *
 * @param callback Called when the source Observable has no more subscribers.
 * @returns
 */
export function unsubscribed<T>(callback: () => void): MonoTypeOperatorFunction<T> {
    return (source) =>
        defer(() => {
            // We can tell if the source is completed or errored -- if neither has happened, we know the source is being
            // finalized because all subscribers have left.
            let completedOrErrored = false;
            return source.pipe(
                tap({
                    complete: () => (completedOrErrored = true),
                    error: () => (completedOrErrored = true),
                }),
                finalize(() => {
                    if (completedOrErrored) return;
                    callback();
                })
            );
        });
}
