import _m0 from "protobufjs/minimal";

export const ServerEventBatch = {
    encode(message: ServerEventBatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.serverUploadTs !== 0) {
            writer.uint32(9).double(message.serverUploadTs);
        }
        for (const v of message.serverAllUpdateEvents) {
            ServerRequestAllUpdates.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        if (message.maxSequenceIdOnInstance !== "0") {
            writer.uint32(24).uint64(message.maxSequenceIdOnInstance);
        }
        for (const v of message.serverEvents) {
            ServerEvent.encode(v!, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<ServerEventBatch>, I>>(object: I): ServerEventBatch {
        const message = createBaseServerEventBatch();
        message.serverUploadTs = object.serverUploadTs ?? 0;
        message.serverAllUpdateEvents =
            object.serverAllUpdateEvents?.map((e) => ServerRequestAllUpdates.fromPartial(e)) || [];
        message.maxSequenceIdOnInstance = object.maxSequenceIdOnInstance ?? "0";
        message.serverEvents = object.serverEvents?.map((e) => ServerEvent.fromPartial(e)) || [];
        return message;
    },
};

function createBaseServerEventBatch(): ServerEventBatch {
    return { serverUploadTs: 0, serverAllUpdateEvents: [], maxSequenceIdOnInstance: "0", serverEvents: [] };
}

export const ServerRequestAllUpdates = {
    encode(_: ServerRequestAllUpdates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<ServerRequestAllUpdates>, I>>(_: I): ServerRequestAllUpdates {
        const message = createBaseServerRequestAllUpdates();
        return message;
    },
};

function createBaseServerRequestAllUpdates(): ServerRequestAllUpdates {
    return {};
}

/** Hardcoded because it is not part of EventSchema.java but is a part of manual event.proto */
export interface ServerEventBatch {
    serverUploadTs: number;
    /** @deprecated */
    serverAllUpdateEvents: ServerRequestAllUpdates[];
    maxSequenceIdOnInstance: string;
    serverEvents: ServerEvent[];
}

export interface ServerRequestAllUpdates {
}

export const ServerEvent = {
    encode(message: ServerEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.eventName !== "") {
            writer.uint32(10).string(message.eventName);
        }
        if (message.serverTs !== 0) {
            writer.uint32(25).double(message.serverTs);
        }
        if (message.userId !== "") {
            writer.uint32(34).string(message.userId);
        }
        if (message.userAgent !== "") {
            writer.uint32(42).string(message.userAgent);
        }
        if (message.country !== "") {
            writer.uint32(50).string(message.country);
        }
        if (message.city !== "") {
            writer.uint32(58).string(message.city);
        }
        if (message.region !== "") {
            writer.uint32(66).string(message.region);
        }
        if (message.eventId !== "") {
            writer.uint32(74).string(message.eventId);
        }
        if (message.instanceId !== "") {
            writer.uint32(82).string(message.instanceId);
        }
        if (message.sequenceId !== "0") {
            writer.uint32(88).uint64(message.sequenceId);
        }
        if (message.osType !== "") {
            writer.uint32(98).string(message.osType);
        }
        if (message.osVersion !== "") {
            writer.uint32(106).string(message.osVersion);
        }
        if (message.appVersion !== "") {
            writer.uint32(114).string(message.appVersion);
        }
        if (message.appBuild !== "") {
            writer.uint32(122).string(message.appBuild);
        }
        if (message.serverUploadTs !== 0) {
            writer.uint32(809).double(message.serverUploadTs);
        }
        if (message.eventTime !== 0) {
            writer.uint32(817).double(message.eventTime);
        }
        if (message.serverReceiptTime !== 0) {
            writer.uint32(825).double(message.serverReceiptTime);
        }
        if (message.maxSequenceIdOnInstance !== "0") {
            writer.uint32(832).uint64(message.maxSequenceIdOnInstance);
        }
        if (message.userGuid !== "") {
            writer.uint32(850).string(message.userGuid);
        }
        if (message.collection !== 0) {
            writer.uint32(872).int32(message.collection);
        }
        if (message.serviceId !== "") {
            writer.uint32(890).string(message.serviceId);
        }
        if (message.appType !== 0) {
            writer.uint32(912).int32(message.appType);
        }
        if (message.spectrumInstanceId !== "") {
            writer.uint32(930).string(message.spectrumInstanceId);
        }
        if (message.spectrumSequenceId !== "0") {
            writer.uint32(944).uint64(message.spectrumSequenceId);
        }
        if (message.eventData !== undefined) {
            ServerEventData.encode(message.eventData, writer.uint32(802).fork()).ldelim();
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<ServerEvent>, I>>(object: I): ServerEvent {
        const message = createBaseServerEvent();
        message.eventName = object.eventName ?? "";
        message.serverTs = object.serverTs ?? 0;
        message.userId = object.userId ?? "";
        message.userAgent = object.userAgent ?? "";
        message.country = object.country ?? "";
        message.city = object.city ?? "";
        message.region = object.region ?? "";
        message.eventId = object.eventId ?? "";
        message.instanceId = object.instanceId ?? "";
        message.sequenceId = object.sequenceId ?? "0";
        message.osType = object.osType ?? "";
        message.osVersion = object.osVersion ?? "";
        message.appVersion = object.appVersion ?? "";
        message.appBuild = object.appBuild ?? "";
        message.serverUploadTs = object.serverUploadTs ?? 0;
        message.eventTime = object.eventTime ?? 0;
        message.serverReceiptTime = object.serverReceiptTime ?? 0;
        message.maxSequenceIdOnInstance = object.maxSequenceIdOnInstance ?? "0";
        message.userGuid = object.userGuid ?? "";
        message.collection = object.collection ?? 0;
        message.serviceId = object.serviceId ?? "";
        message.appType = object.appType ?? 0;
        message.spectrumInstanceId = object.spectrumInstanceId ?? "";
        message.spectrumSequenceId = object.spectrumSequenceId ?? "0";
        message.eventData =
            object.eventData !== undefined && object.eventData !== null
                ? ServerEventData.fromPartial(object.eventData)
                : undefined;
        return message;
    },
};

function createBaseServerEvent(): ServerEvent {
    return {
        eventName: "",
        serverTs: 0,
        userId: "",
        userAgent: "",
        country: "",
        city: "",
        region: "",
        eventId: "",
        instanceId: "",
        sequenceId: "0",
        osType: "",
        osVersion: "",
        appVersion: "",
        appBuild: "",
        serverUploadTs: 0,
        eventTime: 0,
        serverReceiptTime: 0,
        maxSequenceIdOnInstance: "0",
        userGuid: "",
        collection: 0,
        serviceId: "",
        appType: 0,
        spectrumInstanceId: "",
        spectrumSequenceId: "0",
        eventData: undefined,
    };
}

export const ServerEventData = {
    encode(message: ServerEventData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitException !== undefined) {
            CameraKitException.encode(message.cameraKitException, writer.uint32(1866).fork()).ldelim();
        }
        if (message.cameraKitLensSpin !== undefined) {
            CameraKitLensSpin.encode(message.cameraKitLensSpin, writer.uint32(1874).fork()).ldelim();
        }
        if (message.cameraKitAssetDownload !== undefined) {
            CameraKitAssetDownload.encode(message.cameraKitAssetDownload, writer.uint32(1882).fork()).ldelim();
        }
        if (message.cameraKitLensContentValidationFailed !== undefined) {
            CameraKitLensContentValidationFailed.encode(message.cameraKitLensContentValidationFailed, writer.uint32(1922).fork()).ldelim();
        }
        if (message.cameraKitLensDownload !== undefined) {
            CameraKitLensDownload.encode(message.cameraKitLensDownload, writer.uint32(1930).fork()).ldelim();
        }
        if (message.cameraKitAssetValidationFailed !== undefined) {
            CameraKitAssetValidationFailed.encode(message.cameraKitAssetValidationFailed, writer.uint32(1938).fork()).ldelim();
        }
        if (message.cameraKitSession !== undefined) {
            CameraKitSession.encode(message.cameraKitSession, writer.uint32(2130).fork()).ldelim();
        }
        if (message.cameraKitWebLensSwipe !== undefined) {
            CameraKitWebLensSwipe.encode(message.cameraKitWebLensSwipe, writer.uint32(3818).fork()).ldelim();
        }
        if (message.cameraKitWebBenchmarkComplete !== undefined) {
            CameraKitWebBenchmarkComplete.encode(message.cameraKitWebBenchmarkComplete, writer.uint32(3826).fork()).ldelim();
        }
        if (message.cameraKitLegalPrompt !== undefined) {
            CameraKitLegalPrompt.encode(message.cameraKitLegalPrompt, writer.uint32(3874).fork()).ldelim();
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<ServerEventData>, I>>(object: I): ServerEventData {
        const message = createBaseServerEventData();
        message.cameraKitException =
            object.cameraKitException !== undefined && object.cameraKitException !== null
                ? CameraKitException.fromPartial(object.cameraKitException)
                : undefined;
        message.cameraKitLensSpin =
            object.cameraKitLensSpin !== undefined && object.cameraKitLensSpin !== null
                ? CameraKitLensSpin.fromPartial(object.cameraKitLensSpin)
                : undefined;
        message.cameraKitAssetDownload =
            object.cameraKitAssetDownload !== undefined && object.cameraKitAssetDownload !== null
                ? CameraKitAssetDownload.fromPartial(object.cameraKitAssetDownload)
                : undefined;
        message.cameraKitLensContentValidationFailed =
            object.cameraKitLensContentValidationFailed !== undefined && object.cameraKitLensContentValidationFailed !== null
                ? CameraKitLensContentValidationFailed.fromPartial(object.cameraKitLensContentValidationFailed)
                : undefined;
        message.cameraKitLensDownload =
            object.cameraKitLensDownload !== undefined && object.cameraKitLensDownload !== null
                ? CameraKitLensDownload.fromPartial(object.cameraKitLensDownload)
                : undefined;
        message.cameraKitAssetValidationFailed =
            object.cameraKitAssetValidationFailed !== undefined && object.cameraKitAssetValidationFailed !== null
                ? CameraKitAssetValidationFailed.fromPartial(object.cameraKitAssetValidationFailed)
                : undefined;
        message.cameraKitSession =
            object.cameraKitSession !== undefined && object.cameraKitSession !== null
                ? CameraKitSession.fromPartial(object.cameraKitSession)
                : undefined;
        message.cameraKitWebLensSwipe =
            object.cameraKitWebLensSwipe !== undefined && object.cameraKitWebLensSwipe !== null
                ? CameraKitWebLensSwipe.fromPartial(object.cameraKitWebLensSwipe)
                : undefined;
        message.cameraKitWebBenchmarkComplete =
            object.cameraKitWebBenchmarkComplete !== undefined && object.cameraKitWebBenchmarkComplete !== null
                ? CameraKitWebBenchmarkComplete.fromPartial(object.cameraKitWebBenchmarkComplete)
                : undefined;
        message.cameraKitLegalPrompt =
            object.cameraKitLegalPrompt !== undefined && object.cameraKitLegalPrompt !== null
                ? CameraKitLegalPrompt.fromPartial(object.cameraKitLegalPrompt)
                : undefined;
        return message;
    },
};

function createBaseServerEventData(): ServerEventData {
    return {
        cameraKitException: undefined,
        cameraKitLensSpin: undefined,
        cameraKitAssetDownload: undefined,
        cameraKitLensContentValidationFailed: undefined,
        cameraKitLensDownload: undefined,
        cameraKitAssetValidationFailed: undefined,
        cameraKitSession: undefined,
        cameraKitWebLensSwipe: undefined,
        cameraKitWebBenchmarkComplete: undefined,
        cameraKitLegalPrompt: undefined,
    };
}

export interface ServerEvent {
    eventName: string;
    serverTs: number;
    userId: string;
    userAgent: string;
    country: string;
    city: string;
    region: string;
    eventId: string;
    instanceId: string;
    sequenceId: string;
    osType: string;
    osVersion: string;
    appVersion: string;
    appBuild: string;
    serverUploadTs: number;
    eventTime: number;
    serverReceiptTime: number;
    maxSequenceIdOnInstance: string;
    userGuid: string;
    collection: Collection;
    serviceId: string;
    appType: AppType;
    spectrumInstanceId: string;
    spectrumSequenceId: string;
    eventData: ServerEventData | undefined;
}

export interface ServerEventData {
    cameraKitException: CameraKitException | undefined;
    cameraKitLensSpin: CameraKitLensSpin | undefined;
    cameraKitAssetDownload: CameraKitAssetDownload | undefined;
    cameraKitLensContentValidationFailed: CameraKitLensContentValidationFailed | undefined;
    cameraKitLensDownload: CameraKitLensDownload | undefined;
    cameraKitAssetValidationFailed: CameraKitAssetValidationFailed | undefined;
    cameraKitSession: CameraKitSession | undefined;
    cameraKitWebLensSwipe: CameraKitWebLensSwipe | undefined;
    cameraKitWebBenchmarkComplete: CameraKitWebBenchmarkComplete | undefined;
    cameraKitLegalPrompt: CameraKitLegalPrompt | undefined;
}

export enum AppType {
    IOS_NATIVE = 0,
    IOS_WEB_DESKTOP = 1,
    IOS_WEB_MOBILE = 2,
    ANDROID_NATIVE = 3,
    ANDROID_WEB_DESKTOP = 4,
    ANDROID_WEB_MOBILE = 5,
    OSX_WEB_DESKTOP = 6,
    OSX_WEB_MOBILE = 7,
    WINDOWS_WEB_DESKTOP = 8,
    WINDOWS_WEB_MOBILE = 9,
    LINUX_WEB_DESKTOP = 10,
    LINUX_WEB_MOBILE = 11,
    LENSSTUDIO = 12,
    SNAPCAMERA = 13,
    WEB_DESKTOP = 14,
    WEB_MOBILE = 15,
    LENSSTUDIO_REMAKE = 16,
    UNRECOGNIZED = -1
}

export enum Collection {
    COLLECTION_UNUSED = 0,
    GAE = 1,
    GCE_M = 2,
    GCE_S = 3,
    GCE_T = 4,
    GKE_M = 5,
    GKE_S = 6,
    GKE_T = 7,
    LOCAL = 8,
    GCE_ST = 9,
    GKE_ST = 10,
    GCE_C = 11,
    GCE_SC = 12,
    GCE_TC = 13,
    GCE_TSC = 14,
    GKE_C = 15,
    GKE_SC = 16,
    GKE_TC = 17,
    GKE_TSC = 18,
    GKE_W = 19,
    GKE_WT = 20,
    GKE_WC = 21,
    GKE_WSC = 22,
    UNRECOGNIZED = -1
}

export const CameraKitLensSpin = {
    encode(message: CameraKitLensSpin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.lensId !== "") {
            writer.uint32(18).string(message.lensId);
        }
        if (message.viewTimeSec !== 0) {
            writer.uint32(25).double(message.viewTimeSec);
        }
        if (message.lensGroupId !== "") {
            writer.uint32(34).string(message.lensGroupId);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitLensSpin>, I>>(object: I): CameraKitLensSpin {
        const message = createBaseCameraKitLensSpin();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.lensId = object.lensId ?? "";
        message.viewTimeSec = object.viewTimeSec ?? 0;
        message.lensGroupId = object.lensGroupId ?? "";
        return message;
    },
};

function createBaseCameraKitLensSpin(): CameraKitLensSpin {
    return { cameraKitEventBase: undefined, lensId: "", viewTimeSec: 0, lensGroupId: "" };
}

export interface CameraKitLensSpin {
    cameraKitEventBase: CameraKitEventBase | undefined;
    lensId: string;
    viewTimeSec: number;
    lensGroupId: string;
}

export const CameraKitWebLensSwipe = {
    encode(message: CameraKitWebLensSwipe, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.performanceCluster !== "0") {
            writer.uint32(16).uint64(message.performanceCluster);
        }
        if (message.webglRendererInfo !== "") {
            writer.uint32(26).string(message.webglRendererInfo);
        }
        if (message.lensId !== "") {
            writer.uint32(34).string(message.lensId);
        }
        if (message.lensFrameProcessingTimeMsAvg !== 0) {
            writer.uint32(41).double(message.lensFrameProcessingTimeMsAvg);
        }
        if (message.lensFrameProcessingTimeMsStd !== 0) {
            writer.uint32(49).double(message.lensFrameProcessingTimeMsStd);
        }
        if (message.viewTimeSec !== 0) {
            writer.uint32(57).double(message.viewTimeSec);
        }
        if (message.recordingTimeSec !== 0) {
            writer.uint32(65).double(message.recordingTimeSec);
        }
        if (message.applyDelaySec !== 0) {
            writer.uint32(73).double(message.applyDelaySec);
        }
        if (message.avgFps !== 0) {
            writer.uint32(81).double(message.avgFps);
        }
        if (message.isLensFirstWithinDay === true) {
            writer.uint32(88).bool(message.isLensFirstWithinDay);
        }
        if (message.isLensFirstWithinMonth === true) {
            writer.uint32(96).bool(message.isLensFirstWithinMonth);
        }
        if (message.lensGroupId !== "") {
            writer.uint32(106).string(message.lensGroupId);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitWebLensSwipe>, I>>(object: I): CameraKitWebLensSwipe {
        const message = createBaseCameraKitWebLensSwipe();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.performanceCluster = object.performanceCluster ?? "0";
        message.webglRendererInfo = object.webglRendererInfo ?? "";
        message.lensId = object.lensId ?? "";
        message.lensFrameProcessingTimeMsAvg = object.lensFrameProcessingTimeMsAvg ?? 0;
        message.lensFrameProcessingTimeMsStd = object.lensFrameProcessingTimeMsStd ?? 0;
        message.viewTimeSec = object.viewTimeSec ?? 0;
        message.recordingTimeSec = object.recordingTimeSec ?? 0;
        message.applyDelaySec = object.applyDelaySec ?? 0;
        message.avgFps = object.avgFps ?? 0;
        message.isLensFirstWithinDay = object.isLensFirstWithinDay ?? false;
        message.isLensFirstWithinMonth = object.isLensFirstWithinMonth ?? false;
        message.lensGroupId = object.lensGroupId ?? "";
        return message;
    },
};

function createBaseCameraKitWebLensSwipe(): CameraKitWebLensSwipe {
    return {
        cameraKitEventBase: undefined,
        performanceCluster: "0",
        webglRendererInfo: "",
        lensId: "",
        lensFrameProcessingTimeMsAvg: 0,
        lensFrameProcessingTimeMsStd: 0,
        viewTimeSec: 0,
        recordingTimeSec: 0,
        applyDelaySec: 0,
        avgFps: 0,
        isLensFirstWithinDay: false,
        isLensFirstWithinMonth: false,
        lensGroupId: "",
    };
}

export interface CameraKitWebLensSwipe {
    cameraKitEventBase: CameraKitEventBase | undefined;
    performanceCluster: string;
    webglRendererInfo: string;
    lensId: string;
    lensFrameProcessingTimeMsAvg: number;
    lensFrameProcessingTimeMsStd: number;
    viewTimeSec: number;
    recordingTimeSec: number;
    applyDelaySec: number;
    avgFps: number;
    isLensFirstWithinDay: boolean;
    isLensFirstWithinMonth: boolean;
    lensGroupId: string;
}

export const CameraKitLensDownload = {
    encode(message: CameraKitLensDownload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.lensId !== "") {
            writer.uint32(18).string(message.lensId);
        }
        if (message.automaticDownload === true) {
            writer.uint32(24).bool(message.automaticDownload);
        }
        if (message.downloadTimeSec !== 0) {
            writer.uint32(33).double(message.downloadTimeSec);
        }
        if (message.sizeByte !== "0") {
            writer.uint32(40).uint64(message.sizeByte);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitLensDownload>, I>>(object: I): CameraKitLensDownload {
        const message = createBaseCameraKitLensDownload();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.lensId = object.lensId ?? "";
        message.automaticDownload = object.automaticDownload ?? false;
        message.downloadTimeSec = object.downloadTimeSec ?? 0;
        message.sizeByte = object.sizeByte ?? "0";
        return message;
    },
};

function createBaseCameraKitLensDownload(): CameraKitLensDownload {
    return { cameraKitEventBase: undefined, lensId: "", automaticDownload: false, downloadTimeSec: 0, sizeByte: "0" };
}

export interface CameraKitLensDownload {
    cameraKitEventBase: CameraKitEventBase | undefined;
    lensId: string;
    automaticDownload: boolean;
    downloadTimeSec: number;
    sizeByte: string;
}

export const CameraKitAssetDownload = {
    encode(message: CameraKitAssetDownload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.automaticDownload === true) {
            writer.uint32(24).bool(message.automaticDownload);
        }
        if (message.downloadTimeSec !== 0) {
            writer.uint32(33).double(message.downloadTimeSec);
        }
        if (message.sizeByte !== "0") {
            writer.uint32(40).uint64(message.sizeByte);
        }
        if (message.assetId !== "") {
            writer.uint32(50).string(message.assetId);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitAssetDownload>, I>>(object: I): CameraKitAssetDownload {
        const message = createBaseCameraKitAssetDownload();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.automaticDownload = object.automaticDownload ?? false;
        message.downloadTimeSec = object.downloadTimeSec ?? 0;
        message.sizeByte = object.sizeByte ?? "0";
        message.assetId = object.assetId ?? "";
        return message;
    },
};

function createBaseCameraKitAssetDownload(): CameraKitAssetDownload {
    return { cameraKitEventBase: undefined, automaticDownload: false, downloadTimeSec: 0, sizeByte: "0", assetId: "" };
}

export interface CameraKitAssetDownload {
    cameraKitEventBase: CameraKitEventBase | undefined;
    automaticDownload: boolean;
    downloadTimeSec: number;
    sizeByte: string;
    assetId: string;
}

export const CameraKitException = {
    encode(message: CameraKitException, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.lensId !== "") {
            writer.uint32(18).string(message.lensId);
        }
        if (message.type !== "") {
            writer.uint32(26).string(message.type);
        }
        if (message.reason !== "") {
            writer.uint32(34).string(message.reason);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitException>, I>>(object: I): CameraKitException {
        const message = createBaseCameraKitException();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.lensId = object.lensId ?? "";
        message.type = object.type ?? "";
        message.reason = object.reason ?? "";
        return message;
    },
};

function createBaseCameraKitException(): CameraKitException {
    return { cameraKitEventBase: undefined, lensId: "", type: "", reason: "" };
}

export interface CameraKitException {
    cameraKitEventBase: CameraKitEventBase | undefined;
    lensId: string;
    type: string;
    reason: string;
}

export const CameraKitSession = {
    encode(message: CameraKitSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.dailySessionBucket !== 0) {
            writer.uint32(16).int32(message.dailySessionBucket);
        }
        if (message.isFirstWithinMonth === true) {
            writer.uint32(24).bool(message.isFirstWithinMonth);
        }
        if (message.day !== "0") {
            writer.uint32(32).uint64(message.day);
        }
        if (message.month !== "0") {
            writer.uint32(40).uint64(message.month);
        }
        if (message.year !== "0") {
            writer.uint32(48).uint64(message.year);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitSession>, I>>(object: I): CameraKitSession {
        const message = createBaseCameraKitSession();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.dailySessionBucket = object.dailySessionBucket ?? 0;
        message.isFirstWithinMonth = object.isFirstWithinMonth ?? false;
        message.day = object.day ?? "0";
        message.month = object.month ?? "0";
        message.year = object.year ?? "0";
        return message;
    },
};

function createBaseCameraKitSession(): CameraKitSession {
    return {
        cameraKitEventBase: undefined,
        dailySessionBucket: 0,
        isFirstWithinMonth: false,
        day: "0",
        month: "0",
        year: "0",
    };
}

export interface CameraKitSession {
    cameraKitEventBase: CameraKitEventBase | undefined;
    dailySessionBucket: DailySessionBucket;
    isFirstWithinMonth: boolean;
    day: string;
    month: string;
    year: string;
}

export enum DailySessionBucket {
    NO_SESSION_BUCKET = 0,
    ONE_SESSION = 1,
    TWO_SESSION = 2,
    THREE_SESSION = 3,
    FOUR_SESSION = 4,
    FIVE_SESSION = 5,
    SIX_SESSION = 6,
    SEVEN_SESSION = 7,
    EIGHT_SESSION = 8,
    NINE_SESSION = 9,
    TEN_OR_MORE_SESSION = 10,
    UNRECOGNIZED = -1
}

export const CameraKitLensContentValidationFailed = {
    encode(message: CameraKitLensContentValidationFailed, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.lensId !== "") {
            writer.uint32(18).string(message.lensId);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitLensContentValidationFailed>, I>>(object: I): CameraKitLensContentValidationFailed {
        const message = createBaseCameraKitLensContentValidationFailed();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.lensId = object.lensId ?? "";
        return message;
    },
};

function createBaseCameraKitLensContentValidationFailed(): CameraKitLensContentValidationFailed {
    return { cameraKitEventBase: undefined, lensId: "" };
}

export interface CameraKitLensContentValidationFailed {
    cameraKitEventBase: CameraKitEventBase | undefined;
    lensId: string;
}

export const CameraKitAssetValidationFailed = {
    encode(message: CameraKitAssetValidationFailed, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.assetId !== "") {
            writer.uint32(26).string(message.assetId);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitAssetValidationFailed>, I>>(object: I): CameraKitAssetValidationFailed {
        const message = createBaseCameraKitAssetValidationFailed();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.assetId = object.assetId ?? "";
        return message;
    },
};

function createBaseCameraKitAssetValidationFailed(): CameraKitAssetValidationFailed {
    return { cameraKitEventBase: undefined, assetId: "" };
}

export interface CameraKitAssetValidationFailed {
    cameraKitEventBase: CameraKitEventBase | undefined;
    assetId: string;
}

export const CameraKitWebBenchmarkComplete = {
    encode(message: CameraKitWebBenchmarkComplete, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.benchmarkName !== "") {
            writer.uint32(18).string(message.benchmarkName);
        }
        if (message.benchmarkValue !== 0) {
            writer.uint32(25).double(message.benchmarkValue);
        }
        if (message.performanceCluster !== "0") {
            writer.uint32(32).uint64(message.performanceCluster);
        }
        if (message.webglRendererInfo !== "") {
            writer.uint32(42).string(message.webglRendererInfo);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitWebBenchmarkComplete>, I>>(object: I): CameraKitWebBenchmarkComplete {
        const message = createBaseCameraKitWebBenchmarkComplete();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.benchmarkName = object.benchmarkName ?? "";
        message.benchmarkValue = object.benchmarkValue ?? 0;
        message.performanceCluster = object.performanceCluster ?? "0";
        message.webglRendererInfo = object.webglRendererInfo ?? "";
        return message;
    },
};

function createBaseCameraKitWebBenchmarkComplete(): CameraKitWebBenchmarkComplete {
    return {
        cameraKitEventBase: undefined,
        benchmarkName: "",
        benchmarkValue: 0,
        performanceCluster: "0",
        webglRendererInfo: "",
    };
}

export interface CameraKitWebBenchmarkComplete {
    cameraKitEventBase: CameraKitEventBase | undefined;
    benchmarkName: string;
    benchmarkValue: number;
    performanceCluster: string;
    webglRendererInfo: string;
}

export const CameraKitLegalPrompt = {
    encode(message: CameraKitLegalPrompt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cameraKitEventBase !== undefined) {
            CameraKitEventBase.encode(message.cameraKitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.legalPromptId !== "") {
            writer.uint32(18).string(message.legalPromptId);
        }
        if (message.legalPromptResult !== 0) {
            writer.uint32(24).int32(message.legalPromptResult);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitLegalPrompt>, I>>(object: I): CameraKitLegalPrompt {
        const message = createBaseCameraKitLegalPrompt();
        message.cameraKitEventBase =
            object.cameraKitEventBase !== undefined && object.cameraKitEventBase !== null
                ? CameraKitEventBase.fromPartial(object.cameraKitEventBase)
                : undefined;
        message.legalPromptId = object.legalPromptId ?? "";
        message.legalPromptResult = object.legalPromptResult ?? 0;
        return message;
    },
};

function createBaseCameraKitLegalPrompt(): CameraKitLegalPrompt {
    return { cameraKitEventBase: undefined, legalPromptId: "", legalPromptResult: 0 };
}

export const CameraKitEventBase = {
    encode(message: CameraKitEventBase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.kitEventBase !== undefined) {
            KitEventBase.encode(message.kitEventBase, writer.uint32(10).fork()).ldelim();
        }
        if (message.deviceCluster !== "0") {
            writer.uint32(16).uint64(message.deviceCluster);
        }
        if (message.cameraKitVersion !== "") {
            writer.uint32(26).string(message.cameraKitVersion);
        }
        if (message.lensCoreVersion !== "") {
            writer.uint32(34).string(message.lensCoreVersion);
        }
        if (message.deviceModel !== "") {
            writer.uint32(42).string(message.deviceModel);
        }
        if (message.cameraKitVariant !== 0) {
            writer.uint32(48).int32(message.cameraKitVariant);
        }
        if (message.cameraKitFlavor !== 0) {
            writer.uint32(56).int32(message.cameraKitFlavor);
        }
        if (message.appId !== "") {
            writer.uint32(66).string(message.appId);
        }
        if (message.deviceConnectivity !== 0) {
            writer.uint32(72).int32(message.deviceConnectivity);
        }
        if (message.sessionId !== "") {
            writer.uint32(82).string(message.sessionId);
        }
        if (message.appVendorUuid !== "") {
            writer.uint32(90).string(message.appVendorUuid);
        }
        if (message.rankingRequestId !== "") {
            writer.uint32(98).string(message.rankingRequestId);
        }
        if (message.cameraKitEnvironment !== 0) {
            writer.uint32(104).int32(message.cameraKitEnvironment);
        }
        if (message.partnerUuid !== "") {
            writer.uint32(114).string(message.partnerUuid);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<CameraKitEventBase>, I>>(object: I): CameraKitEventBase {
        const message = createBaseCameraKitEventBase();
        message.kitEventBase =
            object.kitEventBase !== undefined && object.kitEventBase !== null
                ? KitEventBase.fromPartial(object.kitEventBase)
                : undefined;
        message.deviceCluster = object.deviceCluster ?? "0";
        message.cameraKitVersion = object.cameraKitVersion ?? "";
        message.lensCoreVersion = object.lensCoreVersion ?? "";
        message.deviceModel = object.deviceModel ?? "";
        message.cameraKitVariant = object.cameraKitVariant ?? 0;
        message.cameraKitFlavor = object.cameraKitFlavor ?? 0;
        message.appId = object.appId ?? "";
        message.deviceConnectivity = object.deviceConnectivity ?? 0;
        message.sessionId = object.sessionId ?? "";
        message.appVendorUuid = object.appVendorUuid ?? "";
        message.rankingRequestId = object.rankingRequestId ?? "";
        message.cameraKitEnvironment = object.cameraKitEnvironment ?? 0;
        message.partnerUuid = object.partnerUuid ?? "";
        return message;
    },
};

function createBaseCameraKitEventBase(): CameraKitEventBase {
    return {
        kitEventBase: undefined,
        deviceCluster: "0",
        cameraKitVersion: "",
        lensCoreVersion: "",
        deviceModel: "",
        cameraKitVariant: 0,
        cameraKitFlavor: 0,
        appId: "",
        deviceConnectivity: 0,
        sessionId: "",
        appVendorUuid: "",
        rankingRequestId: "",
        cameraKitEnvironment: 0,
        partnerUuid: "",
    };
}

export const KitEventBase = {
    encode(message: KitEventBase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.oauthClientId !== "") {
            writer.uint32(10).string(message.oauthClientId);
        }
        if (message.locale !== "") {
            writer.uint32(18).string(message.locale);
        }
        if (message.kitUserAgent !== "") {
            writer.uint32(26).string(message.kitUserAgent);
        }
        if (message.ipAddress !== "") {
            writer.uint32(34).string(message.ipAddress);
        }
        if (message.osMinorVersion !== "") {
            writer.uint32(42).string(message.osMinorVersion);
        }
        if (message.kitVariant !== 0) {
            writer.uint32(48).int32(message.kitVariant);
        }
        if (message.kitVariantVersion !== "") {
            writer.uint32(58).string(message.kitVariantVersion);
        }
        if (message.kitClientTimestampMillis !== "0") {
            writer.uint32(64).uint64(message.kitClientTimestampMillis);
        }
        if (message.clientSequenceId !== "0") {
            writer.uint32(72).uint64(message.clientSequenceId);
        }
        if (message.maxClientSequenceIdOnInstance !== "0") {
            writer.uint32(80).uint64(message.maxClientSequenceIdOnInstance);
        }
        if (message.targetArchitecture !== "") {
            writer.uint32(90).string(message.targetArchitecture);
        }
        if (message.runningWithDebuggerAttached === true) {
            writer.uint32(96).bool(message.runningWithDebuggerAttached);
        }
        if (message.runningInTests === true) {
            writer.uint32(104).bool(message.runningInTests);
        }
        if (message.runningInSimulator === true) {
            writer.uint32(112).bool(message.runningInSimulator);
        }
        if (message.isAppPrerelease === true) {
            writer.uint32(120).bool(message.isAppPrerelease);
        }
        if (message.kitAppId !== "") {
            writer.uint32(130).string(message.kitAppId);
        }
        if (message.kitSessionId !== "") {
            writer.uint32(138).string(message.kitSessionId);
        }
        if (message.kitPluginType !== 0) {
            writer.uint32(144).int32(message.kitPluginType);
        }
        if (message.isFromReactNativePlugin === true) {
            writer.uint32(152).bool(message.isFromReactNativePlugin);
        }
        return writer;
    },
    fromPartial<I extends Exact<DeepPartial<KitEventBase>, I>>(object: I): KitEventBase {
        const message = createBaseKitEventBase();
        message.oauthClientId = object.oauthClientId ?? "";
        message.locale = object.locale ?? "";
        message.kitUserAgent = object.kitUserAgent ?? "";
        message.ipAddress = object.ipAddress ?? "";
        message.osMinorVersion = object.osMinorVersion ?? "";
        message.kitVariant = object.kitVariant ?? 0;
        message.kitVariantVersion = object.kitVariantVersion ?? "";
        message.kitClientTimestampMillis = object.kitClientTimestampMillis ?? "0";
        message.clientSequenceId = object.clientSequenceId ?? "0";
        message.maxClientSequenceIdOnInstance = object.maxClientSequenceIdOnInstance ?? "0";
        message.targetArchitecture = object.targetArchitecture ?? "";
        message.runningWithDebuggerAttached = object.runningWithDebuggerAttached ?? false;
        message.runningInTests = object.runningInTests ?? false;
        message.runningInSimulator = object.runningInSimulator ?? false;
        message.isAppPrerelease = object.isAppPrerelease ?? false;
        message.kitAppId = object.kitAppId ?? "";
        message.kitSessionId = object.kitSessionId ?? "";
        message.kitPluginType = object.kitPluginType ?? 0;
        message.isFromReactNativePlugin = object.isFromReactNativePlugin ?? false;
        return message;
    },
};

function createBaseKitEventBase(): KitEventBase {
    return {
        oauthClientId: "",
        locale: "",
        kitUserAgent: "",
        ipAddress: "",
        osMinorVersion: "",
        kitVariant: 0,
        kitVariantVersion: "",
        kitClientTimestampMillis: "0",
        clientSequenceId: "0",
        maxClientSequenceIdOnInstance: "0",
        targetArchitecture: "",
        runningWithDebuggerAttached: false,
        runningInTests: false,
        runningInSimulator: false,
        isAppPrerelease: false,
        kitAppId: "",
        kitSessionId: "",
        kitPluginType: 0,
        isFromReactNativePlugin: false,
    };
}

export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;

export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;

type KeysOfUnion<T> = T extends T ? keyof T : never;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export interface CameraKitLegalPrompt {
    cameraKitEventBase: CameraKitEventBase | undefined;
    legalPromptId: string;
    legalPromptResult: CameraKitLegalPromptResult;
}

export enum CameraKitLegalPromptResult {
    CAMERA_KIT_LEGAL_PROMPT_MISSING = 0,
    CAMERA_KIT_LEGAL_PROMPT_ACCEPTED = 1,
    CAMERA_KIT_LEGAL_PROMPT_DISMISSED = 2,
    UNRECOGNIZED = -1
}

export interface CameraKitEventBase {
    kitEventBase: KitEventBase | undefined;
    deviceCluster: string;
    cameraKitVersion: string;
    lensCoreVersion: string;
    deviceModel: string;
    cameraKitVariant: CameraKitVariant;
    cameraKitFlavor: CameraKitFlavor;
    appId: string;
    deviceConnectivity: CameraKitConnectivityType;
    sessionId: string;
    appVendorUuid: string;
    rankingRequestId: string;
    cameraKitEnvironment: CameraKitEnvironment;
    partnerUuid: string;
}

export enum CameraKitEnvironment {
    CAMERA_KIT_ENVIRONMENT_UNKNOWN = 0,
    CAMERA_KIT_ENVIRONMENT_STAGING = 1,
    CAMERA_KIT_ENVIRONMENT_PRODUCTION = 2,
    UNRECOGNIZED = -1
}

export enum CameraKitConnectivityType {
    CAMERA_KIT_CONNECTIVITY_TYPE_UNKNOWN = 0,
    CAMERA_KIT_CONNECTIVITY_TYPE_WIFI = 1,
    CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE = 2,
    CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE = 3,
    CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH = 4,
    UNRECOGNIZED = -1
}

export enum CameraKitFlavor {
    CAMERA_KIT_FLAVOR_UNKNOWN = 0,
    CAMERA_KIT_FLAVOR_DEBUG = 1,
    CAMERA_KIT_FLAVOR_RELEASE = 2,
    UNRECOGNIZED = -1
}

export enum CameraKitVariant {
    CAMERA_KIT_VARIANT_UNKNOWN = 0,
    CAMERA_KIT_VARIANT_PARTNER = 1,
    CAMERA_KIT_VARIANT_PUBLIC = 2,
    UNRECOGNIZED = -1
}

export interface KitEventBase {
    oauthClientId: string;
    locale: string;
    kitUserAgent: string;
    ipAddress: string;
    osMinorVersion: string;
    kitVariant: KitType;
    kitVariantVersion: string;
    kitClientTimestampMillis: string;
    clientSequenceId: string;
    maxClientSequenceIdOnInstance: string;
    targetArchitecture: string;
    runningWithDebuggerAttached: boolean;
    runningInTests: boolean;
    runningInSimulator: boolean;
    isAppPrerelease: boolean;
    kitAppId: string;
    kitSessionId: string;
    kitPluginType: KitPluginType;
    isFromReactNativePlugin: boolean;
}

export enum KitPluginType {
    NO_PLUGIN = 0,
    UNITY = 1,
    UNRECOGNIZED = -1
}

export enum KitType {
    UNKNOWN_KIT_TYPE = 0,
    BITMOJI_KIT = 1,
    CREATIVE_KIT = 2,
    LOGIN_KIT = 3,
    STORY_KIT = 4,
    CAMERA_KIT = 5,
    SHOP_KIT = 6,
    EULA_KIT = 7,
    PAYMENTS_KIT = 8,
    INVITE_KIT = 9,
    CAMERA_KIT_WEB = 10,
    UNRECOGNIZED = -1
}
