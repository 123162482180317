import { forActions } from "@snap/state-management";
import { filter } from "rxjs";
import { Injectable } from "../../dependency-injection/Injectable";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import type { LensState } from "../../session/lensState";
import { lensStateFactory } from "../../session/lensState";
import type { MakeTaggedBusinessEvent } from "../businessEventsReporter";
import type { MetricsEventTarget } from "../metricsEventTarget";
import { metricsEventTargetFactory } from "../metricsEventTarget";

/**
 * The LensContentValidationFailed metric reports every time we handle an lens checksum validation failure.
 */
export type LensContentValidationFailed = MakeTaggedBusinessEvent<"lensContentValidationFailed">;

/**
 * @internal
 */
export const reportLensValidationFailed = Injectable(
    "reportLensValidationFailed",
    [lensStateFactory.token, metricsEventTargetFactory.token] as const,
    (lensState: LensState, metricsEventTarget: MetricsEventTarget) => {
        lensState.events
            .pipe(
                forActions("applyLensFailed"),
                filter(([a]) => a.data.error.name === "LensContentValidationError")
            )
            .subscribe({
                next: ([{ data }]) => {
                    const { lens } = data;
                    const lensContentValidationFailed: LensContentValidationFailed = {
                        name: "lensContentValidationFailed",
                        lensId: lens.id,
                    };
                    metricsEventTarget.dispatchEvent(
                        new TypedCustomEvent("lensContentValidationFailed", lensContentValidationFailed)
                    );
                },
            });
    }
);
