import type { TupleOf } from "../common/types";

/**
 * Matrix as a 9-value array in column-major order,
 * where elements are stored sequentially by columns.
 */
export type Matrix = TupleOf<number, 9>;

/**
 * Use this class to supply the CameraKitSession::setSourceTransform with the proper data
 * needed to apply transformations.
 *
 * @category Rendering
 */
export class Transform2D {
    static readonly MirrorX = new Transform2D([-1.0, 0.0, 0.0, 0.0, 1.0, 0.0, 1.0, 0.0, 1.0]);
    static readonly MirrorY = new Transform2D([1.0, 0.0, 0.0, 0.0, -1.0, 0.0, 0.0, 1.0, 1.0]);
    static readonly Identity = new Transform2D([1.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0]);

    /**
     * @param matrix A 3x3 matrix in column-major order, representing a 2D transform to be applied to the source media
     */
    constructor(readonly matrix: Matrix) {}
}

/**
 * @internal
 */
export function isTransform2D(value: unknown): value is Transform2D {
    return value instanceof Transform2D;
}
