import { memoize } from "../common/memoize";
import { getConfigurationOverrides } from "../configurationOverrides";
import { getPlatformInfo } from "./platformInfo";

/** @internal */
export const getCameraKitUserAgent = memoize(function getCameraKitUserAgent(): string {
    const { browser, deviceModel, origin, osName, osVersion, sdkShortVersion, lensCore } = getPlatformInfo();

    // Set this to `DEBUG` manually while testing / root-causing.
    const { userAgentFlavor } = getConfigurationOverrides() ?? { userAgentFlavor: "release" };
    const flavor: "" | "DEBUG " = userAgentFlavor === "release" ? "" : "DEBUG ";

    // This full string is defined here:
    // eslint-disable-next-line max-len
    // https://github.sc-corp.net/Snapchat/useragent/blob/9333afe7cc6ac00503ad46cb234bcf94006dff98/java/useragent/src/main/java/snapchat/client/UserAgent.java#L124
    return (
        `CameraKitWeb/${sdkShortVersion} ${flavor}(${deviceModel}; ${osName} ${osVersion}) ` +
        `${browser.brand}/${browser.version} Core/${lensCore.version} ` +
        // We overload appId, using the origin instead of the true appId parsed from the apiToken -- we do this because
        // origin is human-readable, and this is used to populate the appId dimension in operational metrics.
        `AppId/${origin}`
    );
});
