import type { ChainableHandler } from "./HandlerChainBuilder";

/**
 * Modify a Fetch Request's headers.
 *
 * @param modifyHeaders
 * @returns {@link ChainableHandler}, suitable for use in {@link HandlerChainBuilder.map}
 */
export const createHeadersModifyingFetchHandler =
    <Res>(
        modifyHeaders: (headers: Headers) => Headers
    ): ChainableHandler<RequestInfo, Res, RequestInfo, Res, RequestInit | undefined> =>
    (next) =>
    (input, init) => {
        const headers =
            init && init.headers
                ? new Headers(init.headers)
                : typeof input === "string"
                ? new Headers()
                : input.headers;

        const modifiedHeaders = modifyHeaders(headers);

        // When `init` contains headers, `fetch` uses these *instead* of any headers found in the `input` Request.
        return next(input, { ...init, headers: modifiedHeaders });
    };
