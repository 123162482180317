import type { EventOfType } from "./TypedCustomEvent";
import { TypedCustomEvent } from "./TypedCustomEvent";
import type { EventsFromTarget } from "./TypedEventTarget";
import { TypedEventTarget } from "./TypedEventTarget";

/**
 * Each time an event is emitted, call a given accumulator function with two arguments: some state of type S and the
 * event. The accumulator returns a new state. `scan` returns a new event emitter which emits an event each time a new
 * state is produced by the accumulator.
 *
 * This can be used to implement a Redux-style state management architecture.
 *
 * @param seedState Some initial state, passed to the accumulator when the first event is emitted.
 * @returns A function which takes a source {@link TypedEventTarget}, a list of event types emitted by that target to
 * which to listen, and the accumulator function. The accumulator is called each time an event of the given type(s) is
 * emitted on the source. It is passed the current state and the event, and must return a new state.
 */
export const scan =
    <S>(seedState: S) =>
    <Target extends TypedEventTarget, Events extends EventsFromTarget<Target>, EventType extends Events["type"]>(
        source: Target,
        eventTypes: EventType[],
        accumulator: (state: S, event: EventOfType<EventType, Events>) => S
    ): TypedEventTarget<TypedCustomEvent<"state", S>> => {
        let state = seedState;
        const sink = new TypedEventTarget<TypedCustomEvent<"state", S>>();
        const listener = (event: TypedCustomEvent) => {
            state = accumulator(state, event as EventOfType<EventType, Events>);
            sink.dispatchEvent(new TypedCustomEvent("state", state));
        };

        // We'll use Proxies to make sure that event listeners are added/removed at the appropriate time.
        // Callers can then control when to clean up the listeners we add here in a transparent way –
        // by just removing the listener on the returned event target.
        //
        // We also prevent multiple listeners on the sink, as a simplification.
        let hasListener = false;
        sink.addEventListener = new Proxy(sink.addEventListener, {
            apply: (target, thisArg, args: Parameters<(typeof sink)["addEventListener"]>) => {
                if (hasListener)
                    throw new Error(
                        "Cannot add another event listener. The TypedEventTarget returned by scan only " +
                            "supports a single listener, and one has already been added."
                    );
                hasListener = true;
                eventTypes.forEach((eventType) => source.addEventListener(eventType, listener));
                target.apply(thisArg, args);
            },
        });
        sink.removeEventListener = new Proxy(sink.removeEventListener, {
            apply: (target, thisArg, args: Parameters<(typeof sink)["removeEventListener"]>) => {
                eventTypes.forEach((eventType) => source.removeEventListener(eventType, listener));
                target.apply(thisArg, args);
            },
        });

        return sink;
    };
