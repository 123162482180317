/* eslint-disable @typescript-eslint/member-ordering */

import { copyDefinedProperties } from "../common/copyDefinedProperties";
import { isRecord, isUndefined, isValidNumber, isValidNumberOrUndefined } from "../common/typeguards";
import { validate } from "../common/validate";
import type { LensCore } from "../lens-core-module/lensCore";
import type { Transform2D } from "../transforms/Transform2D";
import { isTransform2D } from "../transforms/Transform2D";

export const defaultDeviceInfo: CameraKitDeviceOptions = {
    cameraType: "user",
    fpsLimit: Number.POSITIVE_INFINITY,
};

const createNotAttachedError = (message: string) =>
    new Error(`${message}. This CameraKitSource is not attached to a CameraKitSession.`);

/**
 * When creating a {@link CameraKitSource}, passing a CameraKitSourceSubscriber allows logic to implemented which will
 * run whenever that source is attached/detached from a CameraKitSession.
 *
 * @category Rendering
 */
export interface CameraKitSourceSubscriber {
    readonly onAttach?: (
        source: CameraKitSource,
        lensCore: LensCore,
        reportError: (error: Error) => void
    ) => void | Promise<void>;
    readonly onDetach?: (reportError: (error: Error) => void) => void | Promise<void>;
}

// Helper set to ensure that changes to cameraType will ensure changes to the guard.
type CameraType = CameraKitDeviceOptions["cameraType"];
const cameraTypeValueSet = new Set(
    Object.keys({
        user: 0,
        environment: 0,
    } satisfies Record<CameraType, 0>) as CameraType[]
);

export function isCameraKitSource(value: unknown): value is CameraKitSource {
    return value instanceof CameraKitSource;
}

export function isPartialCameraKitDeviceOptionsOrUndefined(
    value: unknown
): value is Partial<CameraKitDeviceOptions> | undefined {
    return isUndefined(value) || isPartialCameraKitDeviceOptions(value);
}

function isPartialCameraKitDeviceOptions(value: unknown): value is Partial<CameraKitDeviceOptions> {
    return isRecord(value) && isCameraTypeOrUndefined(value.cameraType) && isValidNumberOrUndefined(value.fpsLimit);
}

function isCameraType(value: unknown): value is CameraType {
    return cameraTypeValueSet.has(value as CameraType);
}

function isCameraTypeOrUndefined(value: unknown): value is CameraType | undefined {
    return isUndefined(value) || isCameraType(value);
}

/**
 * Device options.
 *
 * @category Rendering
 */
export interface CameraKitDeviceOptions {
    /**
     * Specifies the camera type for which certain features are enabled or disabled.
     * For example, surface tracking features are only active when the camera is set to "environment" mode.
     * The default value is "user", which accommodates the majority of Lenses.
     * "user" refers to the front-facing camera, while "environment" refers to the rear-facing camera.
     */
    cameraType: "user" | "environment";

    /**
     * Limits the frames per second (FPS) to optimize performance by reducing compute resources
     * when high FPS is not critical. By default, no limit is set, allowing usage of the video's native FPS.
     * Useful for controlling resource usage on varying device capabilities.
     */
    fpsLimit: number;
}

/** @category Rendering */
export interface CameraKitSourceInfo {
    /**
     * HTML element or MediaStream as an input source.
     * If useManualFrameProcessing is true, then this parameter is optional.
     */
    media?: HTMLCanvasElement | HTMLImageElement | HTMLVideoElement | MediaStream;

    /**
     * Whether to provide frames manually.
     */
    useManualFrameProcessing?: boolean;

    /**
     * If specified, the passed-in tracking data buffer will be used as tracking data for the current
     * media element instead of live tracking data.
     */
    replayTrackingData?: {
        buffer: Uint8Array | ArrayBuffer;
    };
}

/**
 * This general-purpose class represents a source of media for a {@link CameraKitSession}.
 *
 * When an instance is passed to {@link CameraKitSession.setSource | CameraKitSession.setSource}, it will be "attached"
 * to the session. Later it may be "detached" from the session.
 *
 * Passing a {@link CameraKitSourceSubscriber} to the constructor allows callers to specify behavior
 * that will occur when the source is attached and detached. This can be used to e.g. update the render size.
 *
 * @category Rendering
 */
class CameraKitSource {
    private lensCore?: LensCore;
    private readonly deviceInfo: CameraKitDeviceOptions;

    constructor(
        private readonly sourceInfo: CameraKitSourceInfo,
        private readonly subscriber: CameraKitSourceSubscriber = {},
        deviceInfo: Partial<CameraKitDeviceOptions> = {}
    ) {
        this.deviceInfo = { ...defaultDeviceInfo, ...copyDefinedProperties(deviceInfo) };
    }

    /**
     * Called by {@link CameraKitSession} when this source is set as that session's source.
     *
     * @param lensCore
     * @param reportError Calling this function will report an error back to the session.
     * @returns Rejects if any calls to LensCore or CameraKitSource.subscriber.onAttach fail.
     * @internal
     */
    async attach(lensCore: LensCore, reportError: (error: Error) => void): Promise<void> {
        if (this.lensCore) {
            throw new Error(
                "Cannot attach. This CameraKitCustomSource has already been attached to " +
                    "a CameraKitSession. To re-attach, create a copy of this CameraKitCustomSource."
            );
        }

        this.lensCore = lensCore;

        await lensCore.useMediaElement({
            autoplayNewMedia: false,
            autoplayPreviewCanvas: false,
            media: this.sourceInfo.media,
            pauseExistingMedia: false,
            replayTrackingData: this.sourceInfo.replayTrackingData,
            requestWebcam: false,
            startOnFrontCamera: ["user", "front"].includes(this.deviceInfo.cameraType),
            useManualFrameProcessing: this.sourceInfo.useManualFrameProcessing,
        });

        // LensCore uses 0 to remove the limit.
        const fps = this.deviceInfo.fpsLimit < Number.POSITIVE_INFINITY ? this.deviceInfo.fpsLimit : 0;
        await lensCore.setFPSLimit({ fps });
        await lensCore.setRenderSize({ mode: "matchInputResolution" });

        if (this.subscriber.onAttach) await this.subscriber.onAttach(this, lensCore, reportError);
    }

    /**
     * Make a copy of the source, sharing the same {@link CameraKitSourceSubscriber}.
     *
     * @param deviceInfo Optionally provide new device info for the copy (e.g. to change the camera type).
     * @returns The new {@link CameraKitSource}
     */
    copy(deviceInfo: Partial<CameraKitDeviceOptions> = {}): CameraKitSource {
        return new CameraKitSource(this.sourceInfo, this.subscriber, { ...this.deviceInfo, ...deviceInfo });
    }

    /**
     * Called by {@link CameraKitSession} when it must remove this source.
     *
     * @param reportError Calling this function will report an error back to the session.
     * @returns
     * @internal
     */
    detach(reportError: (error: Error) => void): void | Promise<void> {
        if (!this.lensCore) return Promise.reject(createNotAttachedError("Cannot detach"));
        if (this.subscriber.onDetach) return this.subscriber.onDetach(reportError);
    }

    /**
     * Set the resolution used to render this source.
     *
     * If greater performance is required, a smaller render size may boost frame-rate. It does come at a cost, including
     * loss of accuracy in various tracking and computer-vision algorithms (since they'll be operating on fewer pixels).
     *
     * By default (i.e. if this method is never called), then the render size will match the size of the input media.
     * Best performance can be achieved by varying the size of the input media and allowing CameraKit to render at a
     * resolution that matches the input media -- this method should only be used if the input media resolution cannot
     * be changed to the desired size.
     *
     * It’s important to distinguish render size from display size. The size at which the output canvases are displayed
     * on a web page is determined by the CSS of the page. It is distinct from the size at which CameraKit renders
     * Lenses. Performance is dominated by render size, while any display scaling (using CSS) can most often be thought
     * of as free.
     *
     * The size of the Live and Capture {@link RenderTarget} is always the same.
     *
     * @todo Currently it's only valid to call `setRenderSize` after `CameraKitSession.play` has been called. This
     * constraint should be removed, so callers don't have to understand the underlying LensCore state machine.
     *
     * @param width pixels
     * @param height pixels
     * @returns Promise resolves when the render size has been successfully updated.
     */
    @validate(isValidNumber, isValidNumber)
    setRenderSize(width: number, height: number): Promise<void> {
        if (!this.lensCore) return Promise.reject(createNotAttachedError("Cannot setRenderSize"));
        const target = { width, height };
        return this.lensCore.setRenderSize({ mode: "explicit", target });
    }

    /**
     * Apply a 2D transformation to the source (e.g. translation, rotation, scale).
     *
     * @param transform Specifies the 3x3 matrix describing the transformation.
     */
    @validate(isTransform2D)
    setTransform(transform: Transform2D): Promise<void> {
        if (!this.lensCore) return Promise.reject(createNotAttachedError("Cannot setTransform"));
        const matrix = new Float32Array(transform.matrix);
        return this.lensCore.setInputTransform({ matrix });
    }
}

// NOTE: jest doesn't like default exports of classes with decorated methods.
export { CameraKitSource };
