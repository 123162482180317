import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
export const protobufPackage = "com.snap.camerakit.v3";
export interface LegalPrompt {
    documents: LegalDocument[];
    disabled: boolean;
}
export interface LegalDocument {
    type: LegalDocument_Type;
    webUrl: string;
    version: string;
    timestamp: Date | undefined;
}
export enum LegalDocument_Type {
    UNSET = 0,
    TERMS_OF_SERVICE = 1,
    PRIVACY_POLICY = 2,
    LEARN_MORE = 3,
    UNRECOGNIZED = -1
}
function createBaseLegalPrompt(): LegalPrompt {
    return { documents: [], disabled: false };
}
export const LegalPrompt = {
    encode(message: LegalPrompt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.documents) {
            LegalDocument.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        if (message.disabled === true) {
            writer.uint32(16).bool(message.disabled);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): LegalPrompt {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLegalPrompt();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.documents.push(LegalDocument.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.disabled = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<LegalPrompt>, I>>(object: I): LegalPrompt {
        const message = createBaseLegalPrompt();
        message.documents = object.documents?.map((e) => LegalDocument.fromPartial(e)) || [];
        message.disabled = object.disabled ?? false;
        return message;
    },
};
function createBaseLegalDocument(): LegalDocument {
    return { type: 0, webUrl: "", version: "", timestamp: undefined };
}
export const LegalDocument = {
    encode(message: LegalDocument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.webUrl !== "") {
            writer.uint32(18).string(message.webUrl);
        }
        if (message.version !== "") {
            writer.uint32(26).string(message.version);
        }
        if (message.timestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): LegalDocument {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLegalDocument();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32() as any;
                    break;
                case 2:
                    message.webUrl = reader.string();
                    break;
                case 3:
                    message.version = reader.string();
                    break;
                case 4:
                    message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<LegalDocument>, I>>(object: I): LegalDocument {
        const message = createBaseLegalDocument();
        message.type = object.type ?? 0;
        message.webUrl = object.webUrl ?? "";
        message.version = object.version ?? "";
        message.timestamp = object.timestamp ?? undefined;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function toTimestamp(date: Date): Timestamp {
    const seconds = Math.trunc(date.getTime() / 1000).toString();
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t: Timestamp): Date {
    let millis = Number(t.seconds) * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
