import type { EventOfType, TypedCustomEvent } from "./TypedCustomEvent";

export type TypedEventListener<Event extends TypedCustomEvent> = (evt: Event) => void;

export interface TypedEventListenerOptions {
    once?: boolean;
}

/**
 * Extract the generic Events type (which must extend {@link TypedCustomEvent}) from a {@link TypedEventTarget}
 */
export type EventsFromTarget<Target extends TypedEventTarget> = Target extends TypedEventTarget<infer Events>
    ? Events
    : never;

/**
 * This wrapper around EventTarget provides more descriptive type information. By using this class, calls to EventTarget
 * methods are correctly type checked to ensure only allowed event types are used, and that events and their type
 * strings are correctly associated.
 *
 * For example, when calling TypedEventTarget::addEventListener, the event passed to the callback will have the correct
 * type corresponding to the type of event for which the listener has been added.
 */
export class TypedEventTarget<Events extends TypedCustomEvent = TypedCustomEvent> {
    private readonly listeners: Map<string, TypedEventListener<TypedCustomEvent>[]>;
    private readonly options: Map<TypedEventListener<TypedCustomEvent>, TypedEventListenerOptions>;

    constructor() {
        this.listeners = new Map();
        this.options = new Map();
    }

    addEventListener<K extends Events["type"]>(
        type: K,
        callback: TypedEventListener<EventOfType<K, Events>>,
        options?: TypedEventListenerOptions
    ): void {
        // Safety: the type in the method signature ensures the callback handles events of type K, and we use that type
        // as the key when storing the callback – we only ever invoke callbacks obtained by mapping from that event
        // type to the callback, so even though we store the callback with a wider type, we only ever call it with the
        // specific event type specified by K.
        const listener = callback as TypedEventListener<TypedCustomEvent>;
        const listeners = this.listeners.get(type) ?? [];
        this.listeners.set(type, [...listeners, listener]);
        if (options) this.options.set(listener, options);
    }

    dispatchEvent(event: Events): true {
        const listeners = this.listeners.get(event.type);
        if (!listeners) return true;

        listeners.forEach((listener) => {
            const options = this.options.get(listener) ?? {};
            try {
                listener(event);
            } catch (error) {
                // We'll do our best to immitate native behavior, where if a listener throws an error it is caught and
                // emitted as an error event on the window – this might be slightly different from native behavior since
                // we have to use a CustomEvent, but it's as close as we can get.
                if (window) window.dispatchEvent(new CustomEvent("error", { detail: error }));
            }
            if (options.once) this.removeEventListener(event.type, listener);
        });

        return true;
    }

    removeEventListener<K extends Events["type"]>(type: K, callback: TypedEventListener<EventOfType<K, Events>>): void {
        const listener = callback as TypedEventListener<TypedCustomEvent>;
        const listeners = this.listeners.get(type);
        if (!listeners) return;
        this.listeners.set(
            type,
            listeners.filter((l) => l !== listener)
        );
        this.options.delete(listener);
    }
}
