const JPEG_ID = 0xffd8;
const APP1_MARKER = 0xffe1;
const EXIF_ID = 0x45786966;
const LITTLE_ENDIAN = 0x4949;
const ORIENTATION_TAG_OFFSET = 0x0112;

// http://sylvana.net/jpegcrop/exif_orientation.html
export const enum Orientation {
    TopLeft = 1,
    TopRight = 2,
    BottomRight = 3,
    BottomLeft = 4,
    LeftTop = 5,
    RightTop = 6,
    RightBottom = 7,
    LeftBottom = 8,
}

/**
 * Parses JPEG EXIF metadata and returns Orientation tag of it, or undefined if not applicable.
 *
 * Adopted from https://stackoverflow.com/a/32490603
 *
 * @internal
 * @param data JPEG binary.
 * @returns Orientation tag value or undefined.
 */
export function extractJpegOrientationTag(data: ArrayBuffer): Orientation | undefined {
    const view = new DataView(data);

    if (view.getUint16(0, false) !== JPEG_ID) return undefined;

    const length = view.byteLength;
    let offset = 2;

    while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return undefined;
        let marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === APP1_MARKER) {
            if (view.getUint32((offset += 2), false) !== EXIF_ID) {
                return undefined;
            }

            let little = view.getUint16((offset += 6), false) === LITTLE_ENDIAN;
            offset += view.getUint32(offset + 4, little);
            let tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
                if (view.getUint16(offset + i * 12, little) === ORIENTATION_TAG_OFFSET) {
                    return view.getUint16(offset + i * 12 + 8, little) as Orientation;
                }
            }
        } else if ((marker & 0xff00) !== 0xff00) {
            break;
        } else {
            offset += view.getUint16(offset, false);
        }
    }
    return undefined;
}
