import { catchError, firstValueFrom, map, mergeMap } from "rxjs";
import { isRecord, isString } from "../../common/typeguards";
import { Injectable } from "../../dependency-injection/Injectable";
import { createArrayBufferParsingHandler } from "../../handlers/arrayBufferParsingHandler";
import type { FetchHandler } from "../../handlers/defaultFetchHandler";
import { defaultFetchHandlerFactory } from "../../handlers/defaultFetchHandler";
import { HandlerChainBuilder } from "../../handlers/HandlerChainBuilder";
import type { RemoteConfiguration } from "../../remote-configuration/remoteConfiguration";
import { remoteConfigurationFactory } from "../../remote-configuration/remoteConfiguration";
import { withRequestPriority } from "../../handlers/utils";
import type { AssetLoader, AssetResponse } from "./LensAssetRepository";

interface AssetConfig {
    url: string;
    checksum?: string;
}

const hasStringValue = (value: unknown): value is { stringValue: string } => {
    return isRecord(value) && isString(value.stringValue);
};

const isAssetConfig = (value: unknown): value is AssetConfig => {
    return isRecord(value) && isString(value.url) && (value.checksum === undefined || isString(value.checksum));
};

/**
 * @internal
 */
export const deviceDependentAssetLoaderFactory = Injectable(
    "deviceDependentAssetLoader",
    [defaultFetchHandlerFactory.token, remoteConfigurationFactory.token] as const,
    (fetchHandler: FetchHandler, remoteConfiguration: RemoteConfiguration): AssetLoader => {
        const assetHandler = new HandlerChainBuilder(fetchHandler).map(createArrayBufferParsingHandler()).handler;

        return async function deviceDependentAssetLoader({
            assetDescriptor: { assetId },
            lowPriority,
        }): Promise<AssetResponse> {
            const loadingFailed = (reason: string, cause?: unknown) =>
                new Error(`Cannot load device-dependent asset ${assetId}. ${reason}`, { cause });

            return firstValueFrom(
                remoteConfiguration.get(assetId).pipe(
                    catchError((error) => {
                        throw loadingFailed("COF config failed to load.", error);
                    }),
                    map((configs) => {
                        if (configs.length === 0) {
                            throw loadingFailed(`No COF config found corresponding to that assetId.`);
                        }
                        // All of the deviceDependent asset configs will only have one value, so we can safely use the
                        // first (i.e. only) element in the configs list.
                        const [{ value }] = configs;

                        // Asset configurations are all JSON-encoded in the `stringValue` property. If it doesn't
                        // exists, we can't fetch the asset.
                        if (!hasStringValue(value)) throw loadingFailed("COF config malformed (missing stringValue)");

                        let assetConfig: unknown;
                        try {
                            assetConfig = JSON.parse(value.stringValue);
                        } catch (parseError) {
                            throw loadingFailed("COF config malformed (JSON parse error)", parseError);
                        }

                        // Asset configurations have a `url` and `checksum` property. Otherwise we're dealing with some
                        // other kind of configuration, and cannot fetch the asset.
                        if (!isAssetConfig(assetConfig)) throw loadingFailed("COF config malformed (missing URL)");

                        return assetConfig;
                    }),
                    mergeMap(async ({ url, checksum }) => {
                        // TODO: remove force-cache once https://jira.sc-corp.net/browse/CAMKIT-3671 is addressed
                        const [data, response] = await assetHandler(
                            url,
                            withRequestPriority({ cache: "force-cache" }, lowPriority)
                        );
                        if (!response.ok) throw response;
                        return { data, checksum };
                    })
                )
            );
        };
    }
);
