import type { PageVisibility } from "../common/pageVisibility";
import type { ChainableHandler, Handler, RequestMetadata } from "./HandlerChainBuilder";
import { createMappingHandler } from "./mappingHandler";

export interface BatchingHandlerOptions<Req, BatchReq> {
    batchReduce: (batch: BatchReq | undefined, req: Req) => BatchReq | Promise<BatchReq>;
    isBatchComplete: (batch: BatchReq) => boolean;
    maxBatchAge?: number;
    /**
     * Determines whether to flush buffered requests when the page becomes hidden.
     * `false` value indicates that page visibility handling is avoided, while
     * a {@link PageVisibility} instance is used to subscribe to page visibility change events.
     */
    pageVisibility: PageVisibility | false;
}

/**
 * Accumulate requests into batches, which are then sent to the next handler in the chain. Batches are sent when either:
 * - the given `isBatchComplete` function returns true, closing the current batch and sending it down the chain.
 * - an optional `maxBatchAge` time has elapsed since the first request in the batch was received.
 * - the page terminates.
 *
 * When handling a request, the Promise returned will resolve when that request has been successfully added to the
 * current batch – **NOT** when that batch has been successfully processed by the rest of the handler chain.
 *
 * The `next` handler in the chain will receive the batch and should handle any errors arising from further processing
 * on the batch (e.g. sending it to a server).
 *
 * **Note:** This handler does not support aborting handled requests via AbortSignal.
 *
 * @param options
 * @returns {@link ChainableHandler}, suitable for use in {@link HandlerChainBuilder.map}
 */
export const createBatchingHandler = <Req, BatchReq, BatchRes, Meta extends RequestMetadata>({
    batchReduce,
    isBatchComplete,
    maxBatchAge,
    pageVisibility,
}: BatchingHandlerOptions<Req, BatchReq>): ChainableHandler<Req, void, BatchReq, BatchRes, Meta> => {
    // TODO: this should just be `number`, but we're picking up NodeJS types (@types/node) when building, so setTimeout
    // gets a different return type than what it should have in the browser. We should build without NodeJS types, but
    // that will require some fixes across the codebase.
    let batchTimeout: ReturnType<typeof setTimeout>;
    let currentBatch: BatchReq | undefined = undefined;
    let clearOnHidden = () => {};

    const reducingHandler = createMappingHandler<Req, BatchReq, void, Meta>(
        async (request) => {
            currentBatch = await batchReduce(currentBatch, request);
            return currentBatch;
        },
        pageVisibility,
        1
    );

    const batchAndSend = (next: Handler<BatchReq, BatchRes, Meta>, request?: Req, metadata?: Meta) => {
        const batch = request ? batchReduce(currentBatch, request) : currentBatch;
        if (!batch) return;

        // `next` should handle its own errors – that is, the batchingHandler is meant to be placed in a handler chain
        // prior to any error logging, retrying, etc. handlers.
        const complete =
            batch instanceof Promise
                ? batch.then((b) => next(b, metadata)).catch(() => {})
                : next(batch, metadata).catch(() => {});

        currentBatch = undefined;
        clearTimeout(batchTimeout);
        clearOnHidden();

        return complete;
    };

    return (next) => async (request, metadata) => {
        // Requests may be made while the page is transitioning to hidden – for example, the page is being unloaded and
        // we're reporting final metrics. In this case, we need to skip batching and synchronously call `next` so that
        // the request is not lost.
        if (pageVisibility && pageVisibility.isDuringVisibilityTransition("hidden")) {
            await batchAndSend(next, request, metadata);
            return;
        }

        // If this is the first request in a batch, we need to set up some callbacks to flush the batch when certain
        // events occur:
        //
        // - maxBatchAge time passes.
        // - page visibility transitions to hidden (which could indicate the page is being unloaded).
        //
        if (currentBatch === undefined) {
            const sendBatch = () => batchAndSend(next, undefined, metadata);
            if (maxBatchAge !== undefined) batchTimeout = setTimeout(sendBatch, maxBatchAge);
            if (pageVisibility) clearOnHidden = pageVisibility.onPageHidden(sendBatch);
        }

        const handle = reducingHandler(async () => {
            if (!currentBatch) return;
            if (!isBatchComplete(currentBatch)) return;
            await batchAndSend(next, undefined, metadata);
        });

        return handle(request, metadata);
    };
};
