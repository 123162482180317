export const DEFAULT_TIMEZONE = "America/Los_Angeles";

export const dayFormatter = new Intl.DateTimeFormat("en-US", {
    timeZone: DEFAULT_TIMEZONE,
    year: "numeric",
    month: "numeric",
    day: "numeric",
});

export const monthFormatter = new Intl.DateTimeFormat("en-US", {
    timeZone: DEFAULT_TIMEZONE,
    year: "numeric",
    month: "numeric",
});
