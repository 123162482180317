/**
 * Returns a stack trace for a given error, and also appends the stack trace of any nested error, if one exists.
 * @param error Error to stringify.
 * @returns Error stack trace.
 */
export function stringifyError(error: Error): string {
    const outer = error.stack ?? "";
    return error.cause ? `${outer}\nCaused by:\n\t${stringifyError(ensureError(error.cause))}` : outer;
}

/**
 * Returns an error message for a given error, and also appends the error message of any nested error,
 * if one exists. It DOES NOT append error stack trace.
 * @param error Error to stringify.
 * @returns Error message including nested error messages.
 */
export function stringifyErrorMessage(error: Error): string {
    const cause = error.cause ? `; Caused by ${stringifyErrorMessage(ensureError(error.cause))}` : "";
    return `${error.name}: ${error.message}${cause}`;
}

/**
 * If given a value of type Error, return it – otherwise wrap the value in an Error.
 */
export function ensureError(error: unknown): Error {
    if (error instanceof Error) return error;

    try {
        return new Error(`Non-Error type exception thrown. Serialized error value: ${JSON.stringify(error)}`);
    } catch (_) {
        return new Error("Non-Error type exception thrown. Original error value could not be serialized.");
    }
}
