import { Injectable } from "../dependency-injection/Injectable";
import { resetLogger } from "./logger";

/**
 * The factory creates new logEntries subject.
 *
 * NOTE: The factory calls `resetLogger()`, which overrides the global `logEntrySubject`.
 * This is currently necessary to avoid sharing the same subject between multiple `CameraKit` instances.
 * In the future, we may throw an error when bootstrapping more than a single `CameraKit`,
 * that will allow us to have a single log entry subject.
 *
 * @internal
 */
export const logEntriesFactory = Injectable("logEntries", () => resetLogger().asObservable());
