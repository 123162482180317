import { copyDefinedProperties } from "../common/copyDefinedProperties";
import { getLogger } from "../logger/logger";
import type { ChainableHandler, Handler, RequestMetadata } from "./HandlerChainBuilder";

const logger = getLogger("retryingHandler");

const logRetry = (responseOrError: any, sleep: number) => {
    logger.warn(
        `Retrying handler got failed response:`,
        responseOrError,
        `Waited ${sleep} millis, attempting retry now.`
    );
};

const sleep = (millis: number) => new Promise((resolve) => setTimeout(resolve, millis));

const randomInRange = (min: number, max: number) => Math.round(Math.random() * (max - min) + min);

const defaultOptions: RetryingHandlerOptions<unknown> = {
    backoffMultiple: 3,
    baseSleep: 500,
    maxSleep: 5 * 1000,
    maxRetries: 10,

    // The Response type is very common, so our default predicate special-cases to retry all failed Responses.
    retryPredicate: (responseOrError: unknown) => (responseOrError instanceof Response ? !responseOrError.ok : true),
};

/**
 * Returns a cloned instance of Request if the input is of that type, otherwise returns the input unchanged.
 *
 * This is necessary when attempting to retry a request.
 * It is not possible to reuse the same Request instance that has already been sent.
 */
export function ensureClonedRequest<T>(input: T): T {
    return input instanceof Request ? (input.clone() as T) : input;
}

export interface RetryingHandlerOptions<Req> {
    /**
     * Multiple used to increase the random backoff between attempts. Default is 3, usually doesn't need to be changed.
     */
    backoffMultiple: number;

    /**
     * The minimum number of milliseconds to sleep between attempts.
     *
     * The actual number of milliseconds slept between attempts is chosen at random.
     */
    baseSleep: number;

    /**
     * The maximum number of milliseconds to sleep between attempts. Note that this is not a timeout -- if multiple
     * request attempts are made, the total request latency will be longer than this.
     *
     * The actual number of milliseconds slept between attempts is chosen at random.
     */
    maxSleep: number;

    /**
     * The maximum number of retry attempts. The initial request is not counted against this number.
     */
    maxRetries: number;

    /**
     * Determine if a given error is retryable. If `false` is returned, the error will be passed up to the Handler's
     * caller and no additional retry attempts will be made.
     */
    retryPredicate: (responseOrError: Req | Error, retryCount: number) => boolean;
}

/**
 * Retry requests using an exponential backoff with jitter strategy.
 *
 * More about this approach to retries can be found
 * [here](https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/). This implementation uses the
 * "Decorrelated jitter" algorithm described in that post. This offers a good tradeoff between call volume and latency,
 * and also allows for convenient configurability.
 *
 * @param options
 * @returns {@link ChainableHandler}, suitable for use in {@link HandlerChainBuilder.map}
 */
export const createRetryingHandler = <Req, Res, Meta extends RequestMetadata>(
    options: Partial<RetryingHandlerOptions<Res>> = {}
): ChainableHandler<Req, Res, Req, Res, Meta> => {
    const definedOptions = copyDefinedProperties(options);
    const { backoffMultiple, baseSleep, maxSleep, maxRetries, retryPredicate } = {
        ...defaultOptions,
        ...definedOptions,
    };
    let retryCount = -1;

    const jitterSleep = async (priorSleep: number) => {
        const nextSleep = Math.min(maxSleep, randomInRange(baseSleep, priorSleep * backoffMultiple));
        await sleep(nextSleep);
        return nextSleep;
    };

    const makeRequestAttempt =
        (next: Handler<Req, Res, Meta>, priorSleep = baseSleep) =>
        async (req: Req, metadata?: Meta): Promise<Res> => {
            retryCount++;

            try {
                const response = await next(ensureClonedRequest(req), metadata);
                if (retryCount < maxRetries && retryPredicate(response, retryCount)) {
                    const nextSleep = await jitterSleep(priorSleep);
                    // The request may have been aborted while we were sleeping. In that case, we'll resolve
                    // with the failed response. In many cases this will be ignored, because an AbortError has already
                    // been returned to the caller of the Handler chain – but this prevents us from doing
                    // any extra work, and there may be edge cases where the caller could find the response useful.
                    if (metadata?.signal?.aborted) return response;
                    logRetry(response, nextSleep);
                    return makeRequestAttempt(next, nextSleep)(req, metadata);
                }
                return response;
            } catch (error) {
                if (!(error instanceof Error)) {
                    throw new Error(
                        "Invalid type caught by retrying handler. Handlers may only throw Errors. Got " +
                            `${JSON.stringify(error)}`
                    );
                }

                // If the request fails because it was aborted, we assume this was done intentionally and we can stop.
                if (error.name === "AbortError") throw error;

                if (retryCount < maxRetries && retryPredicate(error, retryCount)) {
                    const nextSleep = await jitterSleep(priorSleep);
                    if (metadata?.signal?.aborted) throw error;
                    logRetry(error, nextSleep);
                    return makeRequestAttempt(next, nextSleep)(req, metadata);
                }

                // If no retry is to be attempted, return the error to the caller.
                throw error;
            }
        };

    return (next) => makeRequestAttempt(next);
};
