/* eslint-disable @typescript-eslint/member-ordering */
import type { Observable, Subscription } from "rxjs";
import { filter, firstValueFrom, map, of, takeUntil, tap } from "rxjs";
import { dispatch, forActions, inStates, isAction, isState } from "@snap/state-management";
import type { Lens } from "../lens/Lens";
import { isLens } from "../lens/Lens";
import { Injectable } from "../dependency-injection/Injectable";
import { lensCoreFactory } from "../lens-core-module/loader/lensCoreFactory";
import type { LensLaunchData } from "../lens/LensLaunchData";
import { isLensLaunchDataOrUndefined } from "../lens/LensLaunchData";
import { getTypeName, validate } from "../common/validate";
import { TypedEventTarget } from "../events/TypedEventTarget";
import { TypedCustomEvent } from "../events/TypedCustomEvent";
import type { CameraKitDeviceOptions, CameraKitSource } from "../media-sources/CameraKitSource";
import { isCameraKitSource, isPartialCameraKitDeviceOptionsOrUndefined } from "../media-sources/CameraKitSource";
import { cameraKitSourceError, LensExecutionError } from "../namedErrors";
import { createMediaStreamSource } from "../media-sources/MediaStreamSource";
import { createVideoSource } from "../media-sources/VideoSource";
import type { LogEntry } from "../logger/logger";
import { getLogger } from "../logger/logger";
import { errorLoggingDecorator } from "../logger/errorLoggingDecorator";
import { logEntriesFactory } from "../logger/logEntries";
import type { PageVisibility } from "../common/pageVisibility";
import { pageVisibilityFactory } from "../common/pageVisibility";
import type { LensCore } from "../lens-core-module/lensCore";
import type { CanvasType } from "../lens-core-module/generated-types";
import { isUndefined, isValidNumber } from "../common/typeguards";
import { LensPerformanceMetrics } from "./LensPerformanceMetrics";
import type { LensState } from "./lensState";
import { lensStateFactory } from "./lensState";
import type { SessionState } from "./sessionState";
import { sessionStateFactory } from "./sessionState";
import type { Keyboard, LensKeyboard } from "./LensKeyboard";
import { lensKeyboardFactory } from "./LensKeyboard";
import type { CameraKitSessionEvents } from "./CameraKitSessionEvents";
import { isPublicLensError } from "./CameraKitSessionEvents";

const logger = getLogger("CameraKitSession");

function isAllowedSource(value: unknown): value is CameraKitSource | MediaStream | HTMLVideoElement {
    return isCameraKitSource(value) || isMediaStream(value) || isHTMLVideoElement(value);
}
function isMediaStream(value: unknown): value is MediaStream {
    return value instanceof MediaStream;
}
function isHTMLVideoElement(value: unknown): value is HTMLVideoElement {
    return value instanceof HTMLVideoElement;
}

function isRenderTargetOrUndefined(value: unknown): value is RenderTarget | undefined {
    return isUndefined(value) || value === "live" || value === "capture";
}

/**
 * Enumerates the supported render targets.
 *
 * Lenses may render to different render targets, as designed by the lens creator. In CameraKit, it's possible to choose
 * which render target to render, and the result for each target is available as a separate `<canvas>` element.
 *
 * @category Rendering
 * @category Lenses
 */
export type RenderTarget = "live" | "capture";

/**
 * A CameraKitSession represents a single rendering pipeline connecting an input media source to output `<canvas>`
 * elements. When a Lens is applied to the session, CameraKit uses the Lens to transform the input media into rendered
 * output.
 *
 * CameraKitSession is the primary object that applications interact with when integrating the CameraKit SDK.
 *
 * A CameraKitSession instance is obtained by calling {@link CameraKit.createSession}.
 *
 * @example
 * ```ts
 * const cameraKit = await bootstrapCameraKit(config)
 * const session = await cameraKit.createSession()
 * ```
 *
 * @category Rendering
 * @category Lenses
 */
class CameraKitSession {
    /**
     * CameraKitSession renders video output to a `<canvas>` element. In fact, each session contains two canvas outputs
     * corresponding to the RenderTargets used by Lens creators, when using LensStudio to create a Lens.
     *
     * The `live` output renders content suitable for the Lens user (e.g. it may contain additional UI elements
     * applicable only to the person applying the lens). The `capture` output renders content suitable for sharing with
     * other users (e.g. sent to the other members of a video call, or saved to disk for sharing later).
     *
     * For many lenses, these outputs are identical – but each lens is free to render differently, based on its own
     * use-case.
     */
    readonly output: {
        live: HTMLCanvasElement;
        capture: HTMLCanvasElement;
    };

    /**
     * Indicates whether or not the session is currently rendering. If `false`, rendering is stopped. Otherwise the
     * value indicates which output is being rendered.
     */
    playing: {
        live: boolean;
        capture: boolean;
    };

    /**
     * Add event listeners here to handle events which occur during the CameraKitSession.
     *
     * **Note:** Applications may want to handle the `error` event, and check the contained error type -- if the type
     * is {@link LensExecutionError}, this means the current lens was unable to render and CameraKit will automatically
     * remove the lens.
     *
     * @example
     * ```ts
     * cameraKitSession.events.addEventListener('error', ({ detail }) => {
     *   if (detail.error.name === 'LensExecutionError') {
     *     console.log(`Lens ${detail.lens.name} encountered an error and was removed. Please pick a different lens.`)
     *   }
     * })
     * ```
     */
    readonly events = new TypedEventTarget<CameraKitSessionEvents>();

    /**
     * Use this to measure current lens performance.
     */
    readonly metrics: LensPerformanceMetrics;

    private readonly removePageVisibilityHandlers: () => void;
    private source?: CameraKitSource;
    private subscriptions: Subscription[];

    /**
     * @internal
     */
    constructor(
        /**
         * Use this to interact with lenses which require text input.
         */
        public readonly keyboard: Keyboard,

        private readonly lensCore: LensCore,
        private readonly sessionState: SessionState,
        private readonly lensState: LensState,
        logEntries: Observable<LogEntry>,
        pageVisibility: PageVisibility
    ) {
        const outputs = this.lensCore.getOutputCanvases();
        this.output = {
            live: outputs[this.lensCore.CanvasType.Preview.value],
            capture: outputs[this.lensCore.CanvasType.Capture.value],
        };
        this.playing = {
            live: false,
            capture: false,
        };

        this.metrics = new LensPerformanceMetrics(this.lensCore);

        const removeOnHidden = pageVisibility.onPageHidden(() => this.sessionState.dispatch("suspend", this));
        const removeOnVisible = pageVisibility.onPageVisible(() => this.sessionState.dispatch("resume", this));
        this.removePageVisibilityHandlers = () => {
            removeOnHidden();
            removeOnVisible();
        };

        this.subscriptions = [
            // In case of an abort error, the only option is to destroy the current session,
            // as it becomes inoperable.
            lensCore.errors
                .pipe(filter((error) => error.name === "LensCoreAbortError"))
                .subscribe(() => this.destroy()),

            // In case of LensCore lens execution error, we must remove the lens from rendering
            // NOTE: LensCore doesn't differentiate recoverable vs non-recoverable errors and
            // it is recommended to always remove the lens.
            lensCore.errors
                .pipe(filter((error) => error.name !== "LensCoreAbortError" && error.isFrameError))
                .subscribe(() => this.removeLens()),

            // Forward logged errors that are public to the app
            logEntries
                .pipe(
                    filter((entry) => entry.level === "error"),
                    map((entry) => entry.messages.find((e) => e instanceof Error)),
                    filter(isPublicLensError)
                )
                .subscribe((error) => {
                    const state = lensState.getState();
                    if (!isState(state, "noLensApplied")) {
                        this.events.dispatchEvent(new TypedCustomEvent("error", { error, lens: state.data }));
                    } else {
                        // NOTE: at this point the error is already reported, so we can just log a warning
                        logger.warn("Lens error occurred even though there is no active lens.", error);
                    }
                }),
        ];
    }

    /**
     * Apply a Lens to this session.
     *
     * This method will download (and cache) the Lens executable, and then use that Lens for rendering. If the session
     * is currently playing, this will immediately update the rendered output. Otherwise, the new Lens will be used
     * when session playback in resumed.
     *
     * Calling `applyLens` replaces any prior Lens – only one Lens is allowed at a time (per session).
     *
     * **NOTE**: Errors may occur after the Lens is applied. If the Lens encounters errors while rendering,
     * Camera Kit will automatically remove the Lens from the session and emit a {@link LensExecutionError} event.
     * Applications may want to listen for this error and, for example,
     * prevent the Lens from being selected again by the user.
     *
     * ```ts
     * session.events.addEventListener("error", ({ detail }) => {
     *   if (detail.error.name === "LensExecutionError") {
     *     preventFutureLensSelection(detail.lens);
     *     showMessage("We're sorry, but the Lens you selected encountered an error. Please choose a different Lens.");
     *   }
     * });
     * ```
     *
     * @param lens The Lens to apply to this session.
     * @param launchData This can optionally be provided to pass some initial data to the Lens – only certain Lenses
     * expect launch data.
     * @returns A promise which can have the following results:
     * 1. Resolved with `true`: the Lens has been applied.
     * 2. Resolved with `false`: the Lens has not been applied, but no error occurred – this can happen if a
     * subsequent call to `applyLens` interrupted the Lens application.
     * 3. Rejected: the Lens has not been applied because an error occurred. This can happen if:
     *   - The Lens ID cannot be found in the LensRepository (use LensRepository to load the Lens before calling this
     *     method)
     *   - Lens content download fails, or the download of any required lens assets fails.
     *   - An internal failure occurs in the Lens rendering engine when attempting to apply the Lens.
     */
    @validate(isLens, isLensLaunchDataOrUndefined)
    @errorLoggingDecorator(logger)
    async applyLens(lens: Lens, launchData?: LensLaunchData): Promise<boolean> {
        const action = this.lensState.actions.applyLens({ lens, launchData });
        return firstValueFrom(
            of(action).pipe(
                dispatch(this.lensState),

                // If another applyLens occurs while we're waiting, resolve this applyLens promise early – we're no
                // longer waiting for the requested lens to be applied.
                takeUntil(
                    this.lensState.events.pipe(
                        forActions("applyLens"),
                        filter(([a]) => a !== action)
                    )
                ),

                // If lens application failed, convert this into a rejected promise by throwing the error.
                tap(([a]) => {
                    if (isAction(a, "applyLensFailed") && a.data.lens.id === lens.id) throw a.data.error;
                }),

                inStates("lensApplied"),

                map(() => true)
            ),
            // The default value is used if `takeUntil` completes the Observable early – i.e. the lens was not
            // applied (application was interrupted by a new call to `applyLens`), so we'll resolve with `false`.
            { defaultValue: false }
        );
    }

    /**
     * Remove a Lens from this session.
     *
     * When a Lens is removed, rendering continues if the session is playing. It will just render the session input
     * directly to the outputs without any image processing.
     *
     * @returns A promise which can have the following results:
     * 1. Resolved with `true`: the session's rendered output has no lens applied.
     * 2. Resolved with `false`: the current lens has been removed, but a subsequent call to `applyLens` means that the
     * session's rendered output will still have a (new) lens applied.
     * 3. Rejected: the lens has failed to be removed. This can happen if an internal failure occurs in the Lens
     * rendering engine when attempting to remove the lens.
     */
    @errorLoggingDecorator(logger)
    async removeLens(): Promise<boolean> {
        if (isState(this.lensState.getState(), "noLensApplied")) return true;
        return firstValueFrom(
            of(this.lensState.actions.removeLens()).pipe(
                dispatch(this.lensState),
                // If lens removal failed, convert this into a rejected promise by throwing the error.
                tap(([a]) => {
                    if (isAction(a, "removeLensFailed")) throw a.data;
                }),
                inStates("noLensApplied"),

                // If applyLens is called while we're waiting for removal, complete immediately – applying the next lens
                // will replace the current one.
                takeUntil(this.lensState.events.pipe(forActions("applyLens"))),
                map(() => true)
            ),
            // The default value is used if `takeUntil` completes the Observable early (otherwise firstValueFrom will
            // return a rejected Promise).
            { defaultValue: false }
        );
    }

    /**
     * Start/resume session playback – LensCore will begin rendering frames to the output.
     *
     * If no source has been set for the session, calling `play()` will update the playing state, but no actual image
     * processing will occur until `setSource()` is called.
     *
     * @example
     * ```ts
     * const cameraKitSession = await cameraKit.createSession()
     * await cameraKitSession.setSource(mySource)
     * await cameraKitSession.play()
     *
     * // If you call `play` before `setSource`, the call to `play` will resolve but playback will only begin once a
     * // media source has been set.
     * ```
     *
     * @param target Specify the {@link RenderTarget} to render. Defaults to the `live` RenderTarget.
     * @returns Promise resolves when playback state has been updated. If no source has been set, this means `play` will
     * resolve before any frames are processed -- but once a source is set, frames will immediately begin processing.
     */
    @validate(isRenderTargetOrUndefined)
    @errorLoggingDecorator(logger)
    async play(target: RenderTarget = "live"): Promise<void> {
        if (this.playing[target]) return;

        this.playing[target] = true;
        const type = this.renderTargetToCanvasType(target);
        return this.lensCore.playCanvas({ type }).catch((error) => {
            this.playing[target] = false;
            throw error;
        });
    }

    /**
     * Pause session playback – LensCore will stop rendering frames to the output.
     *
     * @param target Specify the RenderTarget to pause playback. May be either `'live'` or `'capture'`.
     * Default is `'live'`.
     * @returns Promise resolves when playback has stopped.
     */
    @validate(isRenderTargetOrUndefined)
    @errorLoggingDecorator(logger)
    async pause(target: RenderTarget = "live"): Promise<void> {
        if (this.playing[target] === false) return;
        this.playing[target] = false;
        const type = this.renderTargetToCanvasType(target);
        return this.lensCore.pauseCanvas({ type }).catch((error) => {
            this.playing[target] = true;
            throw error;
        });
    }

    /**
     * Mute all sounds (default SDK state is unmuted).
     *
     * @param fade Do we want audio to fade out?
     */
    @errorLoggingDecorator(logger)
    mute(fade: boolean = false): void {
        this.lensCore.setAllSoundsMuted({
            muted: true,
            fade,
        });
    }

    /**
     * Unmute all sounds.
     *
     * @param fade Do we want audio to fade in?
     */
    @errorLoggingDecorator(logger)
    unmute(fade: boolean = false): void {
        this.lensCore.setAllSoundsMuted({
            muted: false,
            fade,
        });
    }

    /**
     * Set the media source for this session.
     *
     * Sessions may only have one source at a time - if `setSource` is called multiple times, subsequent calls replace
     * the prior source. Setting the source does not trigger rendering (that’s done by `session.play()`). If the session
     * is already playing, setting the source will immediately begin rendering the new source.
     *
     * The CameraKit SDK provides implementations for various common sources, which applications can create using the
     * following functions:
     * - {@link createMediaStreamSource}
     * - {@link createVideoSource}
     * - {@link createImageSource}
     *
     * @param source A CameraKitSource object representing input media (e.g. a webcam stream, video, or some other
     * source of image data), which CameraKit will supply to Lenses in order for them to render effects on top of that
     * source.
     * @returns Promise is resolved when the source has successfully be set. If the session was already in the playing
     * state, the Promise resolves when the first frame from the new source has been rendered. The resolved value is
     * the {@link CameraKitSource} object attached to the session.
     */
    async setSource(source: CameraKitSource): Promise<CameraKitSource>;
    async setSource(
        source: MediaStream | HTMLVideoElement,
        options?: Partial<CameraKitDeviceOptions>
    ): Promise<CameraKitSource>;

    @validate(isAllowedSource, isPartialCameraKitDeviceOptionsOrUndefined)
    @errorLoggingDecorator(logger)
    async setSource(
        source: CameraKitSource | MediaStream | HTMLVideoElement,
        options: Partial<CameraKitDeviceOptions> = {}
    ): Promise<CameraKitSource> {
        await this.safelyDetachSource();

        // For convenience, we allow callers to pass in native objects (e.g. MediaStream) as well as CameraKitSource.
        // Native objects are wrapped in corresponding CameraKitSource classes with default options.
        const cameraKitSource = isMediaStream(source)
            ? createMediaStreamSource(source, options)
            : isHTMLVideoElement(source)
            ? createVideoSource(source, options)
            : source;

        const priorPlayingState = this.playing;
        this.playing = {
            live: false,
            capture: false,
        };

        // The source will provide its data to LensCore, and use other LensCore APIs (e.g. setRenderSize,
        // setInputTransform) to render the source correctly.
        await cameraKitSource.attach(this.lensCore, (error) => {
            logger.error(cameraKitSourceError("Error occurred during source attachment.", error));
        });

        // If attachment is successful, we'll update our source so that we can detach it later.
        this.source = cameraKitSource;

        // Finally we'll resume playback, if appropriate.
        if (priorPlayingState.live) await this.play("live");
        if (priorPlayingState.capture) await this.play("capture");

        return cameraKitSource;
    }

    /**
     * Set an FPS limit.
     *
     * This may be useful to reduce CPU/GPU resource usage by CameraKit if, for example, the input
     * media source has a low FPS – CameraKit would then not try to render more frequently than the source produces
     * new frames.
     *
     * This may also be useful to gracefully degrade performance in situations where lowering FPS is preferable over
     * alternatives.
     *
     * @param fpsLimit A maximum FPS, rendering will not exceed this limit
     * @returns Promise is resolved when the limit is successfully set.
     */
    @validate(isValidNumber)
    @errorLoggingDecorator(logger)
    async setFPSLimit(fpsLimit: number): Promise<void> {
        // LensCore uses 0 to remove the limit.
        const fps = fpsLimit < Number.POSITIVE_INFINITY ? fpsLimit : 0;
        return this.lensCore.setFPSLimit({ fps });
    }

    /**
     * Destroy the session.
     *
     * The session will become inoperable. Frame processing stops, and any session-scoped graphical resources are freed.
     */
    @errorLoggingDecorator(logger)
    async destroy(): Promise<void> {
        try {
            await this.lensCore.clearAllLenses();
            await this.lensCore.teardown();
        } catch (error) {
            // If a LensCore is in an aborted state, the above lines may throw an error.
            // In such cases, we should continue with the cleanup process.
            // We are also not interested in reporting these errors to our backend.
            logger.warn("An error occurred in LensCore during the session termination process.", error);
        }
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        await this.safelyDetachSource();
        this.removePageVisibilityHandlers();
        this.sessionState.dispatch("destroy", undefined);
    }

    private renderTargetToCanvasType(target: RenderTarget): CanvasType {
        return target === "capture" ? this.lensCore.CanvasType.Capture : this.lensCore.CanvasType.Preview;
    }

    private async safelyDetachSource(): Promise<void> {
        if (this.source) {
            try {
                await this.source.detach((error) => {
                    logger.error(cameraKitSourceError("Error occurred during source detachment.", error));
                });
                // If there's a failure to detach, we will report the error and proceed. Failure to detach may lead to a
                // memory leak, but it shouldn't prevent us from switching to the new source.
            } catch (error) {
                logger.error(
                    cameraKitSourceError(`Detaching prior source of type ${getTypeName(this.source)} failed.`, error)
                );
            }
        }
    }
}

// NOTE: jest doesn't like default exports of classes with decorated methods.
export { CameraKitSession };

/**
 * @internal
 */
export const cameraKitSessionFactory = Injectable(
    "CameraKitSession",
    [
        lensCoreFactory.token,
        logEntriesFactory.token,
        lensKeyboardFactory.token,
        sessionStateFactory.token,
        lensStateFactory.token,
        pageVisibilityFactory.token,
    ] as const,
    (
        lensCore: LensCore,
        logEntries: Observable<LogEntry>,
        keyboard: LensKeyboard,
        sessionState: SessionState,
        lensState: LensState,
        pageVisibility: PageVisibility
    ) => new CameraKitSession(keyboard, lensCore, sessionState, lensState, logEntries, pageVisibility)
);
