import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "snapchat.cdp.cof";
export enum AbStep {
    USER_WHITELISTED = 0,
    USER_GROUP_WHITELISTED = 1,
    FILTER_CONDITIONS = 2,
    SLICE_RANGE = 3,
    TRAFFIC_ALLOCATED = 4,
    UNRECOGNIZED = -1
}
export enum CofStep {
    VALIDATE_REQUEST = 0,
    REQUEST_ATLAS = 1,
    BUILD_TARGETING_INFO = 2,
    QUERY_INDEX = 3,
    REQUEST_AB = 4,
    UNRECOGNIZED = -1
}
export interface AbStepInfo {
    abStep: AbStep;
    info: string;
}
export interface CofStepInfo {
    cofStep: CofStep;
    info: string;
}
export interface DebugTrace {
    lastCofStep: CofStepInfo | undefined;
    lastAbStep: AbStepInfo | undefined;
    budgetGroupId: number;
}
function createBaseAbStepInfo(): AbStepInfo {
    return { abStep: 0, info: "" };
}
export const AbStepInfo = {
    encode(message: AbStepInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.abStep !== 0) {
            writer.uint32(8).int32(message.abStep);
        }
        if (message.info !== "") {
            writer.uint32(18).string(message.info);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): AbStepInfo {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseAbStepInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.abStep = reader.int32() as any;
                    break;
                case 2:
                    message.info = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<AbStepInfo>, I>>(object: I): AbStepInfo {
        const message = createBaseAbStepInfo();
        message.abStep = object.abStep ?? 0;
        message.info = object.info ?? "";
        return message;
    },
};
function createBaseCofStepInfo(): CofStepInfo {
    return { cofStep: 0, info: "" };
}
export const CofStepInfo = {
    encode(message: CofStepInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.cofStep !== 0) {
            writer.uint32(8).int32(message.cofStep);
        }
        if (message.info !== "") {
            writer.uint32(18).string(message.info);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): CofStepInfo {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCofStepInfo();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.cofStep = reader.int32() as any;
                    break;
                case 2:
                    message.info = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<CofStepInfo>, I>>(object: I): CofStepInfo {
        const message = createBaseCofStepInfo();
        message.cofStep = object.cofStep ?? 0;
        message.info = object.info ?? "";
        return message;
    },
};
function createBaseDebugTrace(): DebugTrace {
    return { lastCofStep: undefined, lastAbStep: undefined, budgetGroupId: 0 };
}
export const DebugTrace = {
    encode(message: DebugTrace, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.lastCofStep !== undefined) {
            CofStepInfo.encode(message.lastCofStep, writer.uint32(10).fork()).ldelim();
        }
        if (message.lastAbStep !== undefined) {
            AbStepInfo.encode(message.lastAbStep, writer.uint32(18).fork()).ldelim();
        }
        if (message.budgetGroupId !== 0) {
            writer.uint32(24).uint32(message.budgetGroupId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): DebugTrace {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDebugTrace();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lastCofStep = CofStepInfo.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.lastAbStep = AbStepInfo.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.budgetGroupId = reader.uint32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<DebugTrace>, I>>(object: I): DebugTrace {
        const message = createBaseDebugTrace();
        message.lastCofStep =
            object.lastCofStep !== undefined && object.lastCofStep !== null
                ? CofStepInfo.fromPartial(object.lastCofStep)
                : undefined;
        message.lastAbStep =
            object.lastAbStep !== undefined && object.lastAbStep !== null
                ? AbStepInfo.fromPartial(object.lastAbStep)
                : undefined;
        message.budgetGroupId = object.budgetGroupId ?? 0;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
