import Long from "long";
import _m0 from "protobufjs/minimal";
import { RankingData } from "./ranking";
import { Lens } from "./lens";
import { LegalPrompt } from "./legal_prompt";
import { OperationalMetricsBundle } from "./operational_metrics";
import { Any } from "../../google/protobuf/any";
import { ExtensionEventBase } from "./business_events";
export const protobufPackage = "com.snap.camerakit.v3";
export interface GetGroupRequest {
    id: string;
    rankingData: RankingData | undefined;
}
export interface GetGroupResponse {
    id: string;
    lenses: Lens[];
}
export interface GetGroupLensRequest {
    lensId: string;
    groupId: string;
}
export interface GetGroupLensResponse {
    lens: Lens | undefined;
    groupId: string;
}
export interface BatchGetGroupLensRequest {
    getRequests: GetGroupLensRequest[];
}
export interface BatchGetGroupLensResponse {
    getResponses: GetGroupLensResponse[];
}
export interface GetPlaceholderConfigRequest {
}
export interface GetPlaceholderConfigResponse {
    configs: {
        [key: string]: string;
    };
}
export interface GetPlaceholderConfigResponse_ConfigsEntry {
    key: string;
    value: string;
}
export interface GetInitializationConfigRequest {
}
export interface GetInitializationConfigResponse {
    appVendorUuidOptIn: boolean;
    watermarkEnabled: boolean;
    childrenProtectionActRestricted: boolean;
    legalPrompt: LegalPrompt | undefined;
}
export interface SetOperationalMetricsRequest {
    metrics: OperationalMetricsBundle | undefined;
}
export interface SetOperationalMetricsResponse {
}
export interface SetBusinessEventsRequest {
    batchEvents: Any | undefined;
}
export interface SetBusinessEventsResponse {
}
export interface SetExtensionBusinessEventsRequest {
    events: Any[];
    extensionEventBase: ExtensionEventBase | undefined;
}
export interface SetExtensionBusinessEventsResponse {
}
function createBaseGetGroupRequest(): GetGroupRequest {
    return { id: "", rankingData: undefined };
}
export const GetGroupRequest = {
    encode(message: GetGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.rankingData !== undefined) {
            RankingData.encode(message.rankingData, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetGroupRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGroupRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.rankingData = RankingData.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetGroupRequest>, I>>(object: I): GetGroupRequest {
        const message = createBaseGetGroupRequest();
        message.id = object.id ?? "";
        message.rankingData =
            object.rankingData !== undefined && object.rankingData !== null
                ? RankingData.fromPartial(object.rankingData)
                : undefined;
        return message;
    },
};
function createBaseGetGroupResponse(): GetGroupResponse {
    return { id: "", lenses: [] };
}
export const GetGroupResponse = {
    encode(message: GetGroupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        for (const v of message.lenses) {
            Lens.encode(v!, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetGroupResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGroupResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.lenses.push(Lens.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetGroupResponse>, I>>(object: I): GetGroupResponse {
        const message = createBaseGetGroupResponse();
        message.id = object.id ?? "";
        message.lenses = object.lenses?.map((e) => Lens.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetGroupLensRequest(): GetGroupLensRequest {
    return { lensId: "", groupId: "" };
}
export const GetGroupLensRequest = {
    encode(message: GetGroupLensRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.lensId !== "") {
            writer.uint32(10).string(message.lensId);
        }
        if (message.groupId !== "") {
            writer.uint32(18).string(message.groupId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetGroupLensRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGroupLensRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lensId = reader.string();
                    break;
                case 2:
                    message.groupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetGroupLensRequest>, I>>(object: I): GetGroupLensRequest {
        const message = createBaseGetGroupLensRequest();
        message.lensId = object.lensId ?? "";
        message.groupId = object.groupId ?? "";
        return message;
    },
};
function createBaseGetGroupLensResponse(): GetGroupLensResponse {
    return { lens: undefined, groupId: "" };
}
export const GetGroupLensResponse = {
    encode(message: GetGroupLensResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.lens !== undefined) {
            Lens.encode(message.lens, writer.uint32(10).fork()).ldelim();
        }
        if (message.groupId !== "") {
            writer.uint32(18).string(message.groupId);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetGroupLensResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetGroupLensResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lens = Lens.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.groupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetGroupLensResponse>, I>>(object: I): GetGroupLensResponse {
        const message = createBaseGetGroupLensResponse();
        message.lens = object.lens !== undefined && object.lens !== null ? Lens.fromPartial(object.lens) : undefined;
        message.groupId = object.groupId ?? "";
        return message;
    },
};
function createBaseBatchGetGroupLensRequest(): BatchGetGroupLensRequest {
    return { getRequests: [] };
}
export const BatchGetGroupLensRequest = {
    encode(message: BatchGetGroupLensRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.getRequests) {
            GetGroupLensRequest.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): BatchGetGroupLensRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchGetGroupLensRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.getRequests.push(GetGroupLensRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<BatchGetGroupLensRequest>, I>>(object: I): BatchGetGroupLensRequest {
        const message = createBaseBatchGetGroupLensRequest();
        message.getRequests = object.getRequests?.map((e) => GetGroupLensRequest.fromPartial(e)) || [];
        return message;
    },
};
function createBaseBatchGetGroupLensResponse(): BatchGetGroupLensResponse {
    return { getResponses: [] };
}
export const BatchGetGroupLensResponse = {
    encode(message: BatchGetGroupLensResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.getResponses) {
            GetGroupLensResponse.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): BatchGetGroupLensResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBatchGetGroupLensResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.getResponses.push(GetGroupLensResponse.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<BatchGetGroupLensResponse>, I>>(object: I): BatchGetGroupLensResponse {
        const message = createBaseBatchGetGroupLensResponse();
        message.getResponses = object.getResponses?.map((e) => GetGroupLensResponse.fromPartial(e)) || [];
        return message;
    },
};
function createBaseGetPlaceholderConfigRequest(): GetPlaceholderConfigRequest {
    return {};
}
export const GetPlaceholderConfigRequest = {
    encode(_: GetPlaceholderConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetPlaceholderConfigRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPlaceholderConfigRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetPlaceholderConfigRequest>, I>>(_: I): GetPlaceholderConfigRequest {
        const message = createBaseGetPlaceholderConfigRequest();
        return message;
    },
};
function createBaseGetPlaceholderConfigResponse(): GetPlaceholderConfigResponse {
    return { configs: {} };
}
export const GetPlaceholderConfigResponse = {
    encode(message: GetPlaceholderConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        Object.entries(message.configs).forEach(([key, value]) => {
            GetPlaceholderConfigResponse_ConfigsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
        });
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetPlaceholderConfigResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPlaceholderConfigResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    const entry1 = GetPlaceholderConfigResponse_ConfigsEntry.decode(reader, reader.uint32());
                    if (entry1.value !== undefined) {
                        message.configs[entry1.key] = entry1.value;
                    }
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetPlaceholderConfigResponse>, I>>(object: I): GetPlaceholderConfigResponse {
        const message = createBaseGetPlaceholderConfigResponse();
        message.configs = Object.entries(object.configs ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        return message;
    },
};
function createBaseGetPlaceholderConfigResponse_ConfigsEntry(): GetPlaceholderConfigResponse_ConfigsEntry {
    return { key: "", value: "" };
}
export const GetPlaceholderConfigResponse_ConfigsEntry = {
    encode(message: GetPlaceholderConfigResponse_ConfigsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetPlaceholderConfigResponse_ConfigsEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetPlaceholderConfigResponse_ConfigsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetPlaceholderConfigResponse_ConfigsEntry>, I>>(object: I): GetPlaceholderConfigResponse_ConfigsEntry {
        const message = createBaseGetPlaceholderConfigResponse_ConfigsEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseGetInitializationConfigRequest(): GetInitializationConfigRequest {
    return {};
}
export const GetInitializationConfigRequest = {
    encode(_: GetInitializationConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetInitializationConfigRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetInitializationConfigRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetInitializationConfigRequest>, I>>(_: I): GetInitializationConfigRequest {
        const message = createBaseGetInitializationConfigRequest();
        return message;
    },
};
function createBaseGetInitializationConfigResponse(): GetInitializationConfigResponse {
    return {
        appVendorUuidOptIn: false,
        watermarkEnabled: false,
        childrenProtectionActRestricted: false,
        legalPrompt: undefined,
    };
}
export const GetInitializationConfigResponse = {
    encode(message: GetInitializationConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.appVendorUuidOptIn === true) {
            writer.uint32(8).bool(message.appVendorUuidOptIn);
        }
        if (message.watermarkEnabled === true) {
            writer.uint32(16).bool(message.watermarkEnabled);
        }
        if (message.childrenProtectionActRestricted === true) {
            writer.uint32(24).bool(message.childrenProtectionActRestricted);
        }
        if (message.legalPrompt !== undefined) {
            LegalPrompt.encode(message.legalPrompt, writer.uint32(34).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): GetInitializationConfigResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseGetInitializationConfigResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.appVendorUuidOptIn = reader.bool();
                    break;
                case 2:
                    message.watermarkEnabled = reader.bool();
                    break;
                case 3:
                    message.childrenProtectionActRestricted = reader.bool();
                    break;
                case 4:
                    message.legalPrompt = LegalPrompt.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<GetInitializationConfigResponse>, I>>(object: I): GetInitializationConfigResponse {
        const message = createBaseGetInitializationConfigResponse();
        message.appVendorUuidOptIn = object.appVendorUuidOptIn ?? false;
        message.watermarkEnabled = object.watermarkEnabled ?? false;
        message.childrenProtectionActRestricted = object.childrenProtectionActRestricted ?? false;
        message.legalPrompt =
            object.legalPrompt !== undefined && object.legalPrompt !== null
                ? LegalPrompt.fromPartial(object.legalPrompt)
                : undefined;
        return message;
    },
};
function createBaseSetOperationalMetricsRequest(): SetOperationalMetricsRequest {
    return { metrics: undefined };
}
export const SetOperationalMetricsRequest = {
    encode(message: SetOperationalMetricsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.metrics !== undefined) {
            OperationalMetricsBundle.encode(message.metrics, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SetOperationalMetricsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetOperationalMetricsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.metrics = OperationalMetricsBundle.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SetOperationalMetricsRequest>, I>>(object: I): SetOperationalMetricsRequest {
        const message = createBaseSetOperationalMetricsRequest();
        message.metrics =
            object.metrics !== undefined && object.metrics !== null
                ? OperationalMetricsBundle.fromPartial(object.metrics)
                : undefined;
        return message;
    },
};
function createBaseSetOperationalMetricsResponse(): SetOperationalMetricsResponse {
    return {};
}
export const SetOperationalMetricsResponse = {
    encode(_: SetOperationalMetricsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SetOperationalMetricsResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetOperationalMetricsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SetOperationalMetricsResponse>, I>>(_: I): SetOperationalMetricsResponse {
        const message = createBaseSetOperationalMetricsResponse();
        return message;
    },
};
function createBaseSetBusinessEventsRequest(): SetBusinessEventsRequest {
    return { batchEvents: undefined };
}
export const SetBusinessEventsRequest = {
    encode(message: SetBusinessEventsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.batchEvents !== undefined) {
            Any.encode(message.batchEvents, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SetBusinessEventsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetBusinessEventsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.batchEvents = Any.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SetBusinessEventsRequest>, I>>(object: I): SetBusinessEventsRequest {
        const message = createBaseSetBusinessEventsRequest();
        message.batchEvents =
            object.batchEvents !== undefined && object.batchEvents !== null ? Any.fromPartial(object.batchEvents) : undefined;
        return message;
    },
};
function createBaseSetBusinessEventsResponse(): SetBusinessEventsResponse {
    return {};
}
export const SetBusinessEventsResponse = {
    encode(_: SetBusinessEventsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SetBusinessEventsResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetBusinessEventsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SetBusinessEventsResponse>, I>>(_: I): SetBusinessEventsResponse {
        const message = createBaseSetBusinessEventsResponse();
        return message;
    },
};
function createBaseSetExtensionBusinessEventsRequest(): SetExtensionBusinessEventsRequest {
    return { events: [], extensionEventBase: undefined };
}
export const SetExtensionBusinessEventsRequest = {
    encode(message: SetExtensionBusinessEventsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.events) {
            Any.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        if (message.extensionEventBase !== undefined) {
            ExtensionEventBase.encode(message.extensionEventBase, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SetExtensionBusinessEventsRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetExtensionBusinessEventsRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.events.push(Any.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.extensionEventBase = ExtensionEventBase.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SetExtensionBusinessEventsRequest>, I>>(object: I): SetExtensionBusinessEventsRequest {
        const message = createBaseSetExtensionBusinessEventsRequest();
        message.events = object.events?.map((e) => Any.fromPartial(e)) || [];
        message.extensionEventBase =
            object.extensionEventBase !== undefined && object.extensionEventBase !== null
                ? ExtensionEventBase.fromPartial(object.extensionEventBase)
                : undefined;
        return message;
    },
};
function createBaseSetExtensionBusinessEventsResponse(): SetExtensionBusinessEventsResponse {
    return {};
}
export const SetExtensionBusinessEventsResponse = {
    encode(_: SetExtensionBusinessEventsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): SetExtensionBusinessEventsResponse {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSetExtensionBusinessEventsResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<SetExtensionBusinessEventsResponse>, I>>(_: I): SetExtensionBusinessEventsResponse {
        const message = createBaseSetExtensionBusinessEventsResponse();
        return message;
    },
};
export const LensesDefinition = {
    name: "Lenses",
    fullName: "com.snap.camerakit.v3.Lenses",
    methods: {
        getGroup: {
            name: "GetGroup",
            requestType: GetGroupRequest,
            requestStream: false,
            responseType: GetGroupResponse,
            responseStream: false,
            options: {
                idempotencyLevel: "NO_SIDE_EFFECTS",
            },
        },
        getGroupLens: {
            name: "GetGroupLens",
            requestType: GetGroupLensRequest,
            requestStream: false,
            responseType: GetGroupLensResponse,
            responseStream: false,
            options: {
                idempotencyLevel: "NO_SIDE_EFFECTS",
            },
        },
        batchGetGroupLens: {
            name: "BatchGetGroupLens",
            requestType: BatchGetGroupLensRequest,
            requestStream: false,
            responseType: BatchGetGroupLensResponse,
            responseStream: false,
            options: {
                idempotencyLevel: "NO_SIDE_EFFECTS",
            },
        },
        getPlaceholderConfig: {
            name: "GetPlaceholderConfig",
            requestType: GetPlaceholderConfigRequest,
            requestStream: false,
            responseType: GetPlaceholderConfigResponse,
            responseStream: false,
            options: {
                idempotencyLevel: "NO_SIDE_EFFECTS",
            },
        },
    },
} as const;
export const MetricsDefinition = {
    name: "Metrics",
    fullName: "com.snap.camerakit.v3.Metrics",
    methods: {
        setOperationalMetrics: {
            name: "SetOperationalMetrics",
            requestType: SetOperationalMetricsRequest,
            requestStream: false,
            responseType: SetOperationalMetricsResponse,
            responseStream: false,
            options: {},
        },
        setBusinessEvents: {
            name: "SetBusinessEvents",
            requestType: SetBusinessEventsRequest,
            requestStream: false,
            responseType: SetBusinessEventsResponse,
            responseStream: false,
            options: {},
        },
        setExtensionBusinessEvents: {
            name: "SetExtensionBusinessEvents",
            requestType: SetExtensionBusinessEventsRequest,
            requestStream: false,
            responseType: SetExtensionBusinessEventsResponse,
            responseStream: false,
            options: {},
        },
        getInitializationConfig: {
            name: "GetInitializationConfig",
            requestType: GetInitializationConfigRequest,
            requestStream: false,
            responseType: GetInitializationConfigResponse,
            responseStream: false,
            options: {
                idempotencyLevel: "NO_SIDE_EFFECTS",
            },
        },
    },
} as const;
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
