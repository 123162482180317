import Long from "long";
import _m0 from "protobufjs/minimal";
import { Any } from "../../google/protobuf/any";
export const protobufPackage = "com.snap.camerakit.v3";
export interface Lens {
    id: string;
    name: string;
    vendorData: {
        [key: string]: string;
    };
    content: Content | undefined;
    isThirdParty: boolean;
    cameraFacingPreference: Lens_CameraFacing;
    featureMetadata: Any[];
    lensCreator: LensCreator | undefined;
    scannable: Scannable | undefined;
}
export enum Lens_CameraFacing {
    CAMERA_FACING_UNSET = 0,
    CAMERA_FACING_FRONT = 1,
    CAMERA_FACING_BACK = 2,
    UNRECOGNIZED = -1
}
export interface Lens_VendorDataEntry {
    key: string;
    value: string;
}
export interface Content {
    lnsUrl: string;
    lnsSha256: string;
    iconUrl: string;
    preview: Preview | undefined;
    assetManifest: LensAssetManifestItem[];
    defaultHintId: string;
    hintTranslations: {
        [key: string]: string;
    };
    lnsUrlBolt: string;
    iconUrlBolt: string;
}
export interface Content_HintTranslationsEntry {
    key: string;
    value: string;
}
export interface LensAssetManifestItem {
    type: LensAssetManifestItem_Type;
    id: string;
    requestTiming: LensAssetManifestItem_RequestTiming;
    assetUrl: string;
    assetChecksum: string;
}
export enum LensAssetManifestItem_Type {
    DEVICE_DEPENDENT_ASSET_UNSET = 0,
    ASSET = 1,
    UNRECOGNIZED = -1
}
export enum LensAssetManifestItem_RequestTiming {
    PRELOAD_UNSET = 0,
    ON_DEMAND = 1,
    REQUIRED = 2,
    UNRECOGNIZED = -1
}
export interface Preview {
    imageUrl: string;
    imageSequenceSize: number;
    imageSequenceWebpUrlPattern: string;
}
export interface LensCreator {
    displayName: string;
}
export interface Scannable {
    snapcodeImageUrl: string;
    snapcodeDeeplink: string;
}
function createBaseLens(): Lens {
    return {
        id: "",
        name: "",
        vendorData: {},
        content: undefined,
        isThirdParty: false,
        cameraFacingPreference: 0,
        featureMetadata: [],
        lensCreator: undefined,
        scannable: undefined,
    };
}
export const Lens = {
    encode(message: Lens, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        Object.entries(message.vendorData).forEach(([key, value]) => {
            Lens_VendorDataEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
        });
        if (message.content !== undefined) {
            Content.encode(message.content, writer.uint32(34).fork()).ldelim();
        }
        if (message.isThirdParty === true) {
            writer.uint32(40).bool(message.isThirdParty);
        }
        if (message.cameraFacingPreference !== 0) {
            writer.uint32(48).int32(message.cameraFacingPreference);
        }
        for (const v of message.featureMetadata) {
            Any.encode(v!, writer.uint32(58).fork()).ldelim();
        }
        if (message.lensCreator !== undefined) {
            LensCreator.encode(message.lensCreator, writer.uint32(66).fork()).ldelim();
        }
        if (message.scannable !== undefined) {
            Scannable.encode(message.scannable, writer.uint32(74).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Lens {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLens();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    const entry3 = Lens_VendorDataEntry.decode(reader, reader.uint32());
                    if (entry3.value !== undefined) {
                        message.vendorData[entry3.key] = entry3.value;
                    }
                    break;
                case 4:
                    message.content = Content.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.isThirdParty = reader.bool();
                    break;
                case 6:
                    message.cameraFacingPreference = reader.int32() as any;
                    break;
                case 7:
                    message.featureMetadata.push(Any.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.lensCreator = LensCreator.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.scannable = Scannable.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Lens>, I>>(object: I): Lens {
        const message = createBaseLens();
        message.id = object.id ?? "";
        message.name = object.name ?? "";
        message.vendorData = Object.entries(object.vendorData ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.content =
            object.content !== undefined && object.content !== null ? Content.fromPartial(object.content) : undefined;
        message.isThirdParty = object.isThirdParty ?? false;
        message.cameraFacingPreference = object.cameraFacingPreference ?? 0;
        message.featureMetadata = object.featureMetadata?.map((e) => Any.fromPartial(e)) || [];
        message.lensCreator =
            object.lensCreator !== undefined && object.lensCreator !== null
                ? LensCreator.fromPartial(object.lensCreator)
                : undefined;
        message.scannable =
            object.scannable !== undefined && object.scannable !== null ? Scannable.fromPartial(object.scannable) : undefined;
        return message;
    },
};
function createBaseLens_VendorDataEntry(): Lens_VendorDataEntry {
    return { key: "", value: "" };
}
export const Lens_VendorDataEntry = {
    encode(message: Lens_VendorDataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Lens_VendorDataEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLens_VendorDataEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Lens_VendorDataEntry>, I>>(object: I): Lens_VendorDataEntry {
        const message = createBaseLens_VendorDataEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseContent(): Content {
    return {
        lnsUrl: "",
        lnsSha256: "",
        iconUrl: "",
        preview: undefined,
        assetManifest: [],
        defaultHintId: "",
        hintTranslations: {},
        lnsUrlBolt: "",
        iconUrlBolt: "",
    };
}
export const Content = {
    encode(message: Content, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.lnsUrl !== "") {
            writer.uint32(10).string(message.lnsUrl);
        }
        if (message.lnsSha256 !== "") {
            writer.uint32(18).string(message.lnsSha256);
        }
        if (message.iconUrl !== "") {
            writer.uint32(26).string(message.iconUrl);
        }
        if (message.preview !== undefined) {
            Preview.encode(message.preview, writer.uint32(34).fork()).ldelim();
        }
        for (const v of message.assetManifest) {
            LensAssetManifestItem.encode(v!, writer.uint32(42).fork()).ldelim();
        }
        if (message.defaultHintId !== "") {
            writer.uint32(50).string(message.defaultHintId);
        }
        Object.entries(message.hintTranslations).forEach(([key, value]) => {
            Content_HintTranslationsEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).ldelim();
        });
        if (message.lnsUrlBolt !== "") {
            writer.uint32(66).string(message.lnsUrlBolt);
        }
        if (message.iconUrlBolt !== "") {
            writer.uint32(74).string(message.iconUrlBolt);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Content {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.lnsUrl = reader.string();
                    break;
                case 2:
                    message.lnsSha256 = reader.string();
                    break;
                case 3:
                    message.iconUrl = reader.string();
                    break;
                case 4:
                    message.preview = Preview.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.assetManifest.push(LensAssetManifestItem.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.defaultHintId = reader.string();
                    break;
                case 7:
                    const entry7 = Content_HintTranslationsEntry.decode(reader, reader.uint32());
                    if (entry7.value !== undefined) {
                        message.hintTranslations[entry7.key] = entry7.value;
                    }
                    break;
                case 8:
                    message.lnsUrlBolt = reader.string();
                    break;
                case 9:
                    message.iconUrlBolt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Content>, I>>(object: I): Content {
        const message = createBaseContent();
        message.lnsUrl = object.lnsUrl ?? "";
        message.lnsSha256 = object.lnsSha256 ?? "";
        message.iconUrl = object.iconUrl ?? "";
        message.preview =
            object.preview !== undefined && object.preview !== null ? Preview.fromPartial(object.preview) : undefined;
        message.assetManifest = object.assetManifest?.map((e) => LensAssetManifestItem.fromPartial(e)) || [];
        message.defaultHintId = object.defaultHintId ?? "";
        message.hintTranslations = Object.entries(object.hintTranslations ?? {}).reduce<{
            [key: string]: string;
        }>((acc, [key, value]) => {
            if (value !== undefined) {
                acc[key] = String(value);
            }
            return acc;
        }, {});
        message.lnsUrlBolt = object.lnsUrlBolt ?? "";
        message.iconUrlBolt = object.iconUrlBolt ?? "";
        return message;
    },
};
function createBaseContent_HintTranslationsEntry(): Content_HintTranslationsEntry {
    return { key: "", value: "" };
}
export const Content_HintTranslationsEntry = {
    encode(message: Content_HintTranslationsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.key !== "") {
            writer.uint32(10).string(message.key);
        }
        if (message.value !== "") {
            writer.uint32(18).string(message.value);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Content_HintTranslationsEntry {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseContent_HintTranslationsEntry();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Content_HintTranslationsEntry>, I>>(object: I): Content_HintTranslationsEntry {
        const message = createBaseContent_HintTranslationsEntry();
        message.key = object.key ?? "";
        message.value = object.value ?? "";
        return message;
    },
};
function createBaseLensAssetManifestItem(): LensAssetManifestItem {
    return { type: 0, id: "", requestTiming: 0, assetUrl: "", assetChecksum: "" };
}
export const LensAssetManifestItem = {
    encode(message: LensAssetManifestItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.id !== "") {
            writer.uint32(18).string(message.id);
        }
        if (message.requestTiming !== 0) {
            writer.uint32(24).int32(message.requestTiming);
        }
        if (message.assetUrl !== "") {
            writer.uint32(34).string(message.assetUrl);
        }
        if (message.assetChecksum !== "") {
            writer.uint32(42).string(message.assetChecksum);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): LensAssetManifestItem {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLensAssetManifestItem();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32() as any;
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                case 3:
                    message.requestTiming = reader.int32() as any;
                    break;
                case 4:
                    message.assetUrl = reader.string();
                    break;
                case 5:
                    message.assetChecksum = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<LensAssetManifestItem>, I>>(object: I): LensAssetManifestItem {
        const message = createBaseLensAssetManifestItem();
        message.type = object.type ?? 0;
        message.id = object.id ?? "";
        message.requestTiming = object.requestTiming ?? 0;
        message.assetUrl = object.assetUrl ?? "";
        message.assetChecksum = object.assetChecksum ?? "";
        return message;
    },
};
function createBasePreview(): Preview {
    return { imageUrl: "", imageSequenceSize: 0, imageSequenceWebpUrlPattern: "" };
}
export const Preview = {
    encode(message: Preview, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.imageUrl !== "") {
            writer.uint32(10).string(message.imageUrl);
        }
        if (message.imageSequenceSize !== 0) {
            writer.uint32(16).int32(message.imageSequenceSize);
        }
        if (message.imageSequenceWebpUrlPattern !== "") {
            writer.uint32(26).string(message.imageSequenceWebpUrlPattern);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Preview {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePreview();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.imageUrl = reader.string();
                    break;
                case 2:
                    message.imageSequenceSize = reader.int32();
                    break;
                case 3:
                    message.imageSequenceWebpUrlPattern = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Preview>, I>>(object: I): Preview {
        const message = createBasePreview();
        message.imageUrl = object.imageUrl ?? "";
        message.imageSequenceSize = object.imageSequenceSize ?? 0;
        message.imageSequenceWebpUrlPattern = object.imageSequenceWebpUrlPattern ?? "";
        return message;
    },
};
function createBaseLensCreator(): LensCreator {
    return { displayName: "" };
}
export const LensCreator = {
    encode(message: LensCreator, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.displayName !== "") {
            writer.uint32(10).string(message.displayName);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): LensCreator {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLensCreator();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.displayName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<LensCreator>, I>>(object: I): LensCreator {
        const message = createBaseLensCreator();
        message.displayName = object.displayName ?? "";
        return message;
    },
};
function createBaseScannable(): Scannable {
    return { snapcodeImageUrl: "", snapcodeDeeplink: "" };
}
export const Scannable = {
    encode(message: Scannable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.snapcodeImageUrl !== "") {
            writer.uint32(10).string(message.snapcodeImageUrl);
        }
        if (message.snapcodeDeeplink !== "") {
            writer.uint32(18).string(message.snapcodeDeeplink);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Scannable {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseScannable();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.snapcodeImageUrl = reader.string();
                    break;
                case 2:
                    message.snapcodeDeeplink = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Scannable>, I>>(object: I): Scannable {
        const message = createBaseScannable();
        message.snapcodeImageUrl = object.snapcodeImageUrl ?? "";
        message.snapcodeDeeplink = object.snapcodeDeeplink ?? "";
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
