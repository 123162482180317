import { exceptions, simd } from "wasm-feature-detect";
import { memoize } from "../common/memoize";
import type { XrCapabilities } from "../lens-core-module/generated-types";
import { platformNotSupportedError } from "../namedErrors";
import { getPlatformInfo } from "./platformInfo";

/** @internal */
export type SupportedCapability<T> = T & { supported: true };
/** @internal */
export type UnsupportedCapability = { supported: false; error: Error };
/** @internal */
export type Capability<T = void> = SupportedCapability<T> | UnsupportedCapability;

//-----------
// WebGL
//-----------

type WebGlCapability = Capability<{ maxTextureSize: number }>;

// This required minimum max texture size is based on data from
// https://web3dsurvey.com/webgl/parameters/MAX_TEXTURE_SIZE. Checking for a reasonable minimum MAX_TEXTURE_SIZE avoids
// attempting to run lenses on platforms that will not support them -- most commonly, we've seen some platforms that
// report 0 MAX_TEXTURE_SIZE, which will cause errors for all lenses.
const minRequiredMaxTextureSize = 1024;

/**
 * @returns An object with fields describing support for various WebGL features.
 *
 * @internal
 */
function getWebGlSupport(): WebGlCapability {
    const ctx = globalThis.document?.createElement("canvas").getContext("webgl2");
    if (!ctx) {
        const cause = globalThis.document?.createElement("canvas").getContext("webgl")
            ? "platform_not_supported_only_webgl1"
            : typeof globalThis.WebGLRenderingContext === "function"
            ? "platform_not_supported_likely_no_hw_accel"
            : "platform_not_supported_no_webgl_browser_support";
        return {
            supported: false,
            error: platformNotSupportedError(
                "CameraKit requires WebGL2, but this browser does not support WebGL2.",
                new Error(cause)
            ),
        };
    }
    const maxTextureSize = ctx.getParameter(ctx.MAX_TEXTURE_SIZE);
    const supported = maxTextureSize >= minRequiredMaxTextureSize;
    return supported
        ? { supported, maxTextureSize }
        : {
              supported,
              error: platformNotSupportedError(
                  `CameraKit requires WebGL's MAX_TEXTURE_SIZE exceed a minimum value of ` +
                      `${minRequiredMaxTextureSize}, but the browser's reported MAX_TEXTURE_SIZE is ${maxTextureSize}.`
              ),
          };
}

//-----------
// WASM
//-----------

type WasmCapability = Capability<{ wasmFeatures: number }>;

/**
 * Because there may be a large number of WASM-related capabilities, and because these may correspond to various builds
 * of LensCore, we encode the various WASM capabilities into a single number by bitwise OR-ing together the numbers
 * corresponding to each capability.
 *
 * Since each combindation of capabilities is represented by a single number, we can easily map between that number and
 * the corresponding LensCore build name that makes use of those capabilities.
 *
 * @internal
 */
export enum WasmFeatures {
    Default = 0b00000000,
    SIMD = 0b00000001,
    ExceptionHandling = 0b00000010,
}

/**
 * @returns A non-negative integer representing the combination of supported WebAssembly features, or -1 if WebAssembly
 * is not supported at all.
 *
 * @internal
 */
async function getWebAssemblyCapabilities(): Promise<WasmCapability> {
    if (globalThis.WebAssembly === undefined)
        return {
            supported: false,
            error: platformNotSupportedError(
                "CameraKit requires WebAssembly, but this browser does not support WebAssembly."
            ),
        };
    return {
        supported: true,
        wasmFeatures: (
            await Promise.all([
                simd().then((supported) => {
                    // Although Safari 16.4 reports SIMD support, LensCore encounters rendering bugs when using
                    // SIMD in Safari 16.4. We will disable SIMD for now until Safari stabilizes the feature.
                    if (getPlatformInfo().browser.brand === "Safari") return WasmFeatures.Default;
                    return supported ? WasmFeatures.SIMD : WasmFeatures.Default;
                }),
                exceptions().then((supported) => (supported ? WasmFeatures.ExceptionHandling : WasmFeatures.Default)),
            ])
        ).reduce((features, feature) => features | feature, WasmFeatures.Default),
    };
}

//-----------
// WebXR
//-----------

type WebXrCapability = Capability<XrCapabilities>;

function getGenericWebXrNotSupported(cause?: unknown): WebXrCapability {
    return {
        supported: false,
        error: platformNotSupportedError(
            `Use of this feature requires WebXR support for immersive AR sessions, but ` +
                `this browser does not support immersive AR sessions.`,
            cause
        ),
    };
}

/**
 * @returns A Promise containing an object with fields describing the support of various WebXR features. This object's
 * type is defined by LensCore, as they consume these capabilities and adjust behavior accordingly.
 *
 * @internal
 */
export async function getWebXrCapabilities(): Promise<WebXrCapability> {
    try {
        if (!isSecureContext || !navigator.xr) return getGenericWebXrNotSupported();
        const isImmersiveArSupported = await navigator.xr.isSessionSupported("immersive-ar");
        return isImmersiveArSupported
            ? {
                  supported: true,
                  sixDofSupported: true,
                  sceneDepthSupported: true,
              }
            : getGenericWebXrNotSupported();
    } catch (error) {
        if (error instanceof Error && error.name === "SecurityError") {
            return {
                supported: false,
                error: platformNotSupportedError(
                    "Failed to check XR capabilities due to permissions or other issues.",
                    error
                ),
            };
        }
        return getGenericWebXrNotSupported(error);
    }
}

/** @internal */
export interface PlatformCapabilities {
    webgl: WebGlCapability;
    wasm: WasmCapability;
    webxr: WebXrCapability;
}

/**
 * Get information about the current platform capabilities, including:
 * - WebGL support and various WebGL parameters.
 * - WASM support and support for various WASM features.
 * - WebXR support and support for various WebXR features.
 *
 * @internal
 */
export const getPlatformCapabilities = memoize(async function getPlatformCapabilities(): Promise<PlatformCapabilities> {
    return {
        webgl: getWebGlSupport(),
        wasm: await getWebAssemblyCapabilities(),
        webxr: await getWebXrCapabilities(),
    };
});
