import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "com.snap.camerakit.v3";
export interface RankingData {
    sessionId: string;
    locale: string;
    osType: RankingData_OSType;
    connectivityType: RankingData_ConnectivityType;
}
export enum RankingData_OSType {
    OS_TYPE_UNSET = 0,
    OS_TYPE_ANDROID = 1,
    OS_TYPE_IOS = 2,
    OS_TYPE_IPAD_OS = 3,
    OS_TYPE_MAC_OS = 4,
    OS_TYPE_WINDOWS = 5,
    OS_TYPE_LINUX = 6,
    UNRECOGNIZED = -1
}
export enum RankingData_ConnectivityType {
    CONNECTIVITY_TYPE_UNSET = 0,
    CONNECTIVITY_TYPE_WIFI = 1,
    CONNECTIVITY_TYPE_MOBILE = 2,
    CONNECTIVITY_TYPE_UNREACHABLE = 3,
    CONNECTIVITY_TYPE_BLUETOOTH = 4,
    UNRECOGNIZED = -1
}
function createBaseRankingData(): RankingData {
    return { sessionId: "", locale: "", osType: 0, connectivityType: 0 };
}
export const RankingData = {
    encode(message: RankingData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.sessionId !== "") {
            writer.uint32(10).string(message.sessionId);
        }
        if (message.locale !== "") {
            writer.uint32(18).string(message.locale);
        }
        if (message.osType !== 0) {
            writer.uint32(24).int32(message.osType);
        }
        if (message.connectivityType !== 0) {
            writer.uint32(32).int32(message.connectivityType);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): RankingData {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRankingData();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.sessionId = reader.string();
                    break;
                case 2:
                    message.locale = reader.string();
                    break;
                case 3:
                    message.osType = reader.int32() as any;
                    break;
                case 4:
                    message.connectivityType = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<RankingData>, I>>(object: I): RankingData {
        const message = createBaseRankingData();
        message.sessionId = object.sessionId ?? "";
        message.locale = object.locale ?? "";
        message.osType = object.osType ?? 0;
        message.connectivityType = object.connectivityType ?? 0;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
