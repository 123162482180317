/**
 * Modifies the provided request object to include a priority field if the lowPriority flag is true.
 * Returns the request object unchanged if lowPriority is false.
 *
 * @internal
 *
 * @param request The request object to be potentially modified.
 * @param lowPriority A boolean flag indicating whether to add a priority field to the request object.
 * @returns The modified request object with a priority field if lowPriority is true,
 *          or the original request object if lowPriority is false.
 */
export function withRequestPriority(request: RequestInit, lowPriority: boolean | undefined): RequestInit {
    if (lowPriority) {
        // Safety: priority is defined in some browsers: https://developer.mozilla.org/en-US/docs/Web/API/fetch
        return { ...request, priority: "low" } as RequestInit;
    }
    return request;
}
