export type EventOfType<K extends string, Event extends TypedCustomEvent> = Extract<Event, TypedCustomEvent<K, any>>;

/**
 * This wrapper around CustomEvent provides more descriptive type information. By using this class, the `type` property
 * of the CustomEvent will be typed as a string literal – this allows [TypedEventTarget] to provide more useful type
 * checking of events.
 */
export class TypedCustomEvent<N extends string = string, T = any> extends CustomEvent<T> {
    // @ts-ignore: The compiler complains that this property "has no initializer and is not definitely set in the
    // constructor" – we must rely on the superclass constructor to set this, because CustomEvent only implements a
    // getter for type, so if we try to do `this.type = type` we get a runtime error. We do need to re-define type,
    // though, so we can give it the specific type N instead of the less-useful string type defined by the superclass.
    readonly type: N;
    constructor(type: N, detail: T, eventInitDict: Omit<CustomEventInit<T>, "detail"> = {}) {
        super(type, { ...eventInitDict, detail });
    }
}
