/* eslint-disable max-len */
import { BrowserHeaders } from "browser-headers";
import { getCameraKitUserAgent } from "../platform/cameraKitUserAgent";
import { ensureError } from "../common/errorHelpers";
import { unionBy } from "../common/unionBy";
import { CircumstancesServiceClientImpl, GrpcWebImpl } from "../generated-proto/pb_schema/cdp/cof/circumstance_service";
import type { ConfigTargetingRequest } from "../generated-proto/pb_schema/cdp/cof/config_request";
import type { ConfigTargetingResponse } from "../generated-proto/pb_schema/cdp/cof/config_response";
import type { RequestMetadata } from "../handlers/HandlerChainBuilder";
import { HandlerChainBuilder } from "../handlers/HandlerChainBuilder";
import type { RequestStateEventTarget } from "../handlers/requestStateEmittingHandler";
import {
    dispatchRequestCompleted,
    dispatchRequestErrored,
    dispatchRequestStarted,
    requestStateEventTargetFactory,
} from "../handlers/requestStateEmittingHandler";
import { createResponseCachingHandler, staleWhileRevalidateStrategy } from "../handlers/responseCachingHandler";
import { createRetryingHandler } from "../handlers/retryingHandler";
import { createTimeoutHandler } from "../handlers/timeoutHandler";
import { IndexedDBPersistence } from "../persistence/IndexedDBPersistence";
import type { CameraKitConfiguration } from "../configuration";
import { configurationToken } from "../configuration";
import { Injectable } from "../dependency-injection/Injectable";
import { getLogger } from "../logger/logger";
import { ExpiringPersistence } from "../persistence/ExpiringPersistence";
import { convertDaysToSeconds } from "../common/time";
import type { MetricsClient } from "../clients/metricsClient";
import { metricsClientFactory } from "../clients/metricsClient";
import { Count } from "../metrics/operational/Count";

export interface Metadata {
    [key: string]: string;
}

export const COF_REQUEST_TYPE = "cof";

export type CofDimensions = { requestType: typeof COF_REQUEST_TYPE; delta: string };

const logger = getLogger("cofHandler");

/**
 * Handler chain used to make COF requests. Uses the COF client to perform the
 * requests, with retries, timeout, and caching.
 *
 * The handler will first attempt to retrieve the COF response from cache. If it is found, the result is returned
 * immediately and the cache is updated in the background. If no response is found, a COF request is made. This request
 * will retry (with exponential backoff + jitter) for 5 seconds before returning an error to the caller.
 */
export const cofHandlerFactory = Injectable(
    "cofHandler",
    [configurationToken, requestStateEventTargetFactory.token, metricsClientFactory.token] as const,
    (config: CameraKitConfiguration, requestStateEventTarget: RequestStateEventTarget, metrics: MetricsClient) => {
        const cofCache = new ExpiringPersistence<ConfigTargetingResponse>(
            () => convertDaysToSeconds(365),
            new IndexedDBPersistence({ databaseName: "COFCache" })
        );
        const getCacheKey = (r: Partial<ConfigTargetingRequest>) => JSON.stringify(r);
        // We need to wrap `targetingQuery` to create a usable Handler – the main issue is that HandlerChainBuilder
        // always adds a `signal` property to the metadata argument (second argument of the Handler), but
        // `targetingQuery` expects the second argument to only contain headers.
        return (
            new HandlerChainBuilder(
                async (
                    request: Partial<ConfigTargetingRequest>,
                    { signal, isSideEffect: _, ...metadata }: Metadata & RequestMetadata
                ) => {
                    const rpc = new GrpcWebImpl(`https://${config.apiHostname}`, {});
                    const client = new CircumstancesServiceClientImpl(rpc);
                    return new Promise<ConfigTargetingResponse>(async (resolve, reject) => {
                        if (signal) {
                            signal.addEventListener("abort", () =>
                                reject(new Error("COF request aborted by handler chain."))
                            );
                        }

                        const cachedResponse = await cofCache.retrieve(getCacheKey(request)).catch((e) => {
                            logger.warn("Unable to get COF response from cache.", e);
                            return {
                                configResultsEtag: undefined,
                                configResults: [],
                            };
                        });
                        const dimensions: CofDimensions = {
                            requestType: COF_REQUEST_TYPE,
                            delta: `${!!cachedResponse?.configResultsEtag}`,
                        };
                        const { requestId } = dispatchRequestStarted(requestStateEventTarget, { dimensions });

                        try {
                            const response = await client.targetingQuery(
                                {
                                    ...request,
                                    configResultsEtag: cachedResponse?.configResultsEtag,
                                    deltaSync: !!cachedResponse?.configResultsEtag,
                                },
                                new BrowserHeaders({
                                    authorization: `Bearer ${config.apiToken}`,
                                    "x-snap-client-user-agent": getCameraKitUserAgent(),
                                    ...metadata,
                                })
                            );

                            // NOTE: in order for cache persistance to work, we need to make the
                            // object cloneable i.e. with no methods (it appears targetingQuery()
                            // attaches toObject() to response object). Safety: We have to cast response
                            // object to a type that has toObject defined, because that is indeed
                            // what generated code has:
                            // eslint-disable-next-line max-len
                            // https://github.sc-corp.net/Snapchat/camera-kit-web-sdk/blob/8d6b4e8bfa3717b376ab197a49972a1e410851f7/packages/web-sdk/src/generated-proto/pb_schema/cdp/cof/circumstance_service.ts#L1459
                            delete (response as any).toObject;

                            // Merge the cached configs into the just-returned configs,
                            // making sure to remove any configs that are marked as deleted -- this will then get cached
                            // by the responseCachingHandler as we return up the handler chain.
                            const configResults = unionBy(
                                "configId",
                                cachedResponse?.configResults ?? [],
                                response.configResults
                            ).filter((config) => !config.delete);

                            // TODO: We hardcode status code and sizeByte values because we do not have access to
                            // underlying transport of configs-web.
                            // When this ticket is done https://jira.sc-corp.net/browse/CAMKIT-2840,
                            // we will remove this handler and benefit from existing ones.
                            const status = 200;
                            let sizeByte = 0;
                            try {
                                sizeByte = new TextEncoder().encode(JSON.stringify(response)).byteLength;
                            } finally {
                                dispatchRequestCompleted(requestStateEventTarget, {
                                    requestId,
                                    dimensions,
                                    status,
                                    sizeByte,
                                });
                            }

                            resolve({
                                ...response,
                                configResults,
                            });
                        } catch (error) {
                            dispatchRequestErrored(requestStateEventTarget, {
                                requestId,
                                dimensions,
                                error: ensureError(error),
                            });
                            reject(error);
                        }
                    });
                }
            )
                // targetingQuery() always converts failed responses into errors (unlike fetch()), so we need a custom
                // retryPredicate that retries all errors. We'll keep retrying (with backoff) for 20 seconds total
                // elapsed time before we return an error back up the chain.
                .map(createRetryingHandler({ retryPredicate: (r) => r instanceof Error }))
                // API gateway has 15 seconds timeout, so we rely on that first
                .map(createTimeoutHandler({ timeout: 20 * 1000 }))
                .map(
                    createResponseCachingHandler(
                        cofCache,
                        getCacheKey,
                        // If we have a matching response already in cache,
                        // we'll return it immediately and then update the cache in the background.
                        staleWhileRevalidateStrategy({
                            onMiss: () => {
                                metrics.setOperationalMetrics(Count.count("cache_miss", 1, { request_type: "cof" }));
                            },
                        })
                    )
                ).handler
        );
    }
);
