import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "snapchat.common";
export interface Ruid {
    type: Ruid_Type;
    stringValue: string;
    loggingIdValue: string;
}
export enum Ruid_Type {
    UNSET = 0,
    USER = 1,
    DEVICE = 2,
    WEB_CLIENT = 3,
    AD_ACCOUNT = 4,
    USERNAME = 5,
    AD_MODERATION_AD = 6,
    WEB_SNAPCHAT_USER = 7,
    INTERNAL = 8,
    AM_ORGANIZATION = 9,
    AM_MEMBER = 10,
    AM_SESSION = 11,
    AM_PROFILE = 12,
    AM_SNAPCHAT_USER = 13,
    SNAPCHAT_ADVERTISING = 14,
    AM_CLIENT = 15,
    MISCHIEF = 16,
    ARES_VISITOR = 17,
    POD_NAME = 18,
    UNRECOGNIZED = -1
}
function createBaseRuid(): Ruid {
    return { type: 0, stringValue: "", loggingIdValue: "" };
}
export const Ruid = {
    encode(message: Ruid, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.stringValue !== "") {
            writer.uint32(18).string(message.stringValue);
        }
        if (message.loggingIdValue !== "") {
            writer.uint32(26).string(message.loggingIdValue);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Ruid {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseRuid();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32() as any;
                    break;
                case 2:
                    message.stringValue = reader.string();
                    break;
                case 3:
                    message.loggingIdValue = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Ruid>, I>>(object: I): Ruid {
        const message = createBaseRuid();
        message.type = object.type ?? 0;
        message.stringValue = object.stringValue ?? "";
        message.loggingIdValue = object.loggingIdValue ?? "";
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
