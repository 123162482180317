import { Injectable } from "../dependency-injection/Injectable";
import { LensesDefinition } from "../generated-proto/pb_schema/camera_kit/v3/service";
import type { TsProtoServiceClient } from "./createTsProtoClient";
import { createTsProtoClient } from "./createTsProtoClient";
import type { GrpcHandler } from "./grpcHandler";
import { grpcHandlerFactory } from "./grpcHandler";

export type LensesClient = TsProtoServiceClient<typeof LensesDefinition>;

export const lensesClientFactory = Injectable(
    "lensesClient",
    [grpcHandlerFactory.token] as const,
    (grpcHandler: GrpcHandler): LensesClient => createTsProtoClient(LensesDefinition, grpcHandler)
);
