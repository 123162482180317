/**
 * Different persistence mechanisms (e.g. localStorage, IndexedDB) may support different key types. This type should be
 * a valid key across all concrete Persistence implementations.
 */
export type ValidKey = string | number;

export const isValidKey = (key: unknown): key is ValidKey => typeof key === "string" || typeof key === "number";

/**
 * The abstract Persistence class defines an async interface for interacting with persistent client-side storage. This
 * is modeled as a simple key/value store.
 *
 * Subclasses may be implemented to support a variety of persistence mechanisms – e.g. localStorage, IndexedDB, etc.
 */
export abstract class Persistence<T> {
    abstract size: number;

    abstract retrieve(key: ValidKey): Promise<T | undefined>;

    abstract retrieveAll(): Promise<Array<[ValidKey, T]>>;

    abstract remove(key: ValidKey): Promise<void>;

    abstract removeAll(): Promise<T[]>;

    /**
     * Persistence implementations must be capable of generating unique keys, or accepting a unique key from the
     * caller.
     */
    abstract store(value: T): Promise<ValidKey>;
    abstract store(key: ValidKey, value: T): Promise<ValidKey>;
}
