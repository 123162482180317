import Long from "long";
import _m0 from "protobufjs/minimal";
import { BenchmarkName } from "./benchmark_name";
import { Timestamp } from "../../google/protobuf/timestamp";
export const protobufPackage = "snapchat.cdp.cof";
export interface BenchmarkValue {
    intValue: number | undefined;
    longValue: string | undefined;
    boolValue: boolean | undefined;
    floatValue: number | undefined;
}
export interface BenchmarkRequest {
    benchmarkNames: BenchmarkName[];
    expirationTimestamp: Date | undefined;
}
export interface BenchmarkResult {
    name: BenchmarkName;
    value: BenchmarkValue | undefined;
}
function createBaseBenchmarkValue(): BenchmarkValue {
    return { intValue: undefined, longValue: undefined, boolValue: undefined, floatValue: undefined };
}
export const BenchmarkValue = {
    encode(message: BenchmarkValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.intValue !== undefined) {
            writer.uint32(8).int32(message.intValue);
        }
        if (message.longValue !== undefined) {
            writer.uint32(16).int64(message.longValue);
        }
        if (message.boolValue !== undefined) {
            writer.uint32(24).bool(message.boolValue);
        }
        if (message.floatValue !== undefined) {
            writer.uint32(37).float(message.floatValue);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): BenchmarkValue {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBenchmarkValue();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.intValue = reader.int32();
                    break;
                case 2:
                    message.longValue = longToString(reader.int64() as Long);
                    break;
                case 3:
                    message.boolValue = reader.bool();
                    break;
                case 4:
                    message.floatValue = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<BenchmarkValue>, I>>(object: I): BenchmarkValue {
        const message = createBaseBenchmarkValue();
        message.intValue = object.intValue ?? undefined;
        message.longValue = object.longValue ?? undefined;
        message.boolValue = object.boolValue ?? undefined;
        message.floatValue = object.floatValue ?? undefined;
        return message;
    },
};
function createBaseBenchmarkRequest(): BenchmarkRequest {
    return { benchmarkNames: [], expirationTimestamp: undefined };
}
export const BenchmarkRequest = {
    encode(message: BenchmarkRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        writer.uint32(10).fork();
        for (const v of message.benchmarkNames) {
            writer.int32(v);
        }
        writer.ldelim();
        if (message.expirationTimestamp !== undefined) {
            Timestamp.encode(toTimestamp(message.expirationTimestamp), writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): BenchmarkRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBenchmarkRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if ((tag & 7) === 2) {
                        const end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2) {
                            message.benchmarkNames.push(reader.int32() as any);
                        }
                    }
                    else {
                        message.benchmarkNames.push(reader.int32() as any);
                    }
                    break;
                case 2:
                    message.expirationTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<BenchmarkRequest>, I>>(object: I): BenchmarkRequest {
        const message = createBaseBenchmarkRequest();
        message.benchmarkNames = object.benchmarkNames?.map((e) => e) || [];
        message.expirationTimestamp = object.expirationTimestamp ?? undefined;
        return message;
    },
};
function createBaseBenchmarkResult(): BenchmarkResult {
    return { name: 0, value: undefined };
}
export const BenchmarkResult = {
    encode(message: BenchmarkResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.name !== 0) {
            writer.uint32(8).int32(message.name);
        }
        if (message.value !== undefined) {
            BenchmarkValue.encode(message.value, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): BenchmarkResult {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseBenchmarkResult();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.name = reader.int32() as any;
                    break;
                case 2:
                    message.value = BenchmarkValue.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<BenchmarkResult>, I>>(object: I): BenchmarkResult {
        const message = createBaseBenchmarkResult();
        message.name = object.name ?? 0;
        message.value =
            object.value !== undefined && object.value !== null ? BenchmarkValue.fromPartial(object.value) : undefined;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function toTimestamp(date: Date): Timestamp {
    const seconds = Math.trunc(date.getTime() / 1000).toString();
    const nanos = (date.getTime() % 1000) * 1000000;
    return { seconds, nanos };
}
function fromTimestamp(t: Timestamp): Date {
    let millis = Number(t.seconds) * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
function longToString(long: Long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
