import Long from "long";
import _m0 from "protobufjs/minimal";
import { Geocircle } from "./geocircle";
export const protobufPackage = "snapchat.lenses";
export interface Lures {
    locations: Geocircle[];
    activeLure: Geocircle | undefined;
    closestLure: Geocircle | undefined;
}
function createBaseLures(): Lures {
    return { locations: [], activeLure: undefined, closestLure: undefined };
}
export const Lures = {
    encode(message: Lures, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.locations) {
            Geocircle.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        if (message.activeLure !== undefined) {
            Geocircle.encode(message.activeLure, writer.uint32(18).fork()).ldelim();
        }
        if (message.closestLure !== undefined) {
            Geocircle.encode(message.closestLure, writer.uint32(26).fork()).ldelim();
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): Lures {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseLures();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.locations.push(Geocircle.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.activeLure = Geocircle.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.closestLure = Geocircle.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<Lures>, I>>(object: I): Lures {
        const message = createBaseLures();
        message.locations = object.locations?.map((e) => Geocircle.fromPartial(e)) || [];
        message.activeLure =
            object.activeLure !== undefined && object.activeLure !== null
                ? Geocircle.fromPartial(object.activeLure)
                : undefined;
        message.closestLure =
            object.closestLure !== undefined && object.closestLure !== null
                ? Geocircle.fromPartial(object.closestLure)
                : undefined;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
