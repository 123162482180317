import type { MetricsClient } from "../../clients/metricsClient";
import { metricsClientFactory } from "../../clients/metricsClient";
import { Injectable } from "../../dependency-injection/Injectable";
import { getPlatformCapabilities } from "../../platform/platformCapabilities";
import { Count } from "../operational/Count";
import { joinMetricNames } from "../operational/Metric";

/**
 * Report the number of total page loads that have support for various capabilities. By dividing by the total number of
 * data points recorded, we can calculate the percent of page loads with support.
 *
 * @internal
 */
export const reportPlatformCapabilities = Injectable(
    "reportPlatformCapabilities",
    [metricsClientFactory.token] as const,
    async (metrics: MetricsClient) => {
        const { webgl, wasm, webxr } = await getPlatformCapabilities();
        metrics.setOperationalMetrics(Count.count(joinMetricNames(["platform", "webgl"]), webgl.supported ? 1 : 0));
        metrics.setOperationalMetrics(Count.count(joinMetricNames(["platform", "wasm"]), wasm.supported ? 1 : 0));
        metrics.setOperationalMetrics(Count.count(joinMetricNames(["platform", "webxr"]), webxr.supported ? 1 : 0));
    }
);
