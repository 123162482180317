import Long from "long";
import _m0 from "protobufjs/minimal";
export const protobufPackage = "com.snap.camerakit.v3";
export enum CameraKitFlavor {
    CAMERA_KIT_FLAVOR_UNSET = 0,
    CAMERA_KIT_FLAVOR_DEBUG = 1,
    CAMERA_KIT_FLAVOR_RELEASE = 2,
    UNRECOGNIZED = -1
}
export enum CameraKitConnectivityType {
    CAMERA_KIT_CONNECTIVITY_TYPE_UNSET = 0,
    CAMERA_KIT_CONNECTIVITY_TYPE_WIFI = 1,
    CAMERA_KIT_CONNECTIVITY_TYPE_MOBILE = 2,
    CAMERA_KIT_CONNECTIVITY_TYPE_UNREACHABLE = 3,
    CAMERA_KIT_CONNECTIVITY_TYPE_BLUETOOTH = 4,
    UNRECOGNIZED = -1
}
export enum CameraKitEnvironment {
    CAMERA_KIT_ENVIRONMENT_UNSET = 0,
    CAMERA_KIT_ENVIRONMENT_STAGING = 1,
    CAMERA_KIT_ENVIRONMENT_PRODUCTION = 2,
    UNRECOGNIZED = -1
}
export interface ExtensionEventBase {
    extensionName: string;
    extensionVersion: string;
    deviceCluster: string;
    cameraKitVersion: string;
    lensCoreVersion: string;
    deviceModel: string;
    cameraKitFlavor: CameraKitFlavor;
    appId: string;
    deviceConnectivity: CameraKitConnectivityType;
    sessionId: string;
    cameraKitEnvironment: CameraKitEnvironment;
}
function createBaseExtensionEventBase(): ExtensionEventBase {
    return {
        extensionName: "",
        extensionVersion: "",
        deviceCluster: "0",
        cameraKitVersion: "",
        lensCoreVersion: "",
        deviceModel: "",
        cameraKitFlavor: 0,
        appId: "",
        deviceConnectivity: 0,
        sessionId: "",
        cameraKitEnvironment: 0,
    };
}
export const ExtensionEventBase = {
    encode(message: ExtensionEventBase, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.extensionName !== "") {
            writer.uint32(10).string(message.extensionName);
        }
        if (message.extensionVersion !== "") {
            writer.uint32(18).string(message.extensionVersion);
        }
        if (message.deviceCluster !== "0") {
            writer.uint32(24).int64(message.deviceCluster);
        }
        if (message.cameraKitVersion !== "") {
            writer.uint32(34).string(message.cameraKitVersion);
        }
        if (message.lensCoreVersion !== "") {
            writer.uint32(42).string(message.lensCoreVersion);
        }
        if (message.deviceModel !== "") {
            writer.uint32(50).string(message.deviceModel);
        }
        if (message.cameraKitFlavor !== 0) {
            writer.uint32(56).int32(message.cameraKitFlavor);
        }
        if (message.appId !== "") {
            writer.uint32(66).string(message.appId);
        }
        if (message.deviceConnectivity !== 0) {
            writer.uint32(72).int32(message.deviceConnectivity);
        }
        if (message.sessionId !== "") {
            writer.uint32(82).string(message.sessionId);
        }
        if (message.cameraKitEnvironment !== 0) {
            writer.uint32(88).int32(message.cameraKitEnvironment);
        }
        return writer;
    },
    decode(input: _m0.Reader | Uint8Array, length?: number): ExtensionEventBase {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseExtensionEventBase();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.extensionName = reader.string();
                    break;
                case 2:
                    message.extensionVersion = reader.string();
                    break;
                case 3:
                    message.deviceCluster = longToString(reader.int64() as Long);
                    break;
                case 4:
                    message.cameraKitVersion = reader.string();
                    break;
                case 5:
                    message.lensCoreVersion = reader.string();
                    break;
                case 6:
                    message.deviceModel = reader.string();
                    break;
                case 7:
                    message.cameraKitFlavor = reader.int32() as any;
                    break;
                case 8:
                    message.appId = reader.string();
                    break;
                case 9:
                    message.deviceConnectivity = reader.int32() as any;
                    break;
                case 10:
                    message.sessionId = reader.string();
                    break;
                case 11:
                    message.cameraKitEnvironment = reader.int32() as any;
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },
    fromPartial<I extends Exact<DeepPartial<ExtensionEventBase>, I>>(object: I): ExtensionEventBase {
        const message = createBaseExtensionEventBase();
        message.extensionName = object.extensionName ?? "";
        message.extensionVersion = object.extensionVersion ?? "";
        message.deviceCluster = object.deviceCluster ?? "0";
        message.cameraKitVersion = object.cameraKitVersion ?? "";
        message.lensCoreVersion = object.lensCoreVersion ?? "";
        message.deviceModel = object.deviceModel ?? "";
        message.cameraKitFlavor = object.cameraKitFlavor ?? 0;
        message.appId = object.appId ?? "";
        message.deviceConnectivity = object.deviceConnectivity ?? 0;
        message.sessionId = object.sessionId ?? "";
        message.cameraKitEnvironment = object.cameraKitEnvironment ?? 0;
        return message;
    },
};
type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin ? T : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>> : T extends {
    $case: string;
} ? {
    [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]>;
} & {
    $case: T["$case"];
} : T extends {} ? {
    [K in keyof T]?: DeepPartial<T[K]>;
} : Partial<T>;
type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P : P & {
    [K in keyof P]: Exact<P[K], I[K]>;
} & Record<Exclude<keyof I, KeysOfUnion<P>>, never>;
function longToString(long: Long) {
    return long.toString();
}
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}
