/**
 * Assert a particular code path is unreachable, e.g. to perform a compile-time exhaustiveness check.
 *
 * Ex:
 * ```ts
 * interface A { type: 'a' }
 * interface B { type: 'b' }
 * type TaggedUnion = A | B
 *
 * function exhaustiveSwitch(value: TaggedUnion) {
 *   switch (value.type) {
 *     case 'a': // do something with A
 *     case 'b': // do something with B
 *     // this will fail to compile if we add another type to TaggedUnion and forget to add a corresponding case
 *     // to the switch statement.
 *     default: assertUnreachable(value)
 *   }
 * }
 * ```
 */
export function assertUnreachable(_: never): never {
    throw new Error("Reached unreachable code at runtime.");
}

/**
 * Assert that a condition is true. Otherwise, throws an error.
 * @param condition Condition to test.
 * @param error Optional error message or error instance to throw.
 */
export function assert(condition: boolean, error: string | Error = "Assertion failed"): asserts condition {
    if (!condition) {
        if (error instanceof Error) {
            throw error;
        } else {
            throw new Error(error);
        }
    }
}
