import type { MetricsClient } from "../../clients/metricsClient";
import { metricsClientFactory } from "../../clients/metricsClient";
import { assertUnreachable } from "../../common/assertions";
import { stringifyError } from "../../common/errorHelpers";
import { Injectable } from "../../dependency-injection/Injectable";
import { scan } from "../../events/scan";
import { TypedCustomEvent } from "../../events/TypedCustomEvent";
import type { Dimensions, RequestStateEventTarget } from "../../handlers/requestStateEmittingHandler";
import { requestStateEventTargetFactory } from "../../handlers/requestStateEmittingHandler";
import type { MakeTaggedBusinessEvent } from "../businessEventsReporter";
import type { CameraKitMetricEvents, MetricsEventTarget } from "../metricsEventTarget";
import { metricsEventTargetFactory } from "../metricsEventTarget";
import { Count } from "../operational/Count";
import type { Timer } from "../operational/Timer";

type InProgressMap = Map<number, { timer: Timer<string> }>;
interface InProgress {
    name: "inProgress";
    inProgress: InProgressMap;
}
interface Completed {
    name: "completed";
    inProgress: InProgressMap;
    event: CameraKitMetricEvents;
}
type RequestState = InProgress | Completed;

const relevantRequestTypes = ["lens_content", "asset"] as const;
export const isLensOrAssetRequest = (value: Dimensions): value is LensDownloadDimensions | AssetDownloadDimensions => {
    const requestType = value["requestType"];
    // Safety: the cast makes the type less specific so we can check if any string is present in the tuple.
    return typeof requestType === "string" && (relevantRequestTypes as readonly string[]).includes(requestType);
};

/**
 * The LensDownload metric is triggered by any download of lens content.
 *
 * It contains download stats, which lens was requested, and whether prefetch was used.
 *
 * It corresponds to the internal CameraKitLensDownload event, described here:
 * https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY/edit#heading=h.stqom49qs91t
 */
export type LensDownload = MakeTaggedBusinessEvent<"lensDownload">;
export type LensDownloadDimensions = { requestType: "lens_content"; lensId: string };

/**
 * The AssetDownload metric is triggered by any type of asset download.
 *
 * It contains download stats, which asset was requested, and whether prefetch was used.
 *
 * It corresponds to the internal CameraKitAssetDownload event, described here:
 * https://docs.google.com/document/d/1-kSzFWCWw9Qo3D08FR1_cqeHTsUtk9p3p3uOptzWDTY/edit#heading=h.vlormd1724fp
 */
export type AssetDownload = MakeTaggedBusinessEvent<"assetDownload">;
export type AssetDownloadDimensions = { requestType: "asset"; assetType: string; assetId: string; lensId: string };

export const reportLensAndAssetDownload = Injectable(
    "reportLensAndAssetDownload",
    [metricsEventTargetFactory.token, metricsClientFactory.token, requestStateEventTargetFactory.token] as const,
    (
        metricsEventTarget: MetricsEventTarget,
        metrics: MetricsClient,
        requestStateEventTarget: RequestStateEventTarget
    ) => {
        scan<RequestState>({ name: "inProgress", inProgress: new Map() })(
            requestStateEventTarget,
            ["started", "completed", "errored"],
            (state, event): RequestState => {
                const { inProgress } = state;
                const { dimensions, requestId } = event.detail;

                if (!isLensOrAssetRequest(dimensions)) return state;

                switch (event.type) {
                    case "started":
                        const timer = event.detail.timer;
                        inProgress.set(requestId, { timer });
                        return { name: "inProgress", inProgress };
                    case "completed":
                        const completedRequest = inProgress.get(requestId);
                        if (!completedRequest) return state;
                        inProgress.delete(requestId);

                        const { duration } = completedRequest.timer.measure() ?? { duration: 0 };
                        const downloadTimeSec = duration / 1000;
                        const { sizeByte } = event.detail;

                        switch (dimensions.requestType) {
                            case "lens_content":
                                return {
                                    name: "completed",
                                    inProgress,
                                    event: new TypedCustomEvent("lensDownload", {
                                        name: "lensDownload",
                                        lensId: dimensions.lensId,
                                        automaticDownload: false,
                                        sizeByte: `${Math.ceil(sizeByte)}`,
                                        downloadTimeSec,
                                    }),
                                };
                            case "asset":
                                return {
                                    name: "completed",
                                    inProgress,
                                    event: new TypedCustomEvent("assetDownload", {
                                        name: "assetDownload",
                                        assetId: dimensions.assetId,
                                        automaticDownload: false,
                                        sizeByte: `${Math.ceil(sizeByte)}`,
                                        downloadTimeSec,
                                    }),
                                };
                            default:
                                assertUnreachable(dimensions);
                        }
                    case "errored":
                        const erroredRequest = inProgress.get(requestId);
                        if (!erroredRequest) return state;
                        inProgress.delete(requestId);
                        const error = event.detail.error;
                        return {
                            name: "completed",
                            inProgress,
                            event: new TypedCustomEvent("exception", {
                                name: "exception",
                                lensId: dimensions.lensId,
                                type: dimensions.requestType === "lens_content" ? "lens" : "asset",
                                reason: stringifyError(error),
                            }),
                        };
                    default:
                        assertUnreachable(event);
                }
            }
        ).addEventListener("state", ({ detail: state }) => {
            if (state.name !== "completed") return;
            metricsEventTarget.dispatchEvent(state.event);
            if (state.event.detail.name === "exception") {
                metrics.setOperationalMetrics(Count.count("handled_exception", 1, { type: state.event.detail.type }));
            }
        });
    }
);
