import { getPlatformCapabilities } from "./platformCapabilities";

/**
 * Throw if the current platform is not capable of running Camera Kit.
 */
export async function assertPlatformSupported(): Promise<void> {
    const { wasm, webgl } = await getPlatformCapabilities();
    if (!wasm.supported) throw wasm.error;
    if (!webgl.supported) throw webgl.error;
}
